<template>
    <div class="container">
      <div class="question_set">答疑服务</div>
      <div class="content_div">
        <div class="det_header">
          <div class="det_left">
            <div></div>
            我的提问
          </div>
          <div class="det_right">
            <img src="../../assets/images/slices/backi.png" alt="" />
            返回
          </div>
        </div>
        <div class="calinfo">
          <div class="cal_name">
            为什么我国非公有制经济主要包括个体经济、私营经济？
          </div>
          <div class="subjinfo">
            <div class="stem latexDiv"><span class="ti_tpye">单选题</span></div>
            <div class="selects">
            <div>
                <!--选项-->
                <!-- <img :src="answer.icon" style="max-width: 100%;height: auto;"> -->
                <!-- <div class="select_list" :class="zqcurrent == index ? 'correct' : cwcurrent == index ? 'error' : ''"
                    v-for="(item, index) in answer.option_name" :key="index" @click="selectClcik(index)">
                    <div class="tag">{{ index }}</div>
                    <div class="cont"
                        v-if="item.indexOf('.jp') !== -1 || item.indexOf('.png') !== -1 || item.indexOf('.gif') !== -1">
                        <img :src="item" style="max-height: 56px;">
                    </div>
                    <div class="cont latexDiv" v-else>{{ item }}</div>
                </div> -->
            </div>
                <div class="queyes">
                    正确答案： <span>A,B</span>
                </div>
                <div class="queyes">
                    答案分析：
                </div>
                <div class="fenxis">
                    答案分析答案分析答案分析答案分析答案分析答案分析答案分析答案分析答案分析答
                    案分析答案分析答案分析答案分析答案分析答案分析答案分析答案分析答案分析答案分析答案
                    分析答案分析答案分析答案分析答案分析答案分析答案分析答案分析答案分析答案分析答案分析答案
                    分析答案分析答案分析答案分析答案分析答案分析答案分析答案分析答案分析答案分析答案分析答案分析答案分析答案分析答案分析
                </div>
			</div>
        </div>
        </div>
        <div class="calwog">
          <div class="woglist">
            <div class="record-wrapper">
              <div class="time"></div>
              <div class="msg msg-right">
                <div class="img-wrapper">
                 <div class="hed_name">
                     <div style="text-align: right;">王老师</div>
                     <div>2024-01-01</div>
                 </div>
                 <div class="head_img">
                  <img class="img" :src="from1" />
                  <div>已解答</div>
                 </div>
                </div>
                <div class="message-wrapper message-wrapper-right">
                  <div class="message">11111111</div>
                </div>
              </div>
  
              <div class="msg msg-left">
                <div class="img-wrapper">
                 <div class="head_img">
                  <img class="img" :src="from1" />
                  <div>已解答</div>
                 </div>
                 <div class="hed_name">
                     <div>王老师</div>
                     <div>2024-01-01</div>
                 </div>
                </div>
                <div class="message-wrapper message-wrapper-left">
                  <div class="message">messa111111111ge</div>
                </div>
              </div>
            </div>
                  <!--  -->
           <div class="gaiws">该问题是否 <span>已解决</span>?</div>
           <el-input
              type="textarea"
              placeholder="请输入内容"
              :rows="10"
              v-model="textarea"
              maxlength="1000"
              show-word-limit
            >
            </el-input>
            <div class="tibtn">
              <el-button type="primary">提交</el-button>
            </div>
          </div>
        </div>
      </div>
      <!-- 评分 -->
      <div class="ask_question" v-if="rateShow">
          <div class="ask_center">
             <div class="ackname">
                <div>感谢您的提问</div>
                <div>老师答题不易，请给这位老师评分吧！</div>
             </div>
             <div>
              <div class="rates">
                <div class="rates_title">评分</div>
                <div><el-rate v-model="value1"></el-rate></div>
              </div>
              <div class="hbtns"><el-button type="primary">主要按钮</el-button></div>
             </div>
          </div>
      </div>
    </div>
  </template>
    
    <script>
  import { getQuestionList } from "@/utils/api.js";
  export default {
    components: {},
    data() {
      return {
        from1: require("../../assets/images/slices/yiwen.png"),
        from2: require("../../assets/images/slices/yiwen.png"),
        from: 2, // 1: 自己    2: 别人
        message: "",
        timestamp: "1",
        value1:3,
        rateShow:false,
      };
    },
    created() {},
  
    methods: {},
  };
  </script>
    <style scoped="scoped" lang="scss">
    .queyes{
        margin: 15px 0;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #666666;
        span{
            color: #5694F7;
        }
    }
    .fenxis{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 26px;
        margin-top: -10px;
    }
    .subjinfo{
        margin-top: 26px;
    }
    /deep/.el-rate__icon{
      font-size: 44px;
    }
    .hbtns{
      display: flex;
      justify-content: center;
    }
    .rates{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px 0;
        div{
          height: 60px;
        }
      .rates_title{
        font-size: 22px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-right: 10px;
        padding-top: 8px;
      }
    }
    .ask_question{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #00000040;
    display: flex;
    align-items: center;
    justify-content: center;
    .ask_center{
      width: 530px;
      height: 345px;
      background: #FFFFFF;
      border-radius: 20px;
      padding: 20px;
      .ackname{
        padding: 30px 0;
        margin: 0 auto;
        border-bottom: 1px solid #F4F5F6;
      }
      .ackname div:first-child{
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
        text-align: center;
      }
      .ackname div:last-child{
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        text-align: center;
        margin-top: 26px;
      }
    }
  }
    // 
    .gaiws{
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin: 30px 0;
      span{
        color: #3C87F2;
      }
    }
    .tibtn{
      width: 100%;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    // 
    .img-wrapper{
      display: flex;
      .head_img{
        width: 50px;
        img{
          width: 37px;
          height: 37px;
          margin-left: 6px;
        }
        div{
          width: 50px;
          height: 20px;
          background: #3c88f215;
          border-radius: 2px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #3C87F2;
          margin-top: 14px;
          text-align: center;
          line-height: 20px;
        }
      }
      .hed_name div:first-child{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      .hed_name div:last-child{
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
    }
  .record-wrapper {
    margin: 4px;
    padding: 4px;
  }
  .time {
    text-align: center;
  }
  .msg {
    display: flex;
    flex-direction: row;
  
    .message-wrapper {
      max-width: 220px;
  
      margin: 0px 10px 0px 10px;
  
      .message {
        margin: 8px;
        word-wrap: break-word; //英文换行
      }
    }
  
    .message-wrapper-left {
      background-color: #ffffff;
      border-radius: 0px 12px 12px 12px;
      border: 1px solid #f3f3f3;
      height: max-content;
      padding:8px 15px;
    }
  
    .message-wrapper-right {
      background-color: #3c88f253;
      border-radius: 12px 0px 12px 12px;
      height: max-content;
      padding:8px 15px;
    }
  
    .img {
      flex: auto;
      height: 36px;
      width: 36px;
      border-radius: 8px;
    }
  }
  .msg-right {
    flex-direction: row-reverse;
  }
  .msg-left {
    flex-direction: row;
  }
  .woglist {
    padding: 20px;
  }
  .calwog {
    width: 1127px;
    background: #fafafa;
    border-radius: 10px;
    margin-top: 40px;
  }
  .calinfo {
    margin-top: 30px;
    padding-bottom: 40px;
    border-bottom: 1px solid #eaeef1;
    .cal_name {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
    .cal_img {
      margin-top: 20px;
      display: flex;
      align-items: center;
      img {
        width: 70px;
        height: 70px;
        background: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 8px;
        margin-right: 10px;
      }
    }
  }
  .content_div {
    width: 1187px;
    background: #ffffff;
    border-radius: 10px;
    margin-top: 25px;
    margin-bottom: 20px;
    padding: 30px 50px;
    .det_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .det_left {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        div {
          width: 5px;
          height: 20px;
          background: #5694f7;
          margin-right: 15px;
        }
      }
      .det_right {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        img {
          width: 18px;
          height: 14px;
          margin-right: 9px;
        }
      }
    }
  }
  .question_set {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #393f47;
  }
  .ti_tpye {
	display: inline;
	padding: 2px 10px;
	margin-right: 10px;
	background: #EEF5FF;
	border-radius: 5px !important;
	font-size: 14px !important;
	color: #5694F7 !important;
}

.stem {
	position: relative;
	font-size: 20px;
	color: #333;
	line-height: 1.5;
}
.tiyhtm{
	width: 80%;
}
.stem span {
	font-size: 20px;
	color: #333;
}
  </style>