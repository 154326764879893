<template>
	<div class="answer" ref="answers">
		<div style="display:flex;justify-content: flex-start;">
			<!--序号-->
			<div class="stem_box">
				<template v-if="answer.genre == '6'">
					<!--题干-->
					<div class="stem">
						<span class="ti_tpye">{{answer.genre_name ||'选择题'}}</span>
						<span v-for="(item,index) in answer.titles" :key="index"  class="latexDiv">
							{{item}}
							<span v-if="index+1 !==answer.titles.length">（
							</span>

							<input class="inputs" :class="answer.correct == 'no' ? 'errinputs':''"
								v-model="inputModle[index]" :disabled="!disbade"
								:style="{width:text(inputModle[index])}" v-if="index+1 !== answer.titles.length" />
							<span v-if="index+1 !==answer.titles.length">）</span>
						</span>
					</div>
				</template>
				<template v-else>
					<div class="stem"><span class="ti_tpye latexDiv">{{answer.genre_name ||'选择题'}}</span><span class="tiyhtm" v-html="answer.title"></span></div>
				</template>
				<div class="selects">
					<template v-if="answer.genre == '1'||answer.genre == '3'">
						<img :src="answer.icon" style="max-width: 100%;height: auto;">
						<!--选项-->
						<div class="select_list" :class="zqcurrent == index ? 'correct':cwcurrent == index ? 'error':''"
							v-for="(item,index) in answer.option_name" :key="index">
							<!-- {{item}} -->
							<div class="tag">{{index}}</div>
							<div class="cont" v-if="item.indexOf('.jp') !== -1||item.indexOf('.png') !== -1||item.indexOf('.gif') !== -1">
								<img :src="item" style="max-height: 56px;">
							</div>
							<div class="cont latexDiv" v-else>{{item}}</div>
						</div>
					</template>
					<template v-if="answer.genre == '2'">
						<img :src="answer.icon" style="max-width: 100%;height: auto;">
						<!-- <div class="select_list" v-for="(item,index) in answer.select" :class="{correct:selectArr.includes(index)}" :key="index"  @click="selectClcik(index)"> -->
						<div class="select_list" v-for="(item,index) in answer.option_name"
						:class="cwArr.includes(index) ? selectArr.includes(index) ?'error':'zqrect' : selectArr.includes(index) ? 'correctx': ''"
							:key="index">
							<div class="tag"><span>{{index}}</span></div>
							<div class="cont" v-if="item.indexOf('.jp') !== -1||item.indexOf('.png') !== -1||item.indexOf('.gif') !== -1">
								<img :src="item" style="max-height: 56px;">
							</div>
							<div class="cont latexDiv" v-else>{{item}}</div>
						</div>
					</template>
					<template v-if="answer.genre == '4' || answer.genre == '8' || answer.genre == '7'">
						<img :src="answer.icon" style="max-width: 100%;height: auto;">
						<div v-if="zqcurrent == 1 && cwcurrent !==2">
							<div class="select_list" :class="zqcurrent == 1 ? 'correct':''">
								<!-- {{item}} -->
								<div class="tag">A</div>
								<div class="cont">会</div>
							</div>
							<div class="select_list">
								<!-- {{item}} -->
								<div class="tag">B</div>
								<div class="cont">不会</div>
							</div>
						</div>
						<div v-else>
							<img :src="answer.icon" style="max-width: 100%;height: auto;">
							<div class="select_list" :class="zqcurrent == 1 ? 'correct':''">
								<!-- {{item}} -->
								<div class="tag">A</div>
								<div class="cont">会</div>
							</div>
							<div class="select_list" :class="cwcurrent == 2 ? 'error':''">
								<!-- {{item}} -->
								<div class="tag">B</div>
								<div class="cont">不会</div>
							</div>
						</div>
					</template>
				</div>
				<div class="btns">
					<div></div>
					<div class="buttons">
						<!-- <div class="btn" style="background:#47BE5E "
							v-if="answer.genre == '2' && !answer.correct">提交</div>

							<div class="btn" :style="index == 0?'background:#ffffff;color:#5694F7':''" @click="nextAnswer(false)">上一题
						</div> -->
						<div class="btn" :style="index + 1 == length?'background:#ffffff;color:#5694F7':''" @click="nextAnswer(true)">
							下一题</div>
					</div>
				</div>
				<div class="answer_cards">
					<div class="js_title">正确答案：
						<template v-if="answer.genre_name == '填空题'">
							<span class="blue latexDiv">{{answer.answer}}</span>
						</template>
						<template v-else>
							<span class="blue latexDiv">{{answer.answer}}</span>
						</template>
					</div>
					<div class="js_title">
						你的答案：
						<template v-if="answer.genre_name == '填空题'">
							<span class="js_answer latexDiv"
								:style="answer.answer == answer.user_answer ?'color:#47BE5E':'color:#FF684B'">{{answer.user_answer?answer.user_answer:'未作答'}}</span>
						</template>
						<template v-else>
							<span class="js_answer latexDiv"

								:style="answer.answer == answer.user_answer ?'color:#47BE5E':'color:#FF684B'">{{answer.user_answer?answer.user_answer:'未作答'}}</span>
						</template>
					</div>
					<div class="js_title">答题用时：<span class="js_time">{{timeCli(answer.ansTimer)}}</span></div>
				</div>
				<div class="slid_box">
					<el-collapse-transition>
						<div class="slid" v-show="slideShow">
							<div class="slid_title">
								<div class="slitte">知识点解析</div>
								<div class="siltt_div">
									<div @click="e=>{
										$refs.askru.cate_id=''
										$refs.askru.vice_id=''
										$refs.askru.dialogImageUrl=[]
										$refs.askru.question=''
										$refs.askru.askShow=true
										} "><div><img src="@/assets/qq.png" alt="">答疑</div></div>
									<div @click="collectTion()">
										<img v-if="answer.is_collect>0" style="width:20px;height:20px;margin-right:4px" src="@/assets/images/login/accelloc.png" alt="">
										<img v-else style="width:20px;height:20px;margin-right:4px" src="@/assets/images/login/celloc.png" alt="">
										收藏</div></div></div>
								<el-dialog title="纠错" :visible.sync="dialogFormVisible" top="20vh">
									<div class="errt">
										<div class="err_text">{{ answer.title }}</div>
										<textarea v-model="el_textarea" name="" id="" cols="30" rows="10" maxlength="1000" placeholder="请输入纠错原因"></textarea>
										<div class="err_btn"><button @click="submitErr(answer.list_id,answer.id)">保存</button></div>
									</div>
									
								</el-dialog>
								<div class="buzhidao_box">
								<div class="analysis">{{answer.wzjx}}</div>
								<!-- <div class="difficulty" style="">
									难度：
									<el-rate v-model="answer.diff_level" disabled :show-score='false'
										disabled-void-color="#E3E3E3" :icon-classes="shuzu" score-template="{value}">
									</el-rate>
								</div> -->
								<div class="difficulty">
									<p class="difficulty_p" v-html="answer.analysis"></p>
								</div>
								<div class="examination" v-if="answer.pivot">
									考点：<div class="examination_box latexDiv">{{ answer.pivot }}</div>
								</div>
							</div>
							<div class="slid_title" style="margin-top: 47px;"><div class="slitte">视频解析</div></div>
							<div class="slid_video">
								<!-- <img src="" alt=""> -->
							</div>
						</div>
					</el-collapse-transition>
				</div>
				<div style="height: 50px;background:#f4f6f8;width: 932px;margin-left: -40px;"></div>
				<div class="slid_bpinl">
					<div class="slid" style="margin-top: 30px;">
						<div class="slid_title">
							<div class="slitte">我的笔记</div>
						</div>
					</div>
					<div class="textare_bt">
						<textarea v-model="arevalue" name="" id="" cols="30" rows="10" maxlength="1000" placeholder="点击输入笔记"></textarea>
						<p>{{ arevalue.length }}/1000</p>
						<div @click="subNote()" v-if="arevalue!=''">提交</div>
					</div>
					<div class="pplist">
						<div class="pplist_i" v-for="(itt, index) in notelist" :key="index">
							<img :src="userInfos.avatar" alt="">
							<div class="pp_details">
								<div class="pp_nxis">
									<div class="pnx_name">
										<div class="name">{{ userInfos.name }}</div>
										<div class="time"></div>{{ itt.create_time||Ctime(itt.create_time) }}
									</div>
									<div class="xiu_btn">
										<div class="gai_btn" @click="open(itt)">修改</div>
										<div @click="getNDel(itt.id)">删除</div>
									</div>
								</div>
								<div class="shiyedx">{{ itt.content }}</div>
							</div>
						</div>
						<el-pagination
							small
							layout="prev, pager, next"
							:total="total"
							@current-change="handleCurrentChange"
							>
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
				<!--  -->
				<askQuestion ref="askru" />
	</div>
</template>

<script>
	import MathJax from '../../utils/math.js'
	import { getNoteList,addNotes,delNotes,addUpdateCollect,delCollect} from '@/utils/erpapi.js'
	import askQuestion from '@/components/answer/askquestion'
export default {
  components: {
    askQuestion
  },
		props: {
			anstype:{
			type: Number,
				default: 0,
			},
			answer: {
				type: Object,
				default: {}
			},
			index: {
				type: Number,
				default: 0,
			},
			length: {
				type: Number,
				default: 0,
			},
			source:{
			type: Number,
			default: 1,
		},
		},
		data() {
			return {
				el_textarea:'',
				dialogFormVisible:false,
				zqcurrent: null,
				cwcurrent: null,
				slideShow: true,
				shuzu: ['el-icon-star-dr el-icon-star-on', 'el-icon-star-dr el-icon-star-on',
					'el-icon-star-dr el-icon-star-on'
				],
				indexs: 0,
				selectArr: [], //多选题储存选中选项的索引值
				disbade: true,
				cwArr: [],
				inputModle: [],
				arevalue: '',
				notelist: [],
				total:10,
				userInfos: JSON.parse(localStorage.getItem('userInfo'))
			}
		},
		created() {
			console.log(this.answer)
			this.suNolist()
			if (this.answer.wd === false) {
				this.slideShow = true
				this.disbade = false
			}
			if (this.answer.genre == '2') { //多选
				let bt = this.getArrDifference(this.answer.answer, this.answer.user_answer) //找到并提取两个字符串中不同的地方并返回数组
				console.log(bt)
				console.log(this.answer.option_name)
				for (let i in this.answer.option_name) {
					for (let j in bt) {
						if (i == bt[j]) {
							this.cwArr.push(i) //找出两个数组相同的
						}
					}
				}
				console.log(this.cwArr)
				let xt = this.answer.user_answer//相同
				console.log(xt)
				for (let i in this.answer.option_name) {
					for (let j in xt) {
						if (i == xt[j]) {
							this.selectArr.push(i) //找出两个数并提取索引值
						}
					}
				}
				console.log(this.selectArr)
			} else if (this.answer.genre == '1' || this.answer.genre == '3') { // 判断和单选
				if (this.answer.answer !== this.answer.user_answer) {
					if (!this.answer.user_answer) {
						for (let i in this.answer.option_name) {
							if (i == this.answer.answer) {
								this.zqcurrent = i
							}
						}
					} else {
						for (let i in this.answer.option_name) {
							if (i == this.answer.user_answer) {
								console.log('999')
								this.cwcurrent = i
							}
						}
						for (let i in this.answer.option_name) {
							if (i == this.answer.answer) {
								this.zqcurrent = i
							}
						}
					}
				} else {
					for (let i in this.answer.option_name) {
						if (i == this.answer.answer) {
							this.zqcurrent = i
						}
					}
				}
			} else if (this.answer.genre == '6') { //填空
				if (this.answer.user_answer !== '不会') {
					this.inputModle = this.answer.user_answer.split(',')
				} else {
					this.inputModle = this.answer.answer.split(',')
				}


			} else if (this.answer.genre == '4' || this.answer.genre == '8') { //简答
				if (this.answer.answer == this.answer.user_answer) {
					this.zqcurrent = 1
				} else {
					this.zqcurrent = 1
					this.cwcurrent = 2
				}
			}else{
				console.log('111')
				if (this.answer.answer !== this.answer.user_answer) {
					if (!this.answer.user_answer) {
						for (let i in this.answer.option_name) {
							if (i == this.answer.answer) {
								this.cwcurrent = i
							}
						}
					} else {
						for (let i in this.answer.option_name) {
							if (i == this.answer.user_answer) {
								this.cwcurrent = i
							}
						}
						for (let i in this.answer.option_name) {
							if (i == this.answer.answer) {
								this.zqcurrent = i
							}
						}
					}
				} else {
					for (let i in this.answer.option_name) {
						if (i == this.answer.answer) {
							this.zqcurrent = i
						}
					}
				}
			}
		},
		methods: {
			footNmu(num){
			let remainder = num - Math.floor(num / 10) * 10;
			console.log(remainder);  // 输出为1
			if(remainder==1||remainder==2||remainder==3||remainder==6){
              return false
			}else{
				return true
			}
		},
			collectTion(){
				console.log(this.$parent.routeInfo)
				addUpdateCollect({
					checkpoint_id: (this.anstype == 6 || this.anstype == 5) ? this.$parent.checkpoint_id: '',
					content_id: (this.anstype != 6 && this.anstype != 5) ? this.$parent.content_id: '',
					type: this.$parent.routeInfo.type,
					ques_list_id: this.answer.id||this.answer.id.ques_list_id,
					source: this.source,
					topic_id: this.$parent.routeInfo.type==4?this.answer.id:'',
					status:this.answer.is_collect>0?2:1,
				}).then(res=>{
					this.answer.is_collect=res.data.id?res.data.id:0
					console.log(this.answer.is_collect)
					this.$forceUpdate()
				})
		},
			submitErr(list_id,id){
				if(this.el_textarea==''){
					this.$message({
							message: '请输入纠错内容',
							type: 'warning'
						});
						return
				}
				examCorrection({list_id:list_id||id,
					content:this.el_textarea}).then(res => {
						if(res.code==200){
							this.$message({
							message: '已提交',
							type: 'success'
						});
					   this.el_textarea=''
					   this.dialogFormVisible=false
					}

				})
			},
			handleCurrentChange(val){
           this.suNolist(val)
		},
		// 时间处理
		timeCli(value) {
			var secondTime = parseInt(value);// 秒
			var minuteTime = 0;// 分
			var hourTime = 0;// 小时
			if (secondTime > 60) {//如果秒数大于60，将秒数转换成整数
				//获取分钟，除以60取整数，得到整数分钟
				minuteTime = parseInt(secondTime / 60);
				//获取秒数，秒数取余，得到整数秒数
				secondTime = parseInt(secondTime % 60);
				//如果分钟大于60，将分钟转换成小时
				if (minuteTime > 60) {
					//获取小时，获取分钟除以60，得到整数小时
					hourTime = parseInt(minuteTime / 60);
					//获取小时后取余的分，获取分钟除以60取余的分
					minuteTime = parseInt(minuteTime % 60);
				}
			}
			var result = parseInt(secondTime) < 10 ? '0' + parseInt(secondTime) : parseInt(secondTime);

			if (minuteTime > 0) {
				let min = parseInt(minuteTime) < 10 ? '0' + parseInt(minuteTime) : parseInt(minuteTime)
				result = min + ":" + result;
			} else {
				result = "00:" + result;
			}
			if (hourTime > 0) {
				let hour = parseInt(hourTime) < 10 ? '0' + parseInt(hourTime) : parseInt(hourTime)
				result = hour + ":" + result;
			} else {
				result = "00:" + result;
			}
			return value?result:'00:00:00';
		},
		// 添加学习笔记
		subNote() {
			console.log(this.arevalue)
			if (this.arevalue.length < 1) {
				this.$message({
					message: '请填写您的学习笔记',
					type: 'warning'
				});
			} else {
				let arrs = {
					checkpoint_id: (this.anstype == 6 || this.anstype == 5) ? this.$parent.checkpoint_id: '',
					content_id: (this.anstype != 6 && this.anstype != 5) ? this.$parent.content_id: '',
				    ques_list_id: this.answer.id||this.answer.id.ques_list_id,
					type: this.$parent.routeInfo.type,
					content: this.arevalue,
					source: this.$parent.routeInfo.source,
					topic_id: this.$parent.routeInfo.type==4?this.answer.id:''
				}

				addNotes(arrs).then(res => {
					this.$message({
						message: '已添加',
						type: 'success'
					});
					this.arevalue = ''
					this.suNolist()
				}).catch(err => {
				})
			}
		},
		suNolist(pages) {
			let arrs = {
				checkpoint_id: (this.anstype == 6 || this.anstype == 5) ? this.$parent.checkpoint_id: '',
				content_id: (this.anstype != 6 && this.anstype != 5) ? this.$parent.content_id: '',
				ques_list_id: this.answer.id||this.answer.id.ques_list_id,
				source: this.$parent.routeInfo.source,
				page: pages||1,
				limit: 10
			}
			getNoteList(arrs).then(res => {
				this.total=res.count
				// this.notelist = this.notelist.concat(res.data.list)
				this.notelist = res.data
				console.log(this.notelist)
			}).catch(err => {
			})
		},
		open(art) {
			this.$prompt('请输入笔记', '', {
				inputType: 'textarea',
				inputValue: art.content,
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				inputValidator: value => {
					if (value.length > 0) {
						return true;
					}
					return !(value.length < 1000);
				},
			}).then(({ value }) => {
				console.log(this.$parent)
				addNotes({ 
					id: art.id,
					checkpoint_id: (this.anstype == 6 || this.anstype == 5) ? this.$parent.checkpoint_id: '',
					content_id: (this.anstype != 6 && this.anstype != 5) ? this.$parent.content_id: '',
					type: this.$parent.routeInfo.type,
					ques_list_id: this.answer.id||this.answer.id.ques_list_id,
					content: value,
					source: this.$parent.routeInfo.source,
					topic_id: this.$parent.routeInfo.type==4?this.answer.id:''
				 }).then(res => {
					this.$message({
						message: '已修改',
						type: 'success'
					});
					this.suNolist()
				}).catch(err => {

				})
			}).catch(({ value }) => {

			});
		},
		// 删除学习笔记
		getNDel(id) {
			console.log('213')
			this.$confirm('您确认要删除该条笔记吗？', '提示', {
				confirmButtonText: '确认',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				delNotes({ id: id }).then(res => {
					this.$message({
						message: '已删除',
						type: 'success'
					});
					this.suNolist()
				}).catch(err => {
				})
			}).catch(() => {

			});

		},
		// 
		Ctime(timestamp) {
			var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
			let Y = date.getFullYear() + "-";
			let M =
				(date.getMonth() + 1 < 10

					? "0" + (date.getMonth() + 1)

					: date.getMonth() + 1) + "-";
			let D = date.getDate() + " ";
			let h = date.getHours() + ":";
			let m = date.getMinutes() + ":";
			let s = date.getSeconds();
			return Y + M + D + h + m + s;
		},
			nextAnswer(e) { //切换题目按钮h)
				this.indexs = this.index + 1
				if (this.index == 0 && e == false) {

				} else if (this.indexs == this.length && e == true) {

				} else {
					this.$emit('tab', {
						next: e,
						index: this.index
					})
				}
			},
			getArrDifference(arr1, arr2) {
				if (arr2) {
					arr1 = arr1.split('')
					arr2 = arr2.split('')
					return arr1.concat(arr2).filter(function(v, i, arr) {
						return arr.indexOf(v) === arr.lastIndexOf(v);
					});
				}
			},
			getSome(arr1, arr2) {
				if (arr2) {
					arr1 = arr1.split('')
					arr2 = arr2.split('')
					let newArr = [];
					for (let i = 0; i < arr2.length; i++) {
						for (let j = 0; j < arr1.length; j++) {
							if (arr1[j] === arr2[i]) {
								newArr.push(arr1[j]);
							}
						}
					}
					return newArr;
				}
			}
		},
		computed: {
			text() {
				return function(value) {
					if (value) {
						return String(value).length * 20 + 20 + 'px'
					}

				}
			}
		},
	}
</script>
<style scoped="scoped" lang="scss">
	.el-rate .el-rate__item .el-icon-star-dr {
		color: #FF684B !important;
	}

	.difficulty p p {
		font-size: 18px !important;
		line-height: 1.5;
	}

	.difficulty_p {
		font-size: 18px;
	}

	.buzhidao_box {
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
		line-height: 30px;
	}
</style>
<style scoped>
	.js_title {
		font-size: 16px;
	}



	.js_answer {
		font-weight: bold;
		font-size: 24px;
	}

	.js_time {
		font-weight: bold;
		font-size: 20px;
		color: #666666;
	}

	.answer {
		width: 854px !important;
		/* border: 1px solid #F5F5F5; */
		margin-left: 20px;
		border-bottom: none;
		padding-top: 28px;
		background: url(../../assets/answer_BG.png) no-repeat;
		background-size: 240px 65px;
		background-position: 300px 150px;
		box-sizing: border-box;
		/* background: url('@/assets/answer_BG.png'); */
	}

	#text {
		font-size: 18px !important;
	}

	.ti_tpye {
	display: inline;
	padding: 2px 10px;
	margin-right: 10px;
	background: #EEF5FF;
	border-radius: 5px !important;
	font-size: 14px !important;
	color: #5694F7 !important;
}

.stem {
	position: relative;
	font-size: 20px;
	color: #333;
	line-height: 1.5;
}
.tiyhtm{
	width: 80%;
}
.stem span {
	font-size: 20px;
	color: #333;
}

	.stem .inputs {
		border: none;
		border-bottom: 1px solid #2FAD48;
		padding: 010px;
		font-size: 20px;
		color: #2FAD48;
		width: 200px;
		max-width: 100% !important;
		text-align: center;
		background: #fff;
	}

	.errinputs {
		color: #FF684B !important;
	}

	.stem_box {
		width: 854px;
		flex: 1;
	}

	/* 滚动条样式结束 */
	/* 选项 */
	.selects {
		margin-top: 32px;
	}

	.select_list {
		/* margin-top: 35px; */
		display: flex;
		height: 64px;
		align-items: center;
		justify-content: flex-start;
	}

	.select_list:hover {
		background: #f6f6f6;
		opacity: .5;
	}

	.select_list .tag {
		width: 30px;
		height: 30px;
		border: 1px solid #666666;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		color: #333;
		margin-right: 20px;
	}

	.correctx .tag {
	border-top-color: #ff6247;
    border-right-color: #ff6247;
    border-bottom-color: #5ba0f6;
    border-left-color: #5ba0f6;
	border-width: 15px;
	transform: rotate(45deg);
	color: #fff
}
.correctx span{
	transform: rotate(-45deg);
}
.zqrect .tag{
	background-color: #5ba0f6;
	border: 1px solid #5ba0f6;
	color: #ffffff;
}
.correctx .cont {
	 color: #5694F7;
}
	.select_list .cont {
		font-size: 20px;
		color: #333;

	}

	.correct .tag {
		background: #5694F7;
		border-color: #5694F7;
		color: #fff
	}

	.correct .cont {
		color: #5694F7
	}

	.error .tag {
		background: #FF684B;
		border-color: #FF684B;
		color: #fff
	}

	.error .cont {
		color: #FF684B
	}

	/* 答案和切换题目 */
	.btns {
		width: 854px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 70px;
	}

	.answers {
		/* display: flex;
		align-items: center;
		justify-content: flex-start; */
	}

	.answers .yes {
		display: flex;
		align-items: center;
		justify-content: flex-start;

	}

	.yes .yuan {
		width: 8px;
		height: 8px;
		background: #47BE5E;
		border-radius: 50%;
		margin-right: 6px;
	}

	.yes span {
		font-size: 18px;
		color: #222;
	}

	.answer_cards .blue {
		color: #5694F7;
		font-weight: bold;
		font-size: 24px;
	}

	.user_answer {
		margin-top: 10px;
	}

	.user_answer,
	.user_answer span {
		font-size: 18px;
	}

	.buttons {
		width: 854px;
		display: flex;
		justify-content: space-between;
		align-content: center;
	}

	.buttons .btn {
		width: 142px;
		height: 42px;
		border-radius: 5px;
		font-size: 16px;
		color: #Fff;
		display: flex;
		cursor: pointer;
		align-items: center;
		justify-content: center;
		background: #5694F7;
		border: 1px solid #5694F7;
	}
	.buttons .chbtn{
		width: 142px;
		height: 42px;
		background: #FFFFFF;
		border: 1px solid #EBEBEB;
		border-radius: 5px;
		display: flex;
		cursor: pointer;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
	}

	/* 下拉显示的解析部分 */
	.slid_box {
		width: 854px;
		margin-top: 20px;
	}

	.slid {
		/* background: #ffffff; */
		border-radius: 10px;
	}

	.slid_title {
		width: 852px;
		font-size: 18px;
		color: #222;
		font-weight: bold;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
    .slitte{
		height: 20px;
		border-left: 5px solid #5694F7;
		padding-left: 15px;
		box-sizing: border-box;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		margin-left: -18px;
	}
	.siltt_div{
		display: flex;
		align-items: center;
		
	}
	.siltt_div a{
		color: #666666;
	}
	.siltt_div img{
		width: 15px;
		height: 15px;
		margin-left: 15px;
	}
	.siltt_div{
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
		cursor: pointer;
	}
	.slid_title img {
		width: 18px;
		height: 20px;
		margin-right: 10px;
	}

	.slid_video {
		margin-top: 25px;
		margin-bottom: 35px
	}

	.slid_video img {
		width: 410px;
		height: 205px;
		background: #F3F5FF;
		border-radius: 10px;
	}

	.analysis {
		margin-top: 20px;
		font-size: 16px;
		color: #333;
	}

	.difficulty {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-top: 19px;

	}


	.examination {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-top: 16px;
	}

	.examination_box {
		padding: 0.037037rem 0.185185rem;
		margin-right: 0.185185rem;
		background: #EEF5FF;
		border-radius: 0.092593rem !important;
		font-size: 0.259259rem !important;
		color: #5694F7 !important;
	}

	.from {
		font-size: 14px;
		margin-top: 16px;
	}

	.answer_cards {
		width: 854px;
		/* width: 100%; */
		height: 100px;
		background: #FFFFFF;
		border: 1px solid #EBEBEB;
		box-shadow: 0px 0px 10px 0px rgba(200, 200, 200, 0.5);
		border-radius: 10px;
		margin-top: 45px;
		display: flex;
		align-items: center;
		justify-content: space-around;


	}

	/*  */
	.textare_bt{
		position: relative;
		width: 852px;
		margin: 20px 0;
	}
	.textare_bt textarea{
		width: 852px;
		height: 150px;
		background: #F7F7F7;
		border-radius: 5px;
		border: none;
		padding: 10px;
		resize: none;
	}
	.textare_bt div{
		width: 80px;
		height: 36px;
		text-align: center;
		background: #5694F7;
		border-radius: 5px;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 36px;
        margin-top: 15px;
	}
	.textare_bt p{
       position: absolute;
	   right: 10px;
	   top: 130px;
	   font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
	}
	.pplist{
	  width: 852px;
	  border-top: 1px solid #cccccc;
	  padding-top: 30px;

	}
	.pplist_i{
     display: flex;
	 padding-bottom: 10px;
	}
	.pplist_i img{
		width: 40px;
		height: 40px;
		border-radius: 20px;
		margin-right: 20px;
	}
	.pp_details{

	}
	.pp_nxis{
		margin-bottom: 27px;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
	.pnx_name{
		display: flex;
		align-items: center;
	}
	.pnx_name .name{
       margin-right: 14px;
	   font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
	}
	.pnx_name .time{
		font-size: 14px;
		font-family: Arial;
		font-weight: 400;
		color: #999999;
	}
	.xiu_btn{
		display: flex;
		align-items: center;
	}
	.xiu_btn div{
		width: 46px;
		height: 24px;
		line-height: 24px;
		text-align: center;
		background: #EEEEEE;
		border-radius: 3px;
		margin-left: 18px;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #999999;
	}
	.gai_btn{
     color: #5694F7 !important;
	 background: #5694f738 !important;
	}
	.shiyedx{
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 30px;
	}
	
.pplist {
	width: 852px;
	border-top: 1px solid #e9e9e9d2;
	padding-top: 30px;

}

.pplist_i {
	display: flex;
	padding-bottom: 20px;
}

.pplist_i img {
	width: 40px;
	height: 40px;
	border-radius: 20px;
	margin-right: 20px;
}

.pp_details {
	width: 792px;
}

.pp_nxis {
	margin-bottom: 27px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.pnx_name {
	display: flex;
	align-items: center;
}

.pnx_name .name {
	margin-right: 14px;
	font-size: 16px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #666666;
}

.pnx_name .time {
	font-size: 14px;
	font-family: Arial;
	font-weight: 400;
	color: #999999;
}

.xiu_btn {
	display: flex;
	align-items: center;
}

.xiu_btn div {
	width: 46px;
	height: 24px;
	line-height: 24px;
	text-align: center;
	background: #EEEEEE;
	border-radius: 3px;
	margin-left: 18px;
	font-size: 14px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #999999;
}

.gai_btn {
	color: #5694F7 !important;
	background: #5694f738 !important;
	cursor: pointer;
}

.xiu_btn div:hover {
	box-shadow: 0px 0px 6px 0px #5a595991;
	scale: 1.05;
}

.shiyedx {
	font-size: 16px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #333333;
	line-height: 30px;
}
.errt textarea{
	width: 100%;
	height: 200px;
	padding: 12px 12px 12px 12px;
    background-color: #f5f5f5;
    margin: 12px 0;
	border: none;
	border-radius: 6px;
	font-size: 18px;
}
.err_text{
	font-size: 21px;
    color: #555;
    line-height: 34px;
    margin-top: 18px;
}
.err_btn{
	width: 100%;
	text-align: right;
}
.err_btn button{
width: 100px;
height: 40px;
margin-top: 8px;
background: #5694F7;
border: none;
color: #ffffff;
border-radius: 2px;
font-size: 16px;
}
/deep/.el-dialog__body{
	padding: 0 20px 30px 20px;
}
</style>
