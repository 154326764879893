<template>
	<div class="warp">
		<div class="name">莘知学员学习系统</div>
		<div class="tab">
			<div class="list" v-for="(item,index) in tabList" :key="index" :class="current == index ?'active':''"
				@click="currentClick(index,item.pages)">
				<img :src="item.activeIcon" alt="" v-if="current == index">
				<img :src="item.icon" alt="" v-else>
				{{item.name}}
				<div class="top_yuan" v-if="current == index"></div>
				<div class="top_yuan2" v-if="current == index"></div>
				<div class="bottom_yuan" v-if="current == index"></div>
				<div class="bottom_yuan2" v-if="current == index"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		EventBus
	} from '@/eventBus/eventBus'
	export default {
		data() {
			return {
				tabList: [{
						name: '首页',
						icon: require('@/assets/images/tabbar/1_no.png'),
						activeIcon: require('@/assets/images/tabbar/1_active.png'),
						pages: 'Index'
					},
					{
					    name:'课后专属服务',
					    icon:require('@/assets/images/tabbar/2_no.png'),
					    // activeIcon:require('@/assets/images/tabbar/2_active.png'),
					    pages:'ServiceView'
					},
					{
						name: '学习中心',
						icon: require('@/assets/images/tabbar/3_no.png'),
						activeIcon: require('@/assets/images/tabbar/3_active.png'),
						pages: 'Study'
					},
					{
						name: '模考大赛',
						icon: require('@/assets/images/tabbar/4_no.png'),
						activeIcon:require('@/assets/images/tabbar/4_active.png'),
						pages: 'Simulation'
					},
					{
						name: '考试信息',
						icon: require('@/assets/images/tabbar/5_no.png'),
						activeIcon:require('@/assets/images/tabbar/5_active.png'),
						pages: 'Examination'
					},
					{
						name: '内部学员答疑',
						icon: require('@/assets/images/tabbar/6_no.png'),
						activeIcon:require('@/assets/images/tabbar/6_active.png'),
						pages: 'Doubt'
					},
					{
						name: '个人中心',
						icon: require('@/assets/images/tabbar/7_no.png'),
						activeIcon:require('@/assets/images/tabbar/7_active.png'),
						pages: 'User'
					},
				],
				current: 0
			}
		},
		created() {
			if (localStorage.getItem('current')) {
				this.current = localStorage.getItem('current');
				console.log(this.current)
			} else {
				this.current = 0
				this.$router.push('/' + 'Index');
			}

		},
		mounted() {
			EventBus.$on('current', res => {
				this.current = res
			})
		},
		methods: {
			currentClick(index, page) {
				if(index == 1){
					this.$message.error('开发中请期待')
					return
				}
				if (this.current != index) {
					this.current = index
					this.$router.push('/' + page);
					localStorage.setItem('current', index);
				}
				if(index == 5){
					localStorage.setItem('userCurrent', 0);
				}else{
					localStorage.removeItem('userCurrent', 0);
				}
			}
		}
	}
</script>

<style scoped>
	.warp {
		width: 15rem;
		height: 60rem;
		background: #505DDF;
		border-radius: 1.25rem;
		padding-top: 3.5rem;
	}

	.name {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.25rem;
		color: #fff;
		font-weight: 600;
	}

	.tab {
		margin-top: 6.1875rem;
	}

	.list {
		height: 3.5rem;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 1.125rem;
		color: #fff;
		position: relative;
		padding-left: 3.125rem;
	}
	.active {
		background: #f3f4ff;
		border-radius: 1.25rem 0px 0px 1.25rem;
		color: #505DDF !important;
		/* font-weight: bold; */
		display: flex;
		align-items: center;

	}

	.top_yuan {
		position: absolute;
		width: 1.875rem;
		height: 3.5625rem;
		border-radius: 0px 1.25rem 1.25rem 0px;
		top: -3.5625rem;
		right: 0;
		z-index: 2;
		background: #505DDF;
	}

	.top_yuan2 {
		position: absolute;
		width: 1.875rem;
		height: 3.4375rem;
		border-radius: 1.25rem 0px 0px 1.25rem;
		top: -1.625rem;
		right: -0.9375rem;
		z-index: 1;
		background: #f3f4ff;
	}

	.bottom_yuan {
		position: absolute;
		width: 1.875rem;
		height: 3.5625rem;
		border-radius: 0px 1.25rem 1.25rem 0px;
		bottom: -3.5625rem;
		right: 0;
		z-index: 2;
		background: #505DDF;
	}

	.bottom_yuan2 {
		position: absolute;
		width: 1.875rem;
		height: 3.4375rem;
		border-radius: 1.25rem 0px 0px 1.25rem;
		bottom: -1.625rem;
		right: -.9375rem;
		z-index: 1;
		background: #f3f4ff;
	}

	.list img {
		/* width: 1.375rem;
		height: 1.375rem; */
		margin-right: 1rem;
	}
</style>
