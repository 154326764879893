<template>
  <div class="container">
    <div class="schedule">
      <div class="schedule_top">
        <div class="schedule_name">考试日程</div>
        <div class="schedule_list">
          <div
            class="schedule_i"
            @click="tabChange(index)"
            :class="tabIndex == index ? 'ac_schedule' : ''"
            v-for="(item, index) in regList"
            :key="index"
            v-if="ahznShow?index<8:true"
          >
           {{item.name}}
          </div>
          <div class="schedule_i" @click="ahznShow=!ahznShow" style="color:#308DFF;border: 1px solid #CFE5FF;">{{ahznShow?'展开':'收起'}}</div>
        </div>
        <div class="schedule_input">
          <img src="../../assets/search.png" alt="" />
          <input @keyup.enter="blurSeacrh" v-model="vaName" type="text" placeholder="输入关键词查询" />
        </div>
      </div>
      <div class="tablex">
      <el-table :data="caleList" stripe style="width: 100%">
        <el-table-column prop="title" label="考试名称">
        </el-table-column>
        <el-table-column prop="station_num" label="招聘人数" width="80" align="center">
          <template slot-scope="scope">
             <div>{{scope.row.station_num}}</div>
           </template>
        </el-table-column>
        <el-table-column prop="exam_start_time" label="报名时间" align="center">
          <template slot-scope="scope">
          <div>
            {{Ctime(scope.row.enroll_start_time)}}-{{Ctime(scope.row.enroll_end_time)}}
          </div></template>
        </el-table-column>
        <el-table-column prop="written_start_time" label="笔试时间" align="center">
          <template slot-scope="scope">
          <div v-if="scope.row.written_start_time">
            {{Ctime(scope.row.written_start_time)}}
          </div></template>
        </el-table-column>
        <!-- <el-table-column prop="address" label="资格审查时间"> </el-table-column> -->
        <el-table-column prop="interview_start_time" label="面试时间" align="center">
          <template slot-scope="scope">
          <div v-if="scope.row.interview_start_time">
            {{Ctime(scope.row.interview_start_time)}}
          </div>
        </template>
        </el-table-column>
        <!-- <el-table-column prop="address" label="体检时间"> </el-table-column> -->
        <el-table-column prop="address" label="公告" width="70px">
           <template slot-scope="scope">
             <img @click="toUrl('https://www.sz517.com/wzxq/'+scope.row.id)" src="../../assets/images/login/icon_rc_info.png" style="width:16px;" alt="">
           </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="elprox"><el-pagination
    background
    layout="prev, pager, next"
    :total="caleCount"
    @current-change="getExamCalendar">
    </el-pagination></div>
    </div>
    
  </div>
</template>

<script>
import {getExamCalendar,getRegion} from '@/utils/erpapi.js'
export default {
  data() {
    return {
      ahznShow:true,
      tabIndex: 0,
      caleList:[],
      caleCount:0,
      vaName:"",
      regList:[],
      province:""
    };
  },
  destroyed(){
    this.loading.close();
		},
    created() {
      this.loading = this.$loading({
        lock: true,
        text: "",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    this.getExamCalendar()
    this.getRegion()
  },
  methods: {
        // 专题搜索
    blurSeacrh(){
      this.getExamCalendar()
    },
    // 城市
    getRegion(){
      getRegion().then(res=>{
         this.regList=[{id:'',name:"全国"},...res.data]
      })
    },
    toUrl(url) {
      window.open(url);
    },
    getExamCalendar(val){
      getExamCalendar({
        page:val||1,
        limit:10,
        name:this.vaName,
        province:this.province
      }).then(res=>{
       this.caleList=res.data.list
       this.caleCount=res.data.count
       this.loading.close();
      })
    },
    Ctime(timestamp) {
			var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
			let Y = date.getFullYear() + "-";
			let M =
				(date.getMonth() + 1 < 10

					? "0" + (date.getMonth() + 1)

					: date.getMonth() + 1) + "-";
			let D = date.getDate() + " ";
			let h = date.getHours() + ":";
			let m = date.getMinutes() + ":";
			let s = date.getSeconds();
			return Y + M + D
		},
    tabChange(index) {
      this.tabIndex = index;
      this.province=this.regList[index].id
      this.getExamCalendar()
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-table thead tr>th{
    height: 50px;
    background-color: #308DFF;
    color: #ffffff;
}
/deep/.el-table__header-wrapper{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
// 更改表格每行背景颜色
/deep/.el-table tbody tr>td{
    height: 75px;
}
/deep/.el-table td{
    border-bottom: none;
}
/deep/.el-table__body-wrapper{
    border-left: 1px solid #E4E8F2;
    border-right: 1px solid #E4E8F2;
    border-top: none;
}
.elprox{
    display: flex;
    justify-content: center;
    margin: 30px auto 0 auto;
}
.tablex{
    width: 1138px;
    margin-top: 10px;
}
.schedule {
  width: 1200px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px #e8edf3;
  border-radius: 6px;
  padding:20px 30px 30px 30px;
  .schedule_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .schedule_list {
    width: 835px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 10px;
    .ac_schedule {
      background: #308dff1c !important;
      color: #308dff !important;
    }
    .schedule_i {
      height: 28px;
      padding: 0 14px;
      text-align: center;
      line-height: 28px;
      background: #ffffff; 
      border: 1px solid #f0f0f0;
      border-radius: 14px;
      margin-right: 15px;
      margin-bottom: 5px;
      margin-top: 5px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
  }
  .schedule_input {
    width: 200px;
    height: 40px;
    background: #f7f7f7;
    border: 1px solid #ebebeb;
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    img {
      width: 16px;
      height: 16px;
    }
    input {
      width: 120px;
      border: none;
      height: 38px;
      background: #f7f7f7;
      margin-left: 10px;
    }
  }
}
</style>
