<template>
    <div class="container">
        <div class="shopinfo">
            <img :src="traInfo.image" alt="">
            <div class="shopdetail">
                <div class="shopdetail-name">{{ traInfo.title }}</div>
                <div class="shopdetail-dec">{{ traInfo.brief }}</div>
                <!-- <div class="shopdetail-nupr">
                    <div class="nupjn">价格</div>
                    <div class="nupr-price"><span>￥</span>28</div>
                </div> -->
                <div class="ci-goum">
                    <div @click="codeShow=true" class="shopdetail-btn">联系开通</div>
                </div>
            </div>
        </div>
        <div class="tadetail">
            <div class="shotab">
                <div class="shotab-i" :class="index2 == treeIndex ? 'stabactive' : ''" v-for="(tre, index2) in tabList"
                    :key="index2" @click="tabClick(index2)">{{ tre }}</div>
            </div>
            <div v-show="treeIndex == 0">
                <div v-html="traInfo.content"></div>
            </div>
            <div v-show="treeIndex == 1">
                <div class="left_i">
                    <div class="question_set" style="margin-bottom: 20px">特训<span style="weight:100px">（最近学习）</span>
                    </div>
                    <div class="course_list" :style="{
                height: kanzkShow
                    ? stutraData.length < 3
                        ? 'auto'
                        : '380px'
                    : 'auto',
            }">
                        <div class="course_i" v-for="(item, index2) in stutraData" :key="index2" style="width: 100%"
                            @click="goopStyindex(0, item, item.training_id)">
                            <div class="cour_info" style="width: 100%">
                                <div class="cour_details">
                                    <div class="cour_name">{{ item.training_name }}</div>
                                </div>
                                <div class="cour_btn" style="background:#308DFF;color:#ffffff;">
                                    学习
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="stutraData.length>2" class="more_n" @click="kanzkShow = !kanzkShow">
                        {{ kanzkShow ? "展开更多" : "收起"
                        }}<img src="../../assets/images/login/icon_tx_more.png" alt="" />
                    </div>
                </div>
                <div class="left_i">
                    <div class="quenflex">
                        <div class="question_set" style="margin-bottom: 20px">专题<div>（最近学习）</div>
                        </div>
                        <!-- <div class="quenflex_input">
                        <img src="../../assets/search.png" alt="" />
                        <input
                        @keyup.enter="blurSeacrh"
                        v-model="inputject"
                        type="text"
                        placeholder="输入专题关键词"
                        />
                    </div> -->
                    </div>
                    <el-collapse accordion v-model="activeName">
                        <div class="elcoll" v-for="(subj, index4) in subjData" :key="index4">
                            <el-collapse-item :name="index4">
                                <template slot="title">
                                    <div class="collapse_title">
                                        <div class="coll_name">{{ subj.catalogue_name }}</div>
                                        <div class="coll_dec">
                                            已添加<span>{{ subj.count }}</span>个专题
                                        </div>
                                    </div>
                                </template>
                                <div class="coll_list">
                                    <div class="coll_i" v-for="(jd, index5) in subj.subject" :key="index5"
                                        @click="goTopicinfo(jd)">
                                        <div class="coll_name">{{ jd.subject_name }}</div>
                                        <div class="coll_dec">
                                            已添加<span>{{ jd.content_nums }}</span>个专题
                                        </div>
                                        <div class="coll_btn">查看详情</div>
                                    </div>
                                </div>
                            </el-collapse-item>
                        </div>
                    </el-collapse>
                </div>
            </div>
        </div>
        <!--  -->
        <div class="codeart" v-if="codeShow">
            <div class="codeart-cen">
                <div class="fic-icon" @click="codeShow=false">
					×
				</div>
                <img src="https://files.sz517.com/szedu/image/2024/0929/15OQG5uNdjwPNXcHKb.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
import tuJsmind from '@/components/indexComp/tuJsmind'
import Swiper from 'swiper'; // 注意引入的是Swiper
import 'swiper/css/swiper.min.css' // 注意这里的引入
import { getOnlineSuccessTrainingClassDetail, getCheckpointSection, getSubjectContent } from "@/utils/api.js";
export default {
    components: {
        tuJsmind,
    },
    data() {
        return {
            tabList: ['课程介绍', '课程详情'],
            treeIndex: 0,
            kanzkShow: true,
            codeShow:false,
            stutraData: [],
            subjData: [],
            traInfo: {},
            inputject: "",
            activeName: "first",
        };
    },
    created() {
        console.log(this.$route)
        this.getOnlineSuccessTrainingClassDetail()
    },
    mounted() {

    },
    methods: {
        getOnlineSuccessTrainingClassDetail() {
            getOnlineSuccessTrainingClassDetail({
                id: this.$route.query.id
            }).then(res => {
                this.traInfo = res.data
                this.stutraData = res.data.training_list;
                this.subjData = res.data.subject_list;
                console.log(this.stutraData)
            })
        },
        tabClick(index) {
            this.treeIndex = index
        },
        // 去学习
			goopStyindex(seid,data,id){
				if(data.condition_type==2)return;
				if(id){
					this.$router.push('/opstyindex?id=' + id+"&section_id="+seid);
				}else{
					this.$router.push('/opstyindex?id=' + data.id+"&section_id="+seid);
				}
				
			},
                // 专题搜索
    blurSeacrh() {
      this.getSubject();
      this.activeName="1"
    },
        // 专题详情
    goTopicinfo(data) {
      this.$router.push("/optopicinfo?id=" + data.id);
    },
    },

};
</script>

<style scoped="scoped" lang="scss">
.tadetail {
    margin-top: 20px;
    background: #ffffff;
    border-radius: 4px;
    padding: 30px;
}

.shotab {
    width: 1180px;
    padding: 20px 0 10px 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    cursor: pointer;

    .shotab-i {
        padding-right: 40px;
        font-family: Microsoft YaHei;
        color: #666666;
        margin-bottom: 12px;
        cursor: pointer;
    }

    .stabactive {
        font-weight: bold;
        color: #2878FF;
    }
}

.shopinfo {
    width: 1200px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    margin-bottom: 30px;
    display: flex;
    padding: 30px;

    img {
        width: 300px;
        height: 276px;
        border-radius: 6px;
    }

    .shopdetail {
        position: relative;
        margin-left: 40px;
        height: 276px;
        display: flex;
        flex-direction: column;

        // justify-content: space-between;
        .shopdetail-name {
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 28px;
            color: #393F47;
        }

        .shopdetail-dec {
            margin-top: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 16px;
            color: #666666;
            overflow: hidden;
            display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
            -webkit-line-clamp: 7; /* 行数，值可以改，表示展示X行后多余的缩略展示 */
            -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
            word-break: break-all;
        }

        .shopdetail-nupr {
            display: flex;
            align-items: baseline;

            .nupjn {
                font-family: Microsoft YaHei;
                font-weight: bold;
                font-size: 14px;
                color: #666666;
                margin-right: 30px;
            }

            .nupr-price {
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 32px;
                color: #FE5E3F;

                span {
                    font-weight: 400;
                    font-size: 14px;
                }
            }
        }

        .shopdetail-btn {
            cursor: pointer;
            width: 160px;
            height: 50px;
            background: #FE5E3F;
            border-radius: 6px;
            text-align: center;
            line-height: 50px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 18px;
            color: #FFFFFF;
        }
    }
}

.cour_progress {
    position: relative;
    width: 100px;
    height: 10px;
    margin-left: 35px;
    margin-right: 15px;
    background: #D6E8FF;
    border-radius: 2px;

    .courpro_lg {
        position: absolute;
        height: 10px;
        z-index: 90;
        width: 50%;
        background: #308DFF;
        top: 0;
        left: 0;
        border-radius: 2px;
    }

    .cour_gard {
        position: absolute;
        z-index: 99;
        width: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;

        div {
            width: 21px;
            height: 10px;
            background: #e4050500 !important;
            border-right: 4px solid #ffffff;
            margin: 0;
        }
    }
}

.cour_btn {
    width: 100px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 16px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    text-align: center;
    line-height: 32px;
    margin-left: 30px;
}

.swiper-container {
    width: 1200px;
    height: 110px !important;
    margin: 20px auto;
    margin: 0 !important;
    padding: 0 !important;
}

.swiper-slide.swiper-slide-active {
    width: 189px !important;
    height: 110px !important;
}

.swiper-slide {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 18px;
    width: 189px !important;
    height: 110px !important;
    background: #ffffff !important;
    border: 1px solid #308dff;
    width: 189px;
    height: 110px;
    border: 1px solid #e2ecfb;
    box-shadow: 0px 0px 5px 0px rgba(162, 177, 188, 0.2);
    border-radius: 5px 5px 4px 4px;
    margin-right: 15px !important;
    margin-top: none !important;
}

.ctype_top {
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ecf4ff;
    padding: 0 15px;

    .ctype_top_name {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #a4a4a4;
    }

    .ctype_top_status {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #a4a4a4;

        img {
            width: 16px;
            height: 16px;
        }
    }
}

.acctype_i {
    border: 1px solid #308dff !important;

    .ctype_top {
        background: linear-gradient(90deg, #308dff, #4a9bff) !important;

        .ctype_top_name {
            color: #ffffff !important;
        }

        .ctype_top_status {
            color: #ffffff !important;

            span {
                font-size: 18px;
                font-family: DIN Next LT Pro;
                font-weight: bold;
                color: #FFFFFF;
            }
        }
    }
}

.ctype_dec {
    height: 74px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    padding: 0 10px;
}

// 
canvas,
jmnodes {
    left: 0px;
}

.twodiv {
    width: 1200px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px #e8edf3;
    border-radius: 6px;
    margin-top: 15px;
    display: flex;
    margin-bottom: 30px;

    .twov_tabs {
        width: 320px;
        background: #f6f7fa;

        .actabs_i {
            background: #ffffff !important;
        }

        .tabs_i {
            width: 320px !important;
            height: 100px;
            padding: 0 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .tabs_i_left {
                padding-top: 17px;
                position: relative;
                height: 100px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .gongguimg {
                    width: 60px;
                    height: 60px;
                    border-radius: 30px;
                    background: linear-gradient(90deg, rgb(15, 162, 255), rgb(23, 189, 255));
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .gongguimg img {
                    max-width: 100%;
                }

                .tabsi_left_inx {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #308dff;

                    span {
                        font-size: 18px;
                        font-weight: bold;
                    }
                }

                .tabsi_left_namae {
                    position: absolute;
                    bottom: 10px;
                    margin-top: 4px;
                    width: 250px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    white-space: normal;
                }
            }

            .gonggus {
                .gonggus_left {
                    width: 190px;
                    height: 50px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .gonggus_left_name {
                        font-size: 16px;
                        color: #3d3e40;
                    }

                    .gonggus_left_dec {
                        font-size: 14px;
                        color: #6c7380;
                    }
                }
            }

            .tabs_i_right {
                display: flex;
                align-items: center;

                .tabsi_right_pro {
                    height: 66px;
                    margin-right: 16px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #308dff;

                    span {
                        font-size: 18px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

.stage_test {
    width: 880px;
    min-height: 500px;
    padding: 30px;

    .stage_cent {
        width: 790px;
        background-color: #f8f9fb;
        padding: 30px;
        margin: 20px auto 0 auto;
        box-sizing: border-box;

        .stage_name {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 25px;
            color: #1f2021;
        }

        .stageinfo {
            display: flex;
            align-items: center;
            margin-top: 10px;

            div {
                font-size: 14px;
                line-height: 1;
                color: #6c7280;
            }

            div:nth-child(2) {
                padding: 0 20px;
                margin: 0 20px;
                border-left: 1px solid #dee1ea;
                border-right: 1px solid #dee1ea;
            }
        }

        .stagebtns {
            margin-top: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .stagebtns_left {
                display: flex;
                align-items: center;

                .stagebtns_left_i:first-child {
                    padding-right: 20px;
                    margin-right: 20px;
                    border-right: 1px solid #dee1ea;
                }

                .stagebtns_left_i {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #6c7380;

                    span {
                        font-size: 18px;
                        font-family: DIN;
                        font-weight: 500;
                        line-height: 22px;
                        color: #3d3e40;
                    }

                    div {
                        width: 45px;
                        height: 18px;
                        font-size: 11px;
                        text-align: center;
                        line-height: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #5694f7;
                        background: #5694f71f;
                        border-radius: 2px;
                        margin-left: 8px;
                    }
                }
            }

            .stagebtns_right {
                display: flex;
                align-items: center;

                div {
                    cursor: pointer;
                    width: 116px;
                    height: 39px;
                    font-size: 14px;
                    text-align: center;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    line-height: 34px;
                    color: #5694f7;
                    border: 1px solid #5694f7;
                    border-radius: 20px;
                }

                div:last-child {
                    margin-left: 20px;
                    color: #ffffff;
                    background: linear-gradient(316deg, #5694f7 0%, #5694f7 100%);
                }
            }
        }
    }

    .tagging-tips-text {
        width: 700px;
        margin: 20px auto;
        background: #fef9f1;
        padding: 9px 12px;
        border-radius: 4px;
        border: 1px solid #ffc77d;
        font-size: 12px;
        color: #ff9f21;
    }

    .study-report-list-item {
        cursor: pointer;
        width: 700px;
        margin: 0 auto 12px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 30px;
        height: 59px;
        background: #f8f9fb;
        border-radius: 4px;

        div:first-child {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 34px;
            color: #3d3e40;
        }

        div:last-child {
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 34px;
                color: #3d3e40;
            }
        }
    }

    .stage_tab {
        position: relative;
        width: 790px;
        margin: 70px auto 0 auto;
        border-bottom: 1px solid #dee2e6;

        div {
            position: absolute;
            left: 0;
            bottom: -1px;
            width: 120px;
            height: 40px;
            border: 1px solid #dee2e6;
            border-bottom: 1px solid #ffffff;
            color: #3d3e40;
            background: #fff;
            border-bottom: none;
            text-align: center;
            line-height: 40px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
        }
    }
}

.gonggcont {
    width: 880px;
    min-height: 500px;
    background-image: url('../../assets/images/oneback.png');
    background-size: 100% 100%;
    padding: 30px;

    .examine_i {
        width: 810px;
        margin: 0 auto 20px auto;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .examine_btn {
            width: 80px;
            height: 30px;
            background: #F6FAFF;
            border-radius: 15px;
            border: 1px solid #308DFF;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            color: #308DFF;
            text-align: center;
            line-height: 30px;
        }

        .examine_left {
            display: flex;
            align-items: center;

            .examine_img {
                width: 60px;
                height: 60px;
                background: #F8F8F8;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 22px;

                img {
                    width: 24px;
                    height: 24px;
                }
            }

            .examine_dec {
                height: 56px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .examine_name {
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    font-size: 14px;
                    color: #333333;
                }

                .examine_box {
                    display: flex;
                    align-items: center;

                    div {
                        height: 24px;
                        padding: 0 10px;
                        background: #F6FAFF;
                        border-radius: 2px;
                        text-align: center;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #308DFF;
                    }
                }
            }
        }
    }

    .gonggcont_prass {
        width: 810px;
        padding: 47px 40px 0 60px;
        display: flex;
        justify-content: space-between;

        .prass_left {
            .proleft_texts {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px 0;
                border-bottom: 1px dashed #E0E2EB;

                div:first-child {
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                }

                div:last-child {
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    font-weight: bold;
                    color: #333333;
                }
            }

            .prass_left_prog {
                position: relative;
                width: 220px;
                height: 220px;
                background: #FFFFFF;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                .prostext {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 220px;
                    height: 220px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    div {
                        font-family: DIN Next LT Pro;
                        font-weight: bold;
                        font-size: 38px;
                        color: #308DFF;
                    }

                    span {
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #666666;
                    }
                }
            }
        }

        .prass_right {
            width: 300px;
            margin-top: 20px;

            .right_nimg {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .dvnimg {
                    margin-right: 50px;

                    div {
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        font-size: 20px;
                        color: #393F47;
                        margin-bottom: 10px;
                    }

                    span {
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #999999;
                    }
                }

                img {
                    width: 122px;
                    height: 95px;
                }
            }

            .nimg_dec {
                width: 275px;
                margin-top: 56px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                color: #333333;
            }

            .nimg_btn {
                margin-top: 50px;
                width: 200px;
                height: 50px;
                background: linear-gradient(90deg, #308DFF, #30B8FF);
                border-radius: 25px;
                text-align: center;
                line-height: 50px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                font-size: 16px;
                color: #FFFFFF;
            }
        }
    }
}

.two_content {
    width: 880px;
}

.question_set {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #393f47;
  display: flex;
  align-items: baseline;
}

.course_list {
    width: 812px;
    margin: 0 auto;
    overflow: hidden;
    .course_i {
        width: 810px;
        height: 110px;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 6px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .cour_info {
            width: 690px;
            padding: 0 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .cour_btn {
                width: 100px;
                height: 32px;
                background: #ffffff;
                border: 1px solid #ebebeb;
                border-radius: 16px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                text-align: center;
                line-height: 32px;
            }

            .cour_details {
                //height: 60px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .cour_name {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #393f47;
                }

                .cour_kankj {
                    display: flex;
                    align-items: center;

                    div {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #999999;

                        // margin-right: 8px;
                        span {
                            font-size: 18px;
                            color: #308dff;
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        .cour_img {
            width: 120px;
            height: 110px;
            background: linear-gradient(90deg, #0fa2ff, #17bdff);
            border-radius: 6px 0px 0px 6px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
                width: 30px;
                height: 30px;
            }

            div {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #ffffff;
                margin-top: 10px;
            }
        }
    }
}

.tpelist_i:nth-child(even) {
    background-color: #ffffff;
}

.tpelist_i {
    width: 810px;
    height: 80px;
    margin: 0 auto;
    background: #fbfbfb;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;

    .tpelist_i_left {
        display: flex;
        align-items: center;

        .tpe_inx {
            font-size: 40px;
            font-family: DIN Next LT Pro;
            font-weight: bold;
            color: #308dff;
        }

        .tpe_type {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 66px;
            height: 24px;
            background: #308dff0a;
            border-radius: 3px;
            margin: 0 20px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #308dff;

            img {
                width: 15px;
                height: 15px;
                margin-right: 6px;
            }
        }

        .tpe_name {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #393f47;
        }
    }

    .tpelist_i_right {
        display: flex;
        align-items: center;

        .tpe_pro {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;

            span {
                font-size: 18px;
                font-family: DIN Next LT Pro;
                font-weight: bold;
                color: #308dff;
            }
        }

        .tpe_btn {
            margin-left: 20px;
            width: 100px;
            height: 32px;
            background: #308dff;
            border-radius: 16px;
            line-height: 30px;
            text-align: center;
            color: #ffffff;
        }
    }
}

.cour_progress {
    position: relative;
    width: 100px;
    height: 10px;
    margin-left: 55px;
    margin-right: 15px;
    background: #d6e8ff;
    border-radius: 2px;

    .courpro_lg {
        position: absolute;
        height: 10px;
        z-index: 90;
        width: 50%;
        background: #308dff;
        top: 0;
        left: 0;
        border-radius: 2px;
    }

    // .cour_gard {
    //   position: absolute;
    //   z-index: 99;
    //   width: 100%;
    //   top: 0;
    //   left: 0;
    //   display: flex;
    //   align-items: center;
    //   div {
    //     width: 20px;
    //     height: 10px;
    //     background: #e4050500;
    //     border-right: 4px solid #ffffff;
    //     margin: 0;
    //   }
    // }
}

.condition_i:nth-child(even) {
    background: #ffffff;
}

.condition_i {
    width: 810px;
    height: 50px;
    background: #fcfcfc;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto 20px auto;

    .condition_i_left {
        display: flex;
        align-items: center;

        .con_inx {
            width: 40px;
            height: 24px;
            background: #F6F6F6;
            border-radius: 12px;
            text-align: center;
            line-height: 24px;
            font-size: 18px;
            font-family: DIN Next LT Pro;
            font-weight: bold;
            color: #999999;
        }

        .con_name {
            margin-left: 20px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
        }
    }

    .condition_i_right {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;

        img {
            width: 24px;
            height: 24px;
            margin-left: 20px;
        }
    }
}

.minddiv {
    margin-top: -100px;
    position: relative;
    overflow: hidden;
    margin-bottom: 50px;

    .clickd {
        position: absolute;
        z-index: 999;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 80px;
        border-radius: 3px;
        text-align: center;
        line-height: 80px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #308DFF;
        background: linear-gradient(to bottom, rgba(255, 0, 0, 0), #ECECEC);
    }
}

.nothing {
    height: 500px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 18px;
}

//
.left_i {
    width: 870px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px #e8edf3;
    border-radius: 6px;
    margin-bottom: 30px;
    padding: 30px;
    box-sizing: border-box;
    margin-top: 30px;
}

.more_n {
    width: 180px;
    height: 40px;
    background: #f7f7f7;
    border-radius: 20px;
    margin: 10px auto;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #393f47;

    img {
        width: 9px;
        height: 10px;
        margin-left: 6px;
    }
}

.collapse_title {
    display: flex;
    align-items: center;

    .coll_name {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #393f47;
        padding-left: 10px;
    }

    .coll_dec {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        margin-left: 10px;
        display: flex;
        align-items: center;

        span {
            color: #308dff;
            font-weight: bold;
            display: block;
            margin: 0 4px;
        }
    }
}

.elcoll {
    border-left: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
    margin-bottom: 20px;
}

/deep/.el-collapse-item__header {
    background: #fafafa;
    height: 60px;
}

.quenflex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.quenflex_input {
    width: 200px;
    height: 40px;
    background: #f7f7f7;
    border: 1px solid #ebebeb;
    border-radius: 20px;
    display: flex;
    align-items: center;

    input {
        height: 38px;
        border: none;
        background: #f7f7f7;
        width: 100px;
    }

    img {
        width: 17px;
        height: 17px;
        margin: 0 10px 0 20px;
    }
}

.coll_list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 30px 30px 0 30px;

    .coll_i {
        width: 360px;
        height: 171px;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 6px;
        padding-top: 24px;
        position: relative;
        margin-bottom: 30px;

        .coll_btn {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 360px;
            height: 50px;
            background: #fafafa;
            border-radius: 6px;
            line-height: 50px;
            text-align: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #393f47;
        }

        .coll_name {
            width: 324px;
            height: 41px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 26px;
            margin: 0 auto 0 10px;
        }

        .coll_dec {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            margin-left: 10px;
            display: flex;
            align-items: center;
            margin-left: 10px;
            margin-top: 18px;

            span {
                color: #308dff;
                font-weight: bold;
                display: block;
                margin: 0 4px;
            }
        }
    }
}

.cour_i {
    width: 810px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 6px;
    padding: 20px 0;
    margin-bottom: 20px;
}

.cour_info {
    width: 810px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cour_btn {
        width: 100px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 16px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        text-align: center;
        line-height: 32px;
    }

    .cour_details {
        // height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .cour_name {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #393f47;
        }

        .cour_kankj {
            display: flex;
            align-items: baseline;

            .cour_progress {
                position: relative;
                width: 100px;
                height: 10px;
                margin-left: 35px;
                margin-right: 15px;
                background: #d6e8ff;
                border-radius: 2px;

                .courpro_lg {
                    position: absolute;
                    height: 10px;
                    z-index: 90;
                    width: 50%;
                    background: #308dff;
                    top: 0;
                    left: 0;
                    border-radius: 2px;
                }

                .cour_gard {
                    position: absolute;
                    z-index: 99;
                    width: 100%;
                    top: 0;
                    left: 0;
                    display: flex;
                    align-items: center;

                    div {
                        width: 20px;
                        height: 10px;
                        background: #e4050500;
                        border-right: 4px solid #ffffff;
                        margin: 0;
                    }
                }
            }

            div {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                margin-right: 8px;

                span {
                    font-size: 18px;
                    color: #308dff;
                    font-weight: bold;
                }
            }
        }
    }
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

/deep/.el-icon-plus {
    border: 1px dashed #999;
}

.ceceflex {
    font-weight: bold;
    display: flex;
    align-items: center;
}

.nibtn {
    color: #409EFF;
}

.inpuwit {
    width: 300px;
    margin-bottom: 15px;
}
.ci-goum{
    position: absolute;
    width: 800px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.codeart{
		position: fixed;
		z-index: 99;
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		background: #00000078;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.codeart-cen{
			position: relative;
			background: #ffffff;
			padding: 20px;
			border-radius: 10px;
			.fic-icon{
				position: absolute;
				z-index: 999;
				right: -20px;
				top: -20px;
				font-size: 30px;
				color: #ffffff;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background: #736e6e;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		.code{
			width: 450rpx;
			border-radius: 10rpx;
		}
	}
</style>