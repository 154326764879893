import {addTrainingLog} from "@/utils/erpapi.js";
export default {
	data() {
		return {
		}
	},
	computed: {

	},
	methods: {
		// 渐变背景色
		prbaColor(type){
			if(type==1){
				return 'linear-gradient(90deg, #0FA2FF, #17BDFF)'
			 }else if(type==3){
				return 'linear-gradient(90deg, #FF761F, #FF8B42)'
			 }else{
				return 'linear-gradient(90deg, #00C289, #4ACA6D)'
			 }
			},
			// 颜色
			backgChange(type){
				return type==1?"#308DFF":type==3?"#FF883D":'#3DC972'
			},
			// 去学习
			goStyindex(seid,data,id){
				if(data.condition_type==2)return;
				if(id){
					this.$router.push('/styindex?id=' + id+"&section_id="+seid);
				}else{
					this.$router.push('/styindex?id=' + data.id+"&section_id="+seid);
				}
				
			},
	},
	destroyed(){
		//  this.loading.close();
	},
}
