<template>
	<div class="wraps">
		<div class="title"><span>请输入你要反馈的内容</span><span style="font-weight: normal;cursor: pointer;" @click="$router.push('/myFeedback')">我的反馈</span></div>
		<div class="con_box">
			<textarea rows="" cols="" placeholder="请输入问题描述" v-model="setCon"></textarea>
		</div>
		<div class="btns">
			<div></div>
			<div class="submit_btn" @click="addFeedback">提交</div>
		</div>
	</div>
</template>

<script>
	import {addFeedback} from '@/utils/api.js'
	import {
		EventBus
	} from '@/eventBus/eventBus'
	export default {
		data() {
			return {
				setCon: '',
				navList:[
					{
						path:'/classList',
						name:'首页',
						studyCurrent:0,
						tabbarChildCurrent:0,
						tabbarCurrent:0
					},
					{
						path:'/userInfo',
						name:'个人中心',
						studyCurrent:0,
						tabbarChildCurrent:0,
						tabbarCurrent:5
					},
					{
						path:'/mySet',
						name:'反馈',
						studyCurrent:0,
						tabbarChildCurrent:4,
						tabbarCurrent:5
					}
				]
			}
		},
		created() {
			EventBus.$emit('navList',this.navList)
		},
		methods:{
			async addFeedback(){
				await addFeedback({content:this.setCon}).then(res => {
					this.$message.success('我们已经收到您的反馈，感谢您的信任与支持')
					setTimeout(() => {
						this.$router.go('-1')
					},2000)
				})
			}
		}
	}
</script>

<style scoped="scoped">
	
	.title {
		color: #FF684B;
		font-size: 18px;
		font-weight: bold;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}

	.con_box {
		margin-top: 20px;

	}

	textarea {
		padding: 20px 28px;
		width: 100%;
		background: #F3F4FF;
		border: none;
		border-radius:10px;
		height: 580px;
		resize: none;
		font-size: 18px;
	}

	.btns {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 60px;
	}

	.submit_btn {
		width: 75px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #5694F7;
		color: #fff;
		cursor: pointer;
		font-size: 20px;
		border-radius:10px;
	}
</style>
