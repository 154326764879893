<template>
  <div class="container">
    <div class="two_content">
      <div class="course_list">
        <div class="schedule_top">
          <div class="schedule_name">
            {{
              sieInfo.subject_name
            }}
          </div>
          <div class="schedule_input">
            <img src="../../assets/search.png" alt="" />
            <input @keyup.enter="blurSeacrh" v-model="vaName" type="text" placeholder="输入关键词查询" />
          </div>
        </div>
        <div class="course_i" @click="tolChange(item, item.id)" v-for="(item, index2) in sieList" :key="index2">
          <div class="cour_img" :style="{ background: prbaColor(item.type) }">
            <img v-if="item.type == 1" src="../../assets/images/login/icon_tj_wk.png" alt="" />
            <img v-if="item.type == 2 || item.type == 4" src="../../assets/images/login/icon_tj_zl.png" alt="" />
            <img v-if="item.type == 3" src="../../assets/images/login/icon_tj_cy.png" alt="" />
            <div v-if="item.type == 1">网课</div>
            <div v-if="item.type == 2">测验</div>
            <div v-if="item.type == 3">资料</div>
            <div v-if="item.type == 4">练习</div>
          </div>
          <div class="cour_info">
            <div class="cour_details">
              <div class="cour_name">{{ item.name }}</div>
              <div class="cour_kankj">
                <div v-if="item.type == 1">
                  课程时长：<span :style="{ color: backgChange(item.type) }">{{
              item.count_times
            }}</span>
                  分钟
                </div>
                <div v-if="item.type == 2 || item.type == 4">
                  总分：<span :style="{ color: backgChange(item.type) }">{{
              item.score || 0
            }}</span>
                  分
                </div>
                <div v-if="item.type == 3">
                  <img src="../../assets/images/login/icon_zt_zip.png" alt="" style="margin-right: 8px" />PDF电子资料
                </div>
                <div v-if="item.type == 3">
                  共<span :style="{ color: backgChange(item.type) }">1</span> 份
                </div>
              </div>
            </div>
            <div class="cuyright">
              <div class="cour_progress" v-if="item.type != 3">
                <div class="courpro_lg" :style="{
              background: backgChange(item.type),
              width: item.progress + '%',
            }"></div>
                <div class="cour_gard">
                  <div v-for="(item, index) in 4" :key="index"></div>
                </div>
              </div>

                <div v-if="item.type != 3">
                  已完成：<span :style="{ color: backgChange(item.type) }" style="font-weight: bold; font-size: 18px">{{
              item.progress }}</span>
                  %
                </div>
                <div class="cour_btn" v-if="item.progress < 100&&sieInfo.is_expire == 1" :style="{
              background: backgChange(item.type),
              color: '#ffffff'
            }">
                  {{ item.type == 3 ? "去下载" : "去学习" }}
                </div>
                <div v-if="item.progress == 100 && item.type != 3&&sieInfo.is_expire == 1" class="cour_btn">
                  已完成
                </div>
                <div v-if="item.progress == 100 && item.type == 3&&sieInfo.is_expire == 1" class="cour_btn" :style="{
              background: backgChange(item.type),
              color: '#ffffff'
            }">
                  去下载
                </div>
              <div class="cour_btn" style="background: #e4e0e0 !important;
    color: #999999 !important;" v-if="sieInfo.is_expire == 0">已过期</div>
            </div>
          </div>
        </div>
      </div>
      <div class="elprox" style="text-align: center">
        <el-pagination background layout="prev, pager, next" :total="sieCount" @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getSubjectContent, addSubjectLog } from "@/utils/erpapi.js";
export default {
  data() {
    return {
      sieList: [],
      sieCount: 0,
      vaName: "",
      sieInfo: {},
    };
  },
  created() {
    this.getSubjectContent();
  },
  methods: {
    handleCurrentChange(val) {
      this.getSubjectContent(val);
    },
    tolChange(data, id) {
      if(this.sieInfo.is_expire == 0){
        this.$message.info({
          message: "已过期",
          showClose: true,
        });
        return
      }
      if (data.progress < 100) {
        this.goSxxi(data, id)
        return
      }
      if (data.progress == 100 && data.type != 3) {
        this.goAns(id, data)
      }
      if (data.progress == 100 && data.type == 3) {
        this.goSxxi(data, id)
      }
    },
    // 去学习
    goSxxi(data, id) {
      this.subjectType = data.type;
      if (data.type == 1) {
        this.$router.push("/courseVideo?id=" + id + "&source=2");
      } else if (data.type == 3) {
        window.open(data.file_url);
        this.addsTrainin(id);
      } else {
        this.$router.push("/Cetopanswer?id=" + id + "&type=" + data.type);
      }
    },
    // 添加学习记录
    addsTrainin(id) {
      addSubjectLog({
        content_id: id,
      }).then((res) => { });
    },
    goAns(id, data) {
      let navarrs = {
        content_id: id,
        result_id: id,
        paths:this.$route.fullPath
      };
      this.subjectType = data.type;
      this.$router.push(
        "/AnswerEnd?parameter=" +
        encodeURIComponent(encodeURIComponent(JSON.stringify(navarrs)))
      );
    },
    blurSeacrh() {
      this.getSubjectContent();
    },
    getSubjectContent(pages) {
      getSubjectContent({
        name: this.vaName,
        subject_id: this.$route.query.id,
        limit: 10,
        page: pages || 1,
      }).then((res) => {
        this.sieList = res.data.list;
        this.sieCount = res.data.count;
        this.sieInfo = res.data.subject;
      });
    },
  },
};
</script>

<style scoped="scoped" lang="scss">
.schedule_name {
  font-size: 16px;
  font-weight: bold;
}

.schedule_top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.schedule_input {
  width: 200px;
  height: 40px;
  background: #f7f7f7;
  border: 1px solid #ebebeb;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding-left: 20px;

  img {
    width: 16px;
    height: 16px;
  }

  input {
    width: 120px;
    border: none;
    height: 38px;
    background: #f7f7f7;
    margin-left: 10px;
  }
}

.two_content {
  width: 1200px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px #e8edf3;
  border-radius: 6px;
  padding-top: 30px;
  padding-bottom: 10px;
}

.course_list {
  width: 1140px;
  margin: 0 auto;

  .course_i {
    width: 1140px;
    height: 110px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .cour_info {
      width: 1020px;
      padding: 0 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .cour_btn {
        width: 100px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 16px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        text-align: center;
        line-height: 32px;
        margin-left: 30px;
      }

      .cour_details {
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .cour_name {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #393f47;
        }

        .cour_kankj {
          display: flex;
          align-items: baseline;

          div {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            margin-right: 8px;

            span {
              font-size: 18px;
              color: #308dff;
              font-weight: bold;
            }
          }
        }
      }
    }

    .cour_img {
      width: 120px;
      height: 110px;
      background: linear-gradient(90deg, #0fa2ff, #17bdff);
      border-radius: 6px 0px 0px 6px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 30px;
        height: 30px;
      }

      div {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        margin-top: 10px;
      }
    }
  }
}

.tpelist_i:nth-child(even) {
  background-color: #ffffff;
}

.tpelist_i {
  width: 1140px;
  height: 80px;
  margin: 0 auto;
  background: #fbfbfb;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;

  .tpelist_i_left {
    display: flex;
    align-items: center;

    .tpe_inx {
      font-size: 40px;
      font-family: DIN Next LT Pro;
      font-weight: bold;
      color: #308dff;
    }

    .tpe_type {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 66px;
      height: 24px;
      background: #308dff0a;
      border-radius: 3px;
      margin: 0 20px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #308dff;

      img {
        width: 15px;
        height: 15px;
        margin-right: 6px;
      }
    }

    .tpe_name {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #393f47;
    }
  }

  .tpelist_i_right {
    display: flex;
    align-items: center;

    .tpe_pro {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;

      span {
        font-size: 18px;
        font-family: DIN Next LT Pro;
        font-weight: bold;
        color: #308dff;
      }
    }

    .tpe_btn {
      margin-left: 20px;
      width: 100px;
      height: 32px;
      background: #308dff;
      border-radius: 16px;
      line-height: 30px;
      text-align: center;
      color: #ffffff;
    }
  }
}

.cour_progress {
  position: relative;
  width: 100px;
  height: 10px;
  margin-left: 55px;
  margin-right: 15px;
  background: #d6e8ff;
  border-radius: 2px;

  .courpro_lg {
    position: absolute;
    height: 10px;
    z-index: 90;
    width: 50%;
    background: #308dff;
    top: 0;
    left: 0;
    border-radius: 2px;
  }

  .cour_gard {
    position: absolute;
    z-index: 99;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;

    div {
      width: 20px;
      height: 10px;
      background: #e4050500;
      border-right: 4px solid #ffffff;
      margin: 0;
    }
  }
}

.cuyright {
  display: flex;
  align-items: center;
}
</style>