<template>
	<div class="container">
		<div class="content" v-loading>
			<div class="study_list">
				<div class="tab_top">
					<div v-for="(item,index) in titleList" :class="current == index?'active':''"
						@click="studyClick(index,item.path)" :key="index">{{item.name}}</div>
				</div>
				<div class="child_box">
					<router-view></router-view>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import navBar from '@/components/navBar/navBar'
	import tableList from '@/components/tableList/tableList'
	import turnPages from '@/components/turnPages/turnPages'
	import {
		getUserCourse
	} from '@/utils/api.js'
	import {
		EventBus
	} from '@/eventBus/eventBus'
	export default {
		components: {
			tableList,
			turnPages,
			navBar
		},
		data() {
			return {
				clasType: '',
				ClassName: '',
				current: 0,
				titleList: [//'务实基础', '真题演练', '考前冲刺'
					{
						name:'务实基础',path:'mySolid',
					},{
						name:'真题巩固',path:'ztSolid',
					}
				],
				tabTitle: ['课程名称','课程类型','学习进度','操作'],
				typeList: [],
				pages: 1,
				count: 0,
				limit: 16,
				type: 1, //基础：type=1 提升：type=2 冲刺：type=3
				name: ''
			}
		},
		mounted() {
			EventBus.$on('studyCurrent',index => {
				console.log(index)
				this.current = index
				localStorage.setItem('studyCurrent',index)
			})
			// localStorage.setItem('studyCurrent',bus.$on('studyCurrent'))
		},
		created() {
			this.getData()
			if(localStorage.getItem('studyCurrent')){
				this.current = localStorage.getItem('studyCurrent')
			}else{
				this.current = 0
			}
			// EventBus.$emit('pageRouter',{
			// 		name: '学习中心',
			// 		pages: '/study',
			// 	})
		},
		methods: {
			async getData() {
				let promise = {
					type: this.type,
					limit: this.limit,
					page: this.pages,
					name: this.name
				}
				await getUserCourse(promise).then(res => {
					this.typeList = res.list
					this.count = Math.ceil(res.total / this.limit)
				})
			},
			studyClick(index,path){
				this.current = index
				localStorage.setItem('studyCurrent',index)
				this.$router.push('/'+path)
			},
		},
	}
</script>
<style scoped>
	.content {
		/* width: 1132px; */
		background: #fff;
		/* height: 50.625rem; */
		min-height: 780px;
		/* margin-top: 1.25rem; */
		/* border-radius: .625rem; */
		/* padding: 1.25rem 1.5625rem; */
		/* padding: 20px; */
		position: relative;
		/* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05); */
	}

	.title {
		font-size: .875rem;
		color: #7B92CB;
	}

	/* .study_list{

} */
	.pages_box {
		position: absolute;
		bottom: 20px;
		right: 20px;
	}

	.tab_top {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.tab_top div {
		width: 125px;
		height: 35px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		 cursor: pointer;
	}

	.tab_top .active {
		background: #47BE5E;
		border-radius: 18px;
		color: #fff;
	}

	.table_box {
		margin-top: 30px;
	}

	.table_title,
	.table_list {
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.table_title {
		background: #F7F7F7;
	}
	.table_title div{
		width: 17%;
		display: flex;
		font-size: 16px;
		font-weight: bold;
		color: #333;
		align-items: center;
		justify-content: center;
	}
	.table_title div:first-child,.table_list div:first-child{
		width: 49%;
		white-space:nowrap;
		overflow:hidden;
		text-overflow:ellipsis;
	}
	.table_list div{
		width: 17%;
		display: flex;
		color: #333;
		align-items: center;
		justify-content: center;
	}
	.table_list div:first-child{
		justify-content: flex-start;
	}
</style>
