<template>
    <div class="drag">
        <js-mind
                :values="mind"
                :options="options"
                ref="jsMind"
                height="600px"
                width="200px"
        ></js-mind>
    </div>
  </template>
  
  <script>
  export default {
    name: "HelloWorld",
    props: {
      msg: String,
    },
    data() {
      return {
        // 
        nodeId: "",
      drawer: false,
      direction: "rtl",
      theme_value: "",
      mind: {
        meta: {
          name: "jsMind remote",
          author: "hizzgdev@163.com",
          version: "0.2",
        },
        format: "node_tree",
        data: {
          id: "root",
          topic: "中国共产党建设理论",
          children: [
                { id: "easy1", topic: "Easy to show" },
                { id: "easy2", topic: "Easy to edit" },
                { id: "easy3", topic: "Easy to store" },
                { id: "easy4", topic: "Easy to embed" },
                { id: "easy5", topic: "Easy to show" },
                { id: "easy6", topic: "Easy to edit" },
                { id: "easy7", topic: "Easy to store" },
                { id: "easy8", topic: "Easy to embed" },
                { id: "easy9", topic: "Easy to show" },
                { id: "easy10", topic: "Easy to edit" }
              ],
        },
      },
    //   options: {
    //     container: "jsmind_container", // [必选] 容器的ID
    //     editable: false, // [可选] 是否启用编辑
    //     theme: "clouds", // [可选] 主题
    //   },
      options: {
        container: "jsmind_container",
        theme: "clouds",
        editable: false,
        default_style: {
          node: {
            background: "#fff",
            width: 140,
          },
          root: {
            color: "#000",
            background: "transparent",
            fontWeight: "bold",
          },
          text: {
            color: "#000",
            background: "transparent",
            lineHeight: "1.2",
          },
          line: {
            color: "#ccc",
          },
        },
      },
      treeData: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      isShow: true,
      };
    },
    methods: {
      
    },
    mounted() {
    this.jm = this.$refs.jsMind.jm;
    let _this = this;
    let div = document.querySelector(".theme-orange");
    let jmnode = div.querySelectorAll("jmnode");
    for (var i = 0; i < jmnode.length; i++) {
      jmnode[i].index = i;
    }
    let TimeFn = null;
    div.oncontextmenu = function (e) {
      e.preventDefault();
    };

    div.onmouseup = function (e) {
      if (!e) {
        e = window.Event;
      }
      if (e.button == 2) {
        console.log("右击");
        console.log(e.target);
        let nodeid = _this.jm.view.get_binded_nodeid(e.target);
        console.log(nodeid);

        const mainContainer = document.querySelector(".jsmind-inner");

        mainContainer.addEventListener("mousemove", runEvent);
        //event handler
        function runEvent(e) {
          e.preventDefault();
          let text = `MouseX: ${e.offsetX},  MouseY: ${e.offsetY}`;
          console.log(text);
        }
      }
    };

    div.addEventListener("click", function (e) {
      clearTimeout(TimeFn);
      TimeFn = setTimeout(function () {
        let nodeid = _this.jm.view.get_binded_nodeid(e.target);
        if (!!nodeid) {
          // let tagName = e.target.tagName.toLowerCase()
          // console.log(tagName)
          // console.log(e.target)
          console.log("单击");

          // 判断是否有子项
          let children = _this.jm.get_node(nodeid).children.length;
          console.log(children);
          if (children > 0) {
            return;
          }

          var selectedNode = _this.jm.get_selected_node(); // as parent of new node
          var nodeid2 = _this.jsMind.util.uuid.newid();
          var topic = "new Node";
          _this.jm.enable_edit();
          _this.jm.add_node(selectedNode, nodeid2, topic);
          _this.jm.disable_edit();
        }
      }, 300);
    });

    div.addEventListener("dblclick", function (e) {
      clearTimeout(TimeFn);
      var nodeid = _this.jm.view.get_binded_nodeid(e.target);
      if (!!nodeid) {
        console.log("双击");
      }
    });
  },
  };
  </script>
  
  <style scoped>
  .drag{
    margin-left: -200px;
  }
  </style>
  