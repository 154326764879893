<template>
	<div class="container">
		<div class="content">
			<div class="title">学习记录</div>
			<div class="video_record" :class="typeList ?'':'nothing'">
				<img src="@/assets/images/nothing.png" v-if="!typeList">
				<div class="video_list" v-for="(item,index) in typeList" :key="index" v-else
					@click="playeVideo(item.sub_name,item.sub_id)">
					<img :src="item.icon" style="border-radius: 10px;">
					<div class="class_name">{{item.sub_name}}</div>
				</div>
				<div v-if="typeList.length % 3 == 2 && typeList" class="video_list"></div>
			</div>
			<turnPages class="pages_box" :count="count" :pages="pages" @fanye="fanyeClick" @inputPage="inputPage">
			</turnPages>
		</div>
	</div>
</template>

<script>
	import turnPages from '@/components/turnPages/turnPages'
	import {
		getStadyList
	} from '@/utils/api.js'
	import {
		EventBus
	} from '../../../eventBus/eventBus'
	export default {
		components: {
			turnPages
		},
		data() {
			return {
				clasType: '',
				ClassName: '',
				count: 1,
				pages: 1,
				limit: 9,
				current: 0,
				titleList: ['务实基础', '真题演练', '考前冲刺'],
				typeList: [],
				type: 1, //基础：type=1 提升：type=2 冲刺：type=3
				name: '',
				navList:[
					{
						path:'/classList',
						name:'首页',
						studyCurrent:0,
						tabbarChildCurrent:0,
						tabbarCurrent:0
					},
					{
						path:'/classList',
						name:'在线学习',
						studyCurrent:0,
						tabbarChildCurrent:0,
						tabbarCurrent:0
					},
					{
						path:'/studyRecord',
						name:'学习记录',
						studyCurrent:0,
						tabbarChildCurrent:1,
						tabbarCurrent:0
					}
				]
			}
		},
		created() {
			this.getData()
			EventBus.$emit('navList',this.navList)
		},
		methods: {
			async getData() {
				let promise = {
					limit: this.limit,
					page: this.pages,
				}
				await getStadyList(promise).then(res => {
					this.typeList = res.data
					this.count = Math.ceil(res.tatol / this.limit)
				})
			},
			listJump(id, title) {
				this.$router.push('/studyList?id=' + id + '&titles=' + title)
			},
			playeVideo(title, id) {
				let arrs = {
					arr: this.navList,
					title: title,
					id: id,
					exclusive:'yes'
				}
				this.$router.push('/VideoPlay?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(arrs))));
			},
			fanyeClick(e) {
				if (e == 'next') {
					this.pages++
					console.log(this.pages)
					if (this.pages <= this.count) {
						this.getData()
					} else {
						this.pages = this.count
					}
				} else {
					this.pages--
					if (this.pages >= 1) {
						this.getData()
					} else {
						this.pages = 1
					}
				}
			},
			inputPage(e) {
				this.pages = e * 1
				this.getData()
			}
		}

	}
</script>
<style scoped>
	.pages_box {
		position: absolute;
		bottom: 20px;
		right: 20px;
	}

	.content {
		width: 1132px;
		background: #fff;
		padding: 20px;
		padding-bottom: 70px;
		position: relative;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
	}

	.title {
		padding-left: 15px;
		border-left: 5px solid #47BE5E;
		font-size: 18px;
		font-weight: bold
	}

	.video_record {
		margin-top: 30px;
		display: flex;
		display: -webkit-flex;
		justify-content: space-around;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.video_list {
		width: 280px;
		height: 240px;
		 cursor: pointer;
		border: 1px solid #EBEBEB;
	}

	.video_list img {
		width: 280px;
		height: 160px;
	}

	.class_name {
		color: #333;
		font-size: 16px;
		padding: 0 15px;
		margin-top: 15px;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
	}
</style>
