<template>
	<div class="warp">
		<div class="title">
			<div class="title_list" v-for="(item,index) in titleList" :key="index">{{item}}</div>
		</div>
		<div class="con_box">
			<div class="con_list" v-for="(item,index) in setData" :key="index">
				<!-- {{setData}} -->
				<div class="con_title">{{item.question}}</div>
				<!-- //第二栏 -->
				<div class="con_type" v-if="tagType == 'study'">{{item.type}}</div>
				<div class="con_type" v-else-if="tagType == 'nostudy'" :style="item.status == 2?'color:#FA4E53':''">
					{{item.status ==2?'未解答':'已解答'}}
				</div>
				<div class="con_type" v-else-if="tagType == 'simulation'">{{item.type_text}}</div>
				<!-- 第三栏 -->
				<div class="line" v-if="tagType == 'study'">
					<el-progress :text-inside="true" :stroke-width="8" :percentage="50" color="#555CEC"
						:show-text="false"></el-progress>
					{{50 + '%'}}
				</div>
				<div class="con_type" v-else>{{item.create_time,2 | dtime}}</div>
				<!-- 第四栏 -->
				<!-- <div class="jixu" v-if="item.num > 0 && tagType == 'study'" @click="answerClcik(item.title)">
					<img src="@/assets/images/pen_green.png">
					继续学习
				</div> -->
				<div class="kaishi" v-if=" tagType == 'study'" @click="answerClcik(item.name,item.id)">
					<img src="@/assets/images/pen_gray.png">立即学习
				</div>
				<div class="con_type" :style="item.status == 2?'color:#00d3aa':'color:#666'"
					v-else-if="tagType == 'nostudy'" @click="detailClick(index)">查看详情</div>
				<div class="con_type last" :style="item.status == '已结束'?'color:#666':'color:#00d3aa'"
					v-else-if="tagType == 'simulation'">
					{{item.status}}
					<span @click="simulationDetail(item.id,item.name)">详情</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		EventBus
	} from "@/eventBus/eventBus.js"
	export default {
		props: {
			titleList: {
				type: Array,
				defult: []
			},
			setData: {
				type: Array,
				defult: []
			},
			tagType: {
				type: String,
				defult: '',
			},
			navList: {
				type: Array,
				default: ''
			}
		},
		methods: {
			answerClcik(title,id) {
				// EventBus.$emit('paths',{arr:,title:title})
				let arrs = {
					arr: this.navList,
					title: title,
					id:id
				}
				this.$router.push('/studyList?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(arrs))));
			},
			detailClick() {
				this.$router.push('/doubtDetail')
			},
			simulationDetail(id,name) {
				console.log(1)
				let arrs = {
					arr: this.navList,
					title: name,
					id:id
				}
				this.$router.push('/simulationDetail?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(arrs))));
				// this.$router.push('/simulationDetail?id='+id)
			}
		}
	}
</script>

<style scoped>
	.jixu {
		font-size: 1.125rem;
		color: #00D3AA;
	}

	.kaishi {
		color: #666666;
		font-size: 1.125rem;
	}

	.jixu img,
	.kaishi img {
		width: 0.9375rem;
		height: 0.9375rem;
		margin: 0.375rem;
	}

	.title {
		display: flex;
		justify-content: space-around;
		align-items: center;
		background: #F3F4FF;
		border-radius: .625rem;
		height: 3.5625rem;
		font-size: 1.25rem;
		font-weight: bold;
	}

	.title_list {
		width: 13.84375rem;
		text-align: center;
		font-size: 1.25rem;
	}

	.con_list {
		display: flex;
		justify-content: space-around;
		align-items: center;
		height: 2.5rem;
		line-height: 2.5rem;
		font-size: 1.25rem;
	}

	.con_list div {
		width: 13.84375rem;
		text-align: center;
		padding: 0 1.25rem;
	}

	.con_title {
		width: 11.25rem;
		font-size: 1.125rem;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		text-align: center;
	}

	.con_type {
		font-size: 1.125rem;
		flex: 1;
	}

	.line {
		width: 18.125rem !important;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #00D3AA;
		font-size: 1.125rem;
	}

	.con_type {
		font-size: 1.125rem;

	}

	.last {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.con_type span {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 3.5625rem;
		border-radius:10px;
		font-size: 1.125rem;
		color: #FA4E53;
		border: 1px solid #FA4E53;
		height: 1.875rem;
		margin-left: 1.375rem;
	}
</style>
