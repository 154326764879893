import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login/login'
import shopindex from '@/views/shops/shopindex'
import Loginssss from '@/views/login/index'
// import Index from '@/views/index/index'
import Index from '@/views/index'
import styindex from '@/views/index/styindex'
import opstyindex from '@/views/shops/opstyindex'
import shodetail from '@/views/index/shodetail'
import erpindex from '@/views/index/erpindex'
import schedule from '@/views/index/schedule'
import studyplan from '@/views/index/studyplan'
import thatperson from '@/views/index/thatperson'
import courseSearch from '@/views/index/courseSearch'
import topicinfo from '@/views/index/topicinfo'
import optopicinfo from '@/views/shops/optopicinfo'
import ServiceView from '@/views/serviceView/service'
import Study from '@/views/study/study'
import Simulation from '@/views/simulation/simulation'
import Examination from '@/views/examination/examination'
import AminationCer from '@/views/aminationCer/aminationCer'
import DryrunDetail from '@/views/aminationCer/dryrunDetail'
import rili from '@/views/examination/rili'
import Doubt from '@/views/doubt/doubt'
import doubtdetail from '@/views/doubt/doubtdetail'
import subjectdetail from '@/views/doubt/subjectdetail'
import User from '@/views/user/user'

//介绍
import recommend from '@/views/recommend/recommend.vue'

// 在线学习
import studyList from '@/views/study/studyChild/studyList.vue'
import Answer from '@/views/study/studyChild/answer.vue'//答题
import Cetopanswer from '@/views/study/studyChild/cetopanswer.vue'//答题
import erroranswer from '@/views/study/studyChild/erroranswer.vue'//答题
import classList from '@/views/study/studyChild/classList.vue'//务实
import classSprint from '@/views/study/studyChild/classSprint.vue'//冲刺
import Analysis from '@/views/study/studyChild/analysis.vue'//解析
import cellAns from '@/views/study/studyChild/cellans.vue'//收藏
import AnswerEnd from '@/views/study/studyChild/answerEnd.vue'//答题结算
import VideoPlay from '@/views/study/studyChild/videoPlay.vue'//看视频
import studyReview from '@/views/study/studyChild/studyReview.vue'//知识点回顾
import trueTopic from '@/views/study/studyChild/trueTopic.vue'//真题答题
import studyRecord from '@/views/study/studyChild/studyRecord.vue'//学习记录
import trueTopicList from '@/views/study/studyChild/trueTopicList.vue'//真题列表
import report from '@/views/study/studyChild/report.vue'//答题报告

//个人中心
import userInfo from '@/views/user/userChild/userinfo'//个人信息
import myClass from '@/views/user/userChild/myClass'//我的课程
import myExam from '@/views/user/userChild/myExam' //我的考试
import myQuestion from '@/views/user/userChild/myQuestion' //我的疑问
import mySolid from '@/views/user/userChild/mySolid' //基础巩固
import setAndHelp from '@/views/user/userChild/setAndHelp' //帮助与反馈
import mySet from '@/views/user/userChild/mySet' //我的反馈
import mygg from '@/views/user/userChild/ctgg' //错题巩固
import ztSolid from '@/views/user/userChild/ztSolid' //真题巩固
import myFeedback from '@/views/user/userChild/myFeedback' //我的反馈
import FeedbackDetail from '@/views/user/userChild/FeedbackDetail' //反馈结果
import chapterList from '@/views/user/userChild/chapterList' //反馈结果
import memory from '@/views/user/userChild/memory' //反馈结果

//模考大赛
import simulationDetail from '@/views/simulation/simulationChild/simulationDetail' //模考详情

import drill from '@/views/serviceView/serviceChild/drill' //真题演练
import knowledge from '@/views/serviceView/serviceChild/knowledge' //知识点网课集
import systemClass from '@/views/serviceView/serviceChild/systemClass' //系统精讲课程
import politics from '@/views/serviceView/serviceChild/politics' //时事政治
import politic from '@/views/serviceView/serviceChild/politics' //时事政治
//直播列表
import liveList from '@/views/live/liveList' //直播列表
import liveDetail from '@/views/live/liveDetail' //直播列表
import livePlayer from '@/views/live/livePlayer' //直播课程


import forum from '@/views/forum/index' //莘知论坛


import studyCenter from '@/views/studyCenter/studyCenter'
import courseVideo from '@/views/studyCenter/courseVideo'


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter)
const routes = [
	{
		path: '/',
		redirect: '/shopindex'  //设置默认指向
	},
	{ path: '/Login', name: 'Login', component: Login, meta: { showTab: false, bg: true, title: '欢迎使用！莘知学习系统' } },//登录
	{ path: "/forum", name: 'forum', component: forum, meta: { showTab: false, nobg: true, title: "莘知论坛" } },//莘知论坛
	{ path: "/shopindex", name: 'shopindex', component: shopindex, meta: { showTab: false, nobg: true, title: "莘知论坛" } },//莘知论坛
	// { path: '/Loginssss',name:'Loginssss',component: Loginssss,meta:{showTab:false,bg:true}},//登录
	{
		path: '/Index', name: 'Index', component: Index, redirect: '/studyCenter', meta: { showTab: false, nobg: true }, children: [

			{
				    path: '/studyCenter', name: 'studyCenter', component: studyCenter, redirect: '/recommend', meta: { tabbar: false, navbox: false, showTab: false, nobg: true, title: "学习中心" }, children: [
					{ path: '/erpindex', name: 'erpindex', component: erpindex, meta: { tabbar: false, navbox: false, showTab: false, nobg: true, title: "学习中心-课程", navbox: false } },//
					{ path: '/recommend', name: 'recommend', component: recommend, meta: { tabbar: false, navbox: false, showTab: false, nobg: true, title: "学习中心-课程", navbox: false } },//
					{ path: '/styindex', name: 'styindex', component: styindex, meta: { tabbar: false, navbox: false, showTab: false, nobg: true, title: "学习中心-课程", navbox: false } },//
					{ path: '/opstyindex', name: 'opstyindex', component: opstyindex, meta: { tabbar: false, navbox: false, showTab: false, nobg: true, title: "学习中心-课程", navbox: false } },//
					{ path: '/shodetail', name: 'shodetail', component: shodetail, meta: { tabbar: false, navbox: false, showTab: false, nobg: true, title: "学习中心-课程", navbox: false } },//
					{ path: '/schedule', name: 'schedule', component: schedule, meta: { tabbar: false, navbox: false, showTab: false, nobg: true, title: "学习中心-课程", navbox: false } },//
					{ path: '/studyplan', name: 'studyplan', component: studyplan, meta: { tabbar: false, navbox: false, showTab: false, nobg: true, title: "学习中心-课程", navbox: false } },//
					{ path: '/thatperson', name: 'thatperson', component: thatperson, meta: { tabbar: false, navbox: false, showTab: false, nobg: true, title: "学习中心-课程", navbox: false } },//
					{ path: '/courseSearch', name: 'courseSearch', component: courseSearch, meta: { tabbar: false, navbox: false, showTab: false, nobg: true, title: "学习中心-课程", navbox: false } },//
					{ path: '/topicinfo', name: 'topicinfo', component: topicinfo, meta: { tabbar: false, navbox: false, showTab: false, nobg: true, title: "学习中心-课程", navbox: false } },
					{ path: '/optopicinfo', name: 'optopicinfo', component: optopicinfo, meta: { tabbar: false, navbox: false, showTab: false, nobg: true, title: "学习中心-课程", navbox: false } },
					{
						path: "/courseVideo", name: 'courseVideo', component: courseVideo, meta: {
							userinfo: false, tabbar: false, navbox: false, showTab: false, nobg: true, title: "莘知教育-视频详情",
							content_style: 'background: none;width: 100vw;padding-top:0;min-height:100vh;min-width: 1400px;'
						}
					},//视屏详情
				]
			},//在线学习	


			{
				path: '/Study', name: 'Study', component: studyCenter, redirect: '/classList', meta: { showTab: false, nobg: true }, children: [
					//    { path: '/Study',name:'Study', component: Study,redirect: '/classList',meta:{showTab:false,nobg:true},children:[//重定向首页
					//    { path:"/classList",name:'classList',component:classList,meta:{showTab:false,nobg:true,title:"莘知教育-在线学习系统"}},//学习记录
					{ path: "/classSprint", name: 'classSprint', component: classSprint, meta: { showTab: false, nobg: true, title: "莘知教育-考前冲刺" } },//学习记录
					{ path: "/trueTopicList", name: 'trueTopicList', component: trueTopicList, meta: { showTab: false, nobg: true, title: "莘知教育-真题列表" } },//真题列表
					{ path: "/politic", name: 'politic', component: politic, meta: { showTab: false, nobg: true, title: "莘知教育-时事政治" } },//时事政治
				]
			},//在线学习	
			{
				path: '/ServiceView', name: 'ServiceView', component: ServiceView, redirect: '/systemClass', meta: { showTab: false, nobg: true }, children: [
					{ path: "/systemClass", name: 'systemClass', component: systemClass, meta: { showTab: false, nobg: true, title: "莘知教育-课后专属服务" } },//系统知识点精讲
					{ path: "/knowledge", name: 'knowledge', component: knowledge, meta: { showTab: false, nobg: true, title: "莘知教育-知识点补充" } },//知识点补充
					{ path: "/drill", name: 'drill', component: drill, meta: { showTab: false, nobg: true, title: "莘知教育-真题列表" } },//真题列表
					{ path: "/politics", name: 'politics', component: politics, meta: { showTab: false, nobg: true, title: "莘知教育-时事政治" } },//时事政治
				]
			},//课后专属服务
			{ path: "/studyList", name: 'studyList', component: studyList, meta: { showTab: false, nobg: true, title: "莘知教育-课程列表" } },//课程列表
			{ path: "/VideoPlay", name: 'VideoPlay', component: VideoPlay, meta: { tabbar: false, navbox: false, showTab: false, nobg: true, title: "莘知教育-视频详情" } },//视屏详情
			{ path: "/studyReview", name: 'studyReview', component: studyReview, meta: { showTab: false, nobg: true, title: "莘知教育-知识点回顾" } },//知识点回顾
			{ path: "/Answer", name: 'Answer', component: Answer, meta: { showTab: false, nobg: true, tabbar: false, navbox: false, title: "莘知教育-章节答题" } },//章节答题
			{ path: "/Cetopanswer", name: 'Cetopanswer', component: Cetopanswer, meta: { showTab: false, nobg: true, tabbar: false, navbox: false, title: "莘知教育-章节答题" } },//章节答题
			{ path: "/erroranswer", name: 'erroranswer', component: erroranswer, meta: { showTab: false, nobg: true, tabbar: false, navbox: false, title: "莘知教育-章节答题" } },//章节答题
			{ path: "/AnswerEnd", name: 'AnswerEnd', component: AnswerEnd, meta: { showTab: false, tabbar: false, nobg: true, navbox: false, title: "莘知教育-答题结算" } },//答题结算
			{ path: "/Analysis", name: 'Analysis', component: Analysis, meta: { showTab: false, nobg: true, tabbar: false, navbox: false, title: "莘知教育-答题解析" } },//答题解析
			{ path: "/cellAns", name: 'cellAns', component: cellAns, meta: { showTab: false, nobg: true, tabbar: false, navbox: false, title: "莘知教育-答题解析" } },//收藏
			{ path: "/studyRecord", name: 'studyRecord', component: studyRecord, meta: { showTab: false, nobg: true, title: "莘知教育-学习记录" } },//学习记录
			{ path: "/trueTopic", name: 'trueTopic', component: trueTopic, meta: { showTab: false, nobg: true, tabbar: false, title: "莘知教育-真题答题" } },//学习记录
			//模拟大赛
			{ path: '/Simulation', name: 'Simulation', component: Simulation, meta: { showTab: false, nobg: true, title: "莘知教育-模考大赛" } },
			{ path: '/simulationDetail', name: 'simulationDetail', component: simulationDetail, meta: { showTab: false, nobg: true, title: "莘知教育-模考详情" } },//考试详情
			//考试中心
			{ path: "/AminationCer", name: 'AminationCer', component: AminationCer, meta: { showTab: false, nobg: true, tabbar: false, navbox: false, title: "莘知教育-考试中心" } },
			{ path: "/dryrunDetail", name: 'dryrunDetail', component: DryrunDetail, meta: { showTab: false, nobg: true, tabbar: false, navbox: false, title: "莘知教育-模考报名" } },
			//考试信息
			{ path: "/Examination", name: 'Examination', component: Examination, meta: { showTab: false, nobg: true } },
			{ path: "/rili", name: 'rili', component: rili, meta: { showTab: false, nobg: true } },
			//内部学员答疑
			{ path: "/Doubt", name: 'Doubt', component: Doubt, meta: { tabbar: false, navbox: false, showTab: false, nobg: true, title: "莘知教育-内部学员答疑" } },
			{ path: '/doubtdetail', name: 'doubtdetail', component: doubtdetail, meta: { tabbar: false, navbox: false, showTab: false, nobg: true, title: "莘知教育-答疑详情" } },//详情
			{ path: '/subjectdetail', name: 'subjectdetail', component: subjectdetail, meta: { tabbar: false, navbox: false, showTab: false, nobg: true, title: "莘知教育-答疑详情" } },//详情
			//个人中心
			{
				path: '/User', name: 'User', redirect: '/userInfo', component: User, meta: { tabbar: false, navbox: false, showTab: false, nobg: true, title: '莘知教育-个人中心' }, children: [
					{ path: '/userInfo', name: 'userInfo', component: userInfo, meta: { tabbar: false, navbox: false, showTab: false, nobg: true, title: '莘知教育-个人信息' } },//个人信息
					{ path: '/myClass', name: 'myClass', component: myClass, meta: { tabbar: false, showTab: false, nobg: true, title: '莘知教育-我的课程' } },//我的课程
					{ path: '/myExam', name: 'myExam', component: myExam, meta: { tabbar: false, showTab: false, nobg: true, title: '莘知教育-我的考试' } },//我的疑问
					{ path: '/myQuestion', name: 'myQuestion', component: myQuestion, meta: { tabbar: false, showTab: false, nobg: true, title: '莘知教育-个人信息' } },//我的疑问
					{ path: '/ztSolid', name: 'ztSolid', component: ztSolid, meta: { tabbar: false, showTab: false, nobg: true, title: '莘知教育-真题巩固' } },
					{ path: '/setAndHelp', name: 'setAndHelp', component: setAndHelp, meta: { tabbar: false, showTab: false, nobg: true, title: '莘知教育-帮助与反馈' } },//帮助与反馈
					{ path: '/mySet', name: 'mySet', component: mySet, meta: { tabbar: false, showTab: false, nobg: true, title: '莘知教育-问题反馈' } },//我的反馈
					{ path: '/myFeedback', name: 'myFeedback', component: myFeedback, meta: { tabbar: false, showTab: false, nobg: true, title: '莘知教育-我的反馈' } },//我的反馈
					{ path: '/FeedbackDetail', name: 'FeedbackDetail', component: FeedbackDetail, meta: { tabbar: false, showTab: false, nobg: true, title: '莘知教育-反馈详情' } },//我的反馈
					{ path: '/chapterList', name: 'chapterList', component: chapterList, meta: { tabbar: false, showTab: false, nobg: true, title: '莘知教育-背题收藏' } },//我的反馈
					{ path: '/memory', name: 'memory', component: memory, meta: { tabbar: false, showTab: false, nobg: true, title: '莘知教育-背题收藏' } },//我的反馈
				]
			},
			{ path: "/report", name: 'report', component: report, meta: { showTab: false, nobg: true, title: "莘知教育-答题报告" } },//答题报告
			{ path: "/liveList", name: 'liveList', component: liveList, meta: { showTab: false, nobg: true, title: "莘知教育-直播列表" } },//直播列表
			{ path: "/liveDetail", name: 'liveDetail', component: liveDetail, meta: { showTab: false, nobg: true, title: "莘知教育-直播列表" } },//直播列表
			{ path: "/livePlayer", name: 'livePlayer', component: livePlayer, meta: { showTab: false, nobg: true, title: "莘知教育-正在直播" } },//直播列表

		]
	},
]

console.log(routes)
const router = new VueRouter({
	mode: '',
	base: process.env.BASE_URL,
	routes
})

export default router
