<template>
	<div class="container">
		<!-- <userInfo></userInfo> -->
		<div class="content">
			<!-- <navBar :navList="navList"></navBar> -->
			<div class="wraps">
				<div class="child_box">
					<router-view></router-view>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				navList: [],
				// childList:['个人信息','我的课程','我的考试','我的疑问','错题巩固','帮助与设置'],
				current:0,
			}
		},
		created(){
			if(localStorage.getItem('userCurrent')){
				this.current = localStorage.getItem('userCurrent')
			}
		},
		methods:{
			navClick(i){
				console.log(i)
				this.current = i
				localStorage.setItem('userCurrent',i)
			}
		}
	}
</script>

<style scoped="scoped">
	.content {
		width: 1200px;
		box-sizing: border-box;
		padding: 20px 0;
		position: relative;
		min-height: 780px;
		/* background: #fff;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05); */
	}
	.wraps{
		margin-top: 10px;
	}
	.child_nav{
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		
	}
	.child_nav .nav_lists{
		font-size: 1.25rem;
		padding-right: 1.5625rem;
		padding: 0 1.875rem;
		height: 2.5rem;
		line-height: 2.5rem;
	}
	.active{
		color: #fff !important;
		border-radius: 1.25rem;
		background: linear-gradient(90deg, #3494FF, #505DDF);
		box-shadow: 0px 6px 12px 0px rgba(35, 113, 255, 0.29);
	}
</style>
