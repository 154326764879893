<template>
	<div class="container">
		<div class="contents" v-if="typeListing.length >0">
			<div class="title">正在直播</div>
			<div class="video_record" :class="typeListing ?'':'nothings'" style="min-height: 280px !important;">
				<img style="width:222px;height:168px ;" src="@/assets/images/nothing.png" v-if="!typeListing">
				<div class="video_lists" v-else v-for="(item,index) in 	typeListing" :key="index"
					@click="gourlsing(item.live_url,item.sub_id,item.old_course_id)">
					<img class="imgsize" :src="item.icon">
					<div class="asdas">
						<div class="class_name">{{item.name}}</div>
						<div class="times" v-if="item.live_start">
							<div class="biaoti">直播时间</div>
							<div>{{item.live_start,2|dtime}} ~ {{item.live_end,2|dtime}} </div>
						</div>
					</div>
				</div>
				<div v-if="typeListing" style="width:290px"></div>
				<div v-if="typeListing" style="width:290px"></div>
				<div v-if="typeListing" style="width:290px"></div>
			</div>
		</div>
		<div class="content">
			<div class="title">今日直播</div>
			<div class="video_record" :class="today.length>0 ?'':'nothing'">
				<img src="@/assets/images/nothing.png" v-if="today.length==0">
				<div class="video_list" v-for="(item,index) in today" :key="index" v-else
					@click="gourls(item.id,item.name)">
					<img :src="item.icon">
					<div class="asdas">
						<div class="class_name">{{item.name}}</div>
						<div class="times" v-if="item.live_start">
							<div class="biaoti">直播时间</div>
							<div>{{item.live_start,2|dtime}} ~ {{item.live_end,2|dtime}} </div>
						</div>
					</div>
				</div>
				<div v-if="today.length>0" style="width:290px"></div>
				<div v-if="today.length>0" style="width:290px"></div>
				<div v-if="today.length>0" style="width:290px"></div>
			</div>
			<!-- <div class="hr"></div>
			<turnPages class="pages_box" :count="count" :pages="pages" @fanye="fanyeClick" @inputPage="inputPage">
			</turnPages> -->
		</div>
		<div class="content" v-if="this.typeList.length>0">
			<div class="title">即将直播</div>
			<div class="video_record" :class="typeList ?'':'nothing'">
				<img src="@/assets/images/nothing.png" v-if="!typeList">
				<div class="video_list" v-for="(item,index) in typeList" :key="index" v-else
					@click="gourls(item.id,item.name)">
					<img :src="item.icon">
					<div class="asdas">
						<div class="class_name">{{item.name}}</div>
						<div class="times" v-if="item.live_start">
							<div class="biaoti">直播时间</div>
							<div>{{item.live_start,2|dtime}} ~ {{item.live_end,2|dtime}} </div>
						</div>
					</div>
				</div>
				<div v-if="typeList" style="width:290px"></div>
				<div v-if="typeList" style="width:290px"></div>
				<div v-if="typeList" style="width:290px"></div>
			</div>
			<!-- <div class="hr"></div>
			<turnPages class="pages_box" :count="count" :pages="pages" @fanye="fanyeClick" @inputPage="inputPage">
			</turnPages> -->
		</div>
	</div>
</template>

<script>
	import turnPages from '@/components/turnPages/turnPages'
	import {
		getZbCourse,
		getCouresing
	} from '@/utils/api.js'
	import {
		EventBus
	} from '../../eventBus/eventBus'
	export default {
		components: {
			turnPages
		},
		data() {
			return {
				clasType: '',
				ClassName: '',
				count: 1,
				pages: 1,
				limit: 9,
				current: 0,
				titleList: ['务实基础', '真题演练', '考前冲刺'],
				typeList: [],
				typeListing: [],
				today: [],
				type: 1, //基础：type=1 提升：type=2 冲刺：type=3
				name: '',
				navList: [{
						path: '/classList',
						name: '首页',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 0
					},
					{
						path: '/liveList',
						name: '在线直播',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 6
					}
				]
			}
		},
		created() {
			this.getData()
			EventBus.$emit('navList', this.navList)
			// this.getDataing()
		},
		methods: {
			async getData() {
				let promise = {
					limit: this.limit,
					page: this.pages,
				}
				await getZbCourse(promise).then(res => {
					this.typeList = res.data.tomorrow
					this.typeListing = res.data.ing
					this.today = res.data.today
					console.log(this.typeList)
					this.count = Math.ceil(res.data.tatol / this.limit)
				})
			},

			// async getDataing() {

			// 	const {
			// 		data: res
			// 	} = await getCouresing()
			// 	this.typeListing = res.data
			// 	console.log("this.typeListing")
			// 	console.log(JSON.stringify(this.typeListing))
			// },
			gourls(id, title) {
				this.navList.push({
					path: '/liveDetail',
					name: title,
					studyCurrent: 0,
					tabbarChildCurrent: 0,
					tabbarCurrent: 0

				})
				this.$router.push('/liveDetail?id=' + id + '&exclusive=yes' + '&titles=' + title + '&navList=' + escape(
					JSON.stringify(this
						.navList)))
			},
			gourlsing(url, id, old_course_id) {
				//  this.$router.push('/livePlayer?url='+url+'&id='+id) 
				let arrs = {
					urls: url,
					id: id,
					chat_id: old_course_id
				}
				this.$router.push('/livePlayer?datas=' + JSON.stringify(arrs))
			},
			fanyeClick(e) {
				if (e == 'next') {
					this.pages++
					console.log(this.pages)
					if (this.pages <= this.count) {
						this.getData()
					} else {
						this.pages = this.count
					}
				} else {
					this.pages--
					if (this.pages >= 1) {
						this.getData()
					} else {
						this.pages = 1
					}
				}
			},
			inputPage(e) {
				this.pages = e * 1
				this.getData()
			}
		}

	}
</script>
<style scoped>
	.nothing {
		width: 100%;
	}

	.pages_box {
		position: absolute;
		bottom: 20px;
		right: 20px;
	}

	.content {
		width: 1132px;
		background: #fff;
		padding: 20px;
		padding-bottom: 70px;
		position: relative;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
	}

	.contents {
		width: 1132px;
		background: #fff;
		padding: 20px;

		position: relative;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
	}

	.hr {
		width: 95%;
		height: 1px;
		background-color: #b3b9b4;
		margin: 0 auto;
		margin-bottom: 20px;
	}

	.title {
		padding-left: 15px;
		border-left: 5px solid #47BE5E;
		font-size: 18px;
		font-weight: bold
	}

	.imgsize {
		width: 285px;
		height: 162px;
	}

	.video_record {
		margin-top: 30px;
		display: flex;
		display: -webkit-flex;
		justify-content: space-around;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.video_list {
		width: 280px;
		padding-bottom: 10px;
		cursor: pointer;
		border: 1px solid #EBEBEB;
		box-shadow: 0px 0px 10px 0px #DCDCDC;
		margin-bottom: 30px;
	}

	.video_lists {
		width: 285px;
		padding-bottom: 10px;
		cursor: pointer;
		border: 1px solid #EBEBEB;
		box-shadow: 0px 0px 10px 0px #DCDCDC;
		margin-bottom: 40px;
	}

	.video_list img {
		width: 279px;
	}

	.times {
		/* padding: 15px; */
		padding-top: 15px;
	}

	.asdas {
		padding: 0 15px;
	}

	.biaoti {
		width: 66px;
		height: 22px;
		background: #FE784F;
		border-radius: 4px;
		font-size: 14px;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 10px;
	}

	.class_name {
		color: #333;
		font-size: 16px;
		/* padding: 0 15px; */
		margin-top: 15px;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		font-weight: bold;
		border-bottom: 1px solid #ccc;
		padding-bottom: 15px;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
	}
</style>
