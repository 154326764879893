<template>
	<div class="container">
		<!-- <userInfo></userInfo> -->
		<div class="page_title">{{ staticInfo.content_name }}</div>
		<div class="content">
			<!-- <navBar :navList="navList">asd</navBar> -->
			<!-- <div class="left_title">正确率</div> -->
			<div class="statistics">
				<div class="statt_left">
					<div class="garden">
						<el-progress type="circle"
							:percentage="Number(Math.floor(endInfo.sussEnd)) || parInttp(staticInfo.right_nums / staticInfo.total_nums * 100 || 0)"
							color="#5694F7" :show-text="false" :stroke-width="12" :width="130"></el-progress>
						<div class="texts" style="color:#5694F7">
							<div>
								<p style="color: #5694F7;" v-if="subjectType == 8">{{ Math.floor(endInfo.sussEnd) }}%</p>
								<p style="color: #5694F7;" v-else>{{
			parInttp(staticInfo.right_nums / staticInfo.total_nums * 100 || 0) }}%</p>
							</div>
							<span>正确率</span>
						</div>
					</div>
				</div>
				<div class="tongjx">
					<div class="tonx_top">
						<div class="daduixs">
							<div class="daduixs_tt"><img src="../../../assets/daduixs.png" alt="">{{subjectType==8?'已巩固':'答对'}}</div>
							<div class="daduixs_bb">{{ staticInfo.right_nums || 0 }} <span>/{{ staticInfo.total_nums
									}}</span>
							</div>
						</div>
						<div class="yongshixs" :style="{borderRight:subjectType==8?'none':'0.018519rem solid #EBEBEB'}">
							<div class="yongshixs_tt"><img src="../../../assets/yongshixs.png" alt="">用时</div>
							<span v-if="subjectType == 8">{{ endInfo.timer }}</span>
							<span v-else>{{
			staticInfo.total_spent_time > 0 ? timeCli(staticInfo.total_spent_time) : '00:00' }}</span>
						</div>
						<div class="paimingxs" v-if="subjectType!=8">
							<div class="paimingxs_tt"><img src="../../../assets/paimingxs.png" alt="">排名</div>
							<span>{{ staticInfo.ranking || 0 }}</span>
						</div>
					</div>
					<div class="tonx_bot" v-if="subjectType!=8">
						<div class="to_max">全国最高分 <span>{{ staticInfo.max_score || 0 }}</span></div>
						<div class="to_min">全国最低分 <span>{{ staticInfo.min_score || 0 }}</span></div>
						<div class="dbox">击败 <span>{{ staticInfo.winning || 0 }}%</span>考生</div>
					</div>
				</div>
			</div>
			<div class="card_box">
				<div class="card_title">
					<div class="name">答题卡</div>
					<div class="annotation">
						<!-- <div class="radio-box" v-for="(item,index) in radios" :key="item.id">
							<span class="radio" :class="{'on':item.isChecked}"></span>
							<input  v-model="sex" :value="item.value" class="input-radio" :checked='item.isChecked'  @click="huoqu(index)" type="radio">正确
						</div> -->
						<div class="yes"><span style="color: #47BE5E;"></span>正确</div>
						<div class="no"><span style="color: #FF684B ;"></span>错误</div>
						<div class="gray"><span style="color: #666;"></span>未答</div>
					</div>
				</div>
				<div class="card_content">
					<div class="card_list" :class="item.is_right == 1 ? 'zq' : item.is_right === 2 ? 'cw' : 'wad'"
						v-for="(item, index) in answerResult.List" :key="index" @click="cardClick(index)">{{ index + 1
						}}
					</div>
					<div class="lj" v-for="index3 in 10" :key="'id' + index3"></div>
				</div>
				<div class="bot_but">
					<div class="error" @click="errorClick">错题解析</div>
					<div class="whole" style="background:#67c23a" @click="analysisClick">全部解析</div>
					<div class="whole" v-if="is_complete != 1" style="margin-left:0"
						@click="moersisClick">{{subjectType==8?'继续巩固':'再次学习'}}</div>
				</div>
			</div>
		</div>
		<div class="mask_box" v-if="show && nextId && types !== 'zt'">
			<!-- <div class="mask_content">
				<img :src="errowNum>0?require('@/assets/zjzl.png'):require('@/assets/cg.png')">
				<div class="mask_big">{{errowNum>0?'再接再厉！马上就要成功了哟~~':'恭喜你！本小节你已经完全掌握了'}}</div>
				<div class="mask_btn" @click="show = false">查看解析</div>
				<div class="mask_small" @click="againClick" v-if="errowNum > 0">继续重练</div>
				<div class="mask_small" @click="nextSection" v-else>{{nextId !== 'last'?'开始下一小节':'返回学习中心'}}</div>
			</div> -->
		</div>
	</div>
</template>

<script>
import aAsnwer from '@/components/answer/index'
import navBar from '@/components/navBar/navBar'
import {
	examSubmit,
	answerSubmit,
	anslilianResult,//真题查看结果
	ansmockResult,//真题查看结果
	getanswerList,
	videoUnlock,
	getwrongRecall,
	videoExamresult
} from '@/utils/api.js'
import {
	addStuTopicLog, getSutTopicStatistics, getSutSubjectStatistics, addStuSubjectLog,
	updateStuRecallLog
} from '@/utils/erpapi.js'
import {
	EventBus
} from '@/eventBus/eventBus'
export default {
	components: {
		navBar
	},
	data() {
		return {
			score: {},
			answerResult: { List: [] },
			navList: [],
			errowNum: '',
			sussNum: '',
			show: true,
			nextId: '',
			types: '',
			radios: [
				{
					label: '正确',
					value: '正确',
					isChecked: false,
				},
				{
					label: '错误',
					value: '错误',
					isChecked: false,
				},
				{
					label: '未知',
					value: '未知',
					isChecked: 0,
				}
			],
			sex: "",
			quesName: '',
			timess: {
				s: '00',
				m: '00',
				h: '00',
				Hour: 0,
				minute: 0,
				second: 0,
			},
			routeQdata: {},
			staticInfo: {},
			loading: "",
			subjectType: "",//特训/专题/错题巩固
			endInfo: {},
			ssType: "",
			is_complete: 0,
		}
	},
	destroyed() {
		this.loading.close();
		localStorage.removeItem('result_id')
	},
	created() {
		this.ssType = this.$route.query.type
		this.routeQdata = JSON.parse(decodeURIComponent(this.$route.query.parameter))
		this.subjectType = this.$route.query.subjectType
		this.endInfo = this.$route.query
		this.loading = this.$loading({
			lock: true,
			text: "",
			spinner: "el-icon-loading",
			background: "rgba(0, 0, 0, 0.7)",
		});
		if (this.routeQdata.result_id) {
			this.queryChange()
		} else {
			if(this.subjectType==8){
				this.subExam()
				return
			}
			if (localStorage.getItem('result_id')) {
				this.queryChange()
			} else {
				localStorage.setItem('result_id', this.routeQdata.result_id)
				this.subExam()
			}
		}

		this.parameter(this.$route.query.parameter)
		// EventBus.$emit('navList', this.navList)
		let nextId = localStorage.getItem('nextId')
		if (nextId) {
			this.nextId = nextId
		}
	},
	mounted() {

	},
	methods: {
		// 提交答案
		subExam() {
			let that = this
			let arr = []
			let errorNum = []
			let subData = JSON.parse(localStorage.getItem('userLes'))
			console.log(subData)
			setTimeout(() => {
				subData.forEach((item, index) => {
					console.log(subData[index].correct)
					if (subData[index].correct == 'no') {
						errorNum.push({ correct: subData[index].correct })
					}
					arr.push({ id: subData[index].id, status: subData[index].status || 3, user_answer: subData[index].user_answer || '' })
				})
				this.loading.close();
				if (this.subjectType == 1) {
					console.log('-------')
					if (this.routeQdata.resutype == 1) {
						this.queryChange()
						return
					};
					addStuTopicLog(JSON.parse(decodeURIComponent(this.$route.query.parameter))).then(res => {
						// localStorage.removeItem('userLes')
						this.queryChange()
					})
				} else if (this.subjectType == 8) {
					let errData = JSON.parse(decodeURIComponent(this.$route.query.parameter)).ques
					console.log('899')
					console.log(errData)
					this.staticInfo.right_nums = subData.filter(function (item) { //对
						return item.is_right == 1;
					}).length
					this.staticInfo.total_nums = subData.length
					let sussData = []
					errData.forEach((item, index) => {
						if (item.is_right == 1) {
							sussData.push(item.ques_list_id)
						}
					})
					if (sussData == '') {
						this.answerResult.List = subData
						return
					}
					updateStuRecallLog({
						checkpoint_id: (this.ssType == 6 || this.ssType == 5) ? this.routeQdata.content_id || this.routeQdata.checkpoint_id : '',
						content_id: (this.ssType != 6 && this.ssType != 5) ? this.routeQdata.content_id || this.routeQdata.checkpoint_id : '',
						ids: sussData,
						type: this.$route.query.type,
						source: this.$route.query.source,
					}).then(res => {
						console.log(subData)
						this.answerResult.List = subData
					})
				} else {
					addStuSubjectLog(JSON.parse(decodeURIComponent(this.$route.query.parameter))).then(res => {
						// localStorage.removeItem('userLes')
						this.queryChange()
					})
				}

			}, 500)

		},
		// 查看结果
		queryChange() {
			let data = {}
			if (this.subjectType == 1) {
				if (this.routeQdata.training_log_id) {
					data = {
						checkpoint_id: (this.ssType == 6 || this.ssType == 5) ? this.routeQdata.content_id || this.routeQdata.checkpoint_id : '',
						content_id: (this.ssType != 6 && this.ssType != 5) ? this.routeQdata.content_id || this.routeQdata.checkpoint_id : '',
						training_log_id: this.routeQdata.training_log_id || ""
					}
				} else {
					data = {
						checkpoint_id: (this.ssType == 6 || this.ssType == 5) ? this.routeQdata.content_id || this.routeQdata.checkpoint_id : '',
						content_id: (this.ssType != 6 && this.ssType != 5) ? this.routeQdata.content_id || this.routeQdata.checkpoint_id : '',
					}
				}
				console.log(this.routeQdata)
				getSutTopicStatistics(data).then(res => {
					this.loading.close();
					this.ssType = this.$route.query.type
					this.answerResult.List = res.data.ques
					this.staticInfo = res.data.static
					this.is_complete = res.data.is_complete
				})
			} else {
				if (this.routeQdata.subject_log_id) {
					data = {
						content_id: this.routeQdata.content_id,
						subject_log_id: this.routeQdata.subject_log_id || ""
					}
				} else {
					data = {
						content_id: this.routeQdata.content_id,
					}
				}
				getSutSubjectStatistics(data).then(res => {
					this.ssType = this.$route.query.type
					this.answerResult.List = res.data.ques
					this.staticInfo = res.data.static
					this.loading.close();
				})
			}
		},
		//  真题题列表
		async zhentiList() {
			let arrs = {
				id: JSON.parse(decodeURIComponent(this.$route.query.parameter)).id,
				page: 1,
				limit: 500
			}
			await getanswerList(arrs).then(res => {
				let axemList = res.data.exam
				axemList.forEach((item, index) => {
					if (axemList[index].denominator) {
						axemList.splice(index, 1);
					}
				})
				this.answerResult.List.forEach((item, index) => {
					this.answerResult.List[index].user_answer = this.answerResult.List[index].user_answer
					this.answerResult.List[index].ansTimer = parseInt(this.answerResult.List[index].spent_time) + '秒'
					this.answerResult.List[index] = { ...this.answerResult.List[index], ...axemList[index] }
				})
				this.answerResult.List = this.removeDuplicateObj(this.answerResult.List)
			}).catch(err => {

			})
		},
		removeDuplicateObj(arr) {
			let obj = {};
			arr = arr.reduce((newArr, next) => {
				obj[next.list_id] ? "" : (obj[next.list_id] = true && newArr.push(next));
				return newArr;
			}, []);
			return arr;
		},
		//保留整数
		parInttp(num) {
			return parseInt(num);
		},
		// 时间处理
		timeCli(value) {
			var secondTime = parseInt(value);// 秒
			var minuteTime = 0;// 分
			var hourTime = 0;// 小时
			if (secondTime > 60) {//如果秒数大于60，将秒数转换成整数
				//获取分钟，除以60取整数，得到整数分钟
				minuteTime = parseInt(secondTime / 60);
				//获取秒数，秒数取余，得到整数秒数
				secondTime = parseInt(secondTime % 60);
				//如果分钟大于60，将分钟转换成小时
				if (minuteTime > 60) {
					//获取小时，获取分钟除以60，得到整数小时
					hourTime = parseInt(minuteTime / 60);
					//获取小时后取余的分，获取分钟除以60取余的分
					minuteTime = parseInt(minuteTime % 60);
				}
			}
			var result = parseInt(secondTime) < 10 ? '0' + parseInt(secondTime) : parseInt(secondTime);

			if (minuteTime > 0) {
				let min = parseInt(minuteTime) < 10 ? '0' + parseInt(minuteTime) : parseInt(minuteTime)
				result = min + ":" + result;
			} else {
				result = "00:" + result;
			}
			if (hourTime > 0) {
				let hour = parseInt(hourTime) < 10 ? '0' + parseInt(hourTime) : parseInt(hourTime)
				result = hour + ":" + result;
			} else {
				result = "00:" + result;
			}
			console.log('result', result);
			return result;
		},
		// 
		parameter(arr) {
			return
			let opdata
			if (arr) {
				opdata = JSON.parse(decodeURIComponent(arr))
				console.log(opdata)
				this.navList = opdata.arr
				// this.navList.pop()
				console.log(opdata.type)
				this.types = opdata.type
				let myPath = {
					name: opdata.title,
					path: this.$route.path,
					studyCurrent: 0,
					tabbarChildCurrent: 0,
					tabbarCurrent: 0,
					type: '',

				}
				console.log(opdata)
				this.navList.push(myPath)
				localStorage.setItem('answerEnd', JSON.stringify(opdata))
			} else {
				opdata = JSON.parse(localStorage.getItem('answerEnd'))
				console.log(opdata)
				this.navList = opdata.arr
				this.types = opdata.type
			}
		},
		restartClick() {
			let rout = JSON.parse(decodeURIComponent(this.$route.query.parameter))
			if (rout.below_shop_course_id) {
				this.$router.push(
					"/courseVideo?id=" + rout.shop_course_id + "&sub_id=" + rout.sub_id
				);
			} else {
				this.$router.replace('/courseList')
			}


		},
		nextSection() {
			if (this.nextId == 'last') {
				this.$router.replace('/classList')
			} else {
				let playerInfo = JSON.parse(localStorage.getItem('playerInfo'))
				playerInfo.id = parseInt(this.nextId)
				localStorage.setItem('playerInfo', JSON.stringify(playerInfo))
				this.$router.replace('/VideoPlay')
			}
		},
		cardClick(kindex) {
			localStorage.setItem('quesName', this.quesName)
			let sussArr = []
			this.answerResult.List.forEach((item, index) => {
				console.log(item)
				if (this.subjectType == 8) {
					item.user_answer = item.user_res
					sussArr.push(item)
					return
				}
				item.ques_list.is_right = item.is_right
				item.ques_list.user_answer = item.user_answer
				console.log(item.ques_list)
				sussArr.push(item.ques_list)
			})
			localStorage.setItem('analysis', JSON.stringify(sussArr))
			// return
			let gt = []
			gt.push(this.answerResult.List[kindex])
			let navarrs = {
				arr: this.navList,
				title: this.quesName,
				kindex: kindex
			}
			const routeData = this.$router.resolve({
				path: '/Analysis?parameter',
				query: {
					parameter: encodeURIComponent(encodeURIComponent(JSON.stringify(
						navarrs))),
					kindex: kindex,
					checkpoint_id: (this.ssType == 6 || this.ssType == 5) ? this.routeQdata.content_id || this.routeQdata.checkpoint_id : '',
					content_id: (this.ssType != 6 && this.ssType != 5) ? this.routeQdata.content_id || this.routeQdata.checkpoint_id : '',
					subjectType: this.subjectType,
					type: this.ssType,
					source: this.$route.query.source


				}
			});
			window.open(routeData.href, '_blank');

			EventBus.$emit('pageRouter', {
				name: '错题解析',
				pages: '/Analysis?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(
					navarrs))),
				checkpoint_id: (this.ssType == 6 || this.ssType == 5) ? this.routeQdata.content_id || this.routeQdata.checkpoint_id : '',
				content_id: (this.ssType != 6 && this.ssType != 5) ? this.routeQdata.content_id || this.routeQdata.checkpoint_id : '',
				subjectType: this.subjectType,
				type: this.ssType,
				source: this.$route.query.source
			})
		},
		// 再次学习
		moersisClick() {
			console.log('888')
		if((this.routeQdata.type==5||this.routeQdata.type==6)&&this.subjectType!=8){
			this.$router.push("/answer?id=" + (this.routeQdata.content_id || this.routeQdata.checkpoint_id)+"&type="+this.routeQdata.type);
		}else{
			this.$router.push(this.routeQdata.paths);
		}
			
		},
		analysisClick() {
			localStorage.setItem('quesName', this.quesName)
			let sussArr = []
			this.answerResult.List.forEach((item, index) => {
				if (this.subjectType == 8) {
					item.user_answer = item.user_res
					sussArr.push(item)
					return
				}
				item.ques_list.is_right = item.is_right
				item.ques_list.user_answer = item.user_answer
				console.log(item.ques_list)
				sussArr.push(item.ques_list)
			})
			localStorage.setItem('analysis', JSON.stringify(sussArr))
			let navarrs = {
				arr: this.navList,
				title: this.quesName,
			}
			const routeData = this.$router.resolve({
				path: '/Analysis?parameter',
				query: {
					parameter: encodeURIComponent(encodeURIComponent(JSON.stringify(
						navarrs))),
					checkpoint_id: (this.ssType == 6 || this.ssType == 5) ? this.routeQdata.content_id || this.routeQdata.checkpoint_id : '',
					content_id: (this.ssType != 6 && this.ssType != 5) ? this.routeQdata.content_id || this.routeQdata.checkpoint_id : '',
					subjectType: this.subjectType,
					type: this.ssType,
					source: this.$route.query.source
				}
			});
			window.open(routeData.href, '_blank');
			// this.$router.push('/Analysis')
			EventBus.$emit('pageRouter', {
				name: '全部解析',
				pages: '/Analysis?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(navarrs))),
				checkpoint_id: (this.ssType == 6 || this.ssType == 5) ? this.routeQdata.content_id || this.routeQdata.checkpoint_id : '',
				content_id: (this.ssType != 6 && this.ssType != 5) ? this.routeQdata.content_id || this.routeQdata.checkpoint_id : '',
				subjectType: this.subjectType,
				type: this.ssType,
				source: this.$route.query.source
			})
		},
		errorClick() {
			let errorArr = []
			let errorTol = this.answerResult.List.filter(item => {
				return item.is_right == 2
			})
			errorTol.forEach((item, index) => {
				if (this.subjectType == 8) {
					item.user_answer = item.user_res
					errorArr.push(item)
					return
				}
				item.ques_list.is_right = item.is_right
				item.ques_list.user_answer = item.user_answer
				console.log(item.ques_list)
				errorArr.push(item.ques_list)
			})
			if (errorArr.length == 0) {
				this.$alert('没有错题哦,你真厉害！', '提示', {
					confirmButtonText: '确定',
					callback: action => {
						this.$message({
							type: 'info',
							message: `action: ${action}`
						});
					}
				});
			} else {
				let navarrs = {
					arr: this.navList,
					title: this.quesName,
				}
				const routeData = this.$router.resolve({
					path: '/Analysis?parameter',
					query: {
						parameter: encodeURIComponent(encodeURIComponent(JSON.stringify(
							navarrs))),
						checkpoint_id: (this.ssType == 6 || this.ssType == 5) ? this.routeQdata.content_id || this.routeQdata.checkpoint_id : '',
						content_id: (this.ssType != 6 && this.ssType != 5) ? this.routeQdata.content_id || this.routeQdata.checkpoint_id : '',
						subjectType: this.subjectType,
						type: this.ssType,
						source: this.$route.query.source
					}
				});
				window.open(routeData.href, '_blank');
				EventBus.$emit('pageRouter', {
					name: '错题解析',
					pages: '/Analysis?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(
						navarrs))),
					checkpoint_id: (this.ssType == 6 || this.ssType == 5) ? this.routeQdata.content_id || this.routeQdata.checkpoint_id : '',
					content_id: (this.ssType != 6 && this.ssType != 5) ? this.routeQdata.content_id || this.routeQdata.checkpoint_id : '',
					subjectType: this.subjectType,
					type: this.ssType,
					source: this.$route.query.source
				})
				localStorage.setItem('quesName', this.quesName)
				localStorage.setItem('analysis', JSON.stringify(errorArr))
			}
		}
	}
}
</script>

<style scoped>
.page_title {
	font-size: 22px;
	font-family: Microsoft YaHei;
	font-weight: bold;
	color: #333333;
	margin: 20px 0;
}

.mask_box {
	width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, .5);
	z-index: 9;

}

.mask_content {
	background: #fff;
	padding: 15px;
	border-radius: 15px;
	padding-bottom: 30px;
}

.mask_content img {
	width: 400px;
	height: auto;
}

.mask_big {
	font-size: 25px;
	margin-top: 15px;
	text-align: center;
}

.mask_btn {
	width: 300px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	margin: 0 auto;
	background: linear-gradient(45deg, #5BA0F6, #2D81E9);
	box-shadow: 0px 6px 20px 0px rgba(91, 160, 246, 0.45);
	border-radius: 25px;
	font-size: 18px;
	margin-top: 40px;
}

.mask_small {
	font-size: 18px;
	color: #5BA0F6;
	text-align: center;
	margin-top: 30px;
}

.container {
	width: 1200px;
	margin: 0 auto 30px auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.content {
	background: #fff;
	min-height: 510px;
	width: 1100px;
	margin-top: 20px;
	border-radius: 10px;
	position: relative;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
	margin: 0 auto;
}

.statistics {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 40px 60px 40px 50px;
	box-sizing: border-box;
	width: 919px;
	height: 220px;
	background: #FFFFFF;
	border: 1px solid #C8E1FF;
	box-shadow: 0px 0px 20px 0px rgba(48, 141, 255, 0.2);
	border-radius: 110px;
	margin: 40px auto 50px auto;
}

.statt_left {
	display: flex;
	align-items: center;
}

.text_box {
	height: 35px;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	margin-left: 20px;
}

.duinum {
	position: relative;
	bottom: -4px;
	font-size: 30px;
	font-weight: bold;
	color: #5694F7;
	margin: 0 4px;
}

.text_box img {
	width: 20px;
	height: 20px;
	margin-right: 10px;
}

.text_box .tit {
	font-size: 16px;
	color: #666666;
}

.text_box .bigs {
	display: flex;
	align-items: flex-end;
	font-size: 16px;
	color: #666666;
}

.left_title {
	border-left: 5px solid #47BE5E;
	padding-left: 8px;
	font-size: 20px;
}

.left_garden {
	display: flex;
	justify-content: center;
	align-items: center;
}

.garden {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 146px;
	height: 146px;
	background: #FFFFFF;
	box-shadow: 0px 0px 10px 0px #CADEFF;
	border-radius: 50%;
}

.garden .texts {
	font-size: 32px;
	font-weight: bold;
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.garden .texts div {
	display: flex;
	align-items: flex-end;
	color: #666666;
}

.garden .texts div p {
	font-size: 36px;
	font-weight: bold;
	color: #000000;
}

.garden .texts span {
	font-size: 14px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #666666;
}

.garden_text {
	font-size: 18px;
	color: #5694F7;
	margin-top: 5px;
}

.left_btns {
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin-top: 30px;
}

.error {
	width: 110px;
	height: 36px;
	text-align: center;
	line-height: 36px;
	background: #FF684B;
	border-radius: 18px;
	font-size: 14px;
	font-family: Microsoft YaHei;
	font-weight: bold;
	color: #FFFFFF;
}

.jxsus {
	width: 110px;
	height: 36px;
	text-align: center;
	line-height: 36px;
	background: #EDEDED;
	border-radius: 18px;
	font-size: 14px;
	font-family: Microsoft YaHei;
	font-weight: bold;
	color: #666666;
}

.news {
	width: 110px;
	height: 36px;
	text-align: center;
	line-height: 36px;
	background: #5694F7;
	border-radius: 18px;
	font-size: 14px;
	font-family: Microsoft YaHei;
	font-weight: bold;
	color: #FFFFFF;
	margin-right: 25px;
}

.whole {
	width: 110px;
	height: 36px;
	text-align: center;
	line-height: 36px;
	background: #5694F7;
	border-radius: 18px;
	font-size: 14px;
	font-family: Microsoft YaHei;
	font-weight: bold;
	color: #FFFFFF;
	margin-right: 25px;
	margin-left: 25px;
}

.right_box div {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-top: 20px;
}

.right_box div span {
	font-size: 16px;
	color: #161616;
	margin-left: 9px;
}

.right_box div img {
	width: 17px;
	height: 17px;
}

.right_box div .tit {
	width: 14px;
	display: inline-block;
	text-align-last: justify;
	text-align: justify;
	color: #666666;
}

.right_box {
	/* display: flex;
		justify-content: space-between;
		flex-flow: column;
		flex-wrap: wrap; */
}

.next_btn {
	display: flex;
	justify-content: space-between !important;
	align-items: center;
}

.btnss {
	width: 86px;
	height: 30px;
	justify-content: center !important;
	align-items: center !important;
	color: #fff;
	background: #47BE5E;
	border-radius: 10px
}

.card_box {
	margin-top: 20px;
	padding: 20px 30px;
	background: #fff;
}

.card_title {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.card_title .name {
	line-height: 20px;
	border-left: 5px solid #5694F7;
	height: 20px;
	padding-left: 8px;
	font-size: 18px;
	font-family: Microsoft YaHei;
	font-weight: bold;
	color: #333333;
}

.card_title .annotation {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.card_title .annotation div {
	/* width: 50px; */
	height: 30px;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	margin-left: 16px;
}

.annotation .yes span {
	width: 12px;
	height: 12px;
	background: #F8FBFF;
	border: 1px solid #5694F7;
	border-radius: 3px;
	margin-right: 6px;
}

.annotation .no span {
	width: 12px;
	height: 12px;
	background: #FFF0ED;
	border: 1px solid #FFBAAD;
	border-radius: 3px;
	margin-right: 6px;
}

.annotation .gray span {
	width: 12px;
	height: 12px;
	background: #F4F4F5;
	border: 1px solid #CDCED0;
	border-radius: 3px;
	margin-right: 6px;
}

.yuan {
	display: block;
	width: 6px;
	height: 6px;
	border-radius: 3px;
	background: #666666;
	margin: 5px;
}

.card_content {
	margin-top: 30px;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	flex-wrap: wrap;
}

.card_list {
	width: 40px;
	height: 40px;
	background: #f2f2f2;
	border-radius: 4px;
	display: flex;
	font-size: 16px;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	color: #666666;
	font-weight: bold;
	margin: 14px 21px;
	border: 1px solid #DADADB;
}

.wad {
	background: #d6d6d6;
	color: #5f5e5e;
	border: 1px solid #CDCED0;
}

.lj {
	width: 40px;
	margin: 14px 21px;
}

.zq {
	background: #5694F7;
	color: #5694F7;
	border: 1px solid #5694F7;
	background: #F8FBFF;
}

.cw {
	background: #FF684B;
	color: #FE7F67;
	border: 1px solid #FFBAAD;
	background: #FFF0ED;
}

.btns {
	display: flex;
}

.bot_but {
	display: flex;
	align-items: center;
	justify-content: center;
}

.radio-box {
	display: inline-block;
	position: relative;
	height: 25px;
	line-height: 25px;
	margin-right: 5px;
}

.radio {
	display: inline-block;
	width: 16px;
	height: 16px;
	vertical-align: middle;
	cursor: pointer;
	background-image: url('../../../assets/weigxs.png');
	background-size: 100%;
	background-position: 0 0;
	margin-right: 6px;
}

.input-radio {
	display: inline-block;
	position: absolute;
	width: 18px;
	height: 18px;
	cursor: pointer;
	left: 0px;
	-webkit-appearance: none;
	white-space: nowrap;
}

.on {
	width: 18px;
	height: 18px;
	background-image: url('../../../assets/goxua.png');
}

.tongjx {
	width: 600px;
}

.tonx_top {
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding-bottom: 20px;
	border-bottom: 1px solid #EBEBEB;
}

.daduixs {
	width: 180px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.daduixs_tt {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #666666;
}

.daduixs_tt img {
	width: 18px;
	height: 18px;
	margin-right: 10px;
}

.daduixs_bb {
	margin-top: 16px;
	font-size: 32px;
	text-align: center;
	font-family: DIN Next LT Pro;
	font-weight: bold;
	color: #5694F7;
}

.daduixs_bb span {
	font-weight: 400;
	margin-left: -6px;
	text-align: center;
}

.yongshixs {
	width: 240px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-left: 1px solid #EBEBEB;
	border-right: 1px solid #EBEBEB;
}

.yongshixs_tt {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #666666;
}

.yongshixs_tt img {
	width: 18px;
	height: 18px;
	margin-right: 10px;
}

.yongshixs span {
	margin-top: 16px;
	font-size: 32px;
	font-family: DIN Next LT Pro;
	font-weight: bold;
	text-align: center;
	color: #999999;
}

.paimingxs {
	width: 180px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.paimingxs_tt {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #FD7000;
}

.paimingxs_tt img {
	width: 19px;
	height: 18px;
	margin-right: 10px;
}

.paimingxs span {
	margin-top: 16px;
	font-size: 32px;
	font-family: DIN Next LT Pro;
	font-weight: bold;
	color: #FD7000;
	text-align: center;
}

.tonx_bot {
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.to_max {
	width: 180px;
	margin-top: 24px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	color: #666666;
	font-size: 14px;
}

.to_max span {
	font-size: 24px;
	font-weight: bold;
	color: #FD7000;
	line-height: 26px;
}

.to_min {
	width: 260px;
	margin-top: 24px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	color: #666666;
	font-size: 14px;
}

.to_min span {
	font-size: 24px;
	font-weight: bold;
	color: #5694F7;
	line-height: 26px;
}

.dbox {
	width: 180px;
	margin-top: 24px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	color: #666666;
	font-size: 14px;
}

.dbox span {
	font-size: 24px;
	font-weight: bold;
	color: #14C395;
	line-height: 26px;
}
</style>
