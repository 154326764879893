<template>
	<div class="container">
		<!-- <userInfo></userInfo> -->
		<div class="content">
			<!-- <navBar :navList="navList"></navBar> -->
			<div class="wraps">
				<div class="wraps_title" v-if="status !== '已结束'">
					<span>{{title}}</span>
					<div class="btn" @click="goEnroll" v-if="type =='公告模考' &&  qq === 2 &&  answer == null">去报名</div>
					<div class="btn" @click="goAnswer"
						v-else-if="JSON.stringify(answerInfo) !== '[]' && answer == null">去答题</div>
					<div class="btn" style="background: #47BE5E ;" v-else-if="answer !== null">已答题</div>
				</div>
				<div class="wraps_title" v-else>
					<span>{{title}}</span>
				</div>
				<div class="times" v-if="JSON.stringify(answerInfo) !== '[]'">
					<i class="el-icon-alarm-clock"></i>
					{{ time,3 | dtime}}
				</div>
				<div class="table_box">
					<div class="table">
						<div class="table_title">{{tabName?tabName:title}}</div>
						<div class="table_types">
							<div class="list">试题类型</div>
							<div class="list">题型</div>
							<div class="list">题数</div>
							<div class="list">每题分数</div>
						</div>
						<div class="content_box" v-if="JSON.stringify(answerInfo) !== '[]'">
							<div class="content_title" :style="{height: answerInfo.length * 3 * 16 + 'px'}">{{'题型'}}
							</div>
							<div class="content_right">
								<div class="content_cons" v-for="(item2,index2) in answerInfo" :key="index2">
									<div class="con_list">{{item2.genre_text}}</div>
									<div class="con_list">{{item2.count}}</div>
									<div class="con_list">{{item2.score}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="rule">
					<div class="rule_title">考试规则</div>
					<p v-for="(item,index) in ruleList" :key="index">{{item}}</p>
				</div>
			</div>
		</div>
		<div class="mask" v-if="maskShow" @click="maskShow = false">
			<div class="mask_content" @click.stop="">
				<div class="mask_title">请选择学科并输入相关疑问</div>
				<div class="mask_con">
					<el-select v-model="selectValue" placeholder="请选择招聘岗位" class="kuang">
						<el-option v-for="item in options" :key="item.id" :label="item.company+'-'+item.name"
							:value="item.id">
						</el-option>
					</el-select>
					<div>
						<input type="text" v-model="school" placeholder="请输入毕业院校" class="kuang">
					</div>
					<div>
						<input type="text" v-model="education" placeholder="请输入最高学历" class="kuang">
					</div>
					<div>
						<input type="text" v-model="major" placeholder="请输入专业名称" class="kuang">
					</div>
					<div class="mask_btn" @click="mokeClick">提交</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getMockDetail,
		getMockPost,
		addMockPost
	} from '@/utils/api.js'
	import {
		EventBus
	} from '@/eventBus/eventBus'
	export default {
		data() {
			return {
				navList: [{
						path: '/classList',
						name: '首页',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 0
					},
					{
						path: '/simulation',
						name: '模考大赛',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 1
					}, {
						path: '/simulationDetail',
						name: '模考详情',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 1
					}
				],
				ruleList: [
					'1、考试前15分钟可进入阅卷（不可答题）。',
					' 2、考试开始半小时后不能入场。',
					'3、考试前半小时不能交卷。',
					' 4、中途退出后，考试未结束前可以进入继续答题。',
					' 5、提交交卷不能看排名，但可以看得分与解析。',
					' 6、考试时间截止后，未交卷的将自动交卷。'
				],
				maskShow: false,
				type: '',
				title: '',
				answerInfo: [],
				options: [],
				selectValue: '',
				school: '',
				education: '',
				major: '',
				tabName: '',
				time: '',
				qq: null,
				answer: null,
				status: ''
			}
		},
		created() {
			// this.id = this.$route.query.id
			this.parameter(this.$route.query)
			this.getData()

		},
		methods: {
			getData() {
				if (this.status == '已结束') {

				} else {
					getMockDetail({
						// mock_id: this.id
						mock_id: this.id
					}).then(res => {
						if (res.msg == '请先报名') {
							this.qq = 2
							this.$message.warning(res.msg)
							return
						} else if (JSON.stringify(res.list) == '[]') {
							this.$message.warning('该模考下暂无试题')
							this.answerInfo = res.list
							this.tabName = res.name
							this.answer = res.answer
							console.log(this.answer)
							this.qq = 1
							this.$forceUpdate()
							return
						} else {
							this.answerInfo = res.list
							this.tabName = res.name
							this.qq = 1
							this.answer = res.answer
						}
					})
				}
			},
			parameter(arr) {
				console.log(arr)
				if (JSON.stringify(arr) !== '{}') {
					this.id = arr.id
					this.type = arr.type
					this.title = arr.name
					this.time = arr.time
					this.status = arr.status
					EventBus.$emit('navList', this.navList)
					let simulationDetail = {
						id: this.id,
						type: this.type,
						title: this.title,
						time: this.time,
						status: this.status,
						navList: this.navList
					}
					localStorage.setItem('simulationDetail', JSON.stringify(simulationDetail))
				} else {
					console.log(1)
					let arr = JSON.parse(localStorage.getItem('simulationDetail'))
					this.id = arr.id
					this.type = arr.type
					this.title = arr.title
					this.time = arr.time
					this.status = arr.status
					this.navList = arr.navList
					EventBus.$emit('navList', this.navList)
				}
			},
			mokeClick() {
				console.log(this.selectValue)
				if (this.selectValue == '') {
					this.$message.error('请选择招聘岗位')
				} else if (this.school == '') {
					this.$message.error('请输入你的毕业院校')
				} else if (this.education == '') {
					this.$message.error('请输入你的学历')
				} else if (this.major == '') {
					this.$message.error('请输入你的专业名称')
				} else {
					addMockPost({
						mock_id: this.id,
						mock_post_id: this.selectValue,
						education: this.education,
						school: this.school,
						major: this.major
					}).then(res => {
						this.$message.success('报名成功')
						this.getData()
						this.maskShow = false
					})
				}
			},
			goAnswer() {
				// mock/getMockQuesList
				let arrs = {
					arr: this.navList,
					title: this.title,
					id: this.id,
					type: 'mock'
				}
				this.$router.push('/trueTopic?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(arrs))));
			},
			goEnroll() {
				this.maskShow = true
				getMockPost({
					mock_id: this.id
				}).then(res => {
					this.options = res.list
				})
			},
		}
	}
</script>

<style scoped="scoped">
	.mask {
		width: 100vw;
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		background: rgba(0, 0, 0, .5);
		top: 0;
		left: 0;
		z-index: 999;
	}

	.mask_content {
		width: 450px;
		height: 410px;
		padding: 25px 30px;
		background: #fff;
		border-radius: 5px;
	}

	.mask_title {
		font-size: 18px;
		font-weight: bold;
		text-align: center;
		color: #333333;
	}

	.mask_con {}

	.kuang {
		width: 390px;
		height: 40px;
		margin-top: 15px;
		border-radius: 5px;
	}

	.mask_con input {
		padding-left: 15px;
		color: #333;
		border: 1px solid #DCDFE6;
	}

	::-moz-placeholder {
		color: #999;
	}

	::-ms-input-placeholder {
		color: #999;
	}

	::-webkit-input-placeholder {
		color: #999;
	}

	.mask_btn {
		width: 388px;
		height: 36px;
		background: #47BE5E;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #Fff;
		font-size: 16px;
		border-radius: 5px;
		margin-top: 15px;
	}

	.content {
		width: 1132px;
		background: #fff;
		min-height: 780px;
		padding: 30px 20px;
		padding-bottom: 50px;
		position: relative;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
	}
	.wraps_title {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.wraps_title span {
		font-size: 20px;
		color: #161616;
		font-weight: bold;
	}

	.wraps_title .btn {
		width: 73px;
		height: 30px;
		background: #FA4E53;
		color: #fff;
		font-size: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 10px;
	}

	.times {
		margin-top: 23px;
		font-size: 18px;
		display: flex;
		align-items: center;
		justify-content: start;
		color: #666666;
	}

	.times i {
		font-size: 20px;
		margin-right: 7px;
	}

	.table_box {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 40px;
	}

	.table {
		width: 100%;
		/* border-radius:10px; */
		overflow: hidden;
		/* box-shadow: 0px 0px 20px 0px rgba(80, 93, 223, 0.2); */
	}

	.table_title {
		height: 56px;
		background: #EEEEEE;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 20px;
		color: #333333;
	}

	.table_types {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 56px;
		border: 1px solid #F5F5F5;
	}

	.table_types .list {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 20px;
		color: #444444;
	}

	.content_box {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.content_title {
		width: 25%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		height: 100%;
		color: #444444;
		border: 1px solid #F5F5F5;
	}

	.content_right {
		flex: 1;
	}

	.content_cons {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 48px;
	}

	.con_list {
		height: 100%;
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		color: #666;
		border: 1px solid #F5F5F5;
	}

	.rule_title {
		border-left: 5px solid #47BE5E;
		padding-left: 15px;
		font-size: 18px;
		font-weight: bold;
		margin-top: 40px;
	}

	p {
		padding-left: 20px;
		line-height: 2;
		color: #666;
		font-size: 14px;
	}
</style>
