<template>
	<div class="container" element-loading-text="正在加载中..."  v-loading="loading">
		<!-- <userInfo></userInfo> -->
		<div class="content" style="position: relative;">
			<div style="display: flex;justify-content: flex-end;"> 
				<img style="width: 20px;height: 20px;" src="@/assets/back.png" @click="$router.go(-1)" title="返回上一页">
			</div>
			<p class="content_title">{{title}}</p>
			<div class="content_box" :class="classList ?'':'nothing'">
				<img src="@/assets/images/nothing.png" v-if="!classList">
				<div class="pian" v-for="(item,index) in classList" :key="index"
					@click="()=>{if(current !== index){slide = true;sild2=true}else{slide = !slide;sild2=false} current = index;current2 = 0;getZhangList(item.id) }"
					v-else>
					<div class="pian_title">
						<img src="@/assets/icon/icon_open_s.png" alt="" v-if="current == index && slide">
						<img src="@/assets/icon/icon_open.png" alt="" v-else>
						{{item.name}}
					</div>
					<div class="pian_content">
						<el-collapse-transition class="pian_content" v-if="current == index && slide">
							<div>
								<div class="zhang" v-for="(item2,index2) in zhangData" :key="index2"
									@click.stop="slidClick(index,index2)">
									<div class=""
										style="display: flex;align-items: center;justify-content: space-between;">
										<div class="zhang_title">
											<img src="@/assets/icon/icon_open_s.png" alt=""
												v-if="current2 == index2  && slide2">
											<img src="@/assets/icon/icon_open.png" alt="" v-else>
											{{item2.name}}
										</div>
									</div>
									<div class="zhang_content">
										<el-collapse-transition class="pian_content"
											v-if="current2 == index2 && slide2">
											<div>
												<div class="jie" v-for="(item3,index3) in item2.courseSub" title="前往学习"
													:key="index3">
													<div class="jie_title"
														@click.stop="playeVideo(item3.name,item3.id,index,index3,index2,item3.chapter_id)">
														<span>{{item3.name}}</span>
														<div
															style="display: flex;align-items: center;justify-content: space-between;">
															<div class="time_boxss">
																本小节共
																&nbsp;<strong>{{(parseInt(item3.count_time/60)<10?'0'+parseInt(item3.count_time/60):parseInt(item3.count_time/60)) +'′'+ (item3.count_time%60<10?'0'+item3.count_time%60:item3.count_time%60 )+'″'}}</strong>&nbsp;
																,累计学习
																&nbsp;<strong v-if="item3.watch_time">
																	{{(parseInt(item3.watch_time_text/60)<10?'0'+parseInt(item3.watch_time_text/60):parseInt(item3.watch_time_text/60)) +'′'+ (item3.watch_time_text%60<10?'0'+item3.watch_time_text%60:item3.watch_time_text%60 )+'″'}}
																</strong>
																<strong v-else>00:00</strong>
																&nbsp;
															</div>
															<div
																v-if="item3.ques == 0 && (item3.video > 0 || item3.knowledge > 0) && exclusive !== 'yes'">
																<div class="dt-ntn btn_boxss"
																	v-if="item3.video > 0 && item3.knowledge == 0 ">已观看
																</div>
																<div class="dt-ntn btn_boxss"
																	v-if="item3.knowledge > 0 &&  exclusive !== 'yes' ">
																	已巩固</div>
															</div>
															<div class="btn_boxss"
																v-else-if="item3.ques> 0 && exclusive !== 'yes'">
																<img src="@/assets/gaizhang.png">
															</div>

															<div class="dt-ntn" v-else>前往学习</div>
														</div>

													</div>
												</div>
											</div>
										</el-collapse-transition>
									</div>
								</div>
								<!-- <div class="">本篇测验</div> -->
							</div>

						</el-collapse-transition>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import turnPages from '@/components/turnPages/turnPages'
	import {
		getCourseDetail,
		getCourseDetail1,
		getCourseSubKnowledge,
		getTestPaperQues,
		getCoursePiece,
		getCourseChapterSub
	} from '@/utils/api.js'
	import {
		EventBus
	} from '../../../eventBus/eventBus'
	export default {
		components: {
			turnPages,
		},
		data() {
			return {
				pages: 1,
				count: 100,
				navList: [],
				classList: [],
				loading: true,
				id: '',
				title: '',
				current: 0,
				current2: 0,
				zhangdt: 0,
				exclusive: '',
				slide: true,
				slide2: true,
				page: '',
				zhangData:[]
			}
		},
		created() {
			localStorage.removeItem('times')
			this.parameter(this.$route.query)
			EventBus.$emit('navList', this.navList)
			// this.getData()
			
			getCoursePiece({id:this.id}).then(res => {
				this.classList = res.data
				this.loading = true
				getCourseChapterSub({id:this.classList[0].id}).then(res => {
					console.log(res)
					
					this.zhangData = res.data
					this.loading = false
				})
			})
			// this.getData()
		},
		mounted() {

		},
		methods: {
			getZhangList(id){
				this.zhangData = []
				this.loading = true
				getCourseChapterSub({id:id}).then(res => {
					console.log(res)
					this.zhangData = res.data
					let yes = []
					for (let item2 of this.zhangData) {
						yes = item2.courseSub.filter(item => {
							return item.ques > 0
						})
						if (item2.courseSub.length == yes.length) {
							item2.zhangdt = 1
						} else {
							item2.zhangdt = 0
						}
					}
					this.loading = false
				})
			},
			async getData() {
				await getCourseDetail({
					id: this.id
				}).then(res => {
					this.$forceUpdate()
					this.classList = res.data
					this.zhangdt = 0
					this.getData1()
				})
			},
			format(percentage) {
				return percentage === 100 ? '满' : `${percentage}%`;
			},
			async getData1() {
				await getCourseDetail1({
					id: this.id
				}).then(res => {
					this.loading = false
					this.$forceUpdate()
					this.classList = res.data
					let yes
					for (let item1 of this.classList) {
						for (let item2 of item1.courseChapter) {
							yes = item2.courseSub.filter(item => {
								return item.ques > 0
							})
							if (item2.courseSub.length == yes.length) {
								item2.zhangdt = 1
							} else {
								item2.zhangdt = 0
							}
						}
					}
				})
			},
			slidClick(index, index2) {
				if (this.current2 !== index2) {
					this.slide2 = true
				} else {
					this.slide2 = !this.slide2
				}
				this.current = index
				this.current2 = index2
			},
			parameter(arr) {
				console.log(arr)
				if (JSON.stringify(arr) !== '{}') {
					this.id = arr.id
					this.title = arr.titles
					this.exclusive = arr.exclusive
					this.page = arr.page
					this.navList = JSON.parse(unescape(arr.navList))
					localStorage.setItem('studyList', JSON.stringify({
						id: this.id,
						title: this.title,
						navList: this.navList,
						exclusive: this.exclusive,
						page: this.page
					}))
				} else {
					let arrsaa = JSON.parse(localStorage.getItem('studyList'))
					console.log(arrsaa)
					this.id = arrsaa.id
					this.title = arrsaa.title
					this.navList = arrsaa.navList
					this.exclusive = arrsaa.exclusive
					this.page = arrsaa.page
					localStorage.setItem('studyList', JSON.stringify({
						id: this.id,
						title: this.title,
						navList: this.navList,
						exclusive: arrsaa.exclusive,
						page: this.page
					}))
					// this.navList = arr
				}
			},
			answerClcik(title, id, yes) {
				if (yes > 0 || this.exclusive == 'yes') {
					let arrs = {
						navList: this.navList,
						title: title,
						id: id,
						type: 1,
					}
					this.$router.push('/Answer?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(arrs))));
				} else {
					this.$message.error('请先完成巩固练习')
				}

			},
			playeVideo(title, id, index, index3, index2, zId) {
				console.log(title, id,index, index3, index2,zId)
				let classLi = this.zhangData
				console.log(classLi[index2])
				if (this.exclusive !== 'yes' && this.page !== 'chongci') {
					if (index3 !== 0 || index2 !== 0) {
						if (index2 >= 1 && classLi[index2 - 1].zhangdt == 0) {
							console.log(classLi[index2 - 1])
							return this.$message.error('请先完成前面的章节')
						} else {
							if (index3 > 0 && classLi[index2].courseSub[index3 - 1].ques == 0) {
								return this.$message.error('请先完成前面的小节')

							}
						}
					}
				}

				let arrs = {
					arr: this.navList,
					title: title,
					id: id
				}
				if (this.exclusive == 'yes') {
					arrs = {
						arr: this.navList,
						title: title,
						id: id,
						exclusive: 'yes'
					}
				} else {
					arrs = {
						arr: this.navList,
						title: title,
						id: id,
						zId: zId,
						listId: this.id,
						page: this.page
					}
				}
				this.$router.push('/VideoPlay?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(arrs))));
			},
			async reviewClick(title, id, yes) {
				if (yes > 0) {
					await getCourseSubKnowledge({
						id: this.id
					}).then(res => {
						if (res.code !== 400) {
							let arrs = {
								arr: this.navList,
								title: title,
								id: id
							}
							this.$router.push('/studyReview?parameter=' + encodeURIComponent(
								encodeURIComponent(JSON.stringify(arrs))));
						}
					})
				} else {
					this.$message.error('请先看完视频')
				}
			},
			testClick(items, id) {
				console.log(items)
				if (items == 0) {
					this.$message.error('请先完成本章节所有测试')
				} else {
					let arrs = {
						// arr: this.navList,
						title: items.name,
						id: id,
						type: 2
					}
					this.$router.push('/Answer?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(arrs))));
				}
				// let yes = items.courseSub.filter(item => {
				// 	return item.knowledge >　0
				// })
				// if(yes.length == items.courseSub.length){

				// }else{
				// 	this.$message.error('请先完成本章节所有测试')
				// }


			},
		}
	}
</script>

<style scoped>
	.time_boxss {
		width: 400px;
		color: #666;
	}

	.time_boxss strong {
		font-size: 16px;
		color: #333;
	}

	.btn_boxss {
		/* width: 86px; */
		display: flex;
		justify-content: center;
	}

	.btn_boxss img {
		width: 60px;
		height: auto;

	}

	.dt-ntn {
		padding: 8px 15px;
		background: #5694F7;
		color: #fff;
		border-radius: 10px;
	}

	.content {
		width: 1132px;
		background: #fff;
		/* height: 510px
		min-height: 780px;
		/* margin-top: 1.25rem; */
		/* border-radius: .625rem; */
		/* padding: 1.25rem 1.5625rem; */
		padding: 20px 20px;
		position: relative;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
	}

	.content_title {
		font-size: 16px;
		color: #333;
		text-align: center;
	}

	.pian_title {
		font-size: 16px;
		font-weight: bold;
		height: 50px;
		display: flex;
		align-items: center;
	}

	.zhang_title img,
	.pian_title img {
		margin-right: 10px;
	}

	.zhang_title,
	.jie_title {
		font-size: 16px;
		min-height: 50px;
		display: flex;
		align-items: center;

	}

	.jie_title {
		justify-content: space-between;
		cursor: pointer;
		padding: 10px 15px;
	}

	.jie_title:hover {

		background: #e7fdeb;
		border-radius: 5px;
	}

	/* .jie_title:hover span {
		color: #fff !important;
	}
	.jie_title:hover div {
		color: #fff !important;
	} */

	img {
		cursor: pointer
	}

	.pian_content,
	.zhang_content {
		padding-left: 20px;
	}

	.pages_box {
		position: absolute;
		bottom: 30px;
		right: 20px;
	}

	.wraps {
		margin-top: .625rem;
		height: calc(100% - 0.875rem);
		overflow-y: scroll;
	}

	.wraps::-webkit-scrollbar {
		display: none;
	}

	.class_title {
		color: #000000;
		font-size: 1.125rem;
		padding: 1.1875rem 0;
	}

	.class_content {
		background: #F3F4FF;
		padding-left: 4.0625rem;
		padding-right: 2.1875rem;
		border-radius: .625rem;
		padding-top: 1.25rem;
	}

	.class_content_box {
		/* display: flex;
		justify-content: space-between;
		align-items: center; */
		/* height: 3.5625rem; */
		border-bottom: 1px solid #E4E4E4;
		margin-bottom: 1.25rem;
	}

	.class_content_box:last-child {
		margin-bottom: 0 !important
	}

	.jie_box {
		padding-left: 1.875rem;
		padding-top: 1.25rem;
	}

	.jie_list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 3.5625rem;
		font-size: 1rem;
	}

	.class_content_box:last-child {
		border: none
	}

	.jie_class_name {
		width: 22.1875rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding-right: 1.875rem;
		font-size: 1.125rem;
		color: #333;
	}

	.class_name {
		/* width: 22.1875rem; */
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		/* padding-right: 1.875rem; */
		font-size: 1.125rem;
		color: #333;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.btns_box {
		flex: 1;
		display: flex;
		justify-content: space-between;
	}

	.btns_box div {
		min-width: 5.625rem;
		color: #fff;
		font-size: 1rem;
		padding: .3125rem .625rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 10px;
	}

	.btns {
		background: #00D3AA;
		min-width: 5.625rem;
		color: #fff;
		font-size: 1rem;
		padding: .3125rem .625rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 10px;
	}

	.sp {
		background: #FA4E53;
	}
</style>
