<template>
    <div class="shopindex">
        <auserInfo @actiChange="actiChange" :cityIndex="ciIndex" :cateShowxx="cateShowxx"></auserInfo>
        <div class='miaov_head' style="background: #ffffff;">
            
            <div class="shotab">
                <div class="shotab-i" :class="index2==treeIndex?'stabactive':''" 
                v-for="(tre,index2) in treeList" :key="index2" @click="tabClick(index2)">{{ tre.name }}</div>
            </div>
            <div class="shotab" style="margin: 0 auto;padding: 0;">
                <div class="shotab-i" :class="renIndex==99?'stabactive':''" @click="renClick(99)">全部</div>
                <div class="shotab-i" :class="index3==renIndex?'stabactive':''" 
                v-for="(ren,index3) in treeList[treeIndex].children" :key="index3" @click="renClick(index3)">{{ ren.name }}</div>
            </div>
        </div>
        <div>
            <div class="sho-swiper">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" @click="goUrl(item.url)" v-for="(item,index) in bannerList" :key="index"><img class="swiperimg" :src="item.banner" alt=""></div>
                    </div>
                </div>
            </div>

            <div class="ashore-list" v-if="traList!=''">
                <div class="ashore-title">
                    <img class="img1" src="../../assets/ashore.png" alt="">
                    <img class="img2" src="../../assets/ashore_text.png" alt="">
                </div>
                <div class="alilist">
                    <div class="alilist-i" @click="toRouter(item1)" v-for="(item1,index1) in traList" :key="index1">
                      <img :src="item1.image" alt="">
                      <div class="alinumpr-name">{{ item1.title }}</div>
                      <div class="alinumpr">
                         <span class="alinumpr-num">{{ item1.brief }}</span>
                         <!-- <div class="alinumpr-price">
                            <span>￥</span>
                            888
                         </div> -->
                      </div>
                    </div>
                    <div style="width: 280px;"></div>
                    <div style="width: 280px;"></div>
                </div>
            </div>
        </div>
        <div class='miaov_footer' v-if="false">
            <div class="page-footer">
                <div class="miaovcontainer">
                    <div class="content">
                        <div class="ad">
                            <div class="detail">
                                <div class="app">
                                    <div class="img">
                                        <img src="../../assets/appcode.png" alt="" class="image_file">
                                    </div>
                                    <div class="text">
                                        <div class="title">莘知教育APP</div>
                                        <div class="desc">考编之路莘知起步</div>
                                        <div class="button">
                                            <a href="https://apps.apple.com/cn/app/id1539888185" target="_blank">
                                                <div class="button-link ios">
                                                    <div class="image">
                                                        <img src="../../assets/ihnpe.png" alt="">
                                                    </div>
                                                    <div class="text">
                                                        IOS下载
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="https://sj.qq.com/appdetail/szedu.com.shenzhi.szedu"
                                                target="_blank">
                                                <div class="button-link  android">
                                                    <div class="image">
                                                        <img src="../../assets/anzo.png" alt="">
                                                    </div>
                                                    <div class="text">
                                                        安卓下载
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="study">
                                    <div class="title">线上学习平台</div>
                                    <div class="study_icons">
                                        <a href="https://sj.qq.com/appdetail/szedu.com.shenzhi.szedu" target="_blank"
                                            class="icons_item">
                                            <div class="img">
                                                <img src="../../assets/goju1.png" alt="">
                                            </div>
                                            <div class="text">莘知教育</div>
                                        </a>
                                        <a href="https://a.app.qq.com/o/simple.jsp?pkgname=cn.net.tiku.jbcgk.syn"
                                            target="_blank" class="icons_item">
                                            <div class="img">
                                                <img src="../../assets/goju2.png" alt="">
                                            </div>
                                            <div class="text">莘知公考</div>
                                        </a>
                                        <a href="https://a.app.qq.com/o/simple.jsp?pkgname=cn.net.tiku.gpjiaoshi.syn"
                                            target="_blank" class="icons_item">
                                            <div class="img">
                                                <img src="../../assets/goju3.png" alt="">
                                            </div>
                                            <div class="text">莘知教师</div>
                                        </a>
                                        <a href="https://a.app.qq.com/o/simple.jsp?pkgname=cn.tiku.android.zsb"
                                            target="_blank" class="icons_item">
                                            <div class="img">
                                                <img src="../../assets/goju4.png" alt="">
                                            </div>
                                            <div class="text">专升本</div>
                                        </a>
                                        <a href="https://a.app.qq.com/o/simple.jsp?pkgname=cn.tiku.android.zsb"
                                            target="_blank" class="icons_item">
                                            <div class="img">
                                                <img src="../../assets/goju5.png" alt="">
                                            </div>
                                            <div class="text">茅习帮</div>
                                        </a>
                                        <a href="https://a.app.qq.com/o/simple.jsp?pkgname=cn.tiku.android.zsb"
                                            target="_blank" class="icons_item">
                                            <div class="img">
                                                <img src="../../assets/goju6.png" alt="">
                                            </div>
                                            <div class="text">考场分布</div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="footer_detail">
                            <div class="friend-contact">
                                <div class="item_box">
                                    <a href="https://www.jinbiaochi.com/" target="_blank" class="contact_item"> 首页 </a>
                                    <a href="https://www.jbcgk.com/" target="_blank" class="contact_item"> 关于我们 </a>
                                    <a href="https://www.jbcjiaoshi.com/" target="_blank" class="contact_item"> 课程 </a>
                                    <a href="https://dingwei.cn/" target="_blank" class="contact_item"> 题库 </a>
                                    <a href="https://dingwei.cn/" target="_blank" class="contact_item"> 咨询 </a>
                                    <a href="https://dingwei.cn/" target="_blank" class="contact_item"> 法律声明 </a>
                                </div>
                            </div>
                            <div class="contact_detail">
                                <div class="left">
                                    <span class="copyright">
                                        <a href="https://beian.miit.gov.cn/" target="_blank"
                                            style="color: #949699;">Copyright©2021 版权所有 重庆题酷信息科技有限公司</a>
                                    </span>
                                    <span class="record">
                                        <a href="https://beian.miit.gov.cn/" target="_blank">
                                            <span class="url">网站备案号：渝ICP备16011660号-1</span>
                                        </a>
                                        <span class="police">
                                            <img src="../../assets/record.png" alt="" class="img">
                                        </span>
                                        <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010502001426"
                                            target="_blank">
                                            <span class="url">渝公网安备50010502001426号</span>
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="footer_box">
      <div class="footer_content">
        <p>
          客服咨询：400-6655-517&nbsp;&nbsp;投诉建议：023-65010703&nbsp;&nbsp;公司邮箱：cqshenzhijiaoyu@163.com
          &nbsp;&nbsp;地址：重庆市渝中区大坪正街118号1、2幢3层
        </p>
        <p>
          Copyright©2020 莘知教育&nbsp;&nbsp;All Rights Reserved
          渝ICP备18013494号-1&nbsp;&nbsp;&nbsp;<img
            src="@/assets/icon/icon_110.png"
            alt=""
          />
          渝公网安备 50010302002746号
        </p>
      </div>
    </div>
    </div>
</template>

<script>
import auserInfo from "@/components/userInfo/userInfo1.vue";
import Swiper from 'swiper';
import { getBannerList,getOnlineSuccessTrainingClass,getCateTreeList} from '@/utils/api.js'
export default {
    components: {
    auserInfo
  },
    data() {
        return {
            bannerList:[],
            traList:[],
            treeList:[],
            treeIndex:0,
            ciIndex:0,
            renIndex:99,
            cityId:500000,
            cateShowxx:false,
        }
    },
    mounted() {
        new Swiper('.swiper-container', {
            loop: true,
            slidesPerView : 'auto',
            autoplay: true,//可选选项，自动滑动
        })
    },
    created() {
        if(!localStorage.getItem('UserToken')){
            this.cateShowxx=true
         }
         if(localStorage.getItem('cityid')){
            this.ciIndex=localStorage.getItem('cityindex')||0
            this.cityId=localStorage.getItem('cityid')
        }
        if(localStorage.getItem('UserToken')){
            this.$router.push('/erpindex');
        return
       }
        this.getCateTreeList()
    },
    methods: {
        actiChange(data){
            this.ciIndex=data.index
            this.cityId=data.id
            localStorage.setItem('cityid', data.id)
            localStorage.setItem('cityindex', data.index)
            this.getBannerList()
            this.getOnlineSuccessTrainingClass()
        },
        tabClick(index){
            this.renIndex=99
            this.treeIndex=index
            this.getBannerList()
            this.getOnlineSuccessTrainingClass()
        },
        renClick(index){
            this.renIndex=index
            this.getBannerList()
            this.getOnlineSuccessTrainingClass()
        },
        getBannerList(){
            getBannerList({
                main_id:this.treeList[this.treeIndex].id,
                cate_id:this.renIndex==99?'':this.treeList[this.treeIndex].children[this.renIndex].id,
                city_id: this.cityId
            }).then(res=>{
              this.bannerList=res.data.list
            })
        },
        getOnlineSuccessTrainingClass(){
            getOnlineSuccessTrainingClass({
                main_id:this.treeList[this.treeIndex].id,
                cate_id:this.renIndex==99?'':this.treeList[this.treeIndex].children[this.renIndex].id,
                city_id: this.cityId
            }).then(res=>{
                this.traList=res.data.list
            })
        },
        getCateTreeList(){
            getCateTreeList().then(res=>{
               this.treeList=res.data
               this.getBannerList()
                this.getOnlineSuccessTrainingClass()
            })
        },
        goUrl(url){
            console.log(url)
            window.open(url,'_blank')
        },
        toRouter(data){
            this.$router.push('/shodetail?id=' +data.id);
        }
    }
}
</script>

<style scoped lang="scss">
.user_info_box{
    box-shadow: none;
}
.swiperimg{
    // width: 1180px;
    // height: 390px;
    width: 100%;
    height: 100%;
    border-radius: 6px;
}
.loginsuss{
    display: flex;
    align-items: center;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    img{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 10px;
    }
    span{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        margin-left: 20px;
    }
}
.alilist{
    margin: 30px auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .alilist-i{
        width: 280px;
        background: #FFFFFF;
        border-radius: 6px;
        border: 1px solid #EBEBEB;
        margin-bottom: 20px;
        padding: 20px;
        cursor: pointer;
        img{
            width: 240px;
            height: 220px;
            border-radius: 6px;
        }
        .alinumpr-name{
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 16px;
            color: #333333;
            margin-top: 25px;
        }
        .alinumpr{
            margin-top: 20px;
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            .alinumpr-num{
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 12px;
                color: #666666;
                overflow: hidden;
                display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
                -webkit-line-clamp: 3; /* 行数，值可以改，表示展示X行后多余的缩略展示 */
                -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
                word-break: break-all;
            }
            .alinumpr-price{
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 21px;
                color: #FE5E3F;
            }
            .alinumpr-price span{
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 12px;
                color: #FE5E3F;
            }
        }
    }
    .alilist-i:not(:nth-child(4n)) {
        margin-right: 12.5px;
    }
}
.ashore-list{
    width: 1180px;
    margin: 50px auto;
    .ashore-title{
        .img1{
            width: 30px;
            height: 30px;
            margin-right: 10px;
        }
        .img2{
           height: 27px;
        }
    }
}
.sho-swiper{
 background: #ffffff;
 padding:30px 0;
 margin-top: 10px;
 .swiper-container{
    // width: 1180px;
    height: 390px;
 }
}
.swiper-slide{
        // width: 1180px !important;
        height: 390px !important;
        background: #cccccc !important;
        border-radius: 10px;
    }
.shotab {
    width: 1180px;
    padding:20px 0 10px 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    cursor: pointer;
    .shotab-i {
        padding-right: 40px;
        font-family: Microsoft YaHei;
        color: #666666;
        margin-bottom: 12px;
        cursor: pointer;
    }

    .stabactive{
        font-weight: bold;
        color: #2878FF;
    }
}

.shopindex {
    min-width: 100vw;
    min-height: 100vh;
    position: relative;
    background: #f5f5f5;
}

.miaov_footer {
    // position: absolute;
    min-width: 100vw;
    left: 0;
    bottom: 0;
    background: #ffffff;
}

.page-footer {
    width: 1180px;
    background: #ffffff;
    margin: 0 auto;
}

.page-footer .content {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    padding: 0;
    /* width: 1180px; */
    padding-top: 30px;
}

.page-footer .content .ad {
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 32px;
}

.page-footer .content .ad .detail {
    display: flex;
    justify-content: space-between;
}

.page-footer .content .ad .detail .app {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    align-content: center;
    padding-right: 60px;
    border-right: 1px solid #f5f5f5;
    height: 128px;
}

.page-footer .content .ad .detail .app>.text {
    flex: 1;
}

.page-footer .content .ad .detail .app .img {
    width: 120px;
    height: 120px;
    border: 1px solid #f3f4f5;
    box-sizing: border-box;
    border-radius: 3px;
}

.page-footer .content .ad .detail .app .img .image_file {
    width: 100%;
}

.page-footer .content .ad .detail .app .text {
    margin-left: 23px;
}

.page-footer .content .ad .detail .app .text .title {
    height: 33px;
    font-size: 22px;
    font-weight: bold;
    line-height: 28px;
    color: #2878FF;
    margin-top: 2px;
}

.page-footer .content .ad .detail .app .text .desc {
    height: 19px;
    font-size: 14px;
    line-height: 20px;
    color: #60656b;
    letter-spacing: 15px;
}

.page-footer .content .ad .detail .app .text .button {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    margin-top: 28px;
}

.page-footer .content .ad .detail .app .text .button .button-link {
    width: 120px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #2878FF;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
}

.page-footer .content .ad .detail .app .text .button .button-link .image .fa {
    font-size: 18px;
}

.page-footer .content .ad .detail .app .text .button .button-link .text {
    margin-left: 8px;
    font-size: 12px;
}

.page-footer .content .ad .detail .app .text .button .ios {
    color: #2878FF;
}

.page-footer .content .ad .detail .app .text .button .ios:hover {
    background: #2878FF;
    color: white;
}

.page-footer .content .ad .detail .app .text .button .ios:hover+.android {
    background: white;
    color: #2878FF;
}

.page-footer .content .ad .detail .app .text .button .android {
    background: white;
    color: #2878FF;
    margin-left: 16px;
}

.page-footer .content .ad .detail .app .text .button .android:hover {
    background: #2878FF;
    color: white;
}

.page-footer .content .ad .detail .contact {
    width: 388px;
    min-height: 120px;
}

.page-footer .content .ad .detail .study {
    height: 120px;
}

.page-footer .content .contact {
    margin-left: 60px;
    border-right: 1px solid #f5f5f5;
}

.page-footer .content .contact>.title {
    min-height: 22px;
    font-size: 16px;
    line-height: 26px;
    color: #393f47;
    font-weight: bold;
    padding-bottom: 6px;
}

.page-footer .content .contact .iphone {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    margin-top: 14px;
}

.page-footer .content .contact .iphone .tel_detail {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 4px;
}

.page-footer .content .contact .iphone .tel_detail .title {
    display: flex;
    color: #60656b;
    font-size: 14px;
}

.page-footer .content .contact .iphone .tel_detail .title .tip2,
.page-footer .content .contact .iphone .tel_detail .title .tip1 {
    display: inline-block;
    min-width: 80px;
}

.page-footer .content .contact .iphone .tel_detail .title .tip1 {
    flex: 1;
    color: #60656b;
    font-family: "-apple-system,BlinkMacSystemFont,Helvetica Neue,Arial,PingFang SC,Hiragino Sans GB,STHeiti,Microsoft YaHei,Microsoft JhengHei,Source Han Sans SC,Noto Sans CJK SC,Source Han Sans CN,Noto Sans SC,Source Han Sans TC,Noto Sans CJK TC,WenQuanYi Micro Hei,SimSun,sans-serif";
}

.page-footer .content .contact .iphone .tel_detail .number {
    color: #60656b;
}

.page-footer .content .contact .tips {
    margin-left: 70px;
    color: #949699;
    font-size: 12px;
    margin-top: 2px;
}

.page-footer .content .study {
    margin-left: 60px;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
}

.page-footer .content .study .title {
    height: 22px;
    font-size: 16px;
    font-weight: bold;
    line-height: 26px;
    color: #393f47;
    padding-left: 8px;
}

.page-footer .content .study .study_icons {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    margin-top: 20px;
}

.page-footer .content .study .study_icons .icons_item {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    width: 65px;
    text-align: center;
    margin-left: 10px;
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: left;
    position: relative;
}

.page-footer .content .study .study_icons .icons_item .img {
    height: 50px;
    width: 50px;
    object-fit: contain;
}

.page-footer .content .study .study_icons .icons_item .img img {
    width: 100%;
    object-fit: contain;
}

.page-footer .content .study .study_icons .icons_item .text {
    height: 32px;
    font-size: 12px;
    line-height: 16px;
    color: #60656b;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    margin-top: 10px;
}

.page-footer .content .study .study_icons .icons_item:first-child {
    margin-left: 0;
}

.page-footer .content .footer_detail {
    padding: 25px 0;
    color: #949699;
    font-size: 12px;
    height: 97px;
    border-top: 1px solid #f5f5f5;
}

.page-footer .content .footer_detail .friend-contact {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
}

.page-footer .content .footer_detail .friend-contact .contact_item {
    color: #949699;
    font-size: 12px;
    padding: 0 16px;
    border-right: 1px solid #ebebeb;
}

.page-footer .content .footer_detail .friend-contact .contact_item:hover {
    color: #333;
}

.page-footer .content .footer_detail .friend-contact .contact_item:last-child {
    border-right: none;
}

.page-footer .content .footer_detail .contact_detail {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    margin-top: 3px;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 15px;
}

.page-footer .content .footer_detail .contact_detail .left {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    height: 16px;
}

.page-footer .content .footer_detail .contact_detail .left .copyright {
    padding-right: 16px;
    border-right: 1px solid #ebebeb;
    padding-left: 16px;
    display: flex;
    align-items: center;
}

.page-footer .content .footer_detail .contact_detail .left .copyright:first-child {
    padding-left: 0;
}

.page-footer .content .footer_detail .contact_detail .left .copyright:last-child {
    border-right: none;
}

.page-footer .content .footer_detail .contact_detail .left .record {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    padding-left: 16px;
}

.page-footer .content .footer_detail .contact_detail .left .record .url {
    color: #949699;
    font-size: 12px;
}

.page-footer .content .footer_detail .contact_detail .left .record .police {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    margin-left: 16px;
}

.page-footer .content .footer_detail .contact_detail .left .record .police .img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.page-footer .content .footer_detail .contact_detail .contact_item {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
}

.page-footer .content .footer_detail .contact_detail .contact_item .item {
    margin-top: 0;
    width: 30px;
    height: 30px;
    background: #f3f4f5;
    border-radius: 50%;
    opacity: 1;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
}

.page-footer .content .footer_detail .contact_detail .contact_item .item .img {
    color: #a5acc0;
}

.page-footer .content .footer_detail .contact_detail .contact_item .item:last-child {
    margin-right: 0;
}



#headerNav {
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 70px;
    // box-shadow: 0 2px 10px 0 rgba(47, 51, 56, 0.08);
    background-color: #fff;
}

#headerNav .header-nav {
    display: flex;
    align-items: center;
    height: 70px;
}

#headerNav .header-nav .logo-link {
    margin-right: 30px;
}

#headerNav .header-nav .nav-select {
    z-index: 1;
    position: relative;
    cursor: pointer;
}

#headerNav .header-nav .nav-select .select-text {
    font-size: 14px;
    color: #121314;
}

#headerNav .header-nav .nav-list {
    flex: 1;
    display: flex;
    margin-left: 38px;
}

#headerNav .header-nav .nav-list-item {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
    flex: 1;
    position: relative;
    max-width: 80px;
    height: 70px;
    color: #121314;
}

#headerNav .header-nav .nav-list-item:hover {
    color: #2878FF;
}

#headerNav .header-nav .nav-list-item.active {
    color: #2878FF;
    font-weight: bold;
}

#headerNav .header-nav .nav-list-item.active::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    width: 20px;
    height: 3px;
    background-color: #2878FF;
}

#headerNav .header-nav .icon-text {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
    position: relative;
    margin-right: 40px;
    cursor: pointer;
    font-size: 0;
    line-height: 80px;
}

#headerNav .header-nav .icon-text:hover .header-nav-hover,
#headerNav .header-nav .icon-text.active .header-nav-hover {
    display: block;
}

#headerNav .header-nav .icon-text:hover .text {
    color: #2878FF;
}

#headerNav .header-nav .icon-text:hover .icon-svg {
    display: inline-block;
}

#headerNav .header-nav .icon-text:hover .icon-svg .svg1,
#headerNav .header-nav .icon-text:hover .icon-svg .svg2 {
    fill: #2878FF;
}

#headerNav .header-nav .icon-text:hover .icon-svg .svg3 {
    stroke: #2878FF;
}

#headerNav .header-nav .icon-text .text {
    font-size: 14px;
    color: #121314;
}

#headerNav .header-nav .icon-text .icon-svg {
    margin-right: 4px;
}

#headerNav .header-nav .icon-text-app {
    cursor: default;
}

#headerNav .header-nav .button-group {
    display: flex;
}

#headerNav .header-nav .button-group .button {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
    width: 60px;
    height: 30px;
    border: solid 1px #2878FF;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
}

#headerNav .header-nav .button-group .button+.button {
    margin-left: 10px;
}

#headerNav .header-nav .button-group .button__login {
    color: #2878FF;
}

#headerNav .header-nav .button-group .button__register {
    background-color: #2878FF;
    color: #fff;
}

#headerNav .header-nav .login-text {
    font-size: 14px;
    color: #121314;
    cursor: pointer;
}

#headerNav .header-nav .login-text .symbol {
    color: #ebebeb;
}

#headerNav .header-nav .login-text:hover {
    color: #2878FF;
}

#headerNav .header-nav .login-text:hover .symbol {
    color: #2878FF;
}

#headerNav .header-nav .user {
    width: 40px;
    height: 40px;
    position: relative;
}

#headerNav .header-nav .user>a>img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    object-fit: cover;
}

#headerNav .header-nav .user .user-list {
    padding-top: 10px;
}

#headerNav .header-nav .user .user-list .user-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 84px;
    font-size: 14px;
    color: #393f47;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 10px 0 18px;
}

#headerNav .header-nav .user .user-list-item {
    padding: 0 10px 10px;
}

#headerNav .header-nav .user .user-list-item.out {
    padding-top: 10px;
    border-top: 1px solid #f5f5f5;
}

#headerNav .header-nav .user .user-list-item .button {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
    font-size: 14px;
    color: #60656b;
    border-radius: 5px;
    height: 30px;
    cursor: pointer;
}

#headerNav .header-nav .user .user-list-item .button:hover {
    background-color: rgba(245, 245, 245, 0.5);
}

#headerNav .header-nav .user .user-list-item .button .icon {
    margin-right: 8px;
}

#headerNav .header-nav .code-box {
    padding: 24px 19px 20px;
}

#headerNav .header-nav .code-box .code {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: initial;
    width: 162px;
    height: 162px;
    padding: 5px;
    box-sizing: border-box;
}

.codeimgs {
    width: 162px;
    height: 162px;
}

#headerNav .header-nav .code-box .code-text {
    font-size: 12px;
    color: #60656b;
    text-align: center;
    margin-top: 16px;
}

#headerNav .header-nav-hover {
    z-index: 1;
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    background-color: #fff;
    display: none;
}

#headerNav .header-nav-hover::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    border-bottom: 8px solid #fff;
    border-top: none;
    top: -5px;
    filter: drop-shadow(0px -8px 5px rgba(0, 0, 0, 0.1));
}

#headerNav .second-nav {
    position: relative;
    margin: 0 -20px;
    padding: 13px 0;
}

#headerNav .second-nav::before {
    content: "";
    position: absolute;
    top: 0;
    left: 20px;
    width: calc(100% - 40px);
    border-top: 1px solid rgba(235, 235, 235, 0.7);
}

#headerNav .second-nav::after {
    content: "";
    position: absolute;
    top: -5px;
    left: 190px;
    width: 12px;
    height: 12px;
    border-top: 1px solid rgba(235, 235, 235, 0.7);
    border-right: 1px solid rgba(235, 235, 235, 0.7);
    box-sizing: border-box;
    transform: rotate(-45deg);
    background-color: #fff;
}

#headerNav .second-nav .link {
    font-size: 14px;
    line-height: 28px;
    display: inline-block;
    color: #60656b;
    margin: 0 20px;
}

#headerNav .second-nav .link:hover {
    color: #2878FF;
}

#headerNav .second-nav .link.active {
    font-weight: bold;
    color: #2878FF;
}

.headcontainer {
    width: 1180px;
    margin: 0 auto;
}
// 
.footer_box {
  height: 120px;
  /* width: calc(100vw - 5px); */
  box-sizing: border-box;
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: center;
  // margin-top: 50px;
  width: 100vw;
  min-width: 1200px;
}

.footer_content {
  width: 1500px;
  height: 120px;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* padding: 30px 0 ; */
}
.footer_content p {
  text-align: center;
  margin-top: 30px;
}
</style>
