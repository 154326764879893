<template>
	<div class="container">
		<div class="userinfo_title" v-if="!pageType">时事政治<div
				style="display: flex;align-items: center;justify-content: space-between;">
				<!-- <el-input v-model="search_content" placeholder="输入搜索关键词" style="margin-right: 10px;"></el-input>
				<el-button @click="searchClick" class="search_btn">搜索</i></el-button>
				<el-button @click="clearClick" class="clear_btn">清空</i></el-button> -->
			</div>
		</div>
		<div class="table_content" :class="dataList ?'':'nothing'">
			<img src="@/assets/images/nothing.png" v-if="!dataList">
			<div class="list" v-for="(item,index) in dataList" v-else>
				<span class="kuai">{{index + 1 + pages*limit -limit}}</span>
				<div class="left">
					<div class="name" style="">{{item.name}}</div>
					<div class="numbers" style="font-size: 12px;">
						共<span>{{item.number}}</span>道真题&nbsp;&nbsp;&nbsp;&nbsp;
						<!-- <i style="font-size: 12px;">{{item.count == 0 ? '未完成' : '已完成'+item.count+'次'}}</i> -->
					</div>
				</div>
				<div class="right" v-if="item.number !== 0" @click="jump(item.id,item.name)">去练习</div>
				<div class="right" v-else @click="notimu()">去练习</div>
			</div>
		</div>
		<turnPages class="pages_box" :count="count" :pages="pages" @fanye="fanyeClick" @inputPage="inputPage">
		</turnPages>
	</div>
</template>
<!-- $router.push('/trueTopic?id='+item3.id) -->
<script>
	import turnPages from '@/components/turnPages/turnPages'
	import {
		currentAffairs
	} from '@/utils/api.js'
	import {
		EventBus
	} from '@/eventBus/eventBus'
	export default {
		components: {
			turnPages
		},
		data() {
			return {
				dataList: [],
				navList: [{
						path: '/classList',
						name: '首页',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 0
					},
					{
						path: '/systemClass',
						name: '课后专属服务',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 1
					},
					{
						path: '/politics',
						name: '时事政治',
						studyCurrent: 0,
						tabbarChildCurrent: 1,
						tabbarCurrent: 1
					}
				],
				navLists: [{
						path: '/classList',
						name: '首页',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 0
					},
					{
						path: '/classList',
						name: '在线学习',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 0
					},
					{
						path: '/politic?type=online',
						name: '时事政治',
						studyCurrent: 3,
						tabbarChildCurrent: 0,
						tabbarCurrent: 0
					}
				],
				county: 0,
				test: 0,
				tatol: 0,
				count: 1,
				pages: 1,
				limit: 8,
				pageType: '',
				search_content:''
			}
		},
		created() {
			this.pageType = this.$route.query.type
			this.getData()
			if (!this.pageType) {
				EventBus.$emit('navList', this.navList)
			} else {
				EventBus.$emit('navList', this.navLists)
				window.localStorage.setItem('studyCurrent',3)
			}
		},
		methods: {
			searchClick() {
				if (this.search_content == this.issearch) {
					return
				}
				this.issearch = this.search_content
				this.getData()
			},
			clearClick() {
				this.search_content = ''
				if (this.search_content == this.issearch) {
					return
				}
				this.issearch = this.search_content
				this.getData()
			},
			async getData() {
				await currentAffairs({
					page: this.pages,
					limit: this.limit,
					search: this.search_content
				}).then(res => {
					this.dataList = res.list
					this.tatol = res.tatol
					this.count = Math.ceil(res.total / this.limit)
				})
			},
			jump(id, name) {
				let navLists = []
				if (!this.pageType) {
					navLists = this.navList
				} else {
					navLists = this.navLists
				}
				let arrs = {
					navList: navLists,
					title: name,
					id: id,
					type: 'sz'
				}
				this.$router.push('/Answer?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(arrs))));
			},
			fanyeClick(e) {
				if (e == 'next') {
					this.pages++
					if (this.pages <= this.count) {
						this.getData()
					} else {
						this.pages = this.count
					}
				} else {
					this.pages--
					if (this.pages >= 1) {
						this.getData()
					} else {
						this.pages = 1
					}
				}
			},
			inputPage(e) {
				this.pages = e * 1
				this.getData()
			},
			notimu() {
				this.$message.error('该套题下暂无试题')
			}
		}
	}
</script>

<style scoped="scoped">
	/* .container{
		position: relative;
		min-height: 100%	;
	} */

	.userinfo_title {
		padding-left: 15px;
		border-left: 5px solid #47BE5E;
		font-size: 18px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-weight: bold;
	}

	.kuai {
		background: #5694F7;
		color: #fff;
		display: inline;
		padding: 5px 10px;
		border-radius: 3px;
		cursor: pointer;
		margin-right: 10px;
	}

	.title {
		height: 65px;
		margin-top: 20px;
		border-bottom: 1px solid #eee;
		padding-bottom: 15px;
		box-sizing: border-box;
	}

	.city {
		font-size: 18px;
		font-weight: bold;
	}

	.numbers {
		font-size: 15px;
		color: #666;
		margin-top: 8px;
	}

	.numbers span {
		font-weight: bold;
		color: #47BE5E !important;

	}

	.list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		/* border-bottom: 1px solid #eee; */
		padding: 15px;
	}

	.list:hover {
		background: rgba(71, 190, 94, .3);
		border-radius: 5px;
		/* color: #FFF; */
	}

	.list:last-child {
		border: none;
	}

	.list .left {
		flex: 1;
		padding-right: 15px;
	}

	.name {
		font-weight: bold;
		font-size: 15px;
	}

	.right {
		background: #5694F7;
		color: #fff;
		display: inline;
		padding: 5px 10px;
		border-radius: 3px;
		cursor: pointer;
	}

	.table_content {
		padding-bottom: 50px;
		padding-top: 15px;
	}

	.pages_box {
		position: absolute;
		bottom: 20px;
		right: 42px;
	}


	.search_btn {
		background: #5694F7;
		color: #fff;
	}

	.clear_btn {
		background: #FF684B;
		color: #fff;
	}
</style>
