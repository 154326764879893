<template>
	<div class="container" v-loading="liading">
		<!-- <userInfo></userInfo> -->
		<div class="content" style="position: relative;">
			
			<div style="display: flex;justify-content: flex-end;"> 
				<img style="width: 20px;height: 20px;" src="@/assets/back.png" @click="$router.go(-1)" title="返回上一页">
			</div>
			<!-- <navBar :navList="navList"></navBar> -->
			<div class="warps">
				<div class="boxs">
					<div class="warps">
						<video-player class="vjs-custom-skin" ref="videoPlayer" :options="playerOptions"
							@ready="onPlayerReadied" @timeupdate="onTimeupdate" :playsinline="playsinline"
							@ended="onPlayerEnded($event)" @play="onPlayerPlay" @pause="onPlayerPause" :title="title">
						</video-player>
					</div>
					<div class="chats">
						<div class="chat_title">交流大厅</div>
						<div class="chat_box" id="gundong">
							<div class="chat_list" v-for="(item,index) in chatList" :key="index">
								<div class="user_info">
									<!-- <img :src="item.user_icon"> -->
									<span>{{item.from_client_name+'：'}}</span>
								</div>
								<div class="chat_content">{{item.content}}</div>
							</div>
						</div>
						<div class="input_box">
							<textarea v-model="sendContent" rows="" cols="" maxlength="60"
								placeholder="请输入你想说的内容"></textarea>
							<div class="send_btn" @click="sendClick">发送</div>
						</div>
					</div>
				</div>
			</div>
			<template v-if="!exclusive">
				<div v-if="k_num > 0 || a_num > 0">
					<div class="titles">知识点回顾</div>
					<div class="hg_btns">
						<div class="btn" @click="reviewClick" v-if="k_num > 0">小节背题模式</div>
						<div class="btn" @click="answerClick" v-if="a_num > 0">小节真题闯关</div>
						<div class=" btn ggbtn" @click="answerggClick" v-if="wrong > 0">
							<div class="gg_num">{{wrong}}</div>错题重练
						</div>
						<!-- <div @click="downloadClick">下载讲义</div> -->
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	import {
		videoPlayer
	} from 'vue-video-player'
	import 'video.js/dist/video-js.css'
	import 'vue-video-player/src/custom-theme.css'
	import 'videojs-contrib-hls'
	// import { createSocket } from '@sven0706/websocket'
	// import { sendWSPush } from '@sven0706/websocket'
	// import { createSocket } from '@/scoket/scoket.js'
	import {
		getCourseSubVideo,
		getCourseSubVideoLog,
		getCourseSubKnowledge,
		getCourseDetail,
		submitKnowledgePlay
	} from '@/utils/api.js'
	import {
		EventBus
	} from '@/eventBus/eventBus'
	export default {
		name: 'live',
		components: {
			videoPlayer
		},
		data() {
			return {
				navList: [{
						path: '/classList',
						name: '首页',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 0
					},
					{
						path: '/liveList',
						name: '在线直播',
						studyCurrent: 6,
						tabbarChildCurrent: 0,
						tabbarCurrent: 0
					}, {
						path: '/livePlayer',
						name: '正在直播',
						studyCurrent: 6,
						tabbarChildCurrent: 0,
						tabbarCurrent: 0
					}
				],
				liading: false,
				initialized: false,
				currentTech: '',
				streams: {
					rtmp: '',
					hls: ''
				},
				name: '',
				title: '',
				playTime: '',
				// playerEnd: 2,
				lookTime: 0,
				timesss: '',
				watch_to: 0,
				exclusive: '',
				zId: '',
				wrong: 0,
				k_num: 0,
				a_num: 0,
				type: 0,
				playerOptions: {
					overNative: true,
					playbackRates: [0.75, 1.0, 1.25, 1.5, 2.0], //播放速度
					autoplay: false, // 如果为true,浏览器准备好时开始回放。
					muted: false, // 默认情况下将会消除任何音频。
					loop: false, // 是否视频一结束就重新开始。
					controls: true,
					preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
					aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
					techOrder: ['html5'],
					fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
					sourceOrder: true,
					language: 'zh-CN',
					flash: {
						hls: {
							withCredentials: false
						}
					},
					html5: {
						hls: {
							withCredentials: false
						}
					},
					controlBar: {
						timeDivider: true, // 当前时间和持续时间的分隔符
						durationDisplay: true, // 显示持续时间
						remainingTimeDisplay: false, // 是否显示剩余时间功能
						fullscreenToggle: true // 是否显示全屏按钮
					},
					notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
					sources: [{
						withCredentials: false,
						type: 'application/x-mpegURL',
						src: ''
					}],
					// poster: require("@/assets/images/study/demo.jpg"), //你的封面地址
				},

				knowledge: 0,
				answer: 0,
				listId: 0,
				idList: [],
				nextId: 0,
				websock: '',
				userinfo: {},
				sid: '',
				chatId: '',
				chatList: [],
				sendContent: '',
				seal:''
			}
		},
		created() {
			let datas = JSON.parse(this.$route.query.datas)
			console.log(datas)
			this.chatId = datas.chat_id
			this.sid = datas.id
			this.playerOptions.sources[0].src = datas.urls
			EventBus.$emit('navList', this.navList)
			// this.getData(this.id)
			this.userinfo = JSON.parse(localStorage.getItem('userInfo'))
			this.seal = this.userinfo.seal
			this.initWebSocket()
		},
		computed: {
			player() {
				return this.$refs.videoPlayer.player
			},
			currentStream() {
				return this.currentTech === 'Flash' ? 'RTMP' : 'HLS'
			},
			playsinline() {
				let ua = navigator.userAgent.toLocaleLowerCase()
				// x5内核
				if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
					return false
				} else {
					// ios端
					return true
				}
			}
		},
		methods: {
			initWebSocket() { //初始化weosocket
				const wsuri = 'wss://socket.sz517.com:8282';
				this.websock = new WebSocket(wsuri);
				this.websock.onmessage = this.websocketonmessage;
				this.websock.onopen = this.websocketonopen;
				this.websock.onerror = this.websocketonerror;
				this.websock.onclose = this.websocketclose;
			},
			websocketonopen() { //连接建立之后执行send方法发送数据

				let that = this
				console.log("打印课程ID")
				console.log(that.userinfo.user_id)
				let data = `{"type":"login","ty":"WEB",
								"client_name":"${that.userinfo.nickname}",
								"sid":"${that.sid}",
								"room_id":"${that.chatId}",
								"uid":"${that.userinfo.user_id}"}`
								console.log(data)
				this.websocketsend(data);
			},
			websocketonerror() { //连接建立失败重连
				console.log('连接失败，正在重新链接')
				this.initWebSocket();
			},
			websocketonmessage(e) { //数据接收
				let that = this
				// console.log("socket连接成功")
				const redata = JSON.parse(e.data);
				console.log(redata);
				if (redata.type !== 'ping') {
					if (redata.type == 'login') {
						if (redata.log) {
							that.chatList = redata.log
						}
						this.$nextTick(() => {
							let msg = document.getElementById('gundong')
							msg.scrollTop = msg.scrollHeight
						})
					} else if(redata.type == 'say') {
						that.chatList.push(redata)
						that.$nextTick(() => {
							let msg = document.getElementById('gundong')
							msg.scrollTop = msg.scrollHeight
						})
					}

				}
				that.$forceUpdate()

			},
			websocketsend(Data) { //数据发送
				this.sendContent = ''
				this.websock.send(Data);
				this.$forceUpdate()
			},
			websocketclose(e) { //关闭
				console.log('断开连接', e);
			},
			sendClick() {
				let that = this
				if (this.sendContent == '' || this.sendContent.replace(/(^\s*)|(\s*$)/g, "") == '') {
					this.$message.warning('请输入你想发的内容')
					return
				} else {
					console.log(that.chatId);
					let data = `{"type":"say","ty":"WEB",
									"client_name":"${that.userinfo.nickname}",
									"sid":"${that.sid}",
									"content":"${that.sendContent.replace(/(^\s*)|(\s*$)/g, "")}",
									"to_client_id":"all",
									"to_client_name":"所有人",
									"from_client_name":"${that.userinfo.nickname}",
									"room_id":"${that.chatId}",
									"luid":"${that.userinfo.user_id}"}`
					this.websocketsend(data);
					
				}

			},
			async getData(id) {
				getCourseSubVideo({
					id: id
				}).then(res => {
					// return
					if (this.exclusive !== 'yes') {
						this.getList()
					}
					if (!res.data.video_url) {
						this.playerOptions.sources[0].src = res.data.live_url

						this.liading = false
						return
					}
					this.playerOptions.sources[0].src = JSON.parse(res.data.video_url.replace(/\\/g, '')).AUTO;
					this.name = res.data.name
					if (res.data.type == 1) {
						this.watch_to = 0
					} else {
						this.watch_to = res.data.watch_to
					}
					this.knowledge = res.data.knowledge
					this.answer = res.data.answer
					this.wrong = res.data.wrong
					this.k_num = res.data.k_num
					this.a_num = res.data.a_num
					this.type = res.data.type
					this.liading = false
					console.log(this.type);

				})
			},
			onPlayerReadied(player) {
				if (!this.initialized) {
					this.initialized = true
					this.currentTech = this.player.techName_
				}
				player.currentTime(this.watch_to)
			},
			onPlayerPlay() { //监听播放按钮
				this.timesss = setInterval(() => {
					this.lookTime++
				}, 1000);
			},
			onPlayerPause() {
				clearInterval(this.timesss)
			},
			// record current time
			onTimeupdate(e) { //播放进度
				this.playTime = e.cache_.currentTime.toFixed(0)
				// this.times()
			},
		},
	}
</script>
<style type="text/css">
	.vjs-mouse-display .vjs-time-tooltip {
		background: rgba(255, 255, 255, 0) !important
	}

	.vjs-play-progress .vjs-time-tooltip {
		display: none !important;
	}

	.vjs-custom-skin>.video-js .vjs-big-play-button {
		width: 60px !important;
		height: 60px !important;
		line-height: 60px !important;
		border-radius: 50% !important;
	}

	.vjs-big-play-button span {
		font-size: 56px !important;
	}

	.vjs-custom-skin>.video-js .vjs-play-progress,
	.vjs-custom-skin>.video-js .vjs-volume-level {
		background: #FF684B !important;
		border-radius: 2px !important;
	}

	.video-js:hover .vjs-big-play-button,
	.vjs-custom-skin>.video-js .vjs-big-play-button:focus,
	.vjs-custom-skin>.video-js .vjs-big-play-button:active {
		background: #FF684B !important;
		opacity: .9 !important;
	}

	.video-js .vjs-play-progress:before {
		font-size: 0.9em;
		position: absolute;
		right: -0.5em;
		top: -0.333333333333333em;
		z-index: 1;
		color: #FF684B !important;
	}

	.video-js .vjs-slider {
		border-radius: 2px !important;
	}

	/* .vjs-custom-skin > .video-js .vjs-time-tooltip, .vjs-custom-skin > .video-js .vjs-mouse-display:after, .vjs-custom-skin > .video-js .vjs-play-progress:after{
		color: #47BE5E !important;
	} */
</style>

<style scoped>
	.boxs .chats {
		flex: 1;
		padding-left: 15px;
		height: 467px;
	}

	.chat_title {
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.chat_box {
		height: 270px;
		border: 1px solid #ccc;
		border-radius: 10px;
		padding: 15px;
		overflow-y: scroll;
	}

	.chat_list {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		line-height: 2.0;
	}

	.user_info {
		color: #10aeef;
	}

	.chat_content {
		color: #666;
		flex: 1;
	}

	.input_box {
		position: relative;
		height: 165px;
	}

	.input_box textarea {
		width: 100%;
		resize: none;
		border: 1px solid #ccc;
		border-radius: 10px;
		border-top: none;
		padding: 15px;
		font-size: 14px;
		color: #333;
		height: 165px;
		padding-bottom: 50px;
	}

	.send_btn {
		position: absolute;
		right: 15px;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 64px;
		height: 24px;
		background: #10aeff;
		color: #fff;
		border-radius: 15px;
		font-size: 12px;
		float: right;
		margin: 0 10px 10px 0;
		transition: all 0.4s ease 0s;

	}

	.titles {
		font-size: 18px;
		font-weight: bold;
		padding-left: 15px;
		border-left: 5px solid #47BE5E;
		margin-top: 30px;
	}

	.hg_btns {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.hg_btns .btn {
		padding: 0 10px;
		height: 36px;
		border-radius: 8px;
		background: #47BE5E;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 30px;
		margin-right: 30px;
	}

	.ggbtn {
		position: relative;
	}

	.gg_num {
		position: absolute;
		top: -8px;
		right: -8px;
		color: #fff;
		background: #FF684B;
		border-radius: 8px;
		color: #fff;
		min-width: 15px;
		height: 15px;
		font-size: 12px;
		padding: 0 3px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.liveView {
		position: relative;
	}

	.wraps {
		margin-top: 10px;
	}

	.content {
		width: 1132px;
		background: #fff;
		padding: 20px;
		position: relative;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
	}

	.video-player {
		width: 830px;
		height: 467px;
	}

	.vjs_video_3-dimensions {
		width: 886px !important;
		height: 590px !important;
	}

	.boxs {
		display: flex;
		/* align-items: center; */
		justify-content: space-between;
		padding-top: 30px;
		position: relative;
		padding-left: 5px;
	}

	.video_title {
		position: absolute;
		left: 60px;
		top: 55px;
		z-index: 99;
		color: #fff;
		font-size: 20px;
	}
</style>
