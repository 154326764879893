import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "lib-flexible/flexible";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import userInfo from "@/components/userInfo/userInfo.vue";
import auserInfo from "@/components/userInfo/userInfo1.vue";
import navBar from "@/components/navBar/navBar.vue";
import VideoPlayer from "vue-video-player";
import "vue-event-calendar/dist/style.css"; //1.1.10之后的版本，css被放在了单独的文件中，方便替换
import vueEventCalendar from "vue-event-calendar";
// fade/zoom 等
import "element-ui/lib/theme-chalk/base.css";
// collapse 展开折叠
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
//富文本开始
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import minmte from './mixins/common/minmte.js'
Vue.mixin(minmte)
Vue.use(Viewer);
Viewer.setDefaults({
  Options: { "inline": true, "button": true, "navbar": true, "title": true, "toolbar": true, "tooltip": true, "movable": true, "zoomable": true, "rotatable": true, "scalable": true, "transition": true, "fullscreen": true, "keyboard": true, "url": "data-source" }
});
import VueJSMind from 'vue-jsmind'
import 'vue-jsmind/src/components/JsMind/jsmind.css' // 引入jsmind样式
Vue.use(VueJSMind)
Vue.use(VueQuillEditor);
//富文本结束
Vue.use(vueEventCalendar, {
  locale: "zh",
}); //可以设置语言，支持中文和英文
Vue.use(VideoPlayer);
/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
Vue.prototype.$EventBus = new Vue();

Vue.prototype.setAll = {}//设置一些全局变量
Vue.prototype.$dowbLoadFile = function(url, name) {
  //下载
  let fileName = fileName;
  let reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/;
  if (!reg.test(url)) {
    throw new Error("传入参数不合法,不是标准的文件链接");
  } else {
    let xhr = new XMLHttpRequest();
    xhr.open("get", url, true);
    xhr.setRequestHeader("Content-Type", `application/pdf`);
    xhr.responseType = "blob";
    let that = this;
    xhr.onload = function() {
      if (this.status == 200) {
        //接受二进制文件流
        let blob = this.response;
        let downloadElement = document.createElement("a");
        let href = blob;
        if (typeof blob == "string") {
          downloadElement.target = "_blank";
        } else {
          href = window.URL.createObjectURL(blob); //创建下载的链接
        }
        downloadElement.href = href;
        downloadElement.download = fileName;
        //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        if (typeof blob != "string") {
          window.URL.revokeObjectURL(href); //释放掉blob对象
        }
      }
    };
    xhr.send();
  }
};
// 时间戳转时间格式
Vue.filter("dtime", function(time, type) {
  if (!type) {
    type = "1";
  }
  let now = new Date(parseInt(time) * 1000);
  var year = now.getFullYear(); //取得4位数的年份
  var month = now.getMonth() + 1; //取得日期中的月份，其中0表示1月，11表示12月
  var date = now.getDate(); //返回日期月份中的天数（1到31）
  var hour = now.getHours(); //返回日期中的小时数（0到23）
  var minute = now.getMinutes(); //返回日期中的分钟数（0到59）
  var second = now.getSeconds(); //返回日期中的秒数（0到59）
  month = month > 9 ? month : "0" + month;
  date = date > 9 ? date : "0" + date;
  hour = hour > 9 ? hour : "0" + hour;
  minute = minute > 9 ? minute : "0" + minute;
  second = second > 9 ? second : "0" + second;
  if (type == "1") {
    return (
      year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second
    ); //精确到时分秒
  } else if (type == "2") {
    return year + "-" + month + "-" + date + " " + hour + ":" + minute; //精确到分钟
  } else if (type == "3") {
    return year + "-" + month + "-" + date; //精确到天
  }
  // 使用方式{{时间戳,类型 | dtime}}
});

Vue.component("userInfo", userInfo); //个人信息组件
Vue.component("auserInfo", auserInfo); //个人信息组件
Vue.use(ElementUI);
Vue.component(CollapseTransition.name, CollapseTransition);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
