<template>
	<div class="container">
		<div class="title">
			<div class="city">重庆市</div>
			<div class="numbers">共<span>{{tatol}}</span>套真题</div>
		</div>
		<div class="content" :class="dataList?'':'nothing'">
			<img src="@/assets/images/nothing.png" v-if="!dataList">
			<div class="list" v-for="(item,index) in dataList" v-else>
				<span class="kuai">{{index + 1 + pages*limit -limit}}</span>
				<div class="left">
					<div class="name list_title">{{item.name}}</div>
					<div class="numbers" style="font-size: 12px;">
						共<span>{{item.number}}</span>道真题&nbsp;&nbsp;&nbsp;&nbsp;
						<i style="font-size: 12px;" v-if="item.count == 0">未完成</i>
						<i v-else>已完成<i style="" class="list_yes">{{item.count}}</i>次</i>
					</div>
				</div>
				<div class="right spans" @click="report(item.result_id,item.name)" v-if="parseInt(item.count) >= 1">查看报告
				</div>
				<div class="right" @click="jump(item.id,item.name)">去练习</div>
			</div>
		</div>
		<turnPages class="pages_box" :count="count" :pages="pages" @fanye="fanyeClick" @inputPage="inputPage">
		</turnPages>
	</div>
</template>s
<!-- $router.push('/trueTopic?id='+item3.id) -->
<script>
	import turnPages from '@/components/turnPages/turnPages'
	import {
		getTestPaperQues
	} from '@/utils/api.js'

	import {
		EventBus
	} from '@/eventBus/eventBus'
	export default {
		components: {
			turnPages
		},
		data() {
			return {
				dataList: [],
				county: 0,
				test: 0,
				tatol: 0,
				count: 1,
				pages: 1,
				limit: 8,
				navList: [{
						path: '/classList',
						name: '首页',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 0
					},
					{
						path: '/classList',
						name: '在线学习',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 0
					},
					{
						path: '/trueTopicList',
						name: '真题演练',
						studyCurrent: 1,
						tabbarChildCurrent: 0,
						tabbarCurrent: 0
					}
				],
				search_content: ''
			}
		},
		created() {
			EventBus.$emit('navList', this.navList)
			window.localStorage.setItem('studyCurrent',1)
			this.getData()
		},
		mounted() {
			EventBus.$on('search', res => {
					if (this.search_content == res) {
						return
					}
					this.search_content = res
					
					this.getData()
				}),
				EventBus.$on('clearSearch', res => {
					if (this.search_content == res) {
						return
					}
					this.search_content = res
					
					this.getData()
				})
		},
		methods: {
			async getData() {
				await getTestPaperQues({
					search: this.search_content,
					page: this.pages,
					limit: this.limit
				}).then(res => {
					this.dataList = res.data
					this.tatol = res.tatol
					this.count = Math.ceil(res.tatol / this.limit)
				})
			},
			fanyeClick(e) {

				if (e == 'next') {
					this.pages++
					if (this.pages <= this.count) {
						this.getData()
					} else {
						this.pages = this.count
					}
				} else {
					this.pages--
					if (this.pages >= 1) {
						this.getData()
					} else {
						this.pages = 1
					}
				}
			},
			inputPage(e) {
				this.pages = e * 1
				this.getData()
			},
			jump(id, name) {
				let arrs = {
					navList: this.navList,
					title: name,
					id: id,
					type: 'zt'
				}
				this.$router.push('/Answer?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(arrs))));

				// this.$router.push('/trueTopic?id=' + id)
				EventBus.$emit('pageRouter', {
					name: name,
					pages: '/trueTopic?id=' + id
				})
			},
			report(id, name) {
				let arrs = {
					arr: this.navList,
					title: name,
					id: id,
					// type: 'sz'
				}
				this.$router.push('/report?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(arrs))));
				EventBus.$emit('pageRouter', {
					name: name,
					pages: '/report?id=' + id + '&number=' + number
				})
			},
		}
	}
</script>

<style scoped="scoped">
	/* .container{
		position: relative;
		min-height: 100%	;
	} */
	.list_title {
		font-weight: bold;
		font-size: 15px;
	}

	.spans {
		margin-right: 10px;
	}

	.list_yes {
		font-size: 15px;
		font-weight: bold;
		color: #47BE5E;
	}

	.title {
		height: 65px;
		margin-top: 20px;
		/* border-bottom: 1px solid #eee; */
		padding-bottom: 15px;
		box-sizing: border-box;
	}

	.city {
		font-size: 18px;
		font-weight: bold;
	}

	.kuai {
		background: #5694F7;
		color: #fff;
		display: inline;
		padding: 5px 10px;
		border-radius: 3px;
		cursor: pointer;
		margin-right: 10px;
	}

	.numbers {
		font-size: 15px;
		color: #666;
		margin-top: 8px;
	}

	.numbers span {
		font-weight: bold;
		color: #47BE5E !important;

	}

	.list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		/* border-bottom: 1px solid #eee; */
		padding: 14px;
	}

	.list:hover {
		background: rgba(71, 190, 94, .3);
		border-radius: 5px;
		/* color: #FFF; */
	}

	.list:last-child {
		border: none;
	}

	.list .left {
		flex: 1;
		padding-right: 15px;
	}

	.name {
		font-size: 16px;
	}

	.right {
		background: #5694F7;
		color: #fff;
		display: inline;
		padding: 5px 10px;
		border-radius: 3px;
		cursor: pointer;
	}

	.content {
		padding-bottom: 50px;
	}

	.pages_box {
		position: absolute;
		bottom: 20px;
		right: 42px;
	}
</style>
