<template>
	<div class="user_info_box">
		<div class="user_info">
			<div class="user_pic">
				<img :src="userInfos.head_portrait?userInfos.head_portrait:noPic">
			</div>
			<div class="user_text">
				<div class="user_name">{{userInfos.nickname?userInfos.nickname:userInfos.name}}</div>
				<div class="user_class">师途启航班——在职定制班（综合+教育）</div>
			</div>
		</div>
		<div class="times_box" v-if="timesShow">
			<div class="tit"><span style="color: #fff;">总学习时长</span><img :src="userInfos.head_portrait?userInfos.head_portrait:noPic"></div>
			<div class="time">125,56h</div>
		</div>
		<div class="login_out" @click="loginOut"><img src="@/assets/images/index/loginout.png">退出登录</div>
	</div>
</template>

<script>
	import {
		EventBus
	} from '@/eventBus/eventBus'
	export default {
		props: {
			timesShow: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				userInfos:[],
				userPic:'',
				noPic: 'https://www.sz517.com/upload/answer_image/20210729/9576015054b54ff779fbdc37342101b8.png',
			}
		},
		created() {
			let userinfo =  localStorage.getItem('userInfo')
			this.userInfos = JSON.parse(userinfo)
		},
		mounted() {
			EventBus.$on('userinfo', res => {
				this.userInfos = res
			})
		},
		methods: {
			loginOut() {
				
				this.$confirm('是否要退出登录？', '提示', {
					confirmButtonText: '确定退出',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					localStorage.clear()
					this.$emit('stop','stop')
					this.$message({
						type: 'success',
						message: '退出成功!'
					});
					this.$router.push('/Login')
				}).catch(() => {
				});
			}
		}
	}
</script>

<style scoped="scoped">
	.user_info_box {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		background: #fff;
		height: 6.25rem;
		margin-top: .9375rem;
		border-radius: .625rem;
		padding: 0 1.5625rem;
		box-shadow: 0px 0px 0.375rem 0px rgba(80, 93, 223, 0.18);
	}

	.user_info {
		/* flex: 1; */
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.user_info .user_pic {
		width: 3.125rem;
		height: 3.125rem;
		border-radius: 50%;
		margin-right: 12px;
	}

	.user_pic img {
		width: 3.125rem;
		height: 3.125rem;
		border-radius: 50%;
	}

	.user_text .user_name {
		font-size: 1rem;
		font-weight: bold;
	}

	.user_text .user_class {
		font-size: .75rem;
		margin-top: .3125rem;
		color: #878C94;
	}

	.times_box {
		width: 14.375rem;
		height: 5rem;
		background: #6672F2;
		padding: .875rem 2.0625rem;
		border-radius: .625rem;
	}

	.times_box .tit {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: #fff;
	}

	.times_box .time {
		font-size: 1.5rem;
		font-weight: bold;
		color: #fff;
	}

	.login_out {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.login_out img {
		margin-right: .625rem;
	}
</style>
