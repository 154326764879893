<template>
  <div style="margin-top:-20px;">
    <div class="table_box">
      <div class="table_header" style="margin-bottom: 0;">
        <img
          src="@/assets/h3.png"
          alt=""
          srcset=""
          style="margin-right: 10px;"
        />考试日历
      </div>
      <div class="calendar">
        <div class="cale_tt">
          <div class="cale_left">
            <div class="cal_tisa">
              <div class="cal_fots">{{ iTme }}</div>
              <div class="lejwv">
                累计完成<span>{{ commpNum }}</span
                >项学习
              </div>
            </div>
            <Calendar
              :markDate="dateArray"
              :markDateMore="dateMoredata"
              @changeMonth="changeDate"
              @choseDay="clickDay"
            ></Calendar>
            <div class="tstatus">
              <div class="tstatus_i" @click="renStaus = 0">
                <img src="../../assets/shixny.png" alt="" /><span
                  :style="{ color: renStaus == 0 ? '#5694F7' : '' }"
                  >未完成任务</span
                >
              </div>
              <div class="tstatus_i" @click="renStaus = 1">
                <img src="../../assets/ywanc.png" alt="" /><span
                  :style="{ color: renStaus == 1 ? '#5694F7' : '' }"
                  >已完成任务</span
                >
              </div>
              <!-- <div class="tstatus_i"><img src="../../assets/vwanc.png" alt=""><span>未完成任务</span></div> -->
              <div></div>
            </div>
            <!-- 近期考试 -->
            <div class="nation">
              <div class="nation_title">
                <img src="@/assets/h4.png" alt="" />学习计划
              </div>
              <div
                class="nation_i"
                v-if="renStaus == 3 ? true : ts.complete == renStaus"
                v-for="(ts, index6) in calenInfo"
                :key="index6"
                @click="openTxs(ts, index6)"
              >
                <div class="det_yu">
                  <div>
                    <img
                      v-if="ts.complete == 0"
                      src="../../assets/shixny.png"
                      alt=""
                    />
                    <img v-else src="../../assets/ywanc.png" alt="" />
                    {{ ts.text }}
                  </div>
                  <img src="../../assets/gcao.png" alt="" />
                </div>
                <div style="position: relative;">
                  <div
                    class="nation_icon"
                    v-if="ts.catevice"
                    @click="calAdd(ts.cate_vice_id)"
                  >
                    {{ ts.catevice.name }}
                  </div>
                </div>
              </div>
              <div
                class="el_totel"
                style="margin-top: 20px;"
                v-if="dettol > 10"
              >
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="dettol"
                  @current-change="dettolChange"
                >
                </el-pagination>
              </div>
              <div class="xixs_btn" @click="dialFomsible()">新建学习计划</div>
              <el-dialog
                title="新建学习计划"
                :visible.sync="dialogFormVisible"
                top="25vh"
              >
                <el-form :model="form">
                  <el-form-item label="计划内容" :label-width="formLabelWidth">
                    <el-input
                      type="textarea"
                      v-model="form.name"
                      autocomplete="off"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="计划时间" :label-width="formLabelWidth">
                    <div>
                      <el-date-picker
                        v-model="value1"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                      >
                      </el-date-picker>
                    </div>
                  </el-form-item>
                  <el-form-item label="标签" :label-width="formLabelWidth">
                    <div class="radlist">
                      <el-radio
                        v-for="(tas, index9) in tasList"
                        :key="index9"
                        v-model="radio1"
                        :label="tas.id"
                        border
                        >{{ tas.name }}</el-radio
                      >
                    </div>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisible = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="submForm()"
                    >确 定</el-button
                  >
                </div>
              </el-dialog>
            </div>
          </div>
          <div style="max-height: 300px;">
            <div class="future">
              <div class="tur_img">
                <img src="" alt="" />近期考试<img src="" alt="" />
              </div>
              <div
                class="future_list"
                :style="{ height: exAct ? '396px' : '' }"
              >
                <div
                  class="future_i"
                  v-if="ae.show != 'hidde'"
                  v-for="(ae, index2) in aEvents"
                  :key="index2"
                >
                  <div class="title">{{ ae.name }}</div>
                  <p>开始时间：{{ ae.start_time }}</p>
                  <p>结束时间：{{ ae.end_time }}</p>
                  <div class="baoQm">
                    <div @click="wiOpen(ae.exam_url)">去报名</div>
                    <div @click="wiOpen(ae.info_url)">查看详情</div>
                  </div>
                </div>
                <div class="ljqwxs" v-if="aEventsNum() == 0">
                  <div class="ljqwxs_ts">
                    温馨提示:查看更多招考信息，请点击前往招聘信息
                  </div>
                  <div class="ljqwxs_bt">
                    <a href="https://www.sz517.com/exam/index" target="_blank"
                      >立即前往查看</a
                    >
                  </div>
                </div>
              </div>
              <div class="zhdiu">
                <img src="../../assets/topxs.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <img
        style="margin: 40px auto;"
        src="@/assets/images/nothing.png"
        v-if="!typeList && !typeList.length"
      />
      <div class="table_content" :class="typeList ? '' : 'nothing'" v-else>
        <div class="table_header" style="margin-bottom: 0;">
          <img
            src="@/assets/h5.png"
            alt=""
            srcset=""
            style="margin-right: 10px;"
          />模考大赛
        </div>
        <div class="cont_list" v-if="0">
          <div class="clis_left">
            <div class="clis_i">
              <div class="cli_top">
                <div class="ctop_title">
                  两江新区鸳鸯街道派遣人员与社区工作者招聘简章（2023年2月批次）
                </div>
                <div class="cli_tname">
                  <div class="time">
                    <img src="../../assets/goxua.png" alt="" />2023.03.03
                  </div>
                  <div class="name">重庆.社区工作者</div>
                </div>
              </div>
              <div class="cli_btn">即将报名</div>
            </div>
            <div class="clis_i">
              <div class="cli_top">
                <div class="ctop_title">
                  两江新区鸳鸯街道派遣人员与社区工作者招聘简章（2023年2月批次）
                </div>
                <div class="cli_tname">
                  <div class="time">
                    <img src="../../assets/goxua.png" alt="" />2023.03.03
                  </div>
                  <div class="name">重庆.社区工作者</div>
                </div>
              </div>
              <div class="cli_btn">即将报名</div>
            </div>
            <div class="clis_i">
              <div class="cli_top">
                <div class="ctop_title">
                  两江新区鸳鸯街道派遣人员与社区工作者招聘简章（2023年2月批次）
                </div>
                <div class="cli_tname">
                  <div class="time">
                    <img src="../../assets/goxua.png" alt="" />2023.03.03
                  </div>
                  <div class="name">重庆.社区工作者</div>
                </div>
              </div>
              <div class="cli_btn">即将报名</div>
            </div>
            <div class="clis_i">
              <div class="cli_top">
                <div class="ctop_title">
                  两江新区鸳鸯街道派遣人员与社区工作者招聘简章（2023年2月批次）
                </div>
                <div class="cli_tname">
                  <div class="time">
                    <img src="../../assets/goxua.png" alt="" />2023.03.03
                  </div>
                  <div class="name">重庆.社区工作者</div>
                </div>
              </div>
              <div class="cli_btn">即将报名</div>
            </div>
            <turnPages
              class="pages_box"
              :count="count"
              :pages="pages"
              @fanye="fanyeClick"
              @inputPage="inputPage"
            >
            </turnPages>
          </div>
          <div>
            <div class="dryrun">
              <div class="dryrun_title">
                <div class="tit_img">
                  <img src="../../assets/goxua.png" alt="" /> 模考大赛
                </div>
                <div class="tit_btn">更多</div>
              </div>
              <div class="dryrun_i">
                <div class="kais_time">
                  <div class="kais">
                    <img src="../../assets/goxua.png" alt="" />未开始
                  </div>
                  <div class="c_time">2月8日09:00-14:00</div>
                </div>
                <div class="run_name">
                  重庆沙坪坝区新大纲《职测（D）类》全真模考第一期
                </div>
                <div class="run_btn">
                  <div class="ru_bleft">已有51人报名</div>
                  <div class="rbtn">去报名</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 搜索 -->
        <div style="margin-top: 0;">
          <el-input placeholder="请输入内容" v-model="input2">
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getMocttp(1, '')"
              >查找</el-button
            >
          </el-input>
        </div>
        <div class="treelis">
          <div
            class="treelis_i"
            v-for="(mo, index3) in mocTent"
            :key="index3"
            @click="goDrun(mo)"
          >
            <div class="treelis_name">{{ mo.name }}</div>
            <div class="treelis_time">
              <div class="kais">
                <span>{{ mo.status }}</span> {{ mo.spent_time_text }}
              </div>
            </div>
            <div class="run_btn">
              <div class="ru_bleft">已有{{ mo.enroll_num }}人报名</div>
              <div class="rbtn">
                {{ mo.status == "已报名" ? "去答题" : "去报名" }}
              </div>
            </div>
          </div>
          <div style="width: 390px;"></div>
          <div style="width: 390px;"></div>
          <div class="el_totel">
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="12"
              :total="moctotal"
              @current-change="getMocttp"
            >
            </el-pagination>
          </div>
        </div>
        <!-- 搜索 -->
        <div class="table_header" style="margin-bottom: 0;">
          <img
            src="@/assets/h6.png"
            alt=""
            srcset=""
            style="margin-right: 10px;"
          />考试真题
        </div>
        <div style="display: flex;align-items: center;">
          <div style="margin-top: 0;">
            <el-input placeholder="请输入内容" v-model="input1">
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="zhenttList(1, '')"
                >查找</el-button
              >
            </el-input>
          </div>
          <!-- <div>共36题</div> -->
        </div>

        <div class="zhenilist treelis">
          <div
            class="treelis_i"
            v-for="(zi, index3) in zhiData"
            :key="index3"
            @click="goaAnsw(zi.id, zi)"
          >
            <div class="treelis_name">{{ zi.name }}</div>
            <div class="treelis_time">
              <div class="kais">
                <!-- <span>{{ zi.status }}</span> {{ zi.spent_time_text }} -->
              </div>
            </div>
            <div class="run_btn">
              <div class="ru_bleft">已有{{ zi.number }}题</div>
              <div
                class="rbtn"
                style="border-color: #FF872B;color:#FF872B;
    background: #fffcf6;"
              >
                <!-- {{ zi.status == "已报名" ? "去答题" : "去报名" }} -->
                去答题
              </div>
            </div>
          </div>
          <div
            class="treelis_i"
            style="height: 0;padding: 0 20px;border: none;"
          ></div>
          <div
            class="treelis_i"
            style="height: 0;padding: 0 20px;border: none;"
          ></div>
          <div class="el_totel" style="margin-top: 30px;">
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="12"
              :total="zhitotal"
              @current-change="zhenttList"
            >
            </el-pagination>
          </div>
        </div>
        <div class="table_header" style="margin-bottom: 0;">
          <img
            src="@/assets/h7.png"
            alt=""
            srcset=""
            style="margin-right: 10px;"
          />招考资讯
        </div>

        <div style="width: 100%;margin: 40px 0;">
          <div
            style="display: flex;flex-direction: column;flex-wrap: wrap;    
              height: 200px;"
          >
            <div
              v-for="(item, index) in job_list"
              :key="item.id"
              @click="toUrl(item.id)"
              style="width: 48%;background: #fff;
              padding: 10px;display: flex;font-size: 16px;"
              :style="index >= 5 ? 'margin-right: 0;' : 'margin-right: 10px;'"
            >
              <div
                style="white-space: nowrap;padding-right: 5px; margin-right: 5px;color: #FF872B;border-right: 2px solid #a4a4a4;"
              >
                {{ item.main_id_text }}
              </div>
              <div
                style="overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;color: #333;"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from "vue-calendar-component";
import navBar from "@/components/navBar/navBar";
import tableList from "@/components/tableList/tableList";
import turnPages from "@/components/turnPages/turnPages";
import {
  studyTabs,
  getUserCourse,
  courseList,
  handoutList,
  getCalendar,
  getexamSign,
  getMockList,
  getcalendarAdd,
  getcalendarList,
  getMockSearch,
  getueionsList,
  getcalendarInfo,
  getcalendarComplete,
  getcalendarTags,
  getcalendarResume,
  jobList,
} from "@/utils/api.js";
import { EventBus } from "@/eventBus/eventBus";
export default {
  components: {
    tableList,
    turnPages,
    navBar,
    Calendar,
  },
  data() {
    return {
      input1: "", //真题搜索
      input2: "",
      renStaus: 3, //3全部1未完成2已完成
      radio1: "00",
      clasType: "",
      ClassName: "",
      current: 0,
      typeList: [],
      pages: 1,
      count: 1,
      limit: 8,
      type: 1, //基础：type=1 提升：type=2 冲刺：type=3
      name: "",
      navList: [
        {
          path: "/classList",
          name: "首页",
          studyCurrent: 0,
          tabbarChildCurrent: 0,
          tabbarCurrent: 0,
        },
        {
          path: "/classList",
          name: "在线学习",
          studyCurrent: 0,
          tabbarChildCurrent: 0,
          tabbarCurrent: 0,
        },
        {
          path: "/classList",
          name: "基础提升",
          studyCurrent: 0,
          tabbarChildCurrent: 0,
          tabbarCurrent: 0,
        },
      ],
      dialogVisible: false,
      fileList: [],
      tableList1: [
        { title: "考试真题" },
        { title: "模考大赛" },
        { title: "考试日历" },
        { title: "招聘信息" },
        // { title: "备考交流" },
      ],
      tableListIndex: 0,
      tableList2: [],
      tableListIndex2: 0,
      // value: new Date(),
      exAct: true,
      dateArray: [],
      dateMoredata: [],
      navList: [
        {
          path: "/classList",
          name: "首页",
          studyCurrent: 0,
          tabbarChildCurrent: 0,
          tabbarCurrent: 0,
        },
        {
          path: "/Examination",
          name: "考试日历",
          studyCurrent: 0,
          tabbarChildCurrent: 0,
          tabbarCurrent: 3,
        },
      ],
      eventTitle: "本月全部信息",
      aEvents: [],
      mocTent: [],
      moctotal: 0,
      dettol: 0, //学习计划分页
      slidShow: true,
      current: 0,
      tsTime: "",
      dialogFormVisible: false,
      form: {
        name: "",
      },
      value1: [],
      formLabelWidth: "120px",
      iTme: "",
      calendarList: [],
      calenInfo: [],
      zhiData: [], //真题列表
      zhitotal: 0,
      isDay: "",
      daily_plainId: 0, //当日学习计划列表id
      tasList: [],
      commpNum: 0,
      job_list: [],
    };
  },
  watch: {
    // 被侦听的变量count
    tableListIndex() {
      if (this.tableListIndex == 3) {
        window.open("https://www.sz517.com/exam/index");
        this.tableListIndex = 0;
      }
    },
  },
  created() {
    this.getData();
    this.getMocttp(); //模考大赛列表
    this.tasHttp(); //学习计划标签
    this.zhenttList(); //真题1
    window.localStorage.setItem("studyCurrent", 0);
    EventBus.$emit("navList", this.navList);
    EventBus.$emit("studyCurrent", 0);
    studyTabs().then((res) => {
      this.tableList2 = res.data.list;
      this.getData(1);
    });
    jobList().then((res) => {
      this.job_list = res.data.list;
    });

    var date = new Date();
    console.log(date.getFullYear());
    this.iTme = date.getFullYear() + "年" + (date.getMonth() + 1) + "月";
    // this.submForm(date.getFullYear(),date.getMonth())
    // this.derLdata(date.getFullYear(),date.getMonth()+1,date.getDate())
    getcalendarList({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
    }).then((res) => {
      res.data.forEach((item, index) => {
        res.data[index].day = new Date(res.data[index].daily * 1000).getDate();
        res.data[index].detaxs =
          res.data[index].year +
          "-" +
          res.data[index].month +
          "-" +
          res.data[index].day;
        this.dateArray.push(res.data[index].detaxs);
        this.dateMoredata.push({
          date: res.data[index].detaxs,
          className:
            res.data[index].complete < res.data[index].number
              ? "mark0"
              : "mark1",
        });
        this.commpNum = this.commpNum += res.data[index].complete;
        if (res.data[index].day == date.getDate()) {
          this.daily_plainId = res.data[index].id;
          this.detInfo(res.data[index].id);
        }
      });
      this.calendarList = res.data;
    });
  },
  methods: {
    // 真题列表
    zhenttList(val) {
      getueionsList({ name: this.input1, page: val || 1, limit: 10 }).then(
        (res) => {
          this.zhiData = res.data.data;
          this.zhitotal = res.data.count;
        }
      );
    },
    // 去答题
    goaAnsw(id, ar) {
      if (ar.number < 1) {
        this.$message.warning("暂无试题");
        return;
      }
      if (ar.result_id && ar.result_id != 0) {
        let navarrs = {
          id: id,
          title: "答题结算",
          result_id: ar.result_id,
          clastiy: "zhenti",
          paths:this.$route.fullPath
        };
        this.$router.push(
          "/AnswerEnd?parameter=" +
            encodeURIComponent(encodeURIComponent(JSON.stringify(navarrs)))
        );
        return;
      }
      let arrs = {
        id: id,
        type: "li",
        clastiy: "zhenti",
      };
      this.$router.replace(
        "/Answer?parameter=" +
          encodeURIComponent(encodeURIComponent(JSON.stringify(arrs)))
      );
    },
    //
    dialFomsible() {
      this.dialogFormVisible = true;
      document.body.scrollTop = 0;
    },
    // 学习计划标签
    tasHttp() {
      getcalendarTags().then((res) => {
        this.tasList = res.data;
      });
    },
    // 完成或恢复计划
    openTxs(pak, index) {
      this.$alert(
        pak.complete == 0 ? "确定完成该任务吗？" : "是否恢复该计划？",
        pak.complete == 0 ? "完成计划" : "恢复计划",
        {
          confirmButtonText: pak.complete == 0 ? "完成" : "恢复",
          callback: (action) => {
            if (action == "confirm") {
              if (pak.complete == 0) {
                getcalendarComplete({
                  daily_plain_id: pak.daily_plan_id || "",
                  daily_plain_list_id: pak.id,
                }).then((res) => {
                  this.$message.success("已完成");
                  console.log(index);
                  this.calenInfo[index].complete = 1;
                });
              } else {
                getcalendarResume({
                  daily_plain_id: pak.daily_plan_id || "",
                  daily_plain_list_id: pak.id,
                }).then((res) => {
                  this.$message.success("已恢复");
                  console.log(index);
                  this.calenInfo[index].complete = 0;
                });
              }
            }
          },
        }
      );
    },
    // 获取学习计划列表1
    derLdata(year, month, daday) {
      getcalendarList({ year: year, month: month }).then((res) => {
        res.data.forEach((item, index) => {
          res.data[index].day = new Date(
            res.data[index].daily * 1000
          ).getDate();
          res.data[index].detaxs =
            res.data[index].year +
            "-" +
            res.data[index].month +
            "-" +
            res.data[index].day;
          this.dateArray.push(res.data[index].detaxs);
          if (res.data[index].day == daday) {
            this.detInfo(res.data[index].id);
          } else {
            this.calenInfo = [];
          }
        });
        this.calendarList = res.data;
      });
    },
    //计算id
    furrLtim() {
      this.calendarList.forEach((item, index) => {
        if (this.calendarList[index].day == this.isDay) {
          this.daily_plainId = this.calendarList[index].id;
        }
      });
    },
    // 完成
    calAdd(id) {},
    //
    // 新增
    submForm() {
      if (this.form.name == "") {
        this.$message.warning("请输入计划内容");
      } else if (this.getD(this.value1[0], this.value1[1])) {
        this.$message.warning("时间为一个月内");
      } else if (this.radio1 == "00") {
        this.$message.warning("请选择标签");
      } else {
        let data = {
          start: new Date(this.value1[0]).getTime() / 1000,
          end: new Date(this.value1[1]).getTime() / 1000,
          text: this.form.name,
          cate_vice_id: this.radio1,
        };
        getcalendarAdd(data).then((res) => {
          if (res.code == 200) {
            this.$message.success("已添加");
            this.dialogFormVisible = false;
            this.form.name = "";
            this.value1 = "";
            this.derLdata(
              new Date(this.form.value1 || new Date()).getFullYear(),
              new Date(this.form.value1 || new Date()).getMonth() + 1,
              new Date(this.form.value1 || new Date()).getDate()
            );
          }
        });
      }
    },
    // 判断时间范围不能大于一个月
    getD(sDate, endDate) {
      if (endDate == undefined) return true;
      var sDate = new Date(sDate);
      var eDate = new Date(endDate);
      if (eDate.getFullYear() - sDate.getFullYear() > 1) {
        //先比较年
        return true;
      } else if (eDate.getMonth() - sDate.getMonth() > 1) {
        //再比较月
        return true;
      } else if (eDate.getMonth() - sDate.getMonth() == 1) {
        if (eDate.getDate() - sDate.getDate() >= 1) {
          return true;
        }
      } else if (eDate.getFullYear() - sDate.getFullYear() == 1) {
        if (eDate.getMonth() + 12 - sDate.getMonth() > 1) {
          return true;
        } else if (eDate.getDate() - sDate.getDate() >= 1) {
          return true;
        }
      }
      return false;
    },
    // 详情
    detInfo(plan_id, pages) {
      this.renStaus = 3;
      this.calenInfo = [];
      getcalendarInfo({ plan_id: plan_id, page: pages || 1, limit: 10 }).then(
        (res) => {
          this.calenInfo = res.data.list;
          this.dettol = res.data.total;
        }
      );
    },
    wiOpen(url) {
      window.open(url);
    },
    dettolChange(val) {
      this.detInfo(this.daily_plainId, val);
    },
    async getMocttp(val) {
      await getMockList({
        page: val || 1,
        limit: 12,
        name: this.input2,
      }).then((res) => {
        console.log(res);
        this.mocTent = res.list;
        this.moctotal = res.total;
        if (res.code !== 200) this.$message.warning("暂无数据");
      });
    },
    //
    goDrun(dr) {
      let datData = JSON.stringify(dr);
      this.$router.push("/dryrunDetail?datData=" + datData);
    },
    //
    setTabs2(index) {
      console.log(index);
      this.tableListIndex2 = index;
      this.getData(1);
    },
    async getData(page) {
      if (page == 1) {
        this.pages = page;
        this.typeList = [];
      }
      let promise = {
        // type: this.type,
        limit: this.limit,
        page: this.pages,
        id: this.tableList2[this.tableListIndex2].id,
        // name: this.name,
      };
      await courseList(promise).then((res) => {
        this.typeList = [...this.typeList, ...res.data.list];
        console.log(123, res.data.list, this.typeList);
        for (let i in this.typeList) {
          this.typeList[i].course.seo_keyword_list = this.typeList[
            i
          ].course.seo_keyword.split("_");
        }
        this.count = Math.ceil(res.data.total / this.limit);
        this.$forceUpdate();
      });
    },
    toUrl(id) {
      window.open("https://www.sz517.com/wzxq/" + id);
    },
    listJump(id) {
      this.$router.push("/courseVideo?id=" + id);

      return;
      this.navList.push({
        path: "/studyList",
        name: title,
        studyCurrent: 0,
        tabbarChildCurrent: 0,
        tabbarCurrent: 0,
      });
      if (guan == 1) {
        this.$router.push(
          "/studyList?id=" +
            id +
            "&titles=" +
            title +
            "&navList=" +
            escape(JSON.stringify(this.navList))
        );
      } else {
        this.$router.push(
          "/studyList?id=" +
            id +
            "&titles=" +
            title +
            "&page=chongci&navList=" +
            escape(JSON.stringify(this.navList))
        );
      }
    },
    fanyeClick(e) {
      if (e == "next") {
        this.pages++;
        console.log(this.pages);
        if (this.pages <= this.count) {
          this.getData();
        } else {
          this.pages = this.count;
        }
      } else {
        this.pages--;
        if (this.pages >= 1) {
          this.getData();
        } else {
          this.pages = 1;
        }
      }
    },
    downBtn(index) {
      handoutList({
        course_id: this.typeList[index].course.id,
        limit: 1000,
        page: 1,
      }).then((res) => {
        this.fileList = res.data.list;

        this.dialogVisible = true;
      });
      /*  if (typeof e == "string") {
          obj.name = e;
          obj.url = e;
          this.fileList.push(obj);
        } else {
          this.fileList = e;
        } */
    },
    dowbLoadFile(e) {
      console.log(e);
      window.open(e.url);
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    inputPage(e) {
      this.pages = e * 1;
      this.getData();
    },
    async getData() {
      var day2 = new Date();
      day2.setTime(day2.getTime());
      // var s2 = day2.getFullYear() + "/" + (day2.getMonth() + 1) + "/" + day2.getDate();
      // console.log(s2)
      this.year = day2.getFullYear();
      this.month = day2.getMonth() + 1;
      // let day = day2
      let aaa = [];
      await getCalendar({
        year: this.year,
        month: this.month,
      }).then((res) => {
        this.aEvents = res.data.list;
        for (let item of this.aEvents) {
          // item.date = s2
          // console.log((item.exam_end_time - item.exam_start_time) / 86400)
          if (item.status_text != "报名已结束") {
            item.date = this.getAllDate(
              item.start_time.split(" ")[0],
              item.end_time.split(" ")[0]
            );
            // this.dateArray = this.unique(this.dateArray.concat(item.date))
          }
        }
      });
      console.log();
    },
    //
    ttMess(year, month, day) {
      this.derLdata(year, month, day);
      this.aEvents.forEach((item, index) => {
        if (
          new Date(this.tsTime) > new Date(this.aEvents[index].start_time) &&
          new Date(this.tsTime) < new Date(this.aEvents[index].end_time)
        ) {
          this.aEvents[index].show = "block";
        } else {
          this.aEvents[index].show = "hidde";
        }
      });
      this.aEvents = this.aEvents;
    },
    aEventsNum() {
      let anum = 0;
      this.aEvents.forEach((item, index) => {
        if (this.aEvents[index].show == "block") {
          anum++;
        }
      });
      return anum;
    },
    // 选中某天
    clickDay(data) {
      for (let item of this.aEvents) {
        item.date = "";
      }
      let times = data.split("/");
      let year = times[0];
      let month = times[1];
      let day = times[2];
      this.eventTitle = year + "年" + month + "月" + day + "日信息";
      this.isDay = day;
      this.tsTime = year + "-" + month + "-" + day;
      this.ttMess(year, month, day);
      this.furrLtim();
      this.slidShow = false;
      this.$EventCalendar.toDate(data);
      let mon =
        data.split("/")[1] < 10 ? "0" + data.split("/")[1] : data.split("/")[1];
      let tian =
        data.split("/")[2] < 10 ? "0" + data.split("/")[2] : data.split("/")[2];
      for (let item of this.aEvents) {
        item.data = "";
        let smon = item.start_time.split("-")[1];
        let sday = item.start_time.split("-")[2].split(" ")[0];
        let emon = item.end_time.split("-")[1];
        let eday = item.end_time.split("-")[2].split(" ")[0];
        if (smon == mon && mon == emon) {
          //全是当月的情况下
          if (sday <= tian && tian <= eday) {
            item.date = data;
          }
        } else if (smon < mon && mon == emon) {
          //开始时间不是当月结束时间是当月的情况下
          if (tian <= eday) {
            item.date = data;
          }
        } else if ((smon = mon && mon < emon)) {
          //开始时间是当月，结束时间不是当月的情况下
          if (tian >= sday) {
            item.date = data;
          }
        } else if (smon < mon && mon < emon) {
          //开始和结束时间都不是当月的情况下
          item.date = data;
        }
      }
      this.$forceUpdate();
    },
    // 左右点击切换月份
    async changeDate(data) {
      this.aEvents = [];
      // this.dateArray = []
      console.log(data);
      let times = data.split("/");
      let year = times[0];
      let month = times[1];
      let day = times[2];
      this.eventTitle = year + "年" + month + "月全部信息";
      this.year = year;
      this.month = month;
      await getCalendar({
        year: this.year,
        month: this.month,
      }).then((res) => {
        this.aEvents = res.data.list;
        for (let item of this.aEvents) {
          // item.date = s2
          // console.log((item.exam_end_time - item.exam_start_time) / 86400)
          if (item.status_text != "报名已结束") {
            item.date = this.getAllDate(
              item.start_time.split(" ")[0],
              item.end_time.split(" ")[0]
            );
            // this.dateArray = this.unique(this.dateArray.concat(item.date))
          }
          // item.date = this.getAllDate(item.start_time.split(' ')[0], item.end_time.split(' ')[0])
          // this.dateArray = this.unique(this.dateArray.concat(item.date))
        }
        // console.log(this.dateArray)
      });
    },
    selectClick(index) {
      this.current = index;
      this.slidShow = true;
    },
    signClick(url, text) {
      //标记
      window.open(url);
    },
    jumpClick(url) {
      // window.location.href = url
      window.open(url);
    },
    getAllDate(start, end) {
      //求开始时间和结束时间中间的时间
      let dateArr = [];
      let startArr = start.split("-");
      let endArr = end.split("-");
      let db = new Date();
      db.setUTCFullYear(startArr[0], startArr[1] - 1, startArr[2]);
      let de = new Date();
      de.setUTCFullYear(endArr[0], endArr[1] - 1, endArr[2]);
      let unixDb = db.getTime();
      let unixDe = de.getTime();
      let stamp;
      const oneDay = 24 * 60 * 60 * 1000;
      for (stamp = unixDb; stamp <= unixDe; ) {
        dateArr.push(this.format(new Date(parseInt(stamp))));
        stamp = stamp + oneDay;
      }
      return dateArr;
    },
    format(time) {
      //拼接日期
      let ymd = "";
      let mouth =
        time.getMonth() + 1 >= 10
          ? time.getMonth() + 1
          : "0" + (time.getMonth() + 1);
      let day = time.getDate() >= 10 ? time.getDate() : "0" + time.getDate();
      ymd += time.getFullYear() + "-"; // 获取年份。
      ymd += mouth + "-"; // 获取月份。
      ymd += day; // 获取日。
      return ymd; // 返回日期。
    },
    unique(arr) {
      //数组去重
      return Array.from(new Set(arr));
    },
  },
};
</script>
<style scoped lang="scss">
.file_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 30px 0;
}

.file_list .down {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #5694f7;
  background: #e5efff;
  padding: 5px 10px;
  border-radius: 10px;
}

.down img {
  width: 20px;
  height: 20px;
}

.list_title {
  font-weight: bold;
  font-size: 15px;
  color: #333;
}

.nav_bar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}
.table_nav_list1 {
  padding: 3px 10px;
  border-radius: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400 !important;
}
.nav_box {
  width: 1500px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.content {
  width: 1132px;
  background: #fff;
  height: 780px;
  padding: 0 20px 0 0;
  position: relative;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
}

.title {
  font-size: 16px;
  color: #7b92cb;
}

.tab_top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.tab_top div {
  width: 125px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.tab_top .active {
  background: #5694f7;
  border-radius: 18px;
  color: #fff;
}

.table_box {
  margin-top: 30px;
  width: 1200px;
  display: flex;
  flex-direction: column;
}

.table_title {
  display: flex;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.table_header {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 40px;
}
.table_content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  box-sizing: border-box;
}
.cont_list {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.clis_left {
  width: 790px;
  background: #ffffff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
  padding: 0 30px;
  box-sizing: border-box;
}
.clis_i {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0 30px 0;
  box-sizing: border-box;
  border-bottom: 1px solid #eeeeee;
}
.ctop_title {
  font-size: 16px;
}
.cli_tname {
  margin-top: 12px;
  display: flex;
  align-items: center;
}
.cli_tname time {
  display: flex;
  align-items: center;
}
.cli_tname .time img {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
.cli_tname .time {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  margin-right: 15px;
}
.cli_tname .name {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.cli_btn {
  width: 80px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  background: #fff1e0;
  border-radius: 15px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ff9d50;
}
//
//   right
.dryrun {
  width: 380px;
  background: #ffffff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}
.dryrun_title {
  width: 380px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tit_img {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  line-height: 30px;
}
.tit_img img {
  width: 24px;
  height: 24px;
}
.tit_btn {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
}
.dryrun_i {
  width: 340px;
  background: #fafafa;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}
.kais_time {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  box-sizing: border-box;
}
.kais {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}
.kais img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.c_time {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 24px;
}
.run_name {
  padding-bottom: 15px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  border-bottom: 1px solid #e5efff;
}
.run_btn {
  padding-top: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ru_bleft {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}
.rbtn {
  width: 80px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background: #f6f9ff;
  border: 1px solid #bcd6ff;
  border-radius: 15px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #5694f7;
}
.rbtn:hover {
  border: 1px solid#5694f7fd;
}

.table_nav {
  background: #fff;
  box-sizing: border-box;
  padding: 22px;
  &_list1 {
    margin-right: 30px;
    font-weight: bold;
    cursor: default;
  }
  &_list1:hover {
    color: #5694f7;
  }
}

/deep/ .wraps {
  display: flex;
  justify-content: center;
}
.pages_box {
  margin-top: 10px;
}
.zhenilist {
  width: 1200px;
  background: #ffffff;
  margin-top: 20px;
  margin-bottom: 30px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
  padding: 30px 30px 30px 30px;
  box-sizing: border-box;
}
.zheni_i {
  padding: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e2dfdf;
}
.zheni_i:hover {
  background-color: #e6e5e55d;
  border-bottom: 1px solid #e6e5e55d;
}
.zhi_name {
  font-size: 16px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.zhi_cent {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dalx {
  display: flex;
  align-items: center;
}
.znumxs {
  color: #666666;
  font-size: 14px;
}
.znumxs span {
  color: #000000;
}
.dastatus {
  font-size: 14px;
  margin-left: 20px;
  color: #666666;
}
.daan {
  display: flex;
  align-items: center;
}
.daan div {
  font-size: 14px;
  color: #5694f7;
  margin-right: 20px;
}
.treelis {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 1200px;
  background: #ffffff;
  margin-top: 20px;
  margin-bottom: 30px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
  padding: 30px 0 30px 30px;
  box-sizing: border-box;
}
.treelis_i {
  width: 360px;
  background: #fafafa;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  margin-right: 30px;
  margin-bottom: 28px;
  padding: 20px 20px 15px 20px;
  box-sizing: border-box;
}
.treelis_i:hover {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
  scale: 1.02;
}
.treelis_name {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.treelis_time {
  padding-bottom: 15px;
  border-bottom: 1px solid #e9e8e8;
  margin-top: 4px;
}
.kais span {
  width: 50px;
  height: 18px;
  text-align: center;
  background: #e2ecff;
  border-radius: 2px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #5694f7;
  line-height: 18px;
  margin-right: 10px;
}
.calendar {
  width: 1200px;
  background: #ffffff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
  margin: 30px 0;
  padding: 30px 35px 50px 35px;
}
.cale_left {
  width: 650px;
}
.cal_tisa {
  width: 610px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.cal_fots {
  font-size: 31px;
  font-family: DIN Next LT Pro;
  font-weight: 500;
  color: #010101;
}
.lejwv {
  font-size: 19px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #666666;
}
.lejwv span {
  color: #5694f7;
  font-size: 22px;
}
.is-selected {
  border-radius: 23px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.is-selected img {
  width: 21px;
  height: 21px;
}
/deep/.el-calendar-table tr:first-child td {
  border-top: 1px solid #ffffff;
}
/deep/.el-calendar__header {
  border: none;
}
/deep/.el-calendar__title {
  font-size: 24px;
  font-family: DIN Next LT Pro;
  font-weight: 500;
  color: #010101;
}
/deep/.el-calendar__button-group {
  display: none;
}
/deep/.el-calendar-table__row .current {
  border: 1px solid #ffffff;
  text-align: center;
  font-size: 23px;
  font-family: DIN Next LT Pro;
  font-weight: 600;
  color: #000000;
}
/deep/.el-calendar-table__row .next {
  display: none;
}
/deep/.el-calendar-table__row .prev {
  border: 1px solid #ffffff;
  text-align: center;
  color: #ffffff;
}
/deep/.prev span {
  // color: #ffffff;
}
/deep/.el-calendar__body {
  padding: 12px 20px 0 0;
}
/deep/.el-calendar__header {
  padding: 12px 0;
}
//
.tstatus {
  width: 748px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 1px 0 35px;
  box-sizing: border-box;
}
.tstatus_i {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tstatus_i img {
  width: 21px;
  height: 21px;
  margin-right: 10px;
}
.cale_tt {
  display: flex;
  justify-content: space-between;
}
.future {
  position: relative;
  width: 380px;
  padding-bottom: 25px;
  background: #5694f7;
  border-radius: 10px;
  box-sizing: border-box;
  margin-top: 40px;
}
.zhdiu {
  position: absolute;
  bottom: 0;
  width: 50px;
  height: 10px;
  left: 50%;
  margin-left: -25px;
  background: #ffffff81;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.zhdiu img {
  width: 12px;
  height: 14px;
  margin-top: 2px;
}
.tur_img {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #ffffff;
  padding-top: 15px;
  box-sizing: border-box;
}
.future_list {
  width: 340px;
  overflow: hidden;
  margin: 20px auto 0 auto;
  transition: height 2s ease 2s;
  overflow-y: scroll;
  background: #5694f7;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.future_list::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.baoQm {
  position: absolute;
  top: 0;
  left: 0;
  width: 340px;
  height: 161px;
  background: #5694f734;
  border: 1px solid #f1f1f1;
  display: none;
  align-items: center;
  justify-content: space-around;
}
.baoQm div {
  width: 120px;
  height: 38px;
  text-align: center;
  line-height: 38px;
  border-radius: 26px;
  color: #ffffff;
  background: #5694f7;
}
.future_i:hover .baoQm {
  display: flex;
}
.future_i {
  position: relative;
  width: 340px;
  height: 161px;
  background: #fafafa;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  margin-bottom: 19px;
  padding: 20px;
  box-sizing: border-box;
}
.future_i:last-child {
  margin-bottom: 0;
}
.future_i .title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-bottom: 20px;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 10px;
}
.future_i p {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  margin-bottom: 5px;
}
//
.wh_jiantou1[data-v-2ebcbc83] {
  width: 12px;
  height: 12px;
  border-top: 2px solid #222 !important;
  border-left: 2px solid #222 !important;
}

.wh_jiantou2[data-v-2ebcbc83] {
  width: 12px;
  height: 12px;
  border-top: 2px solid #222 !important;
  border-right: 2px solid #222 !important;
}

.wh_top_changge li {
  color: #222 !important;
  font-size: 24px !important;
  font-weight: bold !important;
}
/deep/.wh_top_changge {
  display: none !important;
}

.wh_container {
  width: 650px;
  margin: 0 !important;
  max-width: 450px !important;
}

/deep/.wh_content_all {
  width: 650px !important;
  background: #fff !important;
}
/deep/.wh_top_tag {
  font-size: 19px !important;
  font-family: SourceHanSansSC;
  font-weight: 400;
  color: #000000;
}
/deep/.wh_item_date {
  font-size: 16px !important;
  font-family: DIN Next LT Pro;
  font-weight: 600;
  color: #000000;
}
/deep/.wh_isMark {
  font-size: 23px !important;
  font-family: DIN Next LT Pro;
  font-weight: 600;
  color: #000000;
}

.wh_content_item {
  color: #222 !important;
  height: 70px !important;
  width: 14.28% !important;
  font-size: 16px !important;
}

/deep/.wh_content_item > .wh_isMark {
  /* color: #fff !important; */
  background: #fff !important;
  /* border-bottom: 1px solid #47BE5E !important; */
  font-size: 16px !important;
}
/deep/.wh_content:last-child .wh_content_item > .wh_item_date::before {
  content: "";
  position: absolute;
  /* left: 0; */
  top: 36px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  //  background-image: url('../../assets/shixny.png');
  background-size: 100%;
  background-color: #fa4e5400;
}
/deep/.wh_content:last-child .wh_content_item > .wh_isMark::before {
  content: "";
  position: absolute;
  /* left: 0; */
  top: 36px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-image: url("../../assets/shixny.png");
  background-size: 100%;
  background-color: #fa4e5400;
}
/deep/.wh_content:last-child .wh_content_item > .mark1::before {
  content: "";
  position: absolute;
  /* left: 0; */
  top: 36px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-image: url("../../assets/ywanc.png");
  background-size: 100%;
  background-color: #fa4e5400;
}
/deep/.wh_item_date:hover {
  background: #ebebeb !important;
  background-color: #ebebeb !important;
}
/deep/.wh_content_item .wh_chose_day {
  width: 44px;
  height: 70px;
  padding-bottom: 20px;
  background: #5694f7 !important;
  border: 1px solid #5694f7 !important;
  color: #ffffff;
}
/deep/.wh_content:last-child .wh_content_item > .wh_chose_day::before {
  width: 18px;
  height: 18px;
  top: 38px;
  background-image: url("../../assets/jintxs.png");
}
/deep/.wh_content_item .wh_isToday {
  color: #fff !important;
  width: 44px;
  height: 70px;
  padding-bottom: 20px;
  background: #5694f7 !important;
  border: 2px solid #5694f7 !important;
  border-radius: 23px;
}
/deep/.wh_content:last-child .wh_content_item > .wh_isToday::before {
  width: 22px;
  height: 22px;
  top: 38px;
  background-image: url("../../assets/jiday.png");
}
/deep/.wh_content {
  padding: 0 !important;
}
/deep/.wh_content_item:first-child .wh_top_tag {
  color: #000000 !important;
}
/deep/.wh_content_item:last-child .wh_top_tag {
  color: #000000 !important;
}
//
//
.nation {
  width: 1130px;
  padding-top: 30px;
  margin: 30px auto 0 auto;
  border-top: 1px solid #f0eded;
}
.nation_title {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #020202;
}
.nation_title img {
  width: 24px;
  height: 24px;
  margin-right: 14px;
}
.xixs_btn {
  margin: 49px auto 0 auto;
  width: 394px;
  height: 53px;
  text-align: center;
  line-height: 53px;
  background: #5694f7;
  border-radius: 27px;
  font-size: 19px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
}
.nation_i {
  width: 1088px;
  height: 106px;
  background: #f9f9f9;
  border-radius: 10px;
  padding: 17px 50px 17px 24px;
  margin-top: 20px;
}
.det_yu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 19px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #000000;
  line-height: 28px;
}
.det_yu img {
  width: 21px;
  height: 21px;
  margin-right: 18px;
}
.det_yu div {
  font-weight: 600;
}
.nation_icon {
  position: absolute;
  margin: 20px 0 0 40px;
  padding: 0 10px;
  height: 25px;
  // width: 80px;
  text-align: center;
  line-height: 24px;
  background: #e0ecff;
  border-radius: 13px;
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #5694f7;
}
// .nation_icon{
//   height: 25px;
// }
.el_totel {
  width: 1200px;
  text-align: center;
  margin: 0 auto;
}
/deep/.elDatePicker.el-picker-panel {
}
.radlist {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 150px;
  overflow: hidden;
  overflow-y: scroll;
}

/deep/.el-radio {
  margin-bottom: 10px;
  margin-right: 10px;
}
/deep/.el-date-editor .el-range-separator {
  width: 6%;
}
/deep/.el-radio.is-bordered + .el-radio.is-bordered {
  margin-left: 0;
}
// 搜索按钮
/deep/.el-input-group__append,
.el-input-group__prepend {
  color: #ffffff;
  background-color: #5694f7;
}
.ljqwxs {
  width: 320px;
  height: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ljqwxs_ts {
  color: #ffffff;
  font-size: 18px;
  text-align: center;
}
.ljqwxs_bt {
  margin-top: 30px;
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 4px;
  font-weight: bold;
  color: #7396cf;
}
.ljqwxs_bt:hover {
  background: #dde0e3;
}
// <div class="ljqwxs" v-if="aEvents==''">
//                           <div class="ljqwxs_ts">温馨提示:查看更多招考信息，请点击前往招聘信息</div>
//                           <div class="ljqwxs_bt">立即前往查看</div>
//                         </div>
</style>
