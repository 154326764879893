<template>
  <div class="container">
    <div class="question_set">答疑服务</div>
    <div class="content_div">
        <div class="con_header">
            <div class="con_hleft">
               <div v-for="(item,index) in tablist" :style="tabIndex==index?'font-weight: bold':''" :key="index" @click="e=>{
                tabIndex=index
                getAnswerlist()
               }">{{item}}</div>
            </div>
            <div class="cor_button" @click="e=>{
               $refs.askru.cate_id=''
               $refs.askru.vice_id=''
               $refs.askru.dialogImageUrl=[]
              $refs.askru.question=''
              $refs.askru.askShow=true
            } ">
              <img src="../../assets/images/slices/button.png" alt="">
              我要提问
            </div>
        </div>
        <!--  -->
        <div class="subject" v-for="(item,index) in ansList" :key="index" @click="toGdetail(item.id)">
           <div class="subject_header">
              <div class="subject_left">
                <img src="../../assets/images/slices/yiwen.png" alt="">
                <div style="width:960px">{{item.question}}</div>
              </div>
              <div class="subject_right"> 
                <div>查看详情</div>
                <img src="../../assets/images/slices/right.png" alt="">
              </div>
           </div>
           <div class="subtt" v-if="item.ques!=''">
              <div class="subtt_ic">{{item.ques.genre_name}}</div>
              <div class="subtt_name">{{item.ques.title}}</div>
           </div>
        </div>
       <div class="pagin_d">
        <el-pagination
        background
        :page-size="5"
        @current-change="getAnswerlist"
        layout="prev, pager, next"
        :total="ansCont">
      </el-pagination>
       </div>
    </div>
    <!-- 提问弹窗 -->
    <askQuestion ref="askru" @hwuRee="hwuRee" />
  </div>
</template>

<script>
import {
  getCate,
  addAnswer,
  getAnswerlist
} from "@/utils/erpapi.js";
import askQuestion from '@/components/answer/askquestion'
export default {
  components: {
    askQuestion
  },
  data() {
    return {
      importHeaders: {
        "verify-token": localStorage.getItem("UserToken"),
      },
      tablist:['未解决','已解决'],
      tabIndex:0,
      dialogImageUrl:[],
      disabled: false,
      ansList:[],
      ansCont:0
    };
  },
  created() {
    this.getAnswerlist()
  },

  methods: {
    hwuRee(){
      this.getAnswerlist()
    },
    // 列表
    getAnswerlist(val){
      getAnswerlist({
        type:1,
        is_closed:this.tabIndex+1,
        page:val||1,
        limit:5
      }).then(res=>{
        this.ansCont=res.total
        this.ansList=res.list
    })
    },
    //查看详情
    toGdetail(id){
     this.$router.push('/doubtdetail?id='+id)
    },
  },
};
</script>
<style scoped="scoped" lang="scss">
.pagin_d{
 text-align: center;
 margin-top: 30px;
}

.subtt{
  width: 1060px;
  height: 80px;
  background: #FAFBFC;
  display: flex;
  align-items: center;
  padding: 0 30px 0 40px;
  margin-left: 32px;
  .subtt_ic{
    width: 68px;
    height: 30px;
    background: #5694f721;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #5694F7;
    margin-right: 15px;
  }
  .subtt_name{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }
}
.subject{
  .subject_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0;
    .subject_left{
      display: flex;
      align-items: center;
      img{
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      div{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
      }
    }
    .subject_right{
      display: flex;
      align-items: center;
      img{
        width: 6px;
        height: 11px;
        margin-left: 10px;
      }
      div{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #5694F7;
      }
    }
  }
}
.content_div{
  width: 1187px;
  background: #FFFFFF;
  border-radius: 10px;
  margin-top: 25px;
  margin-bottom: 20px;
  padding: 30px 50px;
  .con_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
    border-bottom: 1px solid #EAEEF1;
    .con_hleft{
      display: flex;
      align-items: center;
      div{
        margin-right: 20px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
    .cor_button{
      width: 128px;
      height: 40px;
      background: #5694F7;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      img{
        width: 18px;
        height: 18px;
        margin-right: 6px;
      }
    }
  }
}
  .question_set{
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #393F47;
    }
    </style>