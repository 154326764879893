import { postRequest, getRequest, putRequest, deleteRequest } from "./http.js";
//登录
export const Login = (data) => {
  //登录
  return postRequest("study/login/login", data);
};
export const logout = (data) => {
    //退出登录
    return postRequest("study/login/logout", data);
};
export const getMobileCode = (data) => {
    //发送验证码
    return postRequest("study/login/getMobileCode", data);
};
export const loginMobile = (data) => {
    //验证码登录
    return postRequest("study/login/loginMobile", data);
  };
export const updatePassword = (data) => {
    //发送验证码
    return postRequest("study/login/updatePassword", data);
};
export const getUserInfo = (data) => {
    //获取用户信息
    return postRequest("study/student/getUserInfo", data);
};
export const updateNickname = (data) => {
    //修改用户昵称
    return postRequest("study/student/updateNickname", data);
};
export const updateAvatar = (data) => {
    //修改用户头像
    return postRequest("study/student/updateAvatar", data);
};
export const getMyCollect = (data) => {
    //收藏-个人中心
    return postRequest("study/student/getMyCollect", data);
};
export const getStuCollectQues = (data) => {
    //收藏-题列表
    return postRequest("study/student/getStuCollectQues", data);
};
export const getWrongQuestionRecall = (data) => {
    //错题巩固-个人中心
    return postRequest("study/student/getWrongQuestionRecall", data);
};
export const getStuRecallQues = (data) => {
    //获取学生错题巩固试题
    return postRequest("study/student/getStuRecallQues", data);
};
export const updateStuRecallLog = (data) => {
    //提交学生错题巩固做题记录
    return postRequest("study/student/updateStuRecallLog", data);
};
export const getTeachers = (data) => {
    //班主任信息
    return postRequest("study/student/getTeachers", data);
};
export const getMyInformation = (data) => {
    //获取学生我的资料列表-个人中心
    return postRequest("study/student/getMyInformation", data);
};
export const qiniuImageUpload = (data) => {
    //上传
    return postRequest("study/public/qiniuImageUpload", data);
};
export const getStuTrainingLog = (data) => {
    //获取用户特训内容
    return postRequest("study/student/getStuTrainingLog", data);
};
export const getStuTraining = (data) => {
    //获取用户特训列表
    return postRequest("study/student/getStuTraining", data);
};
export const getSubject = (data) => {
    //获取用户专题
    return postRequest("study/student/getSubject", data);
};
export const getPlan = (data) => {
    //获取用户学习计划
    return postRequest("study/plan/getPlan", data);
};
export const getSubjectStatistics = (data) => {
    //
    return postRequest("study/student/getSubjectStatistics", data);
};
export const updatePlan = (data) => {
    //学习计划总结
    return postRequest("study/plan/updatePlan", data);
};
export const getCheckpointSection = (data) => {
    //获取特训下所有关卡，小节
    return postRequest("study/student/getCheckpointSection", data);
};
export const getopCheckpointSection = (data) => {
    //获取特训下所有关卡，小节
    return postRequest("study/study/getCheckpointSection", data);
};
export const getContent = (data) => {
    //获取特训详情
    return postRequest("study/student/getContent", data);
};
export const getTargetedExercises = (data) => {
    //获取特训详情
    return postRequest("study/student/getTargetedExercises", data);
};
export const getSubjectTopic = (data) => {
    //获取特训详情
    return postRequest("study/student/getSubjectTopic", data);
};
export const makePdf = (data) => {
    //生成PDF
    return postRequest("study/student/makePdf", data);
};
export const addTrainingLog = (data) => {
    //添加学习记录
    return postRequest("study/student/addTrainingLog", data);
};
export const addStuSubjectLog = (data) => {
    //专题添加学习记录
    return postRequest("study/student/addStuSubjectLog", data);
};
export const addSubjectLog = (data) => {
    //添加学习记录 专题
    return postRequest("study/student/addSubjectLog", data);
};
export const addStuTopicLog = (data) => {
    //提交学生做题记录
    return postRequest("study/student/addStuTopicLog", data);
};
export const getSutTopicStatistics = (data) => {
    //获取学生做题统计
    return postRequest("study/student/getSutTopicStatistics", data);
};
export const getSutSubjectStatistics = (data) => {
    //获取专题学生做题统计
    return postRequest("study/student/getSutSubjectStatistics", data);
};
export const searchStuWrongQuestion = (data) => {
    //搜索
    return postRequest("study/student/searchStuWrongQuestion", data);
};
export const addNotes = (data) => {
    //添加用户笔记
    return postRequest("study/student/addNotes", data);
};
export const  delNotes= (data) => {
    //删除用户笔记
    return postRequest("study/student/delNotes", data);
};
export const getNoteList = (data) => {
//学习笔记列表
return postRequest("study/student/getNotes", data);
  };
export const getExamCalendar = (data) => {
//报考日历
return postRequest("study/student/getNewExamCalendar", data);
};
export const getRegion = (data) => {
//报考日历
return postRequest("study/public/getRegion", data);
};
export const getSubjectContent = (data) => {
//报考日历
return postRequest("study/student/getSubjectContent", data);
};
export const getopSubjectContent = (data) => {
    //报考日历
    return postRequest("study/study/getSubjectContent", data);
    };
export const addUpdateCollect = (data) => {
//添加收藏
return postRequest("study/student/addUpdateCollect", data);
};
export const delCollect = (data) => {
//删除收藏
return postRequest("study/student/delCollect", data);
};
export const getNotesList = (data) => {
//笔记
return postRequest("study/student/getNotesList", data);
};
export const addAnswer = (data) => {
//新增答疑
return postRequest("study/answer/addAnswer", data);
};
export const getCate = (data) => {
//获取考试类型
return postRequest("study/answer/getCate", data);
};
export const getAnswerlist = (data) => {
//获取答疑列表
    return postRequest("study/answer/getAnswerlist", data);
};
export const getAnswerDetail = (data) => {
//获取答疑详情
return postRequest("study/answer/getAnswerDetail", data);
};
export const setAnswer = (data) => {
//修改答疑
return postRequest("study/answer/setAnswer", data);
};
export const getCityList = (data) => {
//城市
return postRequest("study/study/getCityList", data);
};