<template>
	<div class="container">
		<!-- <userInfo></userInfo> -->
		<div class="content">
			<div class="aatitle">
				模考大赛
			</div>
			<!-- <navBar :navList="navList"></navBar> -->
			<div class="wraps">
				<div class="table_box">
					<div class="table_title">
						<div v-for="(item,index) in titleList" :key="index">{{item}}</div>
					</div>
					<div class="table_content" :class="content ?'':'nothing'">
						<img src="@/assets/images/nothing.png" v-if="!content">
						<div class="table_list" v-for="(item,index) in content" :key="index" v-else
							@click="$router.push('/simulationDetail?id='+item.id+'&name='+item.name+'&type='+item.type_text+'&time='+item.spent_time+'&status='+item.status)">
							<div class="list_title">{{item.name}}</div>
							<div>{{item.type_text}}</div>
							<div>{{item.spent_time,3|dtime}}</div>
							<div class="btns">
								<div class="status" style="background: #Fdf6ec;color: #E6A23C !important;"
									v-if="item.status == '已开始'">{{item.status}}</div>
								<div class="status" style="background: #fff0ed;color: #FE7F67;"
									v-if="item.status == '已结束'">{{item.status}}</div>
								<div class="status" style="background: #E5FFEC;color: #2FAD48 !important;"
									v-if="item.status == '已报名'">{{item.status}}</div>
								<div class="detail">详情</div>
							</div>
						</div>
					</div>
				</div>
				<turnPages class="pages_box" :count="count" :pages="pages" @fanye="fanyeClick" @inputPage="inputPage">
				</turnPages>
			</div>
		</div>
	</div>
</template>

<script>
	import tableList from '@/components/tableList/tableList'
	import turnPages from '@/components/turnPages/turnPages'
	import {
		EventBus
	} from '@/eventBus/eventBus'
	import {
		getMockList
	} from '@/utils/api.js'
	export default {
		components: {
			tableList,
			turnPages
		},
		data() {
			return {
				navList: [{
						path: '/classList',
						name: '首页',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 0
					},
					{
						path: '/simulation',
						name: '模考大赛',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 2
					}
				],
				clasType: '',
				ClassName: '',
				titleList: ['模考名称', '模考类别', '模考时间', '操作'],

				content: [],
				count: 0,
				pages: 1,
				limit: 9,
			}
		},
		created() {
			this.getData()
			EventBus.$emit('navList', this.navList)
		},
		methods: {
			async getData() {
				await getMockList({
					page: this.pages,
					limit: this.limit
				}).then(res => {
					this.content = res.list
					this.count = Math.ceil(res.total / this.limit)
				})
			},
			fanyeClick(e) {
				if (e == 'next') {
					this.pages++
					console.log(this.pages)
					if (this.pages <= this.count) {
						this.getData()
					} else {
						this.pages = this.count
					}
				} else {
					this.pages--
					if (this.pages >= 1) {
						this.getData()
					} else {
						this.pages = 1
					}
				}
			},
			inputPage(e) {
				this.pages = e * 1
				this.getData()
			},
			listJump(id, name) {
				this
			},
		}
	}
</script>

<style scoped="scoped">
	.list_title {
		font-weight: bold;
		font-size: 15px;
	}

	.aatitle {
		margin-bottom: 15px;
		padding-left: 15px;
		border-left: 5px solid #47BE5E;
		font-size: 18px;
		font-weight: bold;
	}

	.content {
		width: 1132px;
		background: #fff;
		min-height: 780px;
		padding: 30px 20px;
		padding-top: 15px;
		padding-bottom: 50px;
		position: relative;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
	}

	.pages_box {
		position: absolute;
		bottom: 20px;
		right: 42px;
	}

	.table_title,
	.table_list {
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.table_title {
		background: #F7F7F7;
	}

	.table_title div {
		width: 15%;
		display: flex;
		font-size: 16px;
		font-weight: bold;
		color: #333;
		align-items: center;
		justify-content: center;
	}

	.table_title div:first-child,
	.table_list div:first-child {
		width: 49%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.table_title div:last-child {
		flex: 1;
	}

	/* 
	.table_list div:last-child{
		display: flex;
		align-items: center;
		justify-content: space-between;
	} */


	.table_list {
		/* padding: 10px 0 !important; */
		height: 65px;
		padding-left: 15px;
	}

	.table_list:hover {
		background: rgba(71, 190, 94, .3);
		border-radius: 5px;
		/* color: #FFF; */
	}

	.table_list div {
		width: 15%;
		display: flex;
		color: #333;
		align-items: center;
		justify-content: center;
	}

	.table_list div:first-child {
		justify-content: flex-start;
	}

	.btns {
		width: 21% !important;
		display: flex;
		align-items: center;
		justify-content: space-around !important;
	}

	.btns .status {
		display: flex;
		align-items: center;
		justify-content: center !important;
		width: 70px !important;
		height: 35px;
		color: #FF684B;
		background: #E9E9E9;
		font-size: 14px;
		border-radius: 3px;
	}

	.btns .detail {
		width: 60px !important;
		height: 35px;
		background: #5694F7;
		color: #fff;
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: center !important;
		border-radius: 3px;
		cursor: pointer
	}
</style>
