import { render, staticRenderFns } from "./answer.vue?vue&type=template&id=62bd332e&scoped=true&"
import script from "./answer.vue?vue&type=script&lang=js&"
export * from "./answer.vue?vue&type=script&lang=js&"
import style0 from "./answer.vue?vue&type=style&index=0&lang=css&"
import style1 from "./answer.vue?vue&type=style&index=1&id=62bd332e&scoped=scoped&lang=css&"
import style2 from "./answer.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62bd332e",
  null
  
)

export default component.exports