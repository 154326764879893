<template>
	<div class="my_exam">
		<div class="wraps">
			<div class="title_box">
				<div class="title" v-for="(item,index) in titleList" :key="index">{{item}}</div>
			</div>
			<div class="content_box">
				<div class="content_list" v-for="(item,index) in setData" :key="index">
					<div>{{item.title}}</div>
					<div v-if="item.type == '1'">已解答</div>
					<div style="color:#FA4E53" v-else>未解答</div>
					<div>{{item.num}}</div>
					<div class="look" @click="detailClick(index)">
						<div :style="item.type == '1'?'color:#00d3aa':'color:#818181'">
							<img src="@/assets/images/icon_1.png" v-if="item.type !== '1'">
							<img src="@/assets/images/icon3.png" v-if="item.type == '1'">查看详情
						</div>
					</div>
				</div>
			</div>
			<turnPages class="pages_box" :count="count" :pages="pages" @fanye="fanyeClick" @inputPage="inputPage">
			</turnPages>
		</div>
	</div>
</template>

<script>
	import turnPages from '@/components/turnPages/turnPages'
	import tableList from '@/components/tableList/tableList'
	export default {
		components: {
			turnPages,
			tableList
		},
		data() {
			return {
				titleList: ['问题描述', '状态', '时间', '操作'],
				setData: [{
						title: 'Why is the sky blue？',
						type: '2',
						num: '2021年7月22日',
					},
					{
						title: 'Why is the sky blue？',
						type: '1',
						num: '2021年7月22日',
					},
					{
						title: '为什么天是蓝色的？',
						type: '2',
						num: '2021年7月22日',
					},
				],
				navList: [],
				pages: 1,
				count: 0,
				limit: 10,
			}
		},
		methods: {
			detailClick(i) {
				console.log(i)
			},
			fanyeClick(e) {
				if (e == 'next') {
					this.pages++
					console.log(this.pages)
					if (this.pages <= this.count) {
						this.getData()
					} else {
						this.pages = this.count
					}
				} else {
					this.pages--
					if (this.pages >= 1) {
						this.getData()
					} else {
						this.pages = 1
					}
				}
			},
			inputPage(e) {
				this.pages = e * 1
				this.getData()
			}
		}
	}
</script>

<style scoped="scoped">
	.my_exam {
		background: #fff;
		border-radius:10px;
		padding: 1.25rem 1.5625rem;
		height: 100%;
		box-shadow: 0px 0px 6px 0px rgba(80, 93, 223, 0.18);
		height: 45.9375rem;
		position: relative;
		box-shadow: 0px 0px 6px 0px rgba(80, 93, 223, 0.18);
	}

	.pages_box {
		position: absolute;
		bottom: 1.25rem;
		right: 2.625rem;
	}

	.title_box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 3.5625rem;
		background: #F3F4FF;
		border-radius:10px;

	}

	.title {
		font-size: 1.25rem;
		flex: 1;
		text-align: center;
		color: #161616
	}

	.content_list {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.9375rem 0;

	}

	.content_list div {
		text-align: center;
		flex: 1;
		font-size: 1.125rem;
	}

	.look {
		display: flex;
		align-items: center;
	}

	.look img {
		width: 0.875rem;
		height: 1rem;
		margin-right: 0.4375rem;
		top: 0.0625rem;
	}
</style>
