<template>
	<div class="app-login">

		<img class="login-pic" src="/images/login_bg1.png" alt="">
		<img class="login-pic1" src="/images/login_bg1.png" alt="">
		<div class="box box-login">
			<div class="login-tit animated bounceIn">
				<img src="/images/login_1.png" alt="" />
			</div>
			<div class="login-bg">
				<!-- 登录 -->
				<div id="login" class="login login-con animated"
					:class="{bounceInUp:passwordType == 0,'animated-deplay':passwordType == 0,login1:passwordType == 2}">
					<el-form ref="form" :model="form" label-width="0" class="login-form">
						<el-form-item>
							<div class="login-input" style="cursor: text;">
								<el-input v-model="form.username" placeholder="请输入账号"></el-input>
							</div>
						</el-form-item>
						<el-form-item>
							<div class="login-input login-password">
								<el-input type="text" class="hover-cursor" v-model="form.password" placeholder="请输入密码"
									@keyup.enter.native="login()" show-password></el-input>
							</div>
						</el-form-item>
						<!-- <el-form-item>
							<div class="login-code">
								<el-input v-model="form.captcha" placeholder="请输入验证码"></el-input>
								<span>验证码</span>
							</div>
						</el-form-item> -->
					</el-form>

					<div class="login-footer">
						<em @click="passwordType = 2">忘记密码？</em>
						<el-button type="primary" size="small" class="fr" @click="login">登录</el-button>
						<!-- <input @keyup.13="login"/> -->
					</div>
				</div>

				<!-- 忘记密码 -->
				<div class="password login-con" :class="{password1:passwordType == 2}">
					<el-form ref="form" :model="form" label-width="0" class="login-form">
						<el-form-item>
							<div class="login-input">
								<el-input v-model="form_1.username" placeholder="请输入账号"></el-input>
							</div>
						</el-form-item>
						<el-form-item>
							<div class="login-input login-password">
								<el-input v-model="form_1.password" placeholder="请输入密码" show-password></el-input>
							</div>
						</el-form-item>
						<el-form-item>
							<div class="login-input login-password">
								<el-input v-model="form_1.repassword" placeholder="请再次输入密码" show-password></el-input>
							</div>
						</el-form-item>
						<el-form-item>
							<div class="login-code">
								<el-input v-model="form_1.captcha" placeholder="请输入验证码"></el-input>
								<!-- <span @click="send_text">发送短信</span> -->
								<el-button v-if="secend===0" type="primary" size="mini" @click="send_text">发送短信
								</el-button>
								<el-button v-else type="primary" size="mini">{{secend}}</el-button>
							</div>
						</el-form-item>
					</el-form>
					<div class="login-footer">
						<em @click="passwordType = 1">前往登录？</em>
						<el-button type="primary" size="small" class="fr" @click="set_password">确认修改</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {Login} from '@/utils/api.js'
	// import Cookies from "js-cookie";
	import captcha from 'vue-social-captcha'
	import {
		Base64
	} from 'js-base64';
	export default {
		name: "codetest",
		data() {
			return {
				secend: 0,
				timer: '',
				form: {
					username: '',
					password: '',
					captcha: ''
				},
				form_1: {
					username: '',
					password: '',
					repassword: '',
					captcha: ''
				},
				passwordType: 0,
				captchaOption: {
					appid: '2062784548',
				},
				captcha: ''
			};
		},
		methods: {
			login() {
				if (!this.form.username || !this.form.password) {
					return this.$message({
						type: "error",
						message: "账号或密码为空！",
						showClose: true,
					});
				}
				if (!(/^1[34578]\d{9}$/.test(this.form.username))) {
					return this.$message({
						type: "error",
						message: "请输入正确的账号！",
						showClose: true,
					});
				}
				this.captcha = new TencentCaptcha('2062784548', (rsp) => {
					if (rsp.ret === 0) {
						//当ret是0得时候代表成功填写了验证码，我在里面进行了axios操作。
						let datas = {
							mobile: Base64.encode(this.form.username),
							password: Base64.encode(this.form.password)
						};
						Doctor.login(datas).then(res => {
							if (res.code === 200) {
								Cookies.set('userInfo', res.data, {
									expires: 1
								});
								localStorage.setItem('loginType', res.data)
								this.$router.push({
									path: "/list",
								});

							} else {
								this.$message({
									type: "error",
									message: res.msg,
									showClose: true,
								});
							}
						});
					} else {
						this.$message({
							message: '验证不通过',
							type: 'warning'
						})
					}
				})
				this.captcha.show();

			},
			set_password() {
				if (!this.form_1.username || !this.form_1.password || !this.form_1.captcha) {
					return this.$message({
						type: "error",
						message: "账号密码或验证码为空！",
						showClose: true,
					});
				}

				if (this.form_1.password !== this.form_1.repassword) {
					return this.$message({
						type: "error",
						message: "两次密码不一致！",
						showClose: true,
					});
				}
				let datas = {
					mobile: this.form_1.username,
					password: this.form_1.password,
					captcha: this.form_1.captcha
				};
				Doctor.set_password(datas).then(res => {
					if (res.code === 200) {
						this.passwordType = 1;

					} else {
						this.$message({
							type: "error",
							message: res.msg,
							showClose: true,
						});
					}
				});

			},
			send_text() {
				if (!this.form_1.username) {
					return this.$message({
						type: "error",
						message: "账号为空！",
						showClose: true,
					});
				}
				if (!(/^1[34578]\d{9}$/.test(this.form.username))) {
					return this.$message({
						type: "error",
						message: "请输入正确的账号！",
						showClose: true,
					});
				}
				this.captcha = new TencentCaptcha('2062784548', (rsp) => {
					if (rsp.ret === 0) {
						let datas = {
							mobile: this.form_1.username
						};
						Doctor.send_text(datas).then(res => {
							this.counter();
							this.$message({
								type: "error",
								message: res.msg,
								showClose: true,
							});
						});
					} else {
						this.$message({
							message: '验证不通过',
							type: 'warning'
						})
					}
				})
				this.captcha.show();
			},
			counter() {
				this.secend = 30;
				this.timer = setInterval(() => {
					this.secend = this.secend - 1;
					if (this.secend == 0) {
						clearInterval(this.timer);
					}
				}, 1000)
			}
		},
		destroyed() {
			clearInterval(this.timer);
		},
		mounted() {
			var that = this;
			setTimeout(function() {
				that.passwordType = 1
			}, 1500);
		}

	};
</script>

<style>
.hover-cursor{
	cursor: text;
}
	.app-login {
		background: url(/images/login_bg.png) center;
		background-color: 100% 100%;
		height: 100%;
		position: relative;
	}

	.login-pic {
		position: absolute;
		bottom: 0;
		-webkit-animation: pic 30s infinite linear;
		animation: pic 30s infinite linear;

	}

	@-webkit-keyframes pic {
		from {
			-webkit-transform: translateX(0%);
			transform: translateX(0%)
		}

		to {
			-webkit-transform: translateX(-100%);
			transform: translateX(-100%)
		}
	}

	.login-pic1 {
		position: absolute;
		bottom: 0;
		webkit-animation: pic1 30s infinite linear;
		animation: pic1 30s infinite linear;
		-webkit-transform: translateX(100%);
		transform: translateX(100%)
	}

	@-webkit-keyframes pic1 {
		from {
			-webkit-transform: translateX(100%);
			transform: translateX(100)
		}

		to {
			-webkit-transform: translateX(0%);
			transform: translateX(0%)
		}
	}


	/* 使用webkit内核的浏览器 */

	:-moz-placeholder {
		color: #94acc0;
	}

	/* Firefox版本4-18 */

	::-moz-placeholder {
		color: #94acc0;
	}

	/* Firefox版本19+ */

	:-ms-input-placeholder {
		color: #94acc0;
	}

	.login-tit {
		text-align: center;
		padding: 100px 0 40px 0;
	}

	.login {
		width: 439px;
		height: 428px;
		background: url("/images/login_2.png");
		background-size: 100% 100%;
		margin: 0 auto;
		transform: rotateY(0);
		transition: all 0.4s ease 0s;
	}

	.login-con input {
		border: none;
		height: 50px;
	}

	.login-form {
		padding: 190px 55px 0 55px;
	}

	.login-input {
		padding-left: 35px;
		background: url("/images/login_4.png") left 15px center no-repeat #fff;
		background-size: auto 18px;
	}

	.login-password {
		background: url("/images/login_5.png") left 15px center no-repeat #fff;
		background-size: auto 18px;
	}

	.login-code {
		padding: 0 100px 0 35px;
		background: url("/images/login_6.png") left 15px center no-repeat #fff;
		background-size: auto 18px;
		position: relative;
	}

	.login-code button {
		position: absolute;
		right: 10px;
		top: 11px;
		display: block;
		width: 90px;
		text-align: center;

	}

	.login-footer {
		margin: 24px 20px 0 20px;
		background-color: #fff;
		padding: 20px 40px;
		border-radius: 0 0 10px 10px;
		overflow: hidden;
		line-height: 30px;
	}

	.login-footer em {
		color: #94acc0;
		cursor: pointer;
	}

	.login-footer em:hover {
		color: #058eff;
	}

	.password {
		width: 439px;
		height: 572px;
		background: url("/images/login_3.png");
		background-size: 100% 100%;
		margin: 0 auto;
		position: absolute;
		top: 0;
		left: 0;
		transform: rotateY(180deg);
		transition: all 0.4s ease 0s;
		opacity: 0;
		z-index: -1;
	}

	.password .login-footer {
		margin: 26px 20px 0 20px;
	}

	.login-footer button {
		padding: 8px 20px;
	}

	.animated-deplay {
		animation-delay: 0.3s;
	}

	.login-bg {
		width: 439px;
		margin: 0 auto;
		position: relative;
	}

	.login1 {
		transform: rotateY(180deg);
		opacity: 0;
		transition: all 0.4s ease 0s;
		z-index: -1;
	}

	.password1 {
		opacity: 1;
		z-index: 100;
		transform: rotateY(0);
	}
</style>
