<template>
    <div class="container">
      <div class="swiper-container">
          <div class="swiper-wrapper">
              <div class="swiper-slide ctype_i" :class="oneIndex == index ? 'acctype_i' : ''"
                v-for="(item, index) in chekiList"
                :key="index"
                @click="oneType(index,item.checkpoint)">
                <div class="ctype_top">
                <div class="ctype_top_name">第 <span>{{index+1}}</span> 篇
                </div>
              </div>
              <div class="ctype_dec">{{item.checkpoint_name}}</div>
              </div>
              
          </div>
          <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
          <!-- Add Pagination -->
          <div class="swiper-pagination"></div>
      </div>
      <div class="curtype">
        
      </div>
      <div class="twodiv" v-if="chekiList[oneIndex]">
        <div class="twov_tabs">
          <div v-for="(tw, index2) in chekiList[oneIndex].new_section_info"
            :key="index2">
           
            <div
          v-if="tw.is_stage_test==1"
            class="tabs_i"
            :class="twoIndex == index2 ? 'actabs_i' : ''"
            @click="twoChange(index2)"
          >
            <div class="tabs_i_left">
              <div class="gongguimg"><img src="../../assets/images/stage_examination_hover.png" alt=""></div>
            </div>
            <div class="tabs_i_right gonggus">
             <div class="gonggus_left">
                <div class="gonggus_left_name" :style="{color:twoIndex == index2 ? '#308dff' : '',fontWeight:twoIndex == index2 ? 'bold' : ''}">阶段测验</div>
                <div class="gonggus_left_dec" v-if="chekiList">{{chekiList[oneIndex].checkpoint_name}}测验</div>
             </div>
              <div class="tabsi_right_icon">
                <i class="el-icon-arrow-right" :style="{color: twoIndex == index2 ?'#308dff':'#D8D8D8'}"></i>
              </div>
            </div>
          </div>
          <div
          v-else-if="tw.is_consolidate==1"
              class="tabs_i"
              :class="twoIndex == index2 ? 'actabs_i' : ''"
            @click="twoChange(index2)"
            >
              <div class="tabs_i_left">
                <div class="gongguimg"><img src="../../assets/images/icon.png" alt=""></div>
              </div>
              <div class="tabs_i_right gonggus">
              <div class="gonggus_left">
                  <div class="gonggus_left_name" :style="{color:twoIndex == index2 ? '#308dff' : '',fontWeight:twoIndex == index2 ? 'bold' : ''}">复习巩固</div>
                  <div class="gonggus_left_dec">根据关卡学习情况智能巩固</div>
              </div>
                <div class="tabsi_right_icon">
                  <i class="el-icon-arrow-right" style="color: #308dff"></i>
                </div>
              </div>
            </div>
            <div
              v-else-if="chekiList[oneIndex].new_section_info!=''"
              class="tabs_i"
              :class="twoIndex == index2 ? 'actabs_i' : ''"
              @click="twoChange(index2)"
            >
              <div class="tabs_i_left">
                <div class="tabsi_left_inx">第<span>{{chekiList[oneIndex].new_section_info[0].is_consolidate?index2:index2+1}}</span>章</div>
                <div class="tabsi_left_namae">{{tw.section_name}}</div>
              </div>
              <div class="tabs_i_right">
                <!-- <div class="tabsi_right_pro">已学习 <span>{{tw.progress}}</span> %</div> -->
                <div class="tabsi_right_icon">
                  <i class="el-icon-arrow-right" :style="{color: twoIndex == index2 ?'#308dff':'#D8D8D8'}"></i>
                </div>
              </div>
          </div>
        </div>
        </div>

        <div class="two_content">
          <div class="question_set" v-if="chekiList[oneIndex].new_section_info[twoIndex].content_info!=''">{{trainingName}}</div>
          <div class="course_list">
            <div class="course_i" @click="tolChange(item,item.id)" v-for="(item, index8) in chekiList[oneIndex].new_section_info[twoIndex].content_info" :key="index8">
              <div class="cour_img" :style="{background:prbaColor(item.type)}">
                  <img v-if="item.type==1" src="../../assets/images/login/icon_tj_wk.png" alt="">
                  <img v-if="item.type==2||item.type==4" src="../../assets/images/login/icon_tj_zl.png" alt="">
                  <img v-if="item.type==3" src="../../assets/images/login/icon_tj_cy.png" alt="">
                  <div v-if="item.type==1">网课</div>
                  <div v-if="item.type==2">测验</div>
                  <div v-if="item.type==3">资料</div>
                  <div v-if="item.type==4">练习</div>
              </div>
              <div class="cour_info">
                <div class="cour_details">
                  <div class="cour_name">{{item.name}}</div>
                  <div class="cour_kankj">
                      <div v-if="item.type==1" style="width:180px">课程时长：<span :style="{color:backgChange(item.type)}">{{item.count_times}}</span> 分钟</div>
                      <div v-if="item.type==2||item.type==4" style="width:180px">总分：<span :style="{color:backgChange(item.type)}">{{item.score||0}}</span> 分</div>
                      <div v-if="item.type==3" style="width:180px"><img src="../../assets/images/login/icon_zt_zip.png" alt="" style="margin-right:8px">PDF电子资料</div>
                      <div v-if="item.type==3" style="width:180px">共<span :style="{color:backgChange(item.type)}">1</span> 份</div>
                    <!-- <div class="cour_progress" v-if="item.type!=3">
                      <div class="courpro_lg" :style="{background:backgChange(item.type),width:(item.progress||0)+'%'}"></div>
                      <div class="cour_gard">
                        <div v-for="(item, index) in 4" :key="index"></div>
                      </div>
                    </div> -->
                    <div v-if="item.type!=3">
                      <!-- <div v-if="item.type==1">已学习：<span :style="{color:backgChange(item.type)}">{{item.progress||0}}</span> %</div> -->
                       <!-- <div v-else>得分：<span :style="{color:backgChange(item.type)}">{{item.my_score||0}}</span> 分</div> -->
                    </div>
                  </div>
                </div>
                <div v-if="is_expire==0">
                  <div :style="{background:'#e1dfdf',color:'#999999'}" class="cour_btn" >
                      已过期
                  </div>
                </div>
                <div v-else>
                  <div class="cour_btn" :style="{background:backgChange(item.type),color:'#ffffff'}" v-if="!item.completion">
                    {{item.type==3?'去下载':'去学习'}}
                  </div>
                  <div v-if="item.completion>0&&item.type!=3" class="cour_btn" >已完成</div>
                  <div v-if="item.completion>0&&item.type==3" :style="{background:backgChange(item.type),color:'#ffffff'}" class="cour_btn" >
                      去下载
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          <!--  -->
          <div class="tpelist" v-if="false">
            <div class="tpelist_i" v-if="chekiList[oneIndex].new_section_info!=''" v-for="(tiy, index3) in chekiList[oneIndex].new_section_info[twoIndex].content_info" :key="index3">
              <div class="tpelist_i_left">
                <div class="tpe_inx" :style="{color:backgChange(tiy.type)}">{{index3+1}}</div>
                <div class="tpe_type" :style="{color:backgChange(tiy.type)}">
                  <img v-if="tiy.type==1" src="../../assets/images/login/icon_tk_b.png" alt="" />
                    <img v-if="tiy.type==2||tiy.type==4" src="../../assets/images/login/icon_tx_cy.png" alt="" />
                    <img v-if="tiy.type==3" src="../../assets/images/login/icon_tx_zl.png" alt="" />
                          <div v-if="tiy.type==1">网课</div>
                  <div v-if="tiy.type==2">测验</div>
                  <div v-if="tiy.type==3">资料</div>
                  <div v-if="tiy.type==4">练习</div>
                </div>
                <div class="tpe_name">{{tiy.name}}</div>
              </div>
              <div class="tpelist_i_right">
                <div class="cour_progress" v-if="tiy.type!=3">
                  <div class="courpro_lg" :style="{background:backgChange(tiy.type),width:(tiy.completion||0)+'%'}"></div>
                  <div class="cour_gard">
                    <div v-for="(item, index5) in 4" :key="index5"></div>
                  </div>
                </div>
                <div v-if="tiy.type==1" class="tpe_pro">已完成：<span :style="{color:backgChange(tiy.type)}">{{tiy.progress}}</span>%</div>
                <div v-if="tiy.type==2||tiy.type==4" class="tpe_pro">得分：<span :style="{color:backgChange(tiy.type)}">{{tiy.my_score||0}}</span>分</div>
                <div class="cour_btn" :style="{background:backgChange(tiy.type),color:'#ffffff'}" @click="codeShow=true" v-if="!tiy.completion">
                        {{tiy.type==3?'去下载':'去学习'}}
                      </div>
                      <div v-if="tiy.progress==100&&tiy.type!=3" class="cour_btn" @click="goAns(tiy.id,item)">已完成</div>
                      <div v-if="tiy.progress==100&&tiy.type==3" :style="{background:backgChange(tiy.type),color:'#ffffff'}" class="cour_btn" @click="goSxxi(tiy,item.id)">
                         去下载
                    </div>
              </div>
            </div>
          </div>
          <div class="nothing" v-if="chekiList[oneIndex].new_section_info[twoIndex].condition_info==''&&chekiList[oneIndex].new_section_info[twoIndex].content_info==''">
            <img src="../../assets/images/nothing.png" alt="">
          </div>
          <div class="question_set" v-if="chekiList[oneIndex].new_section_info[twoIndex].condition_info!=''">考核条件</div>
          <div class="condition_i" @click="goSxxi(con,con.content_id)" v-for="(con, index6) in chekiList[oneIndex].new_section_info[twoIndex].condition_info" :key="index6">
            <div class="condition_i_left">
              <div class="con_inx" :style="{background:con.is_complete==0?'':'#308DFF',color:con.is_complete==0?'':'#ffffff'}">{{index6<9?0:''}}{{index6+1}}</div>
              <div class="con_name">{{con.title}}</div>
            </div>
            <div class="condition_i_right" :style="{color:con.is_complete==0?'#308DFF':''}">
              {{con.is_complete==0?con.condition_type==1?'去完成':'待审查':'已完成'}}
              <img v-if="con.is_complete==1" src="../../assets/images/login/icon_tj_ok.png" alt="" />
              <img v-else src="../../assets/images/login/icon_tj_no.png" alt="" />
            </div>
          </div>
          <!-- <div class="question_set">相关知识点</div>
          <div class="minddiv" :style="heightShow?'height:400px':'height:auto'">
            <tuJsmind></tuJsmind>
            <div class="clickd" @click="heightShow=!heightShow">点击查看完整知识导图<i class="el-icon-arrow-down" style="color:#308DFF;"></i></div>
          </div> -->
        </div>
      
      </div>
      <div class="codeart" v-if="codeShow">
            <div class="codeart-cen">
                <div class="fic-icon" @click="codeShow=false">
					×
				</div>
                <img src="https://files.sz517.com/szedu/image/2024/0929/15OQG5uNdjwPNXcHKb.png" alt="">
            </div>
        </div>
    </div>
  </template>
  <script>
  import tuJsmind from '@/components/indexComp/tuJsmind'
  import Swiper from 'swiper'; // 注意引入的是Swiper
  import 'swiper/css/swiper.min.css' // 注意这里的引入
  import {getopCheckpointSection,addTrainingLog} from "@/utils/erpapi.js";
  export default {
  components: {
      tuJsmind,
  },
    data() {
      return {
        is_expire:0,
        oneIndex: 0,
        twoIndex: 0,
        codeShow:false,
        heightShow:true,
        swiperInstance: null,
        tringInfo:{},//路由参数
        chekiList:[],
        trainingName:""
      };
    },
    watch: {
      chekiList: {
        //为什么即使监听到数据变化了，还需要放入$nextTick？因为v-for渲染需要时间，在没有渲染完成之前，不能初始化swiper
        immediate: true,
        handler() {
          //只能监听到数据已经有了，但是v-for动态渲染结构我们还是没有办法确定的，因此还是需要用nextTick
          this.$nextTick(() => {
                  var swiperInstance = new Swiper('.swiper-container', {
                    freeMode: true,
                    pagination: '.swiper-pagination',
                    slidesPerView: 'auto',
                    paginationClickable: true,
                    spaceBetween: 30,
                    resistanceRatio: 0,
                    navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  },
                })
                          })
        },
      },
    },
    created() {
      this.oneIndex=this.$route.query.index||0
      this.twoIndex=this.$route.query.twoindex||0
      this.loading = this.$loading({
          lock: true,
          text: "",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
       this.tringInfo=this.$route.query
       this.getopCheckpointSection()
    },
    mounted() {
      // var swiper = new Swiper('.swiper-container', {
      //   slidesPerView: 3,
      //   slidesPerColumn: 2,
      //   spaceBetween: 30,
      //   pagination: {
      //     el: '.swiper-pagination',
      //     clickable: true,
      //   },
      // });
  
      },
    methods: {
      goAns(id,data){
        this.codeShow=true
        return
        if(data.type==1){
           this.$router.push("/courseVideo?id=" + id+"&index=" + this.oneIndex+"&content_id=" + this.$route.query.id+"&twoIndex=" + this.twoIndex);
          return
        }
        let navarrs={
          content_id:id,
          result_id:id,
          training_log_id:data.training_log_id,
      paths:this.$route.fullPath
        }
        this.$router.push('/AnswerEnd?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(
                      navarrs)))+"&subjectType=1");
      },
      tolChange(data,id){
        this.codeShow=true
        return
        if(this.is_expire==0){
          this.$message.info({
            message: "已过期",
            showClose: true,
          });
          return
        }
        if(!data.completion){
           this. goSxxi(data,id)
           return
        }
        if(data.completion>0&&data.type==3){
          this. goSxxi(data,id)
        }
        if(data.completion>0&&data.type!=3){
            this.goAns(id,data)
        }
      },
      //
      goZjesindex(data){
        this.codeShow=true
        return
        let navarrs = {
          checkpoint_id:data.checkpoint_id,
          content_id:'',
          type:data.type,
          training_log_id:data.id,
          resutype:1
              }
         console.log(navarrs)
         localStorage.removeItem('result_id')
          setTimeout(() => {
            this.$router.push('/AnswerEnd?source=1&subjectType=1'+"&type="+data.type+'&parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(
              navarrs))));
          }, 500)
      },
      // 去测验
      goStage(type,id){
        this.codeShow=true
        return
        this.$router.push("/answer?id=" + id+"&type="+type);
      },
          // 错题巩固
          goWorngd(type,data) {
            this.codeShow=true
        return
          this.$router.push(
            "/erroranswer?id=" +data.id +
              "&type=" +
              type +
              "&source=1" +
              "&name=" +
              data.checkpoint_name
          );
      },
      // 查漏补缺
      chaLoub(data){
        this.$router.push("/answer?id=" + data.id+"&chatype=1");
      },
      // 去学习
      goSxxi(data,id){
        this.codeShow=true
        return
       if(data.is_complete>0){
         return
       }
        if(data.condition_type==2)return;
        this.subjectType=data.type
          if(data.type==1){
            this.$router.push("/courseVideo?id=" + id+"&index=" + this.oneIndex+"&content_id=" + this.$route.query.id+"&twoIndex=" + this.twoIndex);
          }else if(data.type==3){
            window.open(data.file_url);
            this.addsTrainin(id)
          }else{
            this.$router.push("/answer?id=" + id+"&type=" + data.type);
          }
       },
                  // 添加学习记录
              addsTrainin(id){
                  addTrainingLog({
                      content_id:id,
                  }).then(res=>{
              
                  })
                  },
      // 获取所有关卡小节
      getopCheckpointSection(){
        getopCheckpointSection({
          id:this.tringInfo.id
        }).then(res=>{
          console.log(res.data)
          this.is_expire=1
          if(res.data.data==''){
            this.$message({
              message: '暂无内容',
              type: 'warning'
            });
            // 
            
           setTimeout(() => {
            this.loading.close();
            this.$router.go(-1)
           }, 1000);
            return
          }
            if(this.$route.query.section_id>0){
              console.log('88')
              res.data.data.forEach((item,index) => {
                if(item.new_section_info){
                  item.new_section_info.forEach((twem,index1) => {
                  if(twem.id==this.$route.query.section_id){
                    this.oneIndex=index
                    this.twoIndex=index1
                  }
                });
                }
                
              });
            }
            this.trainingName=res.data.training_name
          this.loading.close();
          this.chekiList=res.data.data
        })
      },
      oneType(index,status) {
        this.oneIndex = index;
        this.twoIndex=0
      },
      twoChange(index) {
        this.twoIndex = index;
      },
    },
  
  };
  </script>
  
  <style scoped="scoped" lang="scss">
    .cour_progress{
                position: relative;
                width: 100px;
                height: 10px;
                margin-left: 35px;
                margin-right: 15px;
                background: #D6E8FF;
                border-radius:2px;
                .courpro_lg{
                  position: absolute;
                  height: 10px;
                  z-index: 90;
                  width: 50%;
                  background: #308DFF;
                  top: 0;
                  left: 0;
                  border-radius:2px;
                }
                .cour_gard{
                  position: absolute;
                  z-index: 99;
                  width: 100%;
                  top: 0;
                  left: 0;
                  display: flex;
                  align-items: center;
                  div{
                    width: 21px;
                    height: 10px;
                    background: #e4050500 !important;
                    border-right: 4px solid #ffffff;
                    margin: 0;
                  }
                }
              }
    .cour_btn{
            width: 100px;
            height: 32px;
            background: #FFFFFF;
            border: 1px solid #EBEBEB;
            border-radius: 16px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            text-align: center;
            line-height: 32px;
            margin-left: 30px;
          }
      .swiper-container {
          width: 1200px;
          height: 110px !important;
          margin: 20px auto;
          margin: 0 !important;
          padding: 0 !important;
      }
      .swiper-slide.swiper-slide-active {
        width: 189px !important;
        height: 110px !important;
      }
      .swiper-slide {
         display: flex;
         flex-direction: column;
          text-align: center;
          font-size: 18px;
          width: 189px !important;
          height: 110px !important;
          background: #ffffff !important;
          border: 1px solid #308dff;
          width: 189px;
          height: 110px;
          border: 1px solid #e2ecfb;
          box-shadow: 0px 0px 5px 0px rgba(162, 177, 188, 0.2);
          border-radius: 5px 5px 4px 4px;
          margin-right: 15px !important;
          margin-top: none !important;
      }
       .ctype_top {
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #ecf4ff;
        padding: 0 15px;
        .ctype_top_name {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #a4a4a4;
        }
        .ctype_top_status {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #a4a4a4;
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
      .acctype_i {
      border: 1px solid #308dff !important;
      .ctype_top {
        background: linear-gradient(90deg, #308dff, #4a9bff) !important;
        .ctype_top_name {
          color: #ffffff !important;
        }
        .ctype_top_status {
          color: #ffffff !important;
          span{
            font-size: 18px;
          font-family: DIN Next LT Pro;
          font-weight: bold;
          color: #FFFFFF; 
          }
        }
      }
    }
    .ctype_dec {
        height: 74px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        padding:0 10px;
      }
  // 
  canvas,
  jmnodes {
    left: 0px;
  }
  .twodiv {
    width: 1200px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px #e8edf3;
    border-radius: 6px;
    margin-top: 15px;
    display: flex;
    margin-bottom: 30px;
    .twov_tabs {
      width: 320px;
      background: #f6f7fa;
      .actabs_i {
        background: #ffffff !important;
      }
      .tabs_i {
        width: 320px !important;
        height: 100px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .tabs_i_left {
          padding-top: 17px;
          position: relative;
          height: 100px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .gongguimg{
            width: 60px;
            height: 60px;
            border-radius: 30px;
            background: linear-gradient(90deg, rgb(15, 162, 255), rgb(23, 189, 255));
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .gongguimg img{
            max-width: 100%;
          }
          .tabsi_left_inx {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #308dff;
            span {
              font-size: 18px;
              font-weight: bold;
            }
          }
          .tabsi_left_namae{
            position: absolute;
            bottom: 10px;
            margin-top: 4px;
            width: 250px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
          }
        }
        .gonggus{
          .gonggus_left{
            width: 190px;
            height: 50px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .gonggus_left_name{
              font-size: 16px;
              color: #3d3e40;
            }
            .gonggus_left_dec{
              font-size: 14px;
              color: #6c7380;
            }
          }
        }
        .tabs_i_right {
          display: flex;
          align-items: center;
          .tabsi_right_pro {
            height: 66px;
            margin-right: 16px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #308dff;
            span {
              font-size: 18px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  .stage_test{
    width: 880px;
    min-height: 500px;
    padding: 30px;
    .stage_cent{
      width: 790px;
      background-color: #f8f9fb;
      padding: 30px;
      margin: 20px auto 0 auto;
      box-sizing: border-box;
      .stage_name{
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 25px;
        color: #1f2021;
      }
      .stageinfo{
        display: flex;
        align-items: center;
        margin-top: 10px;
        div{
          font-size: 14px;
          line-height: 1;
          color: #6c7280;
        }
        div:nth-child(2){
          padding: 0 20px;
          margin: 0 20px;
          border-left: 1px solid #dee1ea;
          border-right: 1px solid #dee1ea;
        }
      }
      .stagebtns{
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .stagebtns_left{
          display: flex;
          align-items: center;
          .stagebtns_left_i:first-child{
            padding-right: 20px;
            margin-right: 20px;
            border-right: 1px solid #dee1ea;
          }
          .stagebtns_left_i{
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #6c7380;
            span{
              font-size: 18px;
              font-family: DIN;
              font-weight: 500;
              line-height: 22px;
              color: #3d3e40;
            }
            div{
              width: 45px;
              height: 18px;
              font-size: 11px;
              text-align: center;
              line-height: 18px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #5694f7;
              background: #5694f71f;
              border-radius: 2px;
              margin-left: 8px;
            }
          }
        }
        .stagebtns_right{
          display: flex;
          align-items: center;
          div{
            cursor: pointer;
            width: 116px;
            height: 39px;
            font-size: 14px;
            text-align: center;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 34px;
            color: #5694f7;
            border: 1px solid #5694f7;
            border-radius: 20px;
          }
          div:last-child{
            margin-left: 20px;
            color: #ffffff;
            background: linear-gradient(316deg, #5694f7 0%, #5694f7 100%);
          }
        }
      }
    }
    .tagging-tips-text{
      width: 700px;
      margin: 20px auto;
      background: #fef9f1;
      padding: 9px 12px;
      border-radius: 4px;
      border: 1px solid #ffc77d;
      font-size: 12px;
      color: #ff9f21;
    }
    .study-report-list-item{
      cursor: pointer;
      width: 700px;
      margin: 0 auto 12px auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 30px;
      height: 59px;
      background: #f8f9fb;
      border-radius: 4px;
      div:first-child{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 34px;
        color: #3d3e40;
      }
      div:last-child{
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span{
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 34px;
          color: #3d3e40;
        }
      }
    }
    .stage_tab{
      position: relative;
      width: 790px;
      margin: 70px auto 0 auto;
      border-bottom: 1px solid #dee2e6;
      div{
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 120px;
        height: 40px;
        border: 1px solid #dee2e6;
        border-bottom: 1px solid #ffffff;
        color: #3d3e40;
        background: #fff;
        border-bottom: none;
        text-align: center;
        line-height: 40px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
      }
    }
  }
  .gonggcont{
    width: 880px;
    min-height: 500px;
    background-image: url('../../assets/images/oneback.png');
    background-size: 100% 100%;
    padding: 30px;
    .examine_i{
      width: 810px;
      margin: 0 auto 20px auto;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .examine_btn{
        width: 80px;
        height: 30px;
        background: #F6FAFF;
        border-radius: 15px;
        border: 1px solid #308DFF;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #308DFF;
        text-align: center;
        line-height: 30px;
      }
      .examine_left{
        display: flex;
        align-items: center;
        .examine_img{
          width: 60px;
          height: 60px;
          background: #F8F8F8;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 22px;
          img{
            width: 24px;
            height: 24px;
          }
        }
        .examine_dec{
          height: 56px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .examine_name{
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 14px;
            color: #333333;
          }
          .examine_box{
              display: flex;
              align-items: center;
              div{
                height: 24px;
                padding: 0 10px;
                background: #F6FAFF;
                border-radius: 2px;
                text-align: center;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                color: #308DFF;
              }
          }
        }
      }
    }
    .gonggcont_prass{
      width: 810px;
      padding: 47px 40px 0 60px;
      display: flex;
      justify-content: space-between;
      .prass_left{
        .proleft_texts{
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px 0;
          border-bottom: 1px dashed #E0E2EB;
          div:first-child{
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
          }
          div:last-child{
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            font-weight: bold;
            color: #333333;
          }
        }
        .prass_left_prog{
          position: relative;
          width: 220px;
          height: 220px;
          background: #FFFFFF;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          .prostext{
            position: absolute;
            left: 0;
            top: 0;
            width: 220px;
            height: 220px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            div{
              font-family: DIN Next LT Pro;
              font-weight: bold;
              font-size: 38px;
              color: #308DFF;
            }
            span{
              font-family: Microsoft YaHei;
              font-weight: 400;
              font-size: 14px;
              color: #666666;
            }
          }
        }
      }
      .prass_right{
        width: 300px;
        margin-top: 20px;
        .right_nimg{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .dvnimg{
            margin-right: 50px;
            div{
              font-family: Microsoft YaHei;
              font-weight: bold;
              font-size: 20px;
              color: #393F47;
              margin-bottom: 10px;
            }
            span{
              font-family: Microsoft YaHei;
              font-weight: 400;
              font-size: 14px;
              color: #999999;
            }
          }
          img{
            width: 122px;
            height: 95px;
          }
        }
        .nimg_dec{
          width: 275px;
          margin-top: 56px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
        }
        .nimg_btn{
          margin-top: 50px;
          width: 200px;
          height: 50px;
          background: linear-gradient(90deg, #308DFF, #30B8FF);
          border-radius: 25px;
          text-align: center;
          line-height: 50px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          font-size: 16px;
          color: #FFFFFF;
        }
      }
    }
  }
  .two_content {
    width: 880px;
  }
  .question_set {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #393f47;
    height: 100px;
    line-height: 100px;
    padding-left: 40px;
  }
  .course_list {
    width: 812px;
    margin: 0 auto;
    .course_i {
      width: 810px;
      height: 110px;
      background: #ffffff;
      border: 1px solid #ebebeb;
      border-radius: 6px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .cour_info {
        width: 690px;
        padding: 0 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .cour_btn {
          width: 100px;
          height: 32px;
          background: #ffffff;
          border: 1px solid #ebebeb;
          border-radius: 16px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          text-align: center;
          line-height: 32px;
        }
        .cour_details {
          height: 60px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .cour_name {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #393f47;
          }
          .cour_kankj {
            display: flex;
            align-items: center;
            div {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
              // margin-right: 8px;
              span {
                font-size: 18px;
                color: #308dff;
                font-weight: bold;
              }
            }
          }
        }
      }
      .cour_img {
        width: 120px;
        height: 110px;
        background: linear-gradient(90deg, #0fa2ff, #17bdff);
        border-radius: 6px 0px 0px 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 30px;
          height: 30px;
        }
        div {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          margin-top: 10px;
        }
      }
    }
  }
  .tpelist_i:nth-child(even) {
    background-color: #ffffff;
  }
  .tpelist_i {
    width: 810px;
    height: 80px;
    margin: 0 auto;
    background: #fbfbfb;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    .tpelist_i_left {
      display: flex;
      align-items: center;
      .tpe_inx {
        font-size: 40px;
        font-family: DIN Next LT Pro;
        font-weight: bold;
        color: #308dff;
      }
      .tpe_type {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 66px;
        height: 24px;
        background: #308dff0a;
        border-radius: 3px;
        margin: 0 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #308dff;
        img {
          width: 15px;
          height: 15px;
          margin-right: 6px;
        }
      }
      .tpe_name {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #393f47;
      }
    }
    .tpelist_i_right {
      display: flex;
      align-items: center;
      .tpe_pro {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        span {
          font-size: 18px;
          font-family: DIN Next LT Pro;
          font-weight: bold;
          color: #308dff;
        }
      }
      .tpe_btn {
        margin-left: 20px;
        width: 100px;
        height: 32px;
        background: #308dff;
        border-radius: 16px;
        line-height: 30px;
        text-align: center;
        color: #ffffff;
      }
    }
  }
  .cour_progress {
    position: relative;
    width: 100px;
    height: 10px;
    margin-left: 55px;
    margin-right: 15px;
    background: #d6e8ff;
    border-radius: 2px;
    .courpro_lg {
      position: absolute;
      height: 10px;
      z-index: 90;
      width: 50%;
      background: #308dff;
      top: 0;
      left: 0;
      border-radius: 2px;
    }
    // .cour_gard {
    //   position: absolute;
    //   z-index: 99;
    //   width: 100%;
    //   top: 0;
    //   left: 0;
    //   display: flex;
    //   align-items: center;
    //   div {
    //     width: 20px;
    //     height: 10px;
    //     background: #e4050500;
    //     border-right: 4px solid #ffffff;
    //     margin: 0;
    //   }
    // }
  }
  .condition_i:nth-child(even) {
    background: #ffffff;
  }
  .condition_i {
    width: 810px;
    height: 50px;
    background: #fcfcfc;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto 20px auto;
    .condition_i_left {
      display: flex;
      align-items: center;
      .con_inx {
        width: 40px;
        height: 24px;
        background: #F6F6F6;
        border-radius: 12px;
        text-align: center;
        line-height: 24px;
        font-size: 18px;
        font-family: DIN Next LT Pro;
        font-weight: bold;
        color: #999999;
      }
      .con_name {
        margin-left: 20px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
    }
    .condition_i_right {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      img {
        width: 24px;
        height: 24px;
        margin-left: 20px;
      }
    }
  }
  .minddiv{
      margin-top: -100px;
      position: relative;
      overflow: hidden;
      margin-bottom: 50px;
      .clickd{
        position: absolute;
        z-index: 999;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 80px;
        border-radius: 3px;
        text-align: center;
        line-height: 80px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #308DFF;
        background: linear-gradient(to bottom, rgba(255, 0, 0, 0), #ECECEC);
      }
  }
  .nothing{
    height: 500px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .swiper-button-next:after, .swiper-button-prev:after {
    font-size: 18px;
  }
  .codeart{
		position: fixed;
		z-index: 99;
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		background: #00000078;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.codeart-cen{
			position: relative;
			background: #ffffff;
			padding: 20px;
			border-radius: 10px;
			.fic-icon{
				position: absolute;
				z-index: 999;
				right: -20px;
				top: -20px;
				font-size: 30px;
				color: #ffffff;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background: #736e6e;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		.code{
			width: 450rpx;
			border-radius: 10rpx;
		}
	}
  </style>
  