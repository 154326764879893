<template>
  <div class="wrapper">
    <auserInfo style="" v-if="$route.meta.userinfo !== false"></auserInfo>
    <div class="navbox" v-if="$route.meta.navbox !== false">
      <!-- <div style="width: 350px;" v-if="navType == ''"></div> -->
      <el-breadcrumb separator-class="el-icon-arrow-right" class="navlist">
        <i class="el-icon-map-location"></i>
        <div
          v-for="(item, index) in navList"
          :key="index"
          @click="
            navClick(
              item.tabbarChildCurrent,
              item.tabbarCurrent,
              item.studyCurrent
            )
          "
          :class="index < navList.length - 1 ? 'el-icon-arrow-right' : ''"
        >
          <el-breadcrumb-item
            style="cursor: pointer"
            :to.replace="{ path: item.path }"
            >{{ item.name }}
          </el-breadcrumb-item>
        </div>
      </el-breadcrumb>
    </div>
    <div class="content" :style="$route.meta.content_style">
      <div class="tabbar" v-if="$route.meta.tabbar !== false">
        <!-- <div class="tabbar_list" v-for="(item,index) in tabBarList" @click="tabbarClick"> -->
        <div
          class="tabbar_list"
          v-for="(item, index) in tabBarList"
          :key="index"
          @click="tabbarClick(index, item.pages)"
        >
          <router-link class="boxss" :to="{ name: item.path }" tag="div">
            <div class="active" v-if="current == index"></div>
            <img :src="item.icon" />
            <span>{{ item.name }}</span>
          </router-link>
          <el-collapse-transition>
            <div class="slid_box" v-if="current == index">
              <div
                class="slid_select"
                :class="childCurrent == index2 ? 'child_active' : ''"
                v-for="(item2, index2) in item.arr"
                :key="index2"
                @click.stop="childTabClick(index2, item2.pages)"
              >
                {{ item2.name }}
              </div>
            </div>
          </el-collapse-transition>
        </div>
      </div>
      <div class="child_box">
        <router-view></router-view>
      </div>
    </div>
    <div class="footer_box">
      <div class="footer_content">
        <p>
          客服咨询：400-6655-517&nbsp;&nbsp;投诉建议：023-65010703&nbsp;&nbsp;公司邮箱：cqshenzhijiaoyu@163.com
          &nbsp;&nbsp;地址：重庆市渝中区大坪正街118号1、2幢3层
        </p>
        <p>
          Copyright©2020 莘知教育&nbsp;&nbsp;All Rights Reserved
          渝ICP备18013494号-1&nbsp;&nbsp;&nbsp;<img
            src="@/assets/icon/icon_110.png"
            alt=""
          />
          渝公网安备 50010302002746号
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/eventBus/eventBus";
export default {
  data() {
    return {
      current: 0,
      childCurrent: 0,
      navList: [],
      navType: "",
      tabBarList: [
        {
          name: "在线学习平台",
          icon: require("@/assets/icon/icon_sub_a.png"),
          pages: "Study",
          arr: [
            {
              name: "学习中心",
              pages: "Study",
            },
            {
              name: "学习记录",
              pages: "studyRecord",
            },
          ],
        },
        {
          name: "课后专属服务",
          icon: require("@/assets/icon/serve.png"),
          pages: "ServiceView",
          arr: [
            {
              name: "课后巩固课程",
              pages: "systemClass",
            },
            // , {
            // 	name: '知识点更新补充',
            // 	pages: 'knowledge',
            // }, // {
            // 	name: '课后题海解析',
            // 	pages: 'knowledge',
            // }, {
            // 	name: '历年考情分析',
            // 	pages: 'knowledge',
            // },
            {
              name: "时事政治",
              pages: "politics",
            },
            {
              name: "真题演练",
              pages: "drill",
            },
          ],
        },
        {
          name: "模考大赛",
          pages: "simulation",
          icon: require("@/assets/icon/mock.png"),
        },
        {
          name: "考试日历",
          icon: require("@/assets/icon/icon_sub_b.png"),
          pages: "Examination",
        },
        {
          name: "内部学员答疑",
          icon: require("@/assets/icon/icon_sub_c.png"),
          pages: "Doubt",
        },
        {
          name: "个人中心",
          icon: require("@/assets/icon/icon_sub_d.png"),
          pages: "User",
          arr: [
            {
              name: "个人信息",
              pages: "userInfo",
            },
            {
              name: "我的课程",
              pages: "myClass",
            },
            {
              name: "我的考试",
              pages: "myExam",
            },
            {
              name: "背题收藏",
              pages: "chapterList",
            },
            {
              name: "错题巩固",
              pages: "ztSolid",
            },
            {
              name: "反馈",
              pages: "mySet",
            },
          ],
        },
        {
          name: "在线直播",
          icon: require("@/assets/icon/lives.png"),
          pages: "liveList",
        },
      ],
    };
  },
  mounted() {
    EventBus.$on("navList", (res) => {
      console.log(res);
      this.navList = res;
      localStorage.setItem("navListss", JSON.stringify(this.navList));
      console.log("navList", this.navList);
      if (res[res.length - 1].type) {
        this.navType = res[res.length - 1].type;
      } else {
        this.navType = "";
      }
    });
  },
  created() {
    this.current = localStorage.getItem("tabbarCurrent");
    EventBus.$on("tabbarCurrent", (res) => {
      console.log(res);
      this.current = res;
    });
    this.childCurrent = localStorage.getItem("tabbarChildCurrent");
    if (JSON.stringify(this.navList) == "[]") {
      this.navList = JSON.parse(localStorage.getItem("navListss"));
      if (this.navList && this.navList[this.navList.length - 1].type) {
        this.navType = this.navList[this.navList.length - 1].type;
      } else {
        this.navType = "";
      }
    }
  },
  methods: {
    navClick(tabbarChildCurrent, tabbarCurrent, studyCurrent) {
      this.current = tabbarCurrent;
      this.childCurrent = tabbarChildCurrent;
      localStorage.setItem("tabbarCurrent", tabbarCurrent);
      localStorage.setItem("tabbarChildCurrent", tabbarChildCurrent);
      EventBus.$emit("studyCurrent", studyCurrent);
    },
    tabbarClick(index, pages) {
      let userinfo = JSON.parse(localStorage.getItem("userInfo"));
      if (index == 1) {
        console.log(userinfo);
        console.log("userinfo", userinfo);
        if (userinfo.type == 1) {
          this.childCurrent = 0;
          this.current = index;
          localStorage.setItem("tabbarCurrent", index);
          localStorage.setItem("tabbarChildCurrent", 0);
          this.$router.push("/" + pages);
          EventBus.$emit("studyCurrent", 0);
        } else {
          this.$message.error({
            message: "您还没有开通课后专属服务，如果需要开通请联系老师",
            showClose: true,
          });
        }
      } else if (index == 0) {
        if (userinfo.stage == 1) {
          this.childCurrent = 0;
          this.current = index;
          localStorage.setItem("tabbarCurrent", index);
          localStorage.setItem("tabbarChildCurrent", 0);
          this.$router.push("/" + pages);
          EventBus.$emit("studyCurrent", 0);
        } else {
          this.$message.error({
            message: "您还没有开通在线学习平台，如果需要开通请联系老师",
            showClose: true,
          });
        }
      } else {
        this.childCurrent = 0;
        this.current = index;
        localStorage.setItem("tabbarCurrent", index);
        localStorage.setItem("tabbarChildCurrent", 0);
        this.$router.push("/" + pages);
        EventBus.$emit("studyCurrent", 0);
      }
    },
    childTabClick(index, pages) {
      this.childCurrent = index;
      localStorage.setItem("tabbarChildCurrent", index);
      this.$router.push("/" + pages);
    },
  },
};
</script>
<style type="text/css" scoped lang="scss">
.el-icon-map-location {
  margin-right: 10px;
  font-size: 18px;
}

.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  font-weight: 400;
  color: #606266;
  cursor: text;
  cursor: pointer !important;
}

.el-icon-arrow-right {
  color: #47be5e !important;
}

.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  color: #5694f7 !important;
}

.el-breadcrumb__item:last-child .el-breadcrumb__inner:last-child {
}

.navlist div:last-child span {
  color: #5694f7 !important;
}
</style>
<style scoped lang="scss">
.navbox {
  width: 1200px;
  margin: 0 auto;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.navlist {
  width: 1500px;
  display: flex;
  align-items: center;
}

.navlist div {
  display: inline-block;
}

.footer_box {
  height: 120px;
  /* width: calc(100vw - 5px); */
  box-sizing: border-box;
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: center;
  // margin-top: 50px;
  width: 100vw;
  min-width: 1200px;
}

.footer_content {
  width: 1500px;
  height: 120px;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* padding: 30px 0 ; */
}

img {
  width: 20px;
  height: 20px;
}

.footer_content p {
  text-align: center;
  margin-top: 30px;
}

.wrapper {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.slid_box {
}

.slid_select {
  padding-left: 55px;
  font-size: 14px;
  line-height: 50px;
  height: 50px;
  cursor: pointer;
}

.slid_select:hover {
  color: #47be5e !important;
  background: #f3faf5;
}

.tabbar_list:hover span {
  color: #47be5e !important;
}

.content {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  width: 1200px;
  overflow: hidden;
  overflow-y: scroll;
  margin: auto;
  padding-top: 20px;

  min-height: 100vh;
}

.child_active {
  color: #47be5e !important;
  background: #f3faf5;
}

.active {
  width: 5px;
  height: 20px;
  background: #47be5e;
  position: absolute;
  left: 0;
  border-radius: 2.5px;
}

.tabbar {
  width: 350px;
  background: #fff;
  max-height: 700px;
  /* padding-left: 25px; */
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
}

.tabbar_list {
  min-height: 50px;
  width: 100%;
  cursor: pointer;
}

.boxss {
  padding-left: 25px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  position: relative;
}

.boxss span {
  font-size: 16px;
  color: #333333;
  padding-left: 10px;
}
</style>
