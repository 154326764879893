<template>
  <div class="warp">
    <div class="ask_question" v-if="askShow">
      <div class="ask_center">
        <div class="ask_header">
          <img src="../../assets/images/slices/back_bg.png" alt="" />
          <div class="bk_name">我要提问</div>
          <div class="bk_icon" @click="askShow = false">
            <i
              class="el-icon-circle-close"
              style="font-size: 20px; color: #6b8cf7"
            ></i>
          </div>
        </div>
        <div class="ask_sel">
          <el-select
          v-if="!ques_listId"
            style="width: 350px"
            v-model="cate_id"
            @change="cateChange"
            placeholder="选择考试类型"
          >
            <el-option
              v-for="item in cateList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
          v-if="!ques_listId"
            style="margin-left: 15px; width: 350px"
            v-model="vice_id"
            placeholder="选择科目"
          >
            <el-option
              v-for="item in kemList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <el-input
          class="taerp"
          type="textarea"
          :rows="10"
          placeholder="请输入您的提问"
          v-model="question"
        >
        </el-input>
        <div class="imgel">
          <div class="imgel_title">
            图片上传
            <div>最多可上传5张</div>
          </div>
          <div class="upload_img">
            <el-upload
              action="https://erpapi.sz517.com/study/public/qiniuImageUpload"
              list-type="picture-card"
              :on-success="handleAvatarSuccess"
              :headers="importHeaders"
              :limit="5"
            >
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{ file }">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                />
                <!-- <span class="el-upload-list__item-actions">
                    <span
                      v-if="!disabled"
                      class="el-upload-list__item-delete"
                      @click="handleRemove(file)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span> -->
              </div>
            </el-upload>
          </div>
        </div>
        <div class="sub_btns" @click="addAnswer">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCate, addAnswer } from "@/utils/erpapi.js";
export default {
  data() {
    return {
      importHeaders: {
        "verify-token": localStorage.getItem("UserToken"),
      },
      dialogImageUrl: [],
      askShow: false,
      question: "",
      cate_id: "",
      vice_id: "",
      cateList: [],
      kemList: [],
      ques_type: "",
      ques_listId: "",
      quesType: "",
    };
  },
  created() {},
  mounted() {
    if (this.$parent._props) {
        console.log(this.$parent)
      this.ques_listId = this.$parent._props.answer.id || "";
      this.quesType = this.$parent._props.anstype||""
      if (this.$parent._props.source == 1) {
        if (this.quesType == 2) {
          this.ques_type = 1;
        } else {
          this.ques_type = 3;
        }
      } else {
        if (this.quesType == 2) {
          this.ques_type = 2;
        } else {
          this.ques_type = 4;
        }
      }
    }

    this.getCate();
  },
  methods: {
    // 新增提问
    addAnswer() {
      console.log(this.dialogImageUrl);
      addAnswer({
        cate_id: this.cate_id,
        vice_id: this.vice_id,
        question: this.question,
        image: this.dialogImageUrl.join(","),
        ques_type: this.ques_type > 0 ? this.ques_type : "",
        ques_list_id: this.ques_listId || "",
        pid: 0,
      }).then((res) => {
        this.askShow = false;
        this.$message({
            message: '提交成功',
            type: 'success'
        });
        this.$emit("hwuRee", "");
      });
    },
    getCate() {
      getCate().then((res) => {
        this.cateList = res.data;
      });
    },
    cateChange(e) {
      getCate({ main_id: e }).then((res) => {
        this.kemList = res.data;
      });
    },
    //
    handleAvatarSuccess(file) {
      this.dialogImageUrl.push(file.data.src);
    },
    handleRemove(file) {
      console.log(file);
    },
  },
};
</script>

<style scoped="scoped" lang="scss">
/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
}
/deep/.el-upload--picture-card {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
.sub_btns {
  margin-left: 30px;
  margin-top: 40px;
  width: 80px;
  height: 36px;
  background: #6b8cf7;
  border-radius: 3px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.taerp {
  width: 1127px;
  margin: 20px 30px;
}
.imgel {
  margin-left: 30px;
  .imgel_title {
    display: flex;
    align-items: baseline;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    div {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-left: 20px;
    }
  }
  .upload_img {
    margin-top: 20px;
  }
}
.ask_question {
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #00000040;
  display: flex;
  align-items: center;
  justify-content: center;
  .ask_center {
    width: 1187px;
    height: 669px;
    background: #ffffff;
    border-radius: 10px;
    .ask_sel {
      margin-left: 30px;
      margin-top: -20px;
    }
    .ask_header {
      width: 1187px;
      height: 136px;
      position: relative;
      img {
        width: 1187px;
        height: 136px;
      }
      .bk_icon {
        position: absolute;
        top: 20px;
        right: 20px;
      }
      .bk_name {
        width: 50%;
        left: 25%;
        top: 40px;
        position: absolute;
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #6b8cf7;
        text-align: center;
      }
    }
  }
}
</style>