<template>
  <div class="container">
    <iframe
      ref="iframe"
      id="iframe"
      src="forum/列表.html"
      @load="loaded"
    ></iframe>
    <!-- 列表  详情-->
  </div>
</template>

<script>
import apiForum from "../../utils/apiForum.js";
let that;
export default {
  data() {
    return {
      times: "32",
      spList: 4,
    };
  },
  created() {
    that = this;

    window["api"] = () => {
      console.log(123);
    };
  },
  methods: {
    // 监听并接收子页面发送的消息
    loaded(e) {
      window.addEventListener(
        "message",
        function (event) {
          //返回首页
          if (event.data.data == "toHome") {
            return that.$router.push("/index");
          }
          //丢失window对象
          if (event.data.data == "refresh") {
            return that.setWindow();
          }
        },
        false
      );
    },
    //vue与iframe通信
    setWindow() {
      that.$refs.iframe.contentWindow.window.apiForum = apiForum; //api 接口
      that.$refs.iframe.contentWindow.window.$message = that.$message; //toast
      that.$refs.iframe.contentWindow.window.$router = that.$router; //路由
    },
  },
  mounted() {
    that.setWindow();
  },
};
</script>

<style>
::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 5px;
}
iframe {
  width: 100vw;
  height: 100vh;
}
</style>
