<template>
	<div class="answer" ref="answers">
		<div style="display:flex;justify-content: flex-start;">
			<!--序号-->
			<div class="stem_box">
				<template v-if="answer.genreType == '6'">
					<!--题干-->
					<div class="stem">
						<span class="ti_tpye">{{answer.genre_text}}</span>
						<span v-for="(item,index) in answer.titles" :key="index"  class="latexDiv">
							{{item}}
							<span v-if="index+1 !==answer.titles.length">（
							</span>
							<input class="inputs" :class="answer.wd === false ? 'errinputs':''"
								v-model="inputModle[index]" :disabled="!disbade"
								:style="{width:text(inputModle[index])}" v-if="index+1 !== answer.titles.length" />
							<span v-if="index+1 !==answer.titles.length">）</span>
						</span>
					</div>
				</template>
				<template v-else>
					<div class="stem"><span class="ti_tpye">{{answer.genre_text}}</span><span class="tiyhtm" v-html="answer.title"></span></div>
				</template>
				<div class="selects">
					<template v-if="answer.genreType == '1' || answer.genreType == '3'">
						<img :src="answer.icon" style="max-width: 100%;height: auto;">
						<!--选项-->
						<div class="select_list" :class="zqcurrent == index ? 'correct':''"
							v-for="(item,index) in answer.option_name" :key="index" @click="selectClcik(index)">
							<div class="tag">{{index}}</div>
							<div class="cont" v-if="item.indexOf('.jp') !== -1||item.indexOf('.png') !== -1||item.indexOf('.gif') !== -1">
								<img :src="item" style="max-height: 56px;">
							</div>
							<div class="cont latexDiv" v-else>{{item}}</div>
						</div>
					</template>
					<template v-if="answer.genreType == '2'">
						<img :src="answer.icon" style="max-width: 100%;height: auto;">
						<div class="select_list" v-for="(item,index) in answer.option_name"
							:class="selectArr.includes(index)?'correct':''" :key="index" @click="selectClcik(index)">
							<div class="tag">{{index}}</div>
							<div class="cont" v-if="item.indexOf('.jp') !== -1||item.indexOf('.png') !== -1||item.indexOf('.gif') !== -1">
								<img :src="item" style="max-height: 56px;">
							</div>
							<div class="cont latexDiv" v-else>{{item}}</div>
						</div>
					</template>
					<template v-if="answer.genreType == '4' || answer.genreType == '8' || answer.genreType == '7'">
						<img :src="answer.icon" style="max-width: 100%;height: auto;">
						<div class="select_list" :class="zqcurrent == 1 ? 'correct':''" @click="selectClcik(1)">
							<!-- {{item}} -->
							<div class="tag">A</div>
							<div class="cont">会</div>
						</div>
						<div class="select_list" :class="cwcurrent == 2 ? 'error':''" @click="selectClcik(2)">
							<!-- {{item}} -->
							<div class="tag">B</div>
							<div class="cont">不会</div>
						</div>
					</template>
				</div>
				<div class="btns">
					<div class="answers" v-if="slideShow">
					</div>
					<div v-else></div>
					<div class="buttons">
						<div class="btn" style="background:#47BE5E " @click="manySelect"
							v-if="answer.genreType == '2' && !answer.correct">提交</div>
						<div class="btn" style="background:#47BE5E " @click="completionBtn"
							v-if="answer.genreType == '6' && disbade == true">
							提交</div>
						<div class="btn" :style="index == 0?'background:#CCCCCC':''" @click="nextAnswer(false)">上一题
						</div>
						<div class="btn" :style="index + 1 == length?'background:#CCCCCC':''" @click="nextAnswer(true)">
							下一题</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import MathJax from '../../utils/math.js'
	export default {
		props: {
			answer: {
				type: Object,
				default: {}
			},
			index: {
				type: Number,
				default: 0,
			},
			current: {
				type: Number,
				default: 0,
			},
			length: {
				type: Number,
				default: 0,
			}
		},
		data() {
			return {
				zqcurrent: null,
				cwcurrent: null,
				slideShow: false,
				shuzu: ['el-icon-star-dr el-icon-star-on', 'el-icon-star-dr el-icon-star-on',
					'el-icon-star-dr el-icon-star-on'
				],
				indexs: 0,
				selectArr: [], //多选题储存选中选项的索引值
				disbade: true,
				cwArr: [],
				inputModle: [],
				times: '00:00:00',
				timess: {
					s: '00',
					m: '00',
					h: '00',
					Hour: 0,
					minute: 0,
					second: 0,
				},
				ansTimes: '',
			}
		},
		created() {
			if (this.answer.correct === 'no') {

				this.disbade = false
			}

			if (this.answer.user_res) {
				this.slideShow = true
				if (this.answer.genreType == '2') { //多选
					this.selectArr = this.answer.user_res.split(',')
				} else if (this.answer.genreType == '1' || this.answer.genreType == '3') { // 判断和单选
					if (this.answer.answer !== this.answer.user_res) {
						if (!this.answer.user_res) {
							for (let i in this.answer.option_name) {
								if (i == this.answer.answer) {
									this.cwcurrent = i
								}
							}
						} else {
							for (let i in this.answer.option_name) {
								if (i == this.answer.user_res) {
									this.cwcurrent = i
								}
							}
							for (let i in this.answer.option_name) {
								if (i == this.answer.answer) {
									this.zqcurrent = i
								}
							}
						}
					} else {
						for (let i in this.answer.option_name) {
							if (i == this.answer.answer) {
								this.zqcurrent = i
							}
						}
					}
				} else if (this.answer.genreType == '6') { //填空
					if (this.answer.user_res !== '不会') {
						this.inputModle = this.answer.user_res.split(',')
					} else {
						this.inputModle = this.answer.answer.split(',')
					}
				} else if (this.answer.genreType == '4' || this.answer.genreType == '8') { //简答
					if (this.answer.user_res == 'A') {
						this.zqcurrent = 1
					} else if (this.answer.user_res == 'B') {
						this.zqcurrent = 2
					}
				}
			}

			if (this.current == this.index) {
				console.log('asdasd ')
				this.ansTimes = setInterval(() => {
					this.timess.s = ++this.timess.second;
					if (parseInt(this.timess.s) < 10) {
						this.timess.s = '0' + this.timess.second
					}
					if (parseInt(this.timess.s) == 60) {
						this.timess.m = ++this.timess.minute
						this.timess.second = 0
						this.timess.s = '0' + this.timess.second
					}
					if (parseInt(this.timess.m) < 10) {
						this.timess.m = '0' + this.timess.minute
					}
					if (parseInt(this.timess.m) == 60) {
						this.timess.h = ++this.timess.Hour
						this.timess.minute = 0
						this.timess.m = '0' + this.timess.minute
						if (parseInt(this.timess.h) < 10) {
							this.timess.h = '0' + this.timess.Hour
						}
					}
					this.times = this.timess.h + ':' + this.timess.m + ':' + this.timess.s
				}, 1000)
			}
		},
		watch: {
			current(val, old) {
				if (val == this.index && !this.answer.user_res) {

					this.ansTimes = setInterval(() => {
						this.timess.s = ++this.timess.second;
						if (parseInt(this.timess.s) < 10) {
							this.timess.s = '0' + this.timess.second
						}
						if (parseInt(this.timess.s) == 60) {
							this.timess.m = ++this.timess.minute
							this.timess.second = 0
							this.timess.s = '0' + this.timess.second
						}
						if (parseInt(this.timess.m) < 10) {
							this.timess.m = '0' + this.timess.minute
						}
						if (parseInt(this.timess.m) == 60) {
							this.timess.h = ++this.timess.Hour
							this.timess.minute = 0
							this.timess.m = '0' + this.timess.minute
							if (parseInt(this.timess.h) < 10) {
								this.timess.h = '0' + this.timess.Hour
							}
						}
						this.times = this.timess.h + ':' + this.timess.m + ':' + this.timess.s
					}, 1000)
				}
			}
		},
		computed: {
			text() {
				return function(value) {
					if (value) {
						return String(value).length * 20 + 20 + 'px'
					}

				}
			}
		},
		methods: {
			selectClcik(i) {

				// 单选题答案判断
				if (this.answer.genreType == '1' || this.answer.genreType == '3') {
					this.slideShow = true
					clearInterval(this.ansTimes)
					if (!this.answer.user_res) {
						console.log(i)
						console.log(this.answer.answer)
						if (i == this.answer.answer) {
							this.zqcurrent = i
							console.log(i)
							if ((this.index + 1) < this.length) {
								this.$emit('wd', {
									correct: 'yes',
									user_res: i,
									index: this.index,
									next: true,
									ansTimer: this.times
								});
							} else {
								this.$emit('wd', {
									correct: 'yes',
									user_res: i,
									index: this.index,
									next: true,
									ansTimer: this.times
								});
							}
						} else {
							setTimeout(() => {
								this.$emit('swiperHeight', {
									index: this.index,
									height: this.$refs.answers.offsetHeight
								})
							}, 500)
							this.$emit('wd', {
								correct: 'no',
								user_res: i,
								index: this.index,
								next: true,
								ansTimer: this.times
							});
							this.cwcurrent = i
							this.slideShow = true
							for (let j in this.answer.select) {
								if (this.answer.select[j].title == this.answer.zq) {
									this.zqcurrent = j
								}
							}

						}
					}
				} else if (this.answer.genreType == '2') {
					if (this.disbade == true) {
						if (this.selectArr.includes(i)) {
							console.log(1)
							//includes()方法判断是否包含某一元素,返回true或false表示是否包含元素，对NaN一样有效
							//filter()方法用于把Array的某些元素过滤掉，filter()把传入的函数依次作用于每个元素，然后根据返回值是true还是false决定保留还是丢弃该元素：生成新的数组
							this.selectArr = this.selectArr.filter(function(ele) {
								return ele != i;
							});
						} else {
							this.selectArr.push(i);
						}
					}
				} else if (this.answer.genreType == '4' || this.answer.genreType == '8' || this.answer.genreType == '7') {
					this.slideShow = true
					clearInterval(this.ansTimes)
					if (this.disbade == true) {
						if (i == 1) {
							this.zqcurrent = 1
							this.$emit('wd', {
								correct: 'yes',
								user_res: 'A',
								index: this.index,
								next: true,
								ansTimer: this.times
							});
						} else {
							setTimeout(() => {
								this.$emit('swiperHeight', {
									index: this.index,
									height: this.$refs.answers.offsetHeight
								})
							}, 500)
							this.cwcurrent = 2
							this.slideShow = true
							this.$emit('wd', {
								correct: 'no',
								user_res: 'B',
								index: this.index,
								next: true,
								ansTimer: this.times
							});
						}
						this.disbade = false
					}

				}
			},
			nextAnswer(e) { //切换题目按钮h)

				if (!this.answer.user_res && (this.answer.genreType == '2' || this.answer.genreType == '6') && JSON
					.stringify(this.selectArr) !== '[]') {
					this.$confirm('你忘记提交答案了哟！！！！', '提示', {
						confirmButtonText: '继续换题',
						cancelButtonText: '提交本题',
						type: 'warning'
					}).then(() => {
						this.indexs = this.index + 1
						clearInterval(this.ansTimes)
						if (!this.answer.user_res) {
							this.times = '00:00:00'
							this.timess = {
								s: '00',
								m: '00',
								h: '00',
								Hour: 0,
								minute: 0,
								second: 0,
							}
						}
						if (this.index == 0 && e == false) {
							console.log(e)
						} else if (this.indexs == this.length && e == true) {
							console.log(e)
						} else {
							console.log(e)
							this.$emit('tab', {
								next: e,
								index: this.index
							})
						}
					}).catch(() => {
						this.manySelect()
					});
					return
				}
				this.indexs = this.index + 1
				clearInterval(this.ansTimes)
				if (!this.answer.user_res) {
					this.times = '00:00:00'
					this.timess = {
						s: '00',
						m: '00',
						h: '00',
						Hour: 0,
						minute: 0,
						second: 0,
					}
				}
				if (this.index == 0 && e == false) {

				} else if (this.indexs == this.length && e == true) {

				} else {
					this.$emit('tab', {
						next: e,
						index: this.index
					})
				}
			},
			manySelect() {
				this.slideShow = true
				clearInterval(this.ansTimes)
				let answerArr = '' //储存多选题的答案
				let num
				// console.log(this.selectArr)
				if (this.selectArr.length > 0) {
					this.disbade = false
					num = this.selectArr.sort()
					answerArr = num.join(',')
					if (answerArr == this.answer.answer) {
						this.$emit('wd', {
							correct: 'yes',
							user_res: answerArr,
							index: this.index,
							next: true,
							ansTimer: this.times
						})
					} else {
						this.slideShow = true
						setTimeout(() => {
							this.$emit('swiperHeight', {
								index: this.index,
								height: this.$refs.answers.offsetHeight
							})
						}, 500)
						this.$emit('wd', {
							correct: 'no',
							user_res: answerArr,
							index: this.index,
							next: true,
							types: '多选题',
							ansTimer: this.times
						})

					}
				} else {
					this.$message({
						message: '至少选择一个选项才能提交',
						type: 'warning'
					});
				}
			},
			completionBtn() {
				this.slideShow = true
				clearInterval(this.ansTimes)
				let ans = this.answer.answer
				let model = this.inputModle.join(',')
				this.disbade = false
				if (ans == model) {
					this.$emit('wd', {
						correct: 'yes',
						user_res: model,
						index: this.index,
						next: true,
						ansTimer: this.times
					})
				} else {
					this.slideShow = true
					setTimeout(() => {
						this.$emit('swiperHeight', {
							index: this.index,
							height: this.$refs.answers.offsetHeight
						})
					}, 500)
					this.$emit('wd', {
						correct: 'no',
						user_res: model,
						index: this.index,
						next: true,
						ansTimer: this.times
					})
				}
			},
			getArrDifference(arr1, arr2) {
				arr1 = arr1.split('')
				arr2 = arr2.split('')
				return arr1.concat(arr2).filter(function(v, i, arr) {
					return arr.indexOf(v) === arr.lastIndexOf(v);
				});
			}
		}
	}
</script>
<style>
	.el-rate .el-rate__item .el-icon-star-dr {
		color: #FF684B !important;
	}
</style>
<style scoped>
	.answer {
		margin-top: 28px;

		/* background-size:1; */
		/* background-position: 300px 150px; */
	}

	.ti_tpye {
	display: inline;
	padding: 2px 10px;
	margin-right: 10px;
	background: #EEF5FF;
	border-radius: 5px !important;
	font-size: 14px !important;
	color: #5694F7 !important;
}

.stem {
	position: relative;
	font-size: 20px;
	color: #333;
	line-height: 1.5;
}
.tiyhtm{
	width: 80%;
}
.stem span {
	font-size: 20px;
	color: #333;
}

	.stem .inputs {
		border: none;
		/* border-bottom: 1px solid #505DDF; */
		padding: 010px;
		font-size: 20px;
		color: #505DDF;
		width: 48px;
		max-width: 100% !important;

		text-align: center;
		background: #fff;
	}

	.errinputs {
		/* border-bottom: 1px solid #FA4E53 !important; */
		color: #FF684B !important;
	}

	.stem_box {
		flex: 1;
	}

	/* 滚动条样式结束 */
	/* 选项 */
	.selects {
		margin-top: 32px;
	}

	.select_list {
		/* margin-top: 35px		display: flex;
		/* min-height: 56px; */
		padding: 20px 0;
		align-items: center;
		display: flex;
		/* margin-top: 20px; */
		justify-content: flex-start;
	}

	.select_list:hover {
		background: #f6f6f6;
		opacity: .5;
	}

	.select_list .tag {
		width: 26px;
		height: 26px;
		border: 1px solid #666666;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		color: #333;
		margin-right: 20px;
	}

	.select_list .cont {
		font-size: 20px;
		color: #333;
		flex: 1;
	}

	.correct .tag {
		background: #47BE5E;
		border-color: #47BE5E;
		color: #fff
	}

	.correct .cont {
		color: #47BE5E
	}

	.error .tag {
		background: #47BE5E;
		border-color: #47BE5E;
		color: #fff
	}

	.error .cont {
		color: #47BE5E
	}

	/* 答案和切换题目 */
	.btns {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 70px;
	}

	.answers {
		/* display: flex;
		align-items: center;
		justify-content: flex-start; */
	}

	.answers .yes {
		display: flex;
		align-items: center;
		justify-content: flex-start;

	}

	.yes .yuan {
		width: 8px;
		height: 8px;
		background: #47BE5E;
		border-radius: 50%;
		margin-right: 6px;
	}

	.yes span {
		font-size: 18px;
		color: #222;
	}

	.answer_cards .blue {
		color: #47BE5E;
	}

	.user_res {
		margin-top: 10px;
	}

	.user_res,
	.user_res span {
		font-size: 18px;
	}

	.buttons {
		display: flex;
		justify-content: flex-start;
		align-content: center;
	}

	.buttons .btn {
		width: 73px;
		height: 30px;
		border-radius: 10px;
		font-size: 16px;
		color: #Fff;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 20px;
		background: #47BE5E;
	}

	/* 下拉显示的解析部分 */
	.slid_box {
		margin-top: 20px;
	}

	.slid {
		background: #F3F4FF;
		padding: 17px 20px;
		border-radius: 10px;
	}

	.slid_title {
		font-size: 18px;
		color: #222;
		font-weight: bold;
		display: flex;
		align-items: center;
	}

	.slid_title img {
		width: 18px;
		height: 20px;
		margin-right: 10px;
	}

	.slid_video {
		margin-top: 25px;
		margin-bottom: 35px
	}

	.slid_video img {
		width: 2510px;
		height: 205px;
	}

	.analysis {
		margin-top: 20px;
		font-size: 16px;
		color: #333;
	}

	.difficulty {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-top: 19px;
	}

	.examination {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-top: 16px;
	}

	.examination_box {
		background: #D4D8FF;
		border-radius: 4px;
		padding: 5px 14px;
		margin-left: 10px;
		font-size: 14px;
	}

	.from {
		font-size: 14px;
		margin-top: 16px;
	}

	.answer_cards {
		/* width: 854px; */
		height: 100px;
		background: #FFFFFF;
		border: 1px solid #EBEBEB;
		box-shadow: 0px 0px 10px 0px rgba(200, 200, 200, 0.5);
		border-radius: 10px;
		margin-top: 45px;
		display: flex;
		align-items: center;
		justify-content: space-around;


	}
</style>
