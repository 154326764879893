<template>
	<div>
		<div class="table_box">
			<div class="table_title">
				<div v-for="(item,index) in tabTitle" :key="index">{{item}}</div>
			</div>
			<div class="table_content" :class="JSON.stringify(typeList) !== '[]'?'':'nothing'">
				<img src="@/assets/images/nothing.png" v-if="JSON.stringify(typeList) == '[]'">
				<div class="table_list" v-for="(item,index) in typeList" :key="index" v-else>
					<div class="list_ttile"><span
							style="margin-right: 10px;">{{index + 1 + pages*limit -limit }}</span>{{item.ques_name}}
					</div>
					<div>{{'真题练习'}}</div>
					<div>{{item.wgg}}/{{item.exam_num}}</div>
					<div @click="listJump(item.sub_id,item.ques_name,item.type,item.id)"><span>立即巩固</span></div>
				</div>
			</div>
		</div>
		<turnPages class="pages_box" :count="count" :pages="pages" @fanye="fanyeClick" @inputPage="inputPage">
		</turnPages>
	</div>
</template>

<script>
	import navBar from '@/components/navBar/navBar'
	import tableList from '@/components/tableList/tableList'
	import turnPages from '@/components/turnPages/turnPages'
	import {
		EventBus
	} from '@/eventBus/eventBus'
	import {
		getLinianWrong
	} from '@/utils/api.js'
	export default {
		components: {
			tableList,
			turnPages,
			navBar
		},
		data() {
			return {
				tabTitle: ['课程名称', '课程类型', '巩固进度', '操作'],
				typeList: [],
				pages: 1,
				count: 1,
				limit: 9,
				type: 1, //基础：type=1 提升：type=2 冲刺：type=3
				name: '',
				navList: [{
						path: '/classList',
						name: '首页',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 0
					},
					{
						path: '/userInfo',
						name: '个人中心',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 5
					},
					{
						path: '/ztSolid',
						name: '错题巩固',
						studyCurrent: 0,
						tabbarChildCurrent: 4,
						tabbarCurrent: 5
					}
				]
			}
		},
		created() {
			EventBus.$emit('navList', this.navList)
			this.getData()
		},
		methods: {
			async getData() {
				let promise = {
					limit: this.limit,
					page: this.pages,
				}
				await getLinianWrong(promise).then(res => {
					console.log(res)
					this.typeList = res.data.list
					this.count = Math.ceil(res.data.count / this.limit)
				})
			},
			listJump(sub_id, title, type, id) {
				// this.$router.push('/studyList?id='+id+'&titles='+title)
				let arrs = {
					navList: this.navList,
					title: title,
					id: id,
					sub_id: sub_id,
					type: 'ztgg'
				}
				this.$router.push('/Answer?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(arrs))));
			},
			fanyeClick(e) {
				if (e == 'next') {
					this.pages++
					console.log(this.pages)
					if (this.pages <= this.count) {
						this.getData()
					} else {
						this.pages = this.count
					}
				} else {
					this.pages--
					if (this.pages >= 1) {
						this.getData()
					} else {
						this.pages = 1
					}
				}
			},
			inputPage(e) {
				this.pages = e * 1
				this.getData()
			}
		}

	}
</script>
<style scoped>
	.content {
		width: 1132px;
		background: #fff;
		height: 780px;
		padding: 20px;
		position: relative;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
	}

	.aatitle {
		margin-bottom: 15px;
		padding-left: 15px;
		border-left: 5px solid #47BE5E;
		font-size: 18px;
		font-weight: bold;
	}

	.title {
		font-size: 14px;
		color: #7B92CB;
	}

	/* .study_list{

} */
	.pages_box {
		position: absolute;
		bottom: 20px;
		right: 20px;
	}

	.tab_top {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.tab_top div {
		width: 125px;
		height: 35px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
	}

	.tab_top .active {
		background: #47BE5E;
		border-radius: 18px;
		color: #fff;
	}

	.table_box {
		margin-top: 30px;
	}

	.table_title,
	.table_list {
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.table_list {
		/* padding: 10px 0 !important; */
		height: 65px;
		padding-left: 15px;
	}

	.table_list:hover {
		background: rgba(71, 190, 94, .3);
		border-radius: 5px;
		/* color: #FFF; */
	}

	.table_list div span {
		background: #5694F7;
		color: #fff;
		display: inline;
		padding: 5px 10px;
		border-radius: 3px;
		cursor: pointer
	}

	.table_title {
		background: #F7F7F7;
	}

	.table_title div {
		width: 17%;
		display: flex;
		font-size: 16px;
		font-weight: bold;
		color: #333;
		align-items: center;
		justify-content: center;
	}

	.table_title div:first-child,
	.table_list div:first-child {
		width: 49%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.table_list div {
		width: 17%;
		display: flex;
		color: #333;
		align-items: center;
		justify-content: center;
	}

	.list_ttile {
		font-weight: bold;
		font-size: 15px;
	}

	.table_list div:first-child {
		justify-content: flex-start;
	}
</style>
