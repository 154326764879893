<template>
	<div>
		<div class="page_title">{{ quesName }}</div>
	<div class="container">
		<div class="content">
			<!-- <nav-bar :navList="navList"></nav-bar> -->
			<div class="wraps">
				<div class="answer_title">
					<span>{{Number(current)+1}}</span>&nbsp;/&nbsp;{{answer.length}}题
				</div>
			</div>
			<!-- <el-carousel :interval="5000" :autoplay="false" :initial-index="current" :loop="false" arrow="never"
				class="swiper_box" indicator-position="none" ref="cardShow"
				:style="{height:(answer[current].swiperHeight+100) + 'px'}">
				<el-carousel-item v-for="(item,index) in answer" :key="index" class="swiper_box"
					:style="{height:answer[current].swiperHeight + 'px'}">
					<aAnalysis :answer="item" :index="index" :length="answer.length" @tab="nextAnswer"></aAnalysis>
				</el-carousel-item>
			</el-carousel> -->
			<div v-for="(item,index) in answer" :key="index" class="swiper_box" v-if="index == current">
				<aAnalysis :anstype="ssType" :source="subjectType" :answer="item" :index="index" :length="answer.length" @tab="nextAnswer"></aAnalysis>
			</div>
		</div>
		<div class="card" style="">
			<div class="answer_card">
				<div class="time_box_title">答题卡</div>
				<div class="answer_card_content">
					<div class="answer_card_s" @click="cardClick(index)"
						:class="answer[index].is_right ==1?'yesgreen':answer[index].is_right ==2?'yesred':''"
						v-for="(item,index) in answer" :key="index" :style="{background:Number(current)==index?'#5694F7 !important':'',color:Number(current)==index?'#fff !important':'',border:Number(current)==index?'none !important':''}">
						{{index+1}}
					</div>
					<div class="card_jia" v-for="index in 5 " :key="'aa'+ index"></div>
				</div>
			</div>
		</div>
	</div>
	</div>
	
</template>

<script>
	import aAnalysis from '@/components/answer/analysis'
	import navBar from '@/components/navBar/navBar'
	import {
		EventBus
	} from '../../../eventBus/eventBus'
	export default {
		components: {
			aAnalysis,
			navBar
		},
		data() {
			return {
				current: 0,
				navList: [],
				answer: [],
				maskShow: false,
				quesName:'',
				content_id:"",
				checkpoint_id:"",
				routeInfo:{},
				subjectType:""
			}
		},
		created() {
			this.ssType=this.$route.query.type
			this.subjectType=this.$route.query.subjectType
			this.answer = JSON.parse(localStorage.getItem('analysis'))
			console.log(this.$route.query)
			this.current=this.$route.query.kindex||0
			this.content_id=this.$route.query.content_id
			this.checkpoint_id=this.$route.query.checkpoint_id
			this.routeInfo=this.$route.query
			this.quesName=localStorage.getItem('quesName')||''
			this.parameter(this.$route.query.parameter)
			EventBus.$emit('navList', this.navList)
		},
		mounted() {},
		methods: {
			
			parameter(arr) {
				let opdata
				if (arr) {
					opdata = JSON.parse(decodeURIComponent(arr))
					console.log(opdata)
					this.navList = opdata.arr
					let myPath = {
						name: opdata.title,
						path: this.$route.path,
						type: 'answers'
						// current: opdata.arr[opdata.arr.length - 1].current
					}
					this.navList.push(myPath)
				}
			},
			nextAnswer(e) { //切换题目
				if (e.next == true) {
					// this.$refs.cardShow.next()
					this.current = e.index + 1
				} else {
					// this.$refs.cardShow.prev()1
					this.current = e.index - 1
				}
			},
			dtCardClick() {
				this.maskShow = true
				// 计算正确率
				var yes = this.answer.filter(function(item) { //查找回答正确的数组并组成新数组
					return item.wd === true;
				})
				this.correctlv = (yes.length / this.answer.length) * 100
			},
			cardClick(i) {
				this.current = i
				// this.$refs.cardShow.setActiveItem(i)
				this.maskShow = false
			},
		}
	}
</script>

<style scoped>
.page_title{
	width: 932px;
	font-size: 22px;
	font-family: Microsoft YaHei;
	font-weight: bold;
	color: #333333;
	text-align: center;
	margin:20px 0;
	}
	.content {
		width: 932px;
		padding: 20px 20px;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
		background: url(../../../assets/answer_BG.png) #fff;
		box-sizing: border-box;
		margin-bottom: 30px;
	}
	.card_jia{
		width: 30px;
	}
	.card{
		width: 248px;
	}
	.mask {
		position: fixed;
		left: 0;
		top: 0;
		width: 100vw;
		height: 100vh;
		background: rgba(102, 102, 102, .5);
		z-index: 999;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.mask_content {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 495px;
		height: 575px;
		position: relative;
	}

	.mask_content img {
		width: 495px;
		height: 575px;
	}

	.mask_content .correct {
		position: absolute;
		width: 100%;
		top: 334px;
		left: 0;
		display: flex;
		justify-content: center;
	}

	.card_title {
		width: 100%;
		text-align: center;
		font-size: 22px;
		color: #fff;
		font-weight: bold;
	}

	.correctlv {
		font-size: 16px;
		color: #fff;
		margin-top: 10px
	}

	.yesred {
		color: #FF684B !important;
		border: 1px solid #FFBAAD !important;
		background: #FFF0ED !important;
	}

	.yesgreen {
		/* background: #02D19D !important; */
		color: #5694F7 !important;
		border: 1px solid #5694F7 !important;
		/* background: #E5FFEC !important; */
	}

	.number_box {
		position: absolute;
		top: 168px;
		left: 40px;
		width: 415px;
		height: 320px;
		overflow-y: scroll;
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.number_box::-webkit-scrollbar {
		/*滚动条整体样式*/
		width: 4px;
		/*高宽分别对应横竖滚动条的尺寸*/
		height: 4px;
		scrollbar-arrow-color: red;

	}

	.number_box::-webkit-scrollbar-thumb {
		/*滚动条里面小方块*/
		border-radius: 5px;
		/* -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2); */
		background: #fff;
		scrollbar-arrow-color: red;
	}

	.number_box::-webkit-scrollbar-track {
		/*滚动条里面轨道*/
		/* -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2); */
		border-radius: 0;
		background: #fff;
	}

	.number_box div {
		width: 32px;
		margin: 5px 3px;
	}

	.numbers {
		width: 32px;
		height: 32px;
		border-radius: 4px;
		background: #F7F7F9;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 5px 3px;
	}

	.submit_btn {
		position: absolute;
		bottom: 37px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 495px;
		padding: 0 130px;
	}

	.submit_btn div {
		width: 86px;
		height: 234px;
		background: #5694F7;
		border-radius: 10px;
		text-align: center;
		line-height: 35.5px;
		color: #fff;
	}

	.submit_btn .jixu {
		background: #FA4E53;
	}

	.container {
		display: flex;
		justify-content: space-between;
		width: 1200px;
	}

	.time_box {
		height: 135px;
		background: #fff;
		padding: 15px 0;
	}

	.time_box_title {
		border-left: 5px solid #5694F7;
		font-size: 18px;
		font-weight: bold;
		padding-left: 25px;
	}

	.time_box_time {
		font-size: 36px;
		color: #666;
		height: 117px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
	}

	.answer_card {
		/* margin-top: 20px; */
		background: #fff;
	}

	.answer_card {
		padding: 15px 0;
	}

	.answer_card_content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 20px;
		padding: 0 10px;
		flex-direction: row;
		flex-wrap: wrap;
		max-height: 696px;
		overflow-y: scroll;
	}

	.answer_card_content .answer_card_s {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		background: #F7F7F7;
		border: 1px solid #DADADB;
		color: #666;
		cursor: pointer;
		border-radius: 2px;
		margin: 7px;
	}

	.answer_card_content div {
		width: 30px;
		height: 30px;
		margin: 7px;
	}

	.pages_box {
		position: absolute;
		bottom: 20px;
		right: 210px;
	}

	.wraps {
		width: 854px;
		margin-top: 10px;
	}

	.answer_title {
		/* display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative; */
		height: 40px;
		font-size: 16px;
		color: #666;
	}

	.answer_title span:first-child {
		font-size: 26px;
		color: #5694F7;
		font-weight: bold;
	}

	.answer_title .big {
		/* width: 810px; */
		height: 40px;
		background: linear-gradient(90deg, #505DDF 0%, #64B0FE 100%);
		border-radius: 0px 10px 10px 0px;
		display: flex;
		align-items: center;
		color: #fff;
		font-size: 20px;
		padding: 0 27px;
		padding-right: 48px;
		position: absolute;
		left: -27px;
		z-index: 3;
		top: 0;
	}

	.answer_title .big img {
		position: absolute;
		width: 234px;
		right: 0;
		top: 12px;
		height: 17px
	}

	.answer_title .small {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.small div {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 134px;
	}

	.small div img {
		margin-right: 9px;
		width: 18px;
		height: 18px;
	}

	.small .times {
		color: #666666;
	}

	.small .card_btn {
		margin-left: 28px;
		color: #FA4E53;
	}

	.swiper_box {
		min-height: 440px;
	}
</style>
