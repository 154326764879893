<template>
    <div style="margin-top:-20px;">
      <div class="table_box">
        <div class="run_title">{{ dateils.name }}</div>
        <div class="run_cet">
            <div class="tixingxs">
                <div class="tixin">
                  <div class="tal_title"><div>题型</div><div>题数</div></div>
                  <div v-if="tixList!=''" class="tal_i" v-for="(ti,index) in tixList" :key="index"><div>{{ ti.genre_text }}</div><div>{{ti.count}}题</div></div>
                  <div v-else style="text-align: center;margin-top:20px;color:red">试题装备中稍后再试</div>
                </div>
                <div class="guzes">
                    <div class="guzes_title">考试规则</div>
                    <div class="guzes_i" v-for="(gr,index2) in grxLs" :key="index2">{{ gr }}</div>
                </div>
            </div>
            <div>
                <div class="dtime">
               <div class="star_time"><span>开始时间：{{ dateils.spent_time_text }}</span><span>考试时长：{{dateils.use_time}}分钟</span></div>
               <div class="s_tim"><remTmine :remainTime="compareTime(dateils.spent_time)"></remTmine></div>
               <div class="dbtnxs datixs" v-if="compareTime(dateils.spent_time)<2" @click="goQdatis()" :style="dateils.status == '未报名'? 'background:#cccccc;color:#ffffff' : ''">{{(result_id!=undefined&&result_id!=0)?'查看':'开始答题'}}</div>
               <div class="dbtnxs" @click="addMoPst()" :style="dateils.status !== '未报名'? 'background:#cccccc;color:#ffffff' : ''">{{dateils.status}}</div>
               <div class="ren_num">已有<span>{{ dateils.enroll_num }}</span>人报名</div>
             </div>
            </div>
            
        </div>
    </div>
    </div>
  </template>
  
  <script>
  import navBar from "@/components/navBar/navBar";
  import tableList from "@/components/tableList/tableList";
  import turnPages from "@/components/turnPages/turnPages";
  import remTmine from "./components/remTmine.vue";
  import {
		getMockDetail,getMockQuesList,addMockPost
	} from '@/utils/api.js'
 import { json } from "body-parser";
  export default {
    components: {
      tableList,
      turnPages,
      navBar,
      remTmine
    },
    data() {
      return {
        result_id:0,
        dateils:{},
        tixList:[],
        quesId:'',
        grxLs:[
        '1.考试前5分钟截止报名',
        '2.考试前10分钟可进入考场浏览试卷（不可作答）',
        '3.考试开始30分钟后不能入场',
        '4.考试结束前30分钟不能交卷',
        '5.中途退出考场，考试未结束可继续答题',
        '6.如遇到问题可通过APP-我的-联系我们，进行反馈',
        ]
      };
    },
    created() {
      this.dateils = JSON.parse(this.$route.query.datData)
      console.log(this.dateils)
      getMockQuesList({
						// mock_id: this.id
						mock_id: JSON.parse(this.$route.query.datData).id
					}).then(res => {
						
					})
      getMockDetail({
						// mock_id: this.id
						mock_id: JSON.parse(this.$route.query.datData).id
					}).then(res => {
             this.tixList=res.list
             this.quesId=res.mock_data.ques_id
             this.result_id=res.mock_data.result_id
					})
    },
    methods: {
      
      // 答题
      goQdatis(){
        if(this.dateils.status=='已报名' ||this.dateils.status=='已结束'){
          if(!this.tixList.length){
            this.$message.warning('没有试题')
            return;
          }
          if(this.result_id!=undefined&&this.result_id!=0){
          let navarrs = {
            id: this.quesId,
					  type: 'li',
						title: '答题结算',
						result_id: this.result_id,
            clastiy:'mokao',
            paths:this.$route.fullPath,
            mock_id:JSON.parse(this.$route.query.datData).id
					}
						this.$router.push('/AnswerEnd?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(
						navarrs))));
            return;
        }
          let arrs = {
					id: this.quesId,
					type: 'li',
          clastiy:'mokao',
          mock_id:JSON.parse(this.$route.query.datData).id
				}
				this.$router.replace('/Answer?parameter=' +
					encodeURIComponent(
						encodeURIComponent(JSON.stringify(arrs))));
        }
      },
      //报名
      addMoPst(){
         if(this.dateils.status=='未报名'){
          addMockPost({
						// mock_id: this.id
						id: this.dateils.id
					}).then(res => {
            if(res.code==200){
              this.$message.success('已报名')
						  this.dateils.status='已报名'
            }
              
					})
         }
        
      },
        compareTime(time){
          console.log(time)
        var d1 = Date.parse(new Date())
        var d2 = Date.parse(new Date(time))*1000
        console.log(parseInt(d2 - d1) / 1000>0?parseInt(d2 - d1) / 1000:0.1)
        return parseInt(d2 - d1) / 1000>0?parseInt(d2 - d1) / 1000:0.1
      }
    },
  };
  </script>
  <style scoped lang="scss">
   .table_box{
    width: 1200px;
    margin-top: 30px;
    margin-bottom: 30px;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
    border-radius: 4px;
    padding:47px;
    box-sizing: border-box;
   }
   .run_title{
     padding-bottom: 27px;
     text-align: center;
     border-bottom: 1px dashed #ebeaea;
     font-size: 22px;
     font-family: Microsoft YaHei;
     font-weight: bold;
     color: #333333;
   }
   .run_cet{
    margin-top: 46px;
    display: flex;
    justify-content: space-between;
   }
   .tixingxs{
    
   }
   .tixin{

   }
   .tal_title{
    width: 506px;
    height: 60px;
    background: #F9FBFF;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-around;
   }
   .tal_title div{
    width: 50%;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    text-align: center;
   }
   .tal_i{
    width: 506px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid #dad8d86c;
   }
   .tal_i div{
    width: 50%;
    font-size: 16px;
    font-family: Microsoft YaHei;
    color: #666666;
    text-align: center;
   }
   .guzes{
    width: 506px;
    margin-top: 80px;
   }
   .guzes_title{
    height: 16px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    padding-left: 15px;
    border-left: 5px solid #5694F7;
    margin-bottom: 20px;
   }
   .guzes_i{
    margin-left: 20px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 36px;
   }
   .dtime{
    width: 555px;
    padding: 17px 75px 50px 75px;
    border-left: 1px solid #dad8d86c;
   }
   .star_time{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
   }
   .star_time span{
    white-space: nowrap;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-right: 15px;
   }
   .datixs{
    margin-bottom: -50px !important;
   }
   .dbtnxs{
    width: 362px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    background: #5694F7;
    border-radius: 30px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
    margin: 74px auto 0 auto;
   }
   .dbtnxs:hover{
    background: #2673ee;
   }
   .ren_num{
    text-align: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-top: 30px;
   }
   .ren_num span{
    color: #5694F7;
   }
   .s_tim{
    margin: 30px auto 0 auto;
    text-align: center;
   }
  </style>
  