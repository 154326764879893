<template>
	<div class="container">
		<div class="content">
			<div class="wraps">
				<div class="title">
					<div class="big">问题描述</div>
					<!-- <div class="small"><img src="@/assets/images/user/yw.png">我要追问</div> -->
				</div>
				<div class="wenti_con">{{list[0]}}</div>
				<div class="title" style="border-color: ;" v-if="huifu">
					<div class="big">回复</div>
					<!-- <div class="small"><img src="@/assets/images/user/yw.png">我要追问</div> -->
				</div>
				<div class="wenti_con" style="background: #f4f6f8;"  v-if="huifu">{{huifu || '暂无回复'}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getFeedbackDetail
	} from '@/utils/api.js'
	import {
		EventBus
	} from '@/eventBus/eventBus'
	export default {
		data() {
			return {
				navList: [{
						path: '/classList',
						name: '首页',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 0
					},
					{
						path: '/userInfo',
						name: '个人中心',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 5
					},
					{
						path: '/mySet',
						name: '反馈',
						studyCurrent: 0,
						tabbarChildCurrent: 4,
						tabbarCurrent: 5
					},
					{
						path: '/myFeedback',
						name: '我的反馈',
						studyCurrent: 0,
						tabbarChildCurrent: 4,
						tabbarCurrent: 5
					},
					{
						path: '/FeedbackDetail',
						name: '反馈详情',
						studyCurrent: 0,
						tabbarChildCurrent: 4,
						tabbarCurrent: 5
					}
				],
				id: '',
				list: [],
				question: '',
				cate_id: null,
				huifu:''
			}
		},
		created() {
			this.id = this.$route.query.id
			this.getData()
			EventBus.$emit('navList', this.navList)
		},
		methods: {
			async getData() {
				await getFeedbackDetail({
					id: this.id
				}).then(res => {
					this.list.push(res.data.content)
					this.huifu = res.data.reply_content
					// this.cate_id = res.list.cate_id
				})
			},
			questionSubmit() {
				if (this.question == '') {
					this.$message.warning('请输入想要追问老师的问题')
					return
				}
				addQuestionAnswer({
					question: this.question,
					cate_id: this.cate_id,
					pid: this.id
				}).then(res => {
					this.getData()
					this.question = ''
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.content {
		background: #fff;
		padding: 20px;
		position: relative;
	}

	.questions_boxs {
		width: 100%;
		position: relative;
	}

	.wenti_con {
		padding: 15px;
		font-size: 18px;
		color: #666;
		background: #F3F4FF;
		margin-top: 30px;
		border-radius: 10px;
	}

	span.text {
		position: absolute;
		top: 150px;
		right: 20px;
	}

	.zw_btn {
		margin: 30px 0;
		width: 80px;
		height: 36px;
		background: #47BE5E;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;

		color: #fff;
	}

	textarea {
		height: 150px;
		width: 100%;
		background: #F7F7F7;
		border: none;
		padding: 15px;
		resize: none;
	}

	.wraps {
		min-height: 600px;
	}

	.wraps .title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-left: 5px solid #47BE5E;
		font-weight: bold;
		padding-left: 11px;
		margin-top: 20px;
	}

	.wraps .title .big {
		font-size: 20px;
	}

	.wraps .title .small {
		font-size: 18px;
		color: #FA4E53;
		display: flex;
		align-items: center;
	}

	.small img {
		width: 17px;
		height: 17px;
		margin-right: 7px;
	}

	.warps_con {
		margin-top: 40px;
		min-height: 536px;
		padding-bottom: 40px;
	}

	.teacher_box {
		margin-top: 40px;
	}

	.teacher {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.teacher_box .yes {
		width: 73px;
		height: 30px;
		background: #47BE5E;
		box-shadow: 0px 5px 5px 0px rgba(4, 183, 148, 0.3);
		border-radius:10px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size:16px;
		color: #fff;
	}

	.answer {
		box-shadow: 0px 5px 5px 0px rgba(250, 78, 83, 0.3) !important;
		background: rgb(255, 104, 75) !important;
	}

	.teacher_answer {
		display: flex;
		justify-content: flex-start;
		margin-top: 20px;
	}

	.teacher_info {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		height: 64px;
	}

	.student_info img {
		box-shadow: 0px 4px 4px 0px rgba(250, 78, 83, 0.56) !important;
	}

	.teacher_info img {
		width: 64px;
		height: 64px;
		border-radius: 50%;
		box-shadow: 0px 4px 4px 0px rgba(0, 211, 170, 0.56);
	}

	.teacher_info .text {
		margin-left: 17px;
	}

	.teacher_name {
		font-size: 20px;
		color: #161616;
		font-weight: bold;
	}

	.answer_time {
		font-size:16px;
		color: #333333;
		margin-top:10px;
	}

	.answer_content {
		background: #F3F3F3;
		padding:10px 25px;
		border-radius:10px;
		margin-left: 20px;
		/* box-shadow: 0px 0px 10px 0px rgba(80, 93, 223, 0.3); */
		flex: 1;
		display: flex;
		align-items: center;
		font-size:16px;
		color: #161616;
		line-height: 24px;
		position: relative;
	}

	.sj {
		width: 0;
		height: 0;
		border-top: 6.5px solid transparent;
		border-right:13px solid #F3F3F3;
		border-bottom: 6.5px solid transparent;
		/* box-shadow: 0px 0px 10px 0px rgba(80, 93, 223, 0.3); */
		/* filter: drop-shadow(0px 0px 10px 0px rgba(80, 93, 223, 0.3)); */
		position: absolute;
		top: 12px;
		left: -13px;
	}

	.fansj {
		width: 0;
		height: 0;
		border-top: 6.5px solid transparent;
		border-left:13px solid #DBFFE2;
		border-bottom: 6.5px solid transparent;
		position: absolute;
		top: 12px;
		right: -13px;
	}
</style>
