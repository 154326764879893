<template>
  <div class="boxs">
    <img
      src="../../assets/j1.png"
      alt=""
      srcset=""
      class="j1"
      @click="showVideo = true"
    />
    <div
      class="container"
      style="display: flex; justify-content: center; flex-direction: column"
    >
      <div style="height: 46.25vw"></div>
      <div class="j2">
        <div class="j2-1">
          <div class="j2-1-1">莘知在线学习平台特色功能</div>
          <div class="j2-1-2">公职类考试线上一站式学习资源供应</div>
        </div>
        <div class="j2-2">
          <div
            class="j2-2-1"
            :style="{
              backgroundImage: 'url(' + require('@/assets/j2.png') + ')',
            }"
          >
            <div class="j2-2-1-1">
              <div class="j2-2-1-1-1">
                <img src="../../assets/j2_1.png" alt="" srcset="" />
                <div style="margin-left: 12px">学习中心</div>
              </div>
              <div class="j2-2-1-1-2" @click="routerTo('courseList')">
                点击查看
              </div>
            </div>
            <div class="j2-2-1-2">
              <div class="j2-2-1-2-1">
                <img src="../../assets/j1_1.png" alt="" srcset="" />
                <div>观看专属笔面试网络课程和直播课。</div>
              </div>
              <div class="j2-2-1-2-1">
                <img src="../../assets/j1_1.png" alt="" srcset="" />
                <div>
                  课后练习闯关模式，正确率达标60%解锁新课，及时检测学习效果。
                </div>
              </div>
              <div class="j2-2-1-2-1">
                <img src="../../assets/j1_1.png" alt="" srcset="" />
                <div>
                  答疑服务，专业老师线上一对一答疑，疑难问题不懂就问，学习效
                  果有保障。
                </div>
              </div>
            </div>
          </div>

          <div
            class="j2-2-1"
            :style="{
              backgroundImage: 'url(' + require('@/assets/j3.png') + ')',
            }"
          >
            <div class="j2-2-1-1">
              <div class="j2-2-1-1-1">
                <img src="../../assets/j3_1.png" alt="" srcset="" />
                <div style="margin-left: 12px">题库中心</div>
              </div>
              <div class="j2-2-1-1-2" @click="routerTo()">点击查看</div>
            </div>
            <div class="j2-2-1-2">
              <div class="j2-2-1-2-1">
                <img src="../../assets/j1_1.png" alt="" srcset="" />
                <div>
                  莘知题库，及时更新的数万试题，公职类专项训练，时政刷题、历
                  年真题。
                </div>
              </div>
              <div class="j2-2-1-2-1">
                <img src="../../assets/j1_1.png" alt="" srcset="" />
                <div>题库配套详细解析，吃透考点，还可做笔记。</div>
              </div>
              <div class="j2-2-1-2-1">
                <img src="../../assets/j1_1.png" alt="" srcset="" />
                <div>错题巩固自动同步，依托大数据，帮助查漏补缺。</div>
              </div>
            </div>
          </div>

          <div
            class="j2-2-1"
            :style="{
              backgroundImage: 'url(' + require('@/assets/j4.png') + ')',
            }"
          >
            <div class="j2-2-1-1">
              <div class="j2-2-1-1-1">
                <img src="../../assets/j4_1.png" alt="" srcset="" />
                <div style="margin-left: 12px">考试中心</div>
              </div>
              <div class="j2-2-1-1-2" @click="routerTo('aminationCer')">
                点击查看
              </div>
            </div>
            <div class="j2-2-1-2">
              <div class="j2-2-1-2-1">
                <img src="../../assets/j1_1.png" alt="" srcset="" />
                <div>考试日历，考试时间日历提醒，学习计划自主管理。</div>
              </div>
              <div class="j2-2-1-2-1">
                <img src="../../assets/j1_1.png" alt="" srcset="" />
                <div>模考大赛，考前模考摸底预热，充分做好身心准备。</div>
              </div>
              <div class="j2-2-1-2-1">
                <img src="../../assets/j1_1.png" alt="" srcset="" />
                <div>考试真题，历年真题实战演练，精准把握命题方向。</div>
              </div>
              <div class="j2-2-1-2-1">
                <img src="../../assets/j1_1.png" alt="" srcset="" />
                <div>招聘信息，全国招录及时同步，轻松掌握各地考情。</div>
              </div>
            </div>
          </div>

          <div
            class="j2-2-1"
            :style="{
              backgroundImage: 'url(' + require('@/assets/j5.png') + ')',
            }"
          >
            <div class="j2-2-1-1">
              <div class="j2-2-1-1-1">
                <img src="../../assets/j5_1.png" alt="" srcset="" />
                <div style="margin-left: 12px">个人中心</div>
              </div>
              <div class="j2-2-1-1-2" @click="routerTo('userInfo')">
                点击查看
              </div>
            </div>
            <div class="j2-2-1-2">
              <div class="j2-2-1-2-1">
                <img src="../../assets/j1_1.png" alt="" srcset="" />
                <div>
                  学习报告、错题巩固，查看学习进度，大数据分析正确率及错题情
                  况，精准进行查漏补缺。
                </div>
              </div>
              <div class="j2-2-1-2-1">
                <img src="../../assets/j1_1.png" alt="" srcset="" />
                <div>
                  收藏课程、我的资料、我的笔记，汇总个人学习资源和足迹，快速
                  查看学习内容。
                </div>
              </div>
              <div class="j2-2-1-2-1">
                <img src="../../assets/j1_1.png" alt="" srcset="" />
                <div>我的班主任，督学服务，提供学习建议和规划帮助。</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="j3">
        <div class="j3-1">
          <div class="j3-1-1">
            <div class="j3-1-1-1">学习中心</div>
            <div class="j3-1-1-2">
              量身订制的海量网络课程，满足个性化备考需求。
            </div>
            <div style="height: 30px"></div>
            <div class="j3-1-1-3" @click="routerTo('courseList')">进入学习</div>
          </div>
          <img src="../../assets/j6.png" alt="" srcset="" />
        </div>
        <div class="j3-1" style="flex-direction: row-reverse">
          <div class="j3-1-1">
            <div class="j3-1-1-1">题库中心</div>
            <div class="j3-1-1-2">
              依托莘知题库的数万道专项题库、历年真题，备考刷题神器。
            </div>
            <div style="height: 30px"></div>
            <div class="j3-1-1-3" @click="routerTo()">去练习</div>
          </div>
          <img src="../../assets/j7.png" alt="" srcset="" />
        </div>
        <div class="j3-1">
          <div class="j3-1-1">
            <div class="j3-1-1-1">考试中心</div>
            <div class="j3-1-1-2">
              考试信息、模考大赛和真题实战，让考试冲刺更简单。
            </div>
            <div style="height: 30px"></div>
            <div class="j3-1-1-3" @click="routerTo('aminationCer')">
              进入学习
            </div>
          </div>
          <img src="../../assets/j8.png" alt="" srcset="" />
        </div>
        <div class="j3-1" style="flex-direction: row-reverse">
          <div class="j3-1-1">
            <div class="j3-1-1-1">个人中心</div>
            <div class="j3-1-1-2">
              大数据学习分析，个人学习收藏，学习计划督学等，学习备考绝不迷茫。
            </div>
            <div style="height: 30px"></div>
            <div class="j3-1-1-3" @click="routerTo('userInfo')">去练习</div>
          </div>
          <img src="../../assets/j9.png" alt="" srcset="" />
        </div>
      </div>
    </div>

    <img src="../../assets/j10.png" alt="" srcset="" class="j1" />
    <div style="height: 42.45vw"></div>

    <el-dialog
      title="2分钟，了解学习平台"
      :visible.sync="showVideo"
      width="50%"
      :before-close="handleClose"
    >
      <!-- <span>这是一段信息</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showVideo = false">取 消</el-button>
        <el-button type="primary" @click="showVideo = false">确 定</el-button>
      </span> -->
      <!-- <video controls style="width: 100%;" v-if="showVideo">
        <source src="@/assets/jianjie.mp4" type="video/mp4" />
        您的浏览器不支持Video标签。
      </video> -->
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/eventBus/eventBus";
export default {
  name: "recommend",
  components: {},
  data() {
    return { showVideo: false };
  },
  watch: {},
  created() {},
  computed: {},
  methods: {
    routerTo(url) {
      if (url) {
        this.$router.push("/" + url);
      } else {
        window.open("https://www.gongzhaotiku.com/#/");
      }
    },
  },
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.boxs {
}
.j1 {
  width: 100%;
  position: absolute;
  left: 0;
}
.j2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  .j2-1 {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .j2-1-1 {
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      margin: 40px 0 0;
    }
    .j2-1-2 {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin: 20px 0;
    }
  }

  .j2-2 {
    width: 1200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .j2-2-1 {
      width: 584px;
      height: 263px;
      box-sizing: border-box;
      padding: 32px;
      border-radius: 10px;
      background-size: 100%;
      // background-image: url("../../assets/j2.png");
      color: #fff;
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      .j2-2-1-1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #eeeeee;
        padding-bottom: 20px;
        margin-bottom: 20px;
        .j2-2-1-1-1 {
          display: flex;
        }
        .j2-2-1-1-2 {
          padding: 3px 10px;
          background: rgba(255, 255, 255, 0);
          border: 1px solid #eee;
          border-radius: 18px;
        }
      }
      .j2-2-1-2 {
        flex: 1;
        display: flex;
        flex-direction: column;
        // align-items: center;
        // justify-content: space-between;
        &-1 {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          img {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
.j3 {
  display: flex;
  flex-direction: column;
  .j3-1 {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 20px;
    flex-direction: row;
    .j3-1-1 {
      margin-top: 150px;
      .j3-1-1-1 {
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-bottom: 21px;
      }
      .j3-1-1-2 {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-bottom: 21px;
      }
      .j3-1-1-3 {
        color: #ffffff;
        font-size: 16px;
        background: #5694f7;
        border-radius: 25px;
        padding: 14px 39px;
        margin-bottom: 21px;
        display: initial;
      }
    }
  }
}
</style>
