<template>
	<div>
		<div class="aatitle">
			背题收藏
		</div>
		<div class="table_box">
			<!-- <div class="table_title">
				<div v-for="(item,index) in titleList" :key="index">{{item}}</div>
			</div> -->
			<div class="table_content" :class="setData?'':'nothing'">
				<img src="@/assets/images/nothing.png" v-if="!setData">
				<div class="table_list" v-for="(item,index) in setData" :key="index" v-else
					@click="jumpClick(item.chapter_id,item.name)">
					<div class="list_title">{{item.name}}</div>
					<div class="btns">前往小节</div>
				</div>
			</div>
		</div>
		<turnPages class="pages_box" :count="count" :pages="pages" @fanye="fanyeClick" @inputPage="inputPage">
		</turnPages>
	</div>
</template>

<script>
	import turnPages from '@/components/turnPages/turnPages'
	import tableList from '@/components/tableList/tableList'
	import {
		getKnowledgeCollect
	} from '@/utils/api.js'
	import {
		EventBus
	} from '@/eventBus/eventBus'
	export default {
		components: {
			turnPages,
			tableList
		},
		data() {
			return {
				titleList: ['考试名称', '考试类型', '我的成绩', '我的排名'],
				setData: [],
				navList: [],
				count: 0,
				pages: 1,
				limit: 12,
				typeArr: ['专项',
					'随机',
					'历年真题',
					'冲刺密卷',
					'公告模考',
					'考试估分',
					'模拟考试',
					'易错题',
					'时事政治',
				],
				navList: [{
						path: '/classList',
						name: '首页',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 0
					},
					{
						path: '/userInfo',
						name: '个人中心',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 5
					},
					{
						path: '/chapterList',
						name: '背题收藏',
						studyCurrent: 0,
						tabbarChildCurrent: 3,
						tabbarCurrent: 5
					}
				]
			}
		},
		created() {
			EventBus.$emit('navList', this.navList)
			this.getData()
		},
		methods: {
			getData() {
				getKnowledgeCollect({
					limit: this.limit,
					page: this.pages
				}).then(res => {
					console.log(res.data)
					// this.count = Math.ceil(res.data.count / this.limit)
					this.setData = res.list
					this.$forceUpdate()
				})
			},
			detailClick(i) {
				// console.log(i)
			},
			jumpClick(zID, zName) {
				let arr = {
					navList: this.navList,
					zId: zID,
					zName: zName
				}
				this.$router.push('/memory?arr=' + encodeURIComponent(encodeURIComponent(JSON.stringify(arr))))
			},
			fanyeClick(e) {
				if (e == 'next') {
					this.pages++
					console.log(this.pages)
					if (this.pages <= this.count) {
						this.getData()
					} else {
						this.pages = this.count
					}
				} else {
					this.pages--
					if (this.pages >= 1) {
						this.getData()
					} else {
						this.pages = 1
					}
				}
			},
			inputPage(e) {
				this.pages = e * 1
				this.getData()
			}

		}
	}
</script>

<style scoped="scoped">
	.aatitle {
		margin-bottom: 15px;
		padding-left: 15px;
		border-left: 5px solid #47BE5E;
		font-size: 18px;
		font-weight: bold;
	}

	.content {
		width: 1132px;
		background: #fff;
		/* height: 50.625rem; */
		height: 780px;
		/* margin-top: 1.25rem; */
		/* border-radius: .625rem; */
		/* padding: 1.25rem 1.5625rem; */
		padding: 20px;
		position: relative;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
	}

	.title {
		font-size: .875rem;
		color: #7B92CB;
	}

	/* .study_list{
	
	} */
	.pages_box {
		position: absolute;
		bottom: 20px;
		right: 20px;
	}

	.tab_top {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.tab_top div {
		width: 125px;
		height: 35px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
	}

	.tab_top .active {
		background: #47BE5E;
		border-radius: 18px;
		color: #fff;
	}

	.table_box {
		margin-top: 30px;
	}

	.table_content {
		cursor: pointer;
		padding-bottom: 60px;
	}

	.list_title {
		font-size: 16px;
		font-weight: bold;
	}

	.table_title,
	.table_list {
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.table_list {
		/* padding: 10px 0 !important; */
		height: 65px;
		padding-left: 15px;
	}

	.table_list:hover {
		background: rgba(71, 190, 94);
		border-radius: 10px;
		/* color: #FFF; */
	}

	.table_list:hover div {
		color: #fff;
	}

	.table_list .btns {
		padding: 5px 10px;
		width: 100px;
		height: 40px;
		border-radius: 10px;
		background: #5694F7 !important;
		color: #fff;

	}

	.table_title {
		background: #F7F7F7;
	}

	.table_title div {
		width: 17%;
		display: flex;
		font-size: 16px;
		font-weight: bold;
		color: #333;
		align-items: center;
		justify-content: center;
	}

	.table_title div:first-child,
	.table_list div:first-child {
		width: 49%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.table_list div {
		width: 17%;
		display: flex;
		color: #333;
		align-items: center;
		justify-content: center;
	}

	.table_list div:first-child {
		justify-content: flex-start;
	}
</style>
