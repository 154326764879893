<template>
  <div class="container">
    <div class="question_set">答疑服务</div>
    <div class="content_div">
      <div class="det_header">
        <div class="det_left">
          <div></div>
          我的提问
        </div>
        <div class="det_right" @click="goBack">
          <img src="../../assets/images/slices/backi.png" alt="" />
          返回
        </div>
      </div>
      <div class="calinfo">
        <div class="cal_name">
          {{ansInfo.question}}
        </div>
        <div class="cal_img" v-if="imgList!=''">
           <img @click="e=>{
            dialogImageUrl=item
            dialogVisible=true
           }" v-for="(item,index) in imgList" :key="index" :src="item" alt="" />
        </div>
        <div class="subjinfo" v-if="ansInfo.ques!=''">
            <div class="stem latexDiv"><span class="ti_tpye">{{ansInfo.ques.genre_name}}</span>{{ansInfo.ques.title}}</div>
            <div class="selects">
            <div>
                <!--选项-->
                <img :src="ansInfo.ques.icon" style="max-width: 100%;height: auto;margin-top:20px">
                <div class="select_list"
                    v-for="(item, index) in ansInfo.ques.option_name" :key="index" @click="selectClcik(index)">
                    <div class="tag">{{ index }}</div>
                    <div class="cont"
                        v-if="item.indexOf('.jp') !== -1 || item.indexOf('.png') !== -1 || item.indexOf('.gif') !== -1">
                        <img :src="item" style="max-height: 56px;">
                    </div>
                    <div class="cont latexDiv" v-else>{{ item }}</div>
                </div>
            </div>
                <div class="queyes">
                    正确答案： <span>{{ansInfo.ques.answer}}</span>
                </div>
                <div class="queyes">
                    答案分析：
                </div>
                <div class="fenxis" v-html="ansInfo.ques.analysis">
                  </div>
			</div>
        </div>
      </div>
      <div class="calwog">
        <div class="woglist">
          <img  v-if="ansInfo.is_closed==2" class="wbk_img" src="../../assets/images/cgjj.png" alt="">
          <div v-for="(iy,index2) in ansInfo.son" :key="index2" class="record-wrapper">
            <div class="time"></div>
            <div class="msg msg-right" v-if="iy.type==1">
              <div class="img-wrapper">
               <div class="hed_name">
                   <div style="text-align: right;">{{iy.user.name}}</div>
                   <div>{{Ctime(iy.create_time)}}</div>
               </div>
               <div class="head_img">
                <img class="img" :src="iy.user.icon||userInfos.avatar" />
                <div>追问</div>
               </div>
              </div>
              <div class="message-wrapper message-wrapper-right">
                <div class="message">{{iy.question}}</div>
              </div>
            </div>

            <div class="msg msg-left" v-if="iy.type==2">
              <div class="img-wrapper">
               <div class="head_img">
                <img class="img" :src="iy.user.icon" />
                <div>已解答</div>
               </div>
               <div class="hed_name">
                   <div>{{iy.user.name}}</div>
                   <div>{{Ctime(iy.create_time)}}</div>
               </div>
              </div>
              <div class="message-wrapper message-wrapper-left">
                <div class="message">{{iy.question}}</div>
              </div>
            </div>
          </div>
                <!--  -->
         <div class="gaiws"  v-if="ansInfo.is_closed!=2">该问题是否 <span @click="open">已解决</span>?</div>
         <!-- <div class="gaiws" v-else><span @click="open">已解决</span></div> -->
         <el-input
         v-if="ansInfo.is_closed!=2"
            type="textarea"
            placeholder="请输入内容"
            :rows="10"
            v-model="question"
            maxlength="1000"
            show-word-limit
          >
          </el-input>
          <div class="tibtn">
            <el-button type="primary" @click="addAnswer"  v-if="ansInfo.is_closed!=2">提交</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 评分 -->
    <div class="ask_question" v-if="rateShow">
        <div class="ask_center">
           <div class="ackname">
              <div>感谢您的提问</div>
              <div>老师答题不易，请给这位老师评分吧！</div>
           </div>
           <div>
            <div class="rates">
              <div class="rates_title">评分</div>
              <div><el-rate v-model="value1"></el-rate></div>
            </div>
            <div class="hbtns"><el-button @click="rateShow=false">取消</el-button><el-button type="primary" @click="setAnswer">提交</el-button></div>
           </div>
        </div>
    </div>
    <!--  -->
    <el-dialog :visible.sync="dialogVisible" width="30%">
      <img style="width:100%;" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
  
  <script>
import { getAnswerDetail,addAnswer,setAnswer } from "@/utils/erpapi.js";
export default {
  components: {},
  data() {
    return {
      dialogVisible:false,
      dialogImageUrl:"",
      question: "",
      timestamp: "1",
      value1:3,
      rateShow:false,
      ansInfo:{},
      imgList:[],
      userInfos: JSON.parse(localStorage.getItem('userInfo'))
    };
  },
  created() {
    this.getAnswerDetail(this.$route.query.id)
  },

  methods: {
    open() {
        this.$confirm('该问题是否已解决?', '提示', {
          confirmButtonText: '已解决',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.rateShow=true
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      },
    //提交评分
    setAnswer(){
      setAnswer({
        id: this.ansInfo.id,
        score:this.value1,
        is_closed:2
      }).then(res=>{
        this.getAnswerDetail(this.$route.query.id)
         this.rateShow=false
         this.$message({
						message: '感谢您的评价',
						type: 'success'
					});
      })
    },
    // 新增
    addAnswer(){
      addAnswer({
        cate_id: this.ansInfo.cate_id,
        vice_id: this.ansInfo.vice_id,
        question: this.question,
        image: "",
        ques_type: "",
        ques_list_id: "",
        pid: this.ansInfo.id,
      }).then((res) => {
        this.question=""
        this.getAnswerDetail(this.$route.query.id)
      });
    },
    getAnswerDetail(id){
      getAnswerDetail({id}).then(res=>{
        this.imgList=res.list.image.split(',')
         this.ansInfo=res.list
      })
    },
    goBack(){
    this.$router.go(-1)
  },
  Ctime(timestamp) {
			var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
			let Y = date.getFullYear() + "-";
			let M =
				(date.getMonth() + 1 < 10

					? "0" + (date.getMonth() + 1)

					: date.getMonth() + 1) + "-";
			let D = date.getDate() + " ";
			let h = date.getHours() + ":";
			let m = date.getMinutes() + ":";
			let s = date.getSeconds();
			return Y + M + D+h + m +s
		},
  },
};
</script>
  <style scoped="scoped" lang="scss">
  /deep/.el-rate__icon{
    font-size: 44px;
  }
  .hbtns{
    display: flex;
    justify-content: center;
  }
  .rates{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
      div{
        height: 60px;
      }
    .rates_title{
      font-size: 22px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-right: 10px;
      padding-top: 8px;
    }
  }
  .ask_question{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #00000040;
  display: flex;
  align-items: center;
  justify-content: center;
  .ask_center{
    width: 530px;
    height: 345px;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 20px;
    .ackname{
      padding: 30px 0;
      margin: 0 auto;
      border-bottom: 1px solid #F4F5F6;
    }
    .ackname div:first-child{
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      text-align: center;
    }
    .ackname div:last-child{
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      text-align: center;
      margin-top: 26px;
    }
  }
}
  // 
  .gaiws{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin: 30px 0;
    span{
      color: #3C87F2;
    }
  }
  .tibtn{
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  // 
  .img-wrapper{
    display: flex;
    .head_img{
      width: 50px;
      img{
        width: 37px;
        height: 37px;
        margin-left: 6px;
      }
      div{
        width: 50px;
        height: 20px;
        background: #3c88f215;
        border-radius: 2px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #3C87F2;
        margin-top: 14px;
        text-align: center;
        line-height: 20px;
      }
    }
    .hed_name div:first-child{
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
    .hed_name div:last-child{
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
  }
.record-wrapper {
  margin: 4px;
  padding: 4px;
}
.time {
  text-align: center;
}
.msg {
  display: flex;
  flex-direction: row;

  .message-wrapper {
    max-width: 220px;

    margin: 0px 10px 0px 10px;

    .message {
      margin: 8px;
      word-wrap: break-word; //英文换行
    }
  }

  .message-wrapper-left {
    background-color: #ffffff;
    border-radius: 0px 12px 12px 12px;
    border: 1px solid #f3f3f3;
    height: max-content;
    padding:8px 15px;
  }

  .message-wrapper-right {
    background-color: #3c88f253;
    border-radius: 12px 0px 12px 12px;
    height: max-content;
    padding:8px 15px;
  }

  .img {
    flex: auto;
    height: 36px;
    width: 36px;
    border-radius: 8px;
  }
}
.msg-right {
  flex-direction: row-reverse;
}
.msg-left {
  flex-direction: row;
}
.woglist {
  position: relative;
  padding: 20px;
}
.wbk_img{
  position: absolute;
 width: 157px;
 height: 125px;
 top: 10px;
 left: 0px;
}
.calwog {
  width: 1127px;
  background: #fafafa;
  border-radius: 10px;
  margin-top: 40px;
}
.calinfo {
  margin-top: 30px;
  padding-bottom: 40px;
  border-bottom: 1px solid #eaeef1;
  .cal_name {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
  }
  .cal_img {
    margin-top: 20px;
    display: flex;
    align-items: center;
    img {
      width: 70px;
      height: 70px;
      background: #ffffff;
      border: 1px solid #cccccc9b;
      border-radius: 8px;
      margin-right: 10px;
    }
  }
}
.content_div {
  width: 1187px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: 25px;
  margin-bottom: 20px;
  padding: 30px 50px;
  .det_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .det_left {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      div {
        width: 5px;
        height: 20px;
        background: #5694f7;
        margin-right: 15px;
      }
    }
    .det_right {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      img {
        width: 18px;
        height: 14px;
        margin-right: 9px;
      }
    }
  }
}
.question_set {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #393f47;
}
.subjinfo{
        margin-top: 26px;
    }
    .ti_tpye {
	display: inline;
	padding: 2px 10px;
	margin-right: 10px;
	background: #EEF5FF;
	border-radius: 5px !important;
	font-size: 14px !important;
	color: #5694F7 !important;
}

.stem {
	position: relative;
	font-size: 20px;
	color: #333;
	line-height: 1.5;
}
.tiyhtm{
	width: 80%;
}
.stem span {
	font-size: 20px;
	color: #333;
}
.queyes{
        margin: 15px 0;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #666666;
        span{
            color: #5694F7;
        }
    }
    // 
    .select_list {
	/* margin-top: 35px; */
	display: flex;
	height: 50px;
	align-items: center;
	justify-content: flex-start;
}

// .select_list:hover {
// 	background: #f6f6f6;
// 	opacity: .5;
// }

.select_list .tag {
	width: 26px;
	height: 26px;
	border: 1px solid #666666;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	color: #666666;
	margin-right: 20px;
}

.select_list .cont {
	width: 800px;
	font-size: 14px;
	color: #666666;

}
//正确答案
.correctx .tag {
	border-top-color: #ff6247;
    border-right-color: #ff6247;
    border-bottom-color: #5ba0f6;
    border-left-color: #5ba0f6;
	border-width: 15px;
	transform: rotate(45deg);
	color: #fff
}
.correctx span{
	transform: rotate(-45deg);
}
.zqrect .tag{
	background-color: #5ba0f6;
	border: 1px solid #5ba0f6;
	color: #ffffff;
}
.correctx .cont {
	 color: #5694F7;
}
.correct .tag {
		background: #5694F7;
		border-color: #5694F7;
		color: #fff
	}

	.correct .cont {
		color: #5694F7
	}
.error .tag {
	background: #FF684B;
	border-color: #FF684B;
	color: #fff
}

.error .cont {
	color: #FF684B
}
.fenxis{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 26px;
        margin-top: -10px;
    }
</style>