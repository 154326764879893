<template>
	<div class="title">
		<span @click="backClick(item.path,item.current,index)" v-for="(item,index) of navList"
			:key="index">{{item.title + (index+1 == navList.length?'':'>')}}</span>
	</div>
</template>

<script>
	import {
		EventBus
	} from '@/eventBus/eventBus.js'
	export default {
		props: {
			navList: {
				type: Array,
				default: []
			}
		},
		methods: {
			backClick(path, num, index) {
				if (this.navList[this.navList.length - 1] == index) {
					this.$router.go('-1')
				}
				if (path !== this.$route.path) {
					this.$router.replace(path)
					EventBus.$emit("current", num); //事件总线传递current到tabbar
					this.$emit('stop', 'stop')
				}
			},
		}
	}
</script>

<style scoped>
	.title {
		font-size: .875rem;
		color: #7B92CB;
	}

	.title span {
		font-size: 1rem;
		color: #7B92CB;
	}
</style>
