<template>
	<div class="container">
		<!-- <userInfo></userInfo> -->
		<div class="content">
			<!-- <navBar :navList="navList"></navBar> -->
			<div class="study_list">
				<div class="table_box">
					<div class="table_title">
						<div v-for="(item,index) in titleList" :key="index">{{item}}</div>
					</div>
					<div class="table_content" :class="content?'':'nothing'">
						<img src="@/assets/images/nothing.png" v-if="!content">
						<div class="table_list" v-for="(item,index) in content" :key="index" v-else>
							<div>{{item.content}}</div>
							<div v-if="item.status == 2" style="color: #FF684B;">未解决</div>
							<div v-if="item.status == 1" style="color: #47BE5E;">已解决</div>
							<div>{{item.create_time,2 | dtime}}</div>
							<div class="tabbtn" @click="maskClick(item.content,item.id)"><span>查看详情</span></div>
						</div>
					</div>
				</div>
				<turnPages class="pages_box" :count="count" :pages="pages" @fanye="fanyeClick" @inputPage="inputPage">
				</turnPages>
			</div>
		</div>
		<div class="mask" v-if="maskShow" @click="maskShow = false">
			<div class="mask_content">
				<div class="hj_box">
				</div>
				<div style="font-size: 18px;text-align: center;">您的问题暂时没有解答
					目前正火速解答中
					请耐心等待</div>
			</div>
		</div>
	</div>
</template>

<script>
	import navBar from '@/components/navBar/navBar'
	import tableList from '@/components/tableList/tableList'
	import turnPages from '@/components/turnPages/turnPages'
	import {
		EventBus
	} from '@/eventBus/eventBus'
	import {
		// getQuestionList,
		getFeedbackList
	} from '@/utils/api.js'
	export default {
		components: {
			tableList,
			turnPages,
			// navBar
		},
		data() {
			return {
				selectList: [],
				maskShow: false,
				clasType: 0,
				titleList: ['问题描述', '状态', '时间', '操作'],
				content: [],
				count: 1,
				pages: 1,
				limit: 11,
				cate_id: '',
				question: '',
				question1: '',
				navList: [{
						path: '/classList',
						name: '首页',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 0
					},
					{
						path: '/userInfo',
						name: '个人中心',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 5
					},
					{
						path: '/mySet',
						name: '反馈',
						studyCurrent: 0,
						tabbarChildCurrent: 4,
						tabbarCurrent: 5
					},
					{
						path: '/myFeedback',
						name: '我的反馈',
						studyCurrent: 0,
						tabbarChildCurrent: 4,
						tabbarCurrent: 5
					}
				],
			}
		},
		created() {
			this.getType()
			EventBus.$emit('navList', this.navList)
		},
		methods: {
			async getType() {
				await getFeedbackList().then(res => {
					this.content = res.data.list

				})
			},
			maskClick(count, id) {
				if (count == 0) {
					this.maskShow = true
				} else {
					this.$router.push('/FeedbackDetail?id=' + id)
				}
			},
			quizClick() {
				this.$router.push('/quiz')
			},
			searchBtn() {
				if (this.question1 == this.question) {
					if (this.question == '') {
						this.$message.warning('请输入搜索关键词')
					} else {
						this.$message.warning('请不要重复搜索同一个关键词')
					}

					return
				}
				this.getData('sousuo')
			},
			selectClick(e) {
				this.cate_id = e
				this.getData()
			},
			fanyeClick(e) {
				if (e == 'next') {
					this.pages++
					console.log(this.pages)
					if (this.pages <= this.count) {
						this.getData()
					} else {
						this.pages = this.count
					}
				} else {
					this.pages--
					if (this.pages >= 1) {
						this.getData()
					} else {
						this.pages = 1
					}
				}
			},
			inputPage(e) {
				this.pages = e * 1
				this.getData()
			}
		}

	}
</script>
<style scoped="scoped">
	.mask {
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, .5);
		z-index: 999;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.mask_content {
		width: 265px;
		height: 190px;
		background: #fff;
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		padding: 0 35px;
		padding-top: 65px;
	}
	.table_content{
		padding-bottom: 60px;
	}
	.hj_box {
		width: 130px;
		height: 130px;
		top: -65px;
		border-radius: 50%;
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-position: -23px -10px;
		padding-top: 65px;
	}

	.hj_box img {
		width: 150px;
		height: 150px;
	}

	.content {
		background: #fff;
		height: 840px;
		position: relative;
	}

	.pages_box {
		position: absolute;
		bottom: 20px;
		right: 42px;
	}

	.boxs {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.boxs .small {
		font-size: 18px;
		color: rgb(255 104 75);
		display: flex;
		align-items: center;
	}

	.small img {
		width: 17px;
		height: 17px;
		margin-right: 7px;
	}


	.table_title,
	.table_list {
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.table_title {
		background: #F7F7F7;
	}

	.table_list {
		/* padding: 10px 0 !important; */
		height: 65px;
		padding-left: 15px;
	}

	.table_list:hover {
		background: rgba(71, 190, 94, .3);
		border-radius: 5px;
		/* color: #FFF; */
	}

	.table_title div {
		width: 17%;
		display: flex;
		font-size: 16px;
		font-weight: bold;
		color: #333;
		align-items: center;
		justify-content: center;
	}

	.table_title div:first-child,
	.table_list div:first-child {
		width: 49%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.table_list div:first-child {
		font-weight: bold;
	}

	.table_list div {
		width: 17%;
		display: flex;
		color: #333;
		align-items: center;
		justify-content: center;
	}

	.table_list div:first-child {
		justify-content: flex-start;
	}

	.search_btn {
		background: #5694F7;
		margin-left: 20px;
	}

	.tabbtn {
		width: 77px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer
	}

	.tabbtn span {
		width: 77px;
		height: 30px;
		display: block;
		background: #5694F7;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 5px;
	}
</style>
