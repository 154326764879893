<template>
	<div>
		<div class="aatitle">
			课后巩固课程
			<div style="display: flex;align-items: center;justify-content: space-between;">
				<el-input v-model="search_content" placeholder="输入搜索关键词" style="margin-right: 10px;"></el-input>
				<el-button @click="searchClick" class="search_btn">搜索</i></el-button>
				<el-button @click="clearClick" class="clear_btn">清空</i></el-button>
			</div>
		</div>
		<div class="table_box">
			<div class="table_title">
				<div v-for="(item,index) in tabTitle" :key="index">{{item}}</div>
			</div>
			<div class="table_content" :class="typeList ?'':'nothing'">
				<img src="@/assets/images/nothing.png" v-if="!typeList">
				<div class="table_list" v-for="(item,index) in typeList" :key="index" v-else
					@click="listJump(item.id,item.name)" :title="item.name">
					<div class="list_title"><span>{{index + 1 + pages*limit -limit}}</span>
						{{item.name}}
					</div>
					<!-- <div>{{item.count_time + '分钟'}}</div>
					<div>{{item.watch_time + '分钟'}}</div>
					<div>{{item.type}}</div> -->
					<!-- <div>{{item.progress}}/100</div> -->
					<div class="price" style="width: 24% !important;">￥{{item.price}}</div>
					<div style="width: 12% !important;"><span>立即学习</span></div>
					<div style="width: 12% !important;">
						<span v-if="item.handout.length>0" @click.stop="downBtn(item.handout)">下载课件</span>
					</div>
				</div>
			</div>
		</div>

		<el-dialog title="提示" :visible.sync="dialogVisible" width="50%">
			<div v-for="(item,index) in fileList" :key="index" class="file_list">
				<div style="font-size: 14px;">{{item.name}}</div>
				<div class="down" style="font-size: 14px;" @click="dowbLoadFile(item)"><img
						src="@/assets/downloadIcon.png">下载课件</div>
			</div>
		</el-dialog>
		<turnPages class="pages_box" :count="count" :pages="pages" @fanye="fanyeClick" @inputPage="inputPage">
		</turnPages>
	</div>
</template>

<script>
	import navBar from '@/components/navBar/navBar'
	import tableList from '@/components/tableList/tableList'
	import turnPages from '@/components/turnPages/turnPages'
	import {
		EventBus
	} from '@/eventBus/eventBus'
	import {
		getZsCourse
	} from '@/utils/api.js'
	export default {
		components: {
			tableList,
			turnPages,
			navBar
		},
		data() {
			return {
				clasType: '',
				ClassName: '',
				navList: [{
						path: '/classList',
						name: '首页',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 0
					},
					{
						path: '/systemClass',
						name: '课后专属服务',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 1
					},
					{
						path: '/systemClass',
						name: '课后巩固课程',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 1
					}
				],
				current: 0,
				tabTitle: ['课程名称','课程价格', '操作'], // '总时长', '已观看时长', '课程类型', '学习进度',
				typeList: [],
				pages: 1,
				count: 1,
				
				dialogVisible: false,
				limit: 9,
				type: 1, //基础：type=1 提升：type=2 冲刺：type=3
				name: '',
				fileList:[],
				search_content: '',
			}
		},
		created() {
			this.getData()
			EventBus.$emit('navList', this.navList)
		},
		methods: {
			searchClick() {
				if(this.search_content == this.issearch){
					return
				}
				this.issearch = this.search_content
				this.getData()
			},
			clearClick() {
				this.search_content = ''
				if(this.search_content == this.issearch){
					return
				}
				this.issearch = this.search_content
				this.getData()
			},
			async getData() {
				let promise = {
					type: this.type,
					limit: this.limit,
					page: this.pages,
					name: this.name,
					source: 1,
					search:this.search_content
				}
				await getZsCourse(promise).then(res => {
					this.typeList = res.data.data
					console.log(this.typeList)
					this.count = Math.ceil(res.data.tatol / this.limit)
				})
			},
			listJump(id, title) {
				this.navList.push({
					path: '/studyList',
					name: title,
					studyCurrent: 0,
					tabbarChildCurrent: 0,
					tabbarCurrent: 2,
				})
				this.$router.push('/studyList?id=' + id + '&titles=' + title + '&exclusive=yes' + '&navList=' + escape(JSON
					.stringify(this.navList)))
			},
			downBtn(e) {
				console.log(e)
				this.fileList = []
				this.dialogVisible =true
				let obj = {}
				if(typeof e == 'string'){
					obj.name = e
					obj.url = e
					this.fileList.push(obj)
				}else{
					this.fileList = e
				}
			},
			dowbLoadFile(e){
				console.log(e)
				window.open(e.url)
			},
			fanyeClick(e) {
				if (e == 'next') {
					this.pages++
					console.log(this.pages)
					if (this.pages <= this.count) {
						this.getData()
					} else {
						this.pages = this.count
					}
				} else {
					this.pages--
					if (this.pages >= 1) {
						this.getData()
					} else {
						this.pages = 1
					}
				}
			},
			inputPage(e) {
				this.pages = e * 1
				this.getData()
			}
		}

	}
</script>
<style scoped>
	
	.search_btn {
		background: #5694F7;
		color: #fff;
	}
	
	.clear_btn {
		background: #FF684B;
		color: #fff;
	}
	.file_list{
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #eee;
		padding:30px 0;
		
	}
	.file_list .down{
		
		display: flex;
		align-items: center;
		justify-content: flex-start;
		color: #47be5e;
		background: #E5FFEC;
		padding: 5px 10px;
		border-radius: 10px;
	}
	.down img{
		width: 20px;
		height: 20px;
	}
	.aatitle {
		margin-bottom: 15px;
		padding-left: 15px;
		border-left: 5px solid #47BE5E;
		font-size: 18px;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.list_title {
		font-weight: bold;
		font-size: 16px;
	}

	.list_title span {
		margin-right: 10px;
	}

	.content {
		width: 1132px;
		background: #fff;
		height: 780px;
		padding: 20px;
		position: relative;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
	}

	.table_content {
		padding-top: 15px;
	}

	.title {
		font-size: 14px;
		color: #7B92CB;
	}

	/* .study_list{

} */
	.pages_box {
		position: absolute;
		bottom: 20px;
		right: 20px;
	}

	.tab_top {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.tab_top div {
		width: 125px;
		height: 35px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
	}

	.tab_top .active {
		background: #47BE5E;
		border-radius: 18px;
		color: #fff;
	}

	.table_box {
		/* margin-top: 30px; */
		padding-bottom: 50px;
	}

	.table_title,
	.table_list {
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.price{
		color: #5694F7 !important;
		font-weight: bold;
		font-size: 16px;
	}
	.table_title {
		background: #F7F7F7;
	}

	.table_list {
		/* padding: 10px 0 !important; */
		height: 70px;
		padding-left: 15px;
	}

	.table_list:hover {
		background: rgba(71, 190, 94, .3);
		border-radius: 10px;
		/* color: #FFF; */
	}

	.table_title div {
		width: 24%;
		display: flex;
		font-size: 16px;
		font-weight: bold;
		color: #333;
		align-items: center;
		justify-content: center;
	}

	.table_title div:first-child,
	.table_list div:first-child {
		flex: 1;
		white-space: nowrap;
		overflow: hidden;
		/* text-overflow属性表示规定当文本溢出包含元素时发生的事情;
		            ellipsis表示显示省略符号来代表被修剪的文本。*/
		text-overflow: ellipsis;
	}

	.table_list div {
		cursor: pointer;
		width: 17%;
		display: flex;
		color: #333;
		align-items: center;
		justify-content: center;
	}

	.table_list div:first-child {
		justify-content: flex-start;
	}

	.table_list div span {
		background: #5694F7;
		color: #fff;
		display: inline;
		padding: 5px 10px;
		border-radius: 3px;
	}
</style>
