import {
	postRequest,
	getRequest,
	putRequest,
	deleteRequest
} from "./http.js"
export default {
	//论坛列表
	getForumPostList: (data) => {
		return postRequest('forum/getForumPostList', data)
	},
	//论坛详情
	getForumPostDetail: (data) => {
		return postRequest('forum/getForumPostDetail', data)
	},
	//回复
	addForumComment: (data) => {
		return postRequest('forum/addForumComment', data)
	},
	//赞
	likes: (data) => {
		return postRequest('forum/likes', data)
	},
	//添加需求
	addForumPost:  (data) => {
		return postRequest('forum/addForumPost', data)
	},
}