<template>
	<div class="container" v-loading="loading">
		<!-- <userInfo></userInfo> -->
		<div class="content">
			<!-- <navBar :navList="navList">asd</navBar> -->
			<div class="left_title">正确率</div>
			<div class="statistics">
				<div class="garden">
					<el-progress type="circle" class="" :percentage="parseInt(chengji.correct)" color="#47BE5E"
						:show-text="false" :stroke-width="14" :width="136"></el-progress>
					<div class="texts" style="color:#47BE5E">
						{{chengji.correct}}%
					</div>
				</div>
				<div class="btns">
					<div class="error" @click="errorClick">错题解析</div>
					<!-- <div class="news" @click="restartClick">重新开始</div> -->
					<div class="whole" @click="analysisClick">全部解析</div>
				</div>
				<!-- style="" -->
				<div class="box_box">
					<div style="display: flex;align-items: center;justify-content: space-between">
						<div class="text_box">
							<img src="@/assets/1.png">
							<span class="tit">正确率：</span>
							<span class="bigs">{{chengji.correct + '%'}}</span>
						</div>
						<div class="text_box">
							<img src="@/assets/3.png">
							<span class="tit">最高分：</span>
							<span class="bigs">{{chengji.highestScore}}</span>
						</div>
						<div class="text_box">
							<img src="@/assets/4.png">
							<span class="tit">平均分：</span>
							<span class="bigs">{{chengji.average}}</span>
						</div>
					</div>
					<div class="bottom_box">
						<div class="text_box">
							<img src="@/assets/5.png">
							<span class="tit">超过学员：</span>
							<span class="bigs">{{chengji.exceed + '%'}}</span>
						</div>
						<div class="text_box">
							<img src="@/assets/2.png">
							<span class="tit">排名：</span>
							<span class="bigs">{{chengji.ranking}}</span>
						</div>
						<div class="text_box">
							<img src="@/assets/6.png">
							<span class="tit">你的分数：</span>
							<span class="bigs">{{chengji.score}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="card_box">
				<div class="card_title">
					<div class="name">答题卡</div>
					<div class="annotation">
						<div class="yes"><span style="color: #47BE5E;">正确</span></div>
						<div class="no"><span style="color: #FF684B ;">错误</span></div>
						<div class="gray"><span style="color: #666;">未答</span></div>
					</div>
				</div>
				<div class="card_content">
					<div class="card_list" :class="item.is_right == 1?'zq':item.is_right !== 1?'cw':''"
						v-for="(item,index) in answerResult" :key="index" @click="cardClick(index)">{{index+1}}</div>
					<div class="lj" v-for="index3 in 20" :key="'id'+index3"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import aAsnwer from '@/components/answer/index'
	import navBar from '@/components/navBar/navBar'
	import {
		getAnswerResult
	} from '@/utils/api.js'
	import {
		EventBus
	} from '@/eventBus/eventBus'
	export default {
		components: {
			navBar
		},
		data() {
			return {
				answerResult: [],
				navList: [],
				chengji: [],
				errowNum: '',
				result_id: '',
				loading: true,
			}
		},
		created() {
			this.parameter(this.$route.query.parameter)
			EventBus.$emit('navList', this.navList)

		},
		mounted() {

		},
		methods: {
			parameter(arr) {
				let opdata
				if (arr) {
					opdata = JSON.parse(decodeURIComponent(arr))
					this.navList = opdata.arr
					this.result_id = opdata.id
					let myPath = {
						name: opdata.title,
						path: this.$route.path,
						studyCurrent: opdata.arr[opdata.arr.length - 1].studyCurrent,
						tabbarChildCurrent: opdata.arr[opdata.arr.length - 1].tabbarChildCurrent,
						tabbarCurrent: opdata.arr[opdata.arr.length - 1].tabbarChildCurrent,
						type: ''
					}
					this.navList.push(myPath)
					localStorage.setItem('report', JSON.stringify(opdata))
				} else {
					opdata = JSON.parse(localStorage.getItem('report'))
					this.navList = opdata.arr
					this.result_id = opdata.id
				}
				this.getData()
			},
			async getData() {
				let result = {
					id: this.result_id
				}
				await getAnswerResult(result).then(res => {
					let das = res.data.resultData
					let arr = []
					for (let i of das) {
						i.answer.user_res = i.user_answer
						i.answer.genre_text = i.answer.genre_name
						if (i.is_right == 1) {
							i.answer.correct = 'yes'
						} else {
							i.answer.correct = 'no'
						}
						if (i.answer.genre == 1002) {
							i.answer.genreType = 4
						} else {
							let str = i.answer.genre.toString()
							i.answer.genreType = str.substr(str.length - 1, 1)

							if (i.answer.genreType == '6') {
								arr = i.answer.title.split('____')
								i.answer.titles = arr
							}
						}
					}
					this.answerResult = das
					this.chengji = res.data.score
					this.loading = false
				})
			},
			restartClick() {
				this.$router.go(-1)
			},
			cardClick(index) {
				// return
				let gt = []
				gt.push(this.answerResult[index].answer)
				let navarrs = {
					arr: this.navList,
					title: '单题解析',
				}
				this.$router.push('/Analysis?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(
					navarrs))));
				EventBus.$emit('pageRouter', {
					name: '错题解析',
					pages: '/Analysis?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(
						navarrs)))
				})
				localStorage.setItem('analysis', JSON.stringify(gt))
			},
			analysisClick() {
				let navarrs = {
					arr: this.navList,
					title: '全部解析',
				}
				this.$router.push('/Analysis?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(
					navarrs))));
				// this.$router.push('/Analysis')
				EventBus.$emit('pageRouter', {
					name: '全部解析',
					pages: '/Analysis?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(navarrs)))
				})
				let Analysis = []
				for (let i of this.answerResult) {
					Analysis.push(i.answer)
				}
				localStorage.setItem('analysis', JSON.stringify(Analysis))
			},
			errorClick() {

				let errorArr = this.answerResult.filter(item => {
					return item.is_right !== 1
				})
				if (errorArr.length == 0) {
					this.$alert('没有错题哦,你真厉害！', '提示', {
						confirmButtonText: '确定',
						callback: action => {
							this.$message({
								type: 'info',
								message: `action: ${ action }`
							});
						}
					});
				} else {
					let navarrs = {
						arr: this.navList,
						title: '错题解析',
					}
					this.$router.push('/Analysis?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(
						navarrs))));
					EventBus.$emit('pageRouter', {
						name: '错题解析',
						pages: '/Analysis?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(
							navarrs)))
					})
					let Analysis = []
					for (let i of errorArr) {
						Analysis.push(i.answer)
					}
					localStorage.setItem('analysis', JSON.stringify(Analysis))
				}
			}
		}
	}
</script>

<style scoped>
	.container {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.box_box{
		width: 600px;
	}
	.bottom_box {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 35px;
	}

	.content {
		background: #fff;
		min-height: 810px;
		width: 1132px;
		margin-top: 20px;
		border-radius: 10px;
		padding: 20px 25px;
		position: relative;
		box-shadow: 0px 0px 6px 0px rgba(80, 93, 223, 0.18);
		margin: 0 auto;
	}

	.statistics {
		margin-top: 30px;
		display: flex;
		justify-content: space-around;
		align-items: center;
		box-shadow: 0px 0px 6px 0px rgba(80, 93, 223, 0.18);
		padding: 40px 0;
		border-radius: 10px;
	}

	.text_box {
		width: 200px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.text_box img {
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}

	.text_box .tit {
		font-size: 16px;
		color: #666666;
	}

	.text_box .bigs {
		font-size: 22px;
		font-weight: bold;
		color: #666;
	}

	.left_title {
		border-left: 5px solid #47BE5E;
		padding-left: 8px;
		font-size: 20px;
	}

	.left_garden {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.garden {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
	}

	.garden .texts {
		font-size: 32px;
		font-weight: bold;
		color: #02D19D;
		position: absolute;

	}

	.garden_text {
		font-size: 18px;
		color: #02D19D;
		margin-top: 5px;
	}

	.left_btns {
		display: flex;
		justify-content: space-around;
		align-items: center;
		margin-top: 30px;
		cursor: pointer;
	}

	.error {
		border: 1px solid #FF684B;
		font-size: 16px;
		padding: 10px 20px;
		border-radius: 30px;
		cursor: pointer;
		color: #FF684B;
	}

	.news {
		border: 1px solid #505DDF;
		font-size: 14px;
		padding: 5px 15px;
		border-radius: 10px;
		cursor: pointer;
		color: #505DDF;
	}

	.whole {
		font-size: 16px;
		padding: 10px 20px;
		border-radius: 30px;
		color: #fff;
		background: #47BE5E;
		margin-top: 17px;
	}

	.right_box div {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-top: 16px;
	}

	.right_box div span {
		font-size: 16px;
		color: #161616;
		margin-left: 9px;
	}

	.right_box div img {
		width: 24px;
		height: 24px;
	}

	.right_box div .tit {
		width: 70px;
		display: inline-block;
		text-align-last: justify;
		text-align: justify;

	}

	.right_box {
		/* display: flex;
		justify-content: space-between;
		flex-flow: column;
		flex-wrap: wrap; */
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.next_btn {
		display: flex;
		justify-content: space-between !important;
		align-items: center;
	}

	.btnss {
		width: 85px;
		height: 30px;
		justify-content: center !important;
		align-items: center !important;
		color: #fff;
		background: #47BE5E;
		border-radius: 10px;
	}

	.card_box {
		margin-top: 20px;
		padding: 20px 30px;
		background: #fff;
		box-shadow: 0px 0px 6px 0px rgba(80, 93, 223, 0.18);
		border-radius: 10px;
	}

	.card_title {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.card_title .name {
		border-left: 5px solid #47BE5E;
		padding-left: 8px;
		font-size: 20px;
	}

	.card_title .annotation {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.card_title .annotation div {
		width: 50px;
		height: 30px;
		font-size: 14px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 4px;
		margin-left: 16px;
	}

	.annotation .yes {
		background: #E5FFEC;
		border: 1px solid #7CEA91;
	}

	.annotation .no {
		background: #FFF0ED;
		border: 1px solid #FFBAAD;
	}

	.annotation .gray {
		background: #F4F4F5;
		border: 1px solid #CDCED0;
	}

	.yuan {
		display: block;
		width: 6px;
		height: 6px;
		border-radius: 3px;
		background: #666666;
		margin: 5px;
	}

	.card_content {
		margin-top: 30px;
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.card_list {
		width: 40px;
		height: 40px;
		background: #f2f2f2;
		border-radius: 4px;
		display: flex;
		cursor: pointer;
		font-size: 16px;
		align-items: center;
		justify-content: center;
		color: #666666;
		font-weight: bold;
		margin: 8px;
		border: 1px solid #DADADB;
	}

	.lj {
		width: 40px;
		margin: 8px;
	}

	.zq {
		background: #5694F7;
		color: #5694F7;
		border: 1px solid #5694F7;
		background: #F8FBFF;
	}

	.cw {
		background: #FF684B;
		color: #FE7F67;
		border: 1px solid #FFBAAD;
		background: #FFF0ED;
	}
</style>
