<template>
	<div class="wraps">
		<div></div>
		<div class="page_box">
			<div class="page_btn" @click="fanyeClick('prev')" :style="pages == 1 ? 'background:#ccc':''">上一页</div>
			第<span class="pages">{{pages || 1}}</span>页
			<div class="page_btn" @click="fanyeClick('next')" :style="pages == count || !count ? 'background:#ccc':''">
				下一页</div>
			共<span class="pages">{{count || 1}}</span>页
			<span class="texts">跳转到第</span>
			<input class="inputs" v-model="inputPage" type="number" style="margin-right: 5px;color: #5694F7;">页
			<span class="go" @click="pagesJump">Go</span>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			pages: {
				type: Number,
				defult: 0,
			},
			count: {
				type: Number,
				defult: 0
			}
		},
		data() {
			return {
				inputPage: '',
				inputTime: 0,
			}
		},
		methods: {
			fanyeClick(type) {
				if (this.count) {
					this.$emit('fanye', type)
					this.inputPage = ''
				} else {
					this.count == 1
				}

			},
			pagesJump() {
				if(this.inputPage == ''){
					return
				}
				if (parseInt(this.inputPage) > this.count) {
					this.inputPage = this.count
				} else if (parseInt(this.inputPage) < 1) {

					this.inputPage = 1

				}
				if (this.count > 1) {
					this.$emit('inputPage', parseInt(this.inputPage))
				}
				this.inputPage = null
			}
		}
	}
</script>

<style scoped>
	.go {
		font-weight: bold;
		font-size: 14px;
		color: #5694F7;
		padding: 0 5px;
		cursor: pointer
	}

	.pages {
		font-weight: bold;
		font-size: 16px;
		color: #5694F7;
		padding: 0 5px;
	}

	.wraps {
		display: flex;
		justify-content: space-between;
		font-size: 14px;
	}

	.page_box {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.page_btn {
		width: 70px;
		height: 36px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #5694F7;
		color: #fff;
		border-radius: 4px;
		font-size: 14px;
		margin: 10px;
		cursor: pointer;
	}

	/* 谷歌 */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		appearance: none;
		margin: 0;
	}

	/* 火狐 */
	input {
		-moz-appearance: textfield;
	}

	.inputs {
		width: 40px;
		height: 30px;
		background: #FCFCFC;
		border: 1px solid #ccc;
		border-radius: 4px;
		margin: 0 6.25px;
		text-align: center;
	}

	input:focus {
		/* background: #47BE5E; */
		color: blue;
		border: 1px solid #5694F7;
	}

	.texts {
		font-size: 14px;
		font-weight: 400;
		color: #666666;
		margin-left: 10px;
	}

	.texts:last-child {
		margin-left: 0;
	}
</style>
