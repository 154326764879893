import axios from "axios";
import { Message } from "element-ui";
import router from "../router/index";
import domMessage from './messageOnce'
//请求拦截器
const messageOnce = new domMessage()
let instance = axios.create({
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
  },
  changeOrigin: true,
});

axios.interceptors.request.use(
  (config) => {
    //如果存在token，请求携带token
    if (window.localStorage.getItem("UserToken")) {
      config.headers["verify-token"] = window.localStorage.getItem("UserToken");
    }
    return config;
  },
  (error) => {
    Message.error({
      message: error,
      showClose: true,
    });
  }
);

//api接口 响应成功 / 响应失败 【响应拦截器】
//后端提供：响应码，响应信息，对象
axios.interceptors.response.use(
  (res) => {
    // console.log('res',res)
    if (res && res.status == 200) {
      // console.log('data返回值：',res.data)
      if (res.data.code == 200) {
        message: res.data.msg;
        return res.data;
      } else {
        if (res.data.code == 20001) {
          messageOnce.error({
            message: res.data.msg,
            type: 'error'
          })
          return;
        }
        if (
          res.data.code == 2001 ||
          res.data.code == 1002 ||
          res.data.code == 24001
        ) {
          console.log('789789')
          messageOnce.error({
            message: res.data.msg,
            type: 'error'
          })
          router.push("/Login");
          window.localStorage.removeItem("UserToken");
          return;
        } else if (res.data.code == 2004) {
          router.push("/Login");
          window.localStorage.removeItem("UserToken");
          messageOnce.error({
            message: '此账号可能已在其他设备上登陆',
            type: 'error'
          })
        }else if (res.data.code !== 400) {
          Message.error({
            message: res.data.msg,
            showClose: true,
          });
          return;
        } else if (res.data.code == 400) {
          if (res.data.msg == "暂无试题") {
          } else if (res.data.msg == "该小节下暂无知识点") {
          } else {
            console.log(res)
            Message.error({
              message: res.data.msg,
              showClose: true,
            });
            return;
          }
        }
      }
    }else{
      messageOnce.error({
        message: res.data.msg,
        type: 'error'
      })
      router.push("/Login");
    }
  },
  (error) => {
    console.log(error);
    if (error.response.code == 504 || error.response.code == 404) {
      Message.error({
        message: "服务器被吃了( ╯□╰ )",
        showClose: true,
      });
    } else if (error.response.code == 403) {
      Message.error({
        message: "权限不足，请联系管理员",
        showClose: true,
      });
    } else if (error.response.code == 401) {
      Message.error({
        message: "请登录账号",
        showClose: true,
      });
      router.replace("/");
    } else {
      if (error.response.message) {
        Message({
          message: error.response.message,
        });
      } else {
        messageOnce.info({
          message: "该错误触及盲区(●ˇ∀ˇ●)",
          type: 'info'
        })
      }
    }
  }
);

let baseUrl = "";
console.log(process.env.NODE_ENV);
if (process.env.NODE_ENV === "development") {
  // alert("开发环境");
  // baseUrl = 'http://jiekou_dev.sz517.com/join/';
  baseUrl = "https://erpapi.sz517.com/";
} else {
  // alert("生产环境");
  baseUrl = "https://erpapi.sz517.com/";
}
//封装请求
//post

export const postRequest = (url, params, base) => {
  // console.log('参数', params) //参数
  if (base) {
    return axios({
      method: "post",
      url: base + url,
      data: params,
    });
  } else {
    return axios({
      method: "post",
      url: baseUrl + url,
      data: params,
    });
  }
};

//put
export const putRequest = (url, params) => {
  return axios({
    method: "put",
    url: baseUrl + url,
    data: params,
  });
};

//get
export const getRequest = (bas, url, params) => {
  return axios({
    method: "get",
    url: baseUrl + url,
    data: params,
  });
};

//del
export const deleteRequest = (url, params) => {
  return axios({
    method: "delete",
    url: baseUrl + url,
    data: params,
  });
};
