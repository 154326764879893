import { postRequest, getRequest, putRequest, deleteRequest } from "./http.js";
//登录
export const Login = (data) => {
  //登录
  return postRequest("login/login", data);
};
export const loginMobile = (data) => {
  //验证码登录
  return postRequest("/study/login/loginMobile", data);
};
//首页
export const getIndex = (data) => {
  //获取个人信息
  return postRequest("join/user/index", data);
};
//个人中心
export const getUserInfo = (data) => {
  //获取个人信息
  return postRequest("user/getUserInfo", data);
};
export const getMyVideo = (data) => {
  //视频观看记录
  return postRequest("user/getMyVideo", data);
};
export const getMyMock = (data) => {
  //我的报考信息
  return postRequest("user/getMyMock", data);
};
export const getUserClass = (data) => {
  //用户课程
  return postRequest("ucl/list", data);
};
export const getMyStudy = (data) => {
  //用户课程
  return postRequest("user/getMyStudy", data);
};
export const updatePic = (data) => {
  //设置头像
  return postRequest("setup/updateImg", data);
};
export const updateNickname = (data) => {
  //修改昵称
  return postRequest("setup/updateNickname", data);
};
export const imageUpload = (data) => {
  //图片上传
  return postRequest("public/imageUpload", data);
};
export const addFeedback = (data) => {
  //添加反馈
  return postRequest("user/addFeedback", data);
};
export const getWrongList = (data) => {
  //错题巩固
  return postRequest("user/getWrongList", data);
};
export const getWrongExam = (data) => {
  //错题题目
  return postRequest("user/getWrongExam", data);
};
export const submitWrong = (data) => {
  //错题提交
  return postRequest("user/submitWrong", data);
};
export const getLinianWrong = (data) => {
  //错题提交
  return postRequest("user/getLinianWrong", data);
};
export const getLinianInfo = (data) => {
  //真题题目
  return postRequest("user/getLinianInfo", data);
};
export const getWrongQuestion = (data) => {
  //lt错题巩固答题列表
  return postRequest("user/getWrongQuestion", data);
};
export const getwrongRecall = (data) => {
  //lt错题巩固答题
  return postRequest("user/wrongRecall", data);
};
export const submitLinianWrong = (data) => {
  //真题提交
  return postRequest("user/submitLinianWrong", data);
};
export const examSubmit = (data) => {
  //刷题提交
  return postRequest("study/course/exam/submit", data);
};
export const answerSubmit = (data) => {
  //真题，模考答题提交提交
  return postRequest("study/answer/submit", data);
};
export const ansmockResult = (data) => {
  //模考查看结果
  return postRequest("study/mock/result", data);
};
export const anslilianResult = (data) => {
  //历年真题查看结果
  return postRequest("study/lilian/result", data);
};
export const videoExamresult = (data) => {
  //刷题查看结果
  return postRequest("study/course/video/exam/result", data);
};
export const examCorrection = (data) => {
  //纠错
  return postRequest("study/course/exam/correction", data);
};
//答疑
export const getQuestionList = (data) => {
  //答疑列表
  return postRequest("join_answer/getQuestionList", data);
  // return postRequest("answer/list", data,"https://jiekou.sz517.com/shenzhijiaoyu/");
};
export const getQuestionDetail = (data) => {
  //答疑详情
  return postRequest("join_answer/getQuestionDetail", data);
};
export const addQuestionAnswer = (data) => {
  //提交答疑
  return postRequest("join_answer/addQuestionAnswer", data);
};
export const getExamineSub = (data) => {
  //学科分类
  return postRequest(
    // "answer/getExamineSub",
    "join_answer/getMainCate",// 获取顶级分类
    data,
    // "https://jiekou.sz517.com/com/"
  );
};
export const newgetViceCate = (data) => {
  //学科分类
  return postRequest(
    // "answer/getExamineSub",
    "join_answer/newgetViceCate",//获取科目分类
    data,
    // "https://jiekou.sz517.com/com/"
  );
};
export const newGetPieceData = (data) => {
  //学科分类
  return postRequest(
    // "answer/getExamineSub",
    "join_answer/newGetPieceData",// 获取能力分类
    data,
    // "https://jiekou.sz517.com/com/"
  );
};
export const setAnswer = (data) => {
  //评分
  return postRequest(
    // "answer/getExamineSub",
    "join_answer/setAnswer",
    data,
  );
};

export const urgeTeacherAnswer = (data) => {
  //催促
  return postRequest("join_answer/notify_some_one", data);
  // return postRequest("join_answer/notify_some_one", data);
};

//考试信息
export const getCalendar = (data) => {
  //报考日历
  return postRequest("user/calendar", data);
};
export const getexamSign = (data) => {
  //标记报考日历
  return postRequest("user/examSign", data);
};
export const getSignList = (data) => {
  //标记的日历列表
  return postRequest("user/signList", data);
};
export const getcalendarList = (data) => {
  //学习计划列表
  return postRequest("study/calendar/list", data);
};
export const getcalendarInfo = (data) => {
  //学习计划详情
  return postRequest("study/calendar/info", data);
};
export const getcalendarComplete = (data) => {
  //完成学习计划
  return postRequest("study/calendar/complete", data);
};
export const getcalendarResume = (data) => {
  //恢复学习计划
  return postRequest("study/calendar/resume", data);
};
export const getcalendarTags = (data) => {
  //标签列表
  return postRequest("study/calendar/tags", data);
};
// 真题列表
export const getueionsList = (data) => {
  //历年真题列表
  return postRequest("study/lilian/list", data);
};
export const getanswerList = (data) => {
  //历年真题答题列表
  return postRequest("study/lilian/answer", data);
};
//模考大赛

export const getMockList = (data) => {
  //模考列表
  return postRequest("mock/getMockList", data);
};
export const getMockSearch = (data) => {
  //模考搜索
  return postRequest("mock/getMockList", data);
};
export const submitUserAnswerResult = (data) => {
  //模考答题提交
  return postRequest("mock/submitUserAnswerResult", data);
};
export const getMockQuesList = (data) => {
  //获取模考试题
  return postRequest("mock/getMockQuesList", data);
};
export const getMockPost = (data) => {
  //获取模考岗位
  return postRequest("mock/getMockPost", data);
};
export const addMockPost = (data) => {
  //模考提交报名
  return postRequest("study/mock/add", data);
};
export const getMockDetail = (data) => {
  //模考详情
  return postRequest("mock/getMockDetail", data);
};
//
export const getcalendarAdd = (data) => {
  //模考详情
  return postRequest("study/calendar/add", data);
};
//在线学习
export const getUserCourse = (data) => {
  //获取课程列表
  return postRequest("login/getUserCourse", data);
};
export const getCourseDetail = (data) => {
  //获取篇章节
  return postRequest("login/getCourseDetail", data);
};
export const getCourseDetail1 = (data) => {
  //获取篇章节
  return postRequest("login/getCourseDetail1", data);
};
export const getCourseSubVideo = (data) => {
  //获取视频
  return postRequest("login/getCourseSubVideo", data);
};
export const getCourseSubVideoLog = (data) => {
  //添加视频记录
  return postRequest("login/getCourseSubVideoLog", data);
};
export const getCourseSubQuesList = (data) => {
  //获取试题列表
  return postRequest("study/course/exam/shua", data);
};
export const submitAnswerWrongList = (data) => {
  //提交小节错题
  return postRequest("login/submitAnswerWrongList", data);
};
export const getAnswerWrongList = (data) => {
  //获取小节错题列表
  return postRequest("login/getAnswerWrongList", data);
};
export const submitAnswerPlay = (data) => {
  //练习提交答题记录
  return postRequest("login/submitAnswerPlay", data);
};
export const getCourseSubKnowledge = (data) => {
  //知识点回顾
  return postRequest("login/getCourseSubKnowledge", data);
};
export const addKnowledgeCollect = (data) => {
  //知识点回顾收藏
  return postRequest("login/addKnowledgeCollect ", data);
};
export const getKnowledgeCollect = (data) => {
  //知识点回顾收藏列表
  return postRequest("login/getKnowledgeCollect ", data);
};
export const getCollectKnowledge = (data) => {
  //知识点回顾收藏巩固
  return postRequest("login/getCollectKnowledge ", data);
};
export const submitKnowledgePlay = (data) => {
  //知识点完成提交
  return postRequest("login/submitKnowledgePlay", data);
};
export const collectVideo = (data) => {
  //添加视频收藏
  return postRequest("login/collectVideo", data);
};
export const collectVideoList = (data) => {
  //视频收藏列表
  return postRequest("login/collectVideoList", data);
};
export const getTestPaperQues = (data) => {
  //获取真题列表
  return postRequest("login/getTestPaperQues", data);
};
export const getTestPaperQuesList = (data) => {
  //获取真题做题
  return postRequest("study/course/exam/question", data);
};
export const userAnswerResult = (data) => {
  //真题提交
  return postRequest("mock/userAnswerResult", data);
};
export const getStadyList = (data) => {
  //学习记录
  return postRequest("login/getStadyList", data);
};
export const getAnswerResult = (data) => {
  //查看报告
  return postRequest("login/getAnswerResult", data);
};
export const getFeedbackList = (data) => {
  //我的反馈
  return postRequest("user/getFeedbackList", data);
};
export const getFeedbackDetail = (data) => {
  //反馈详情
  return postRequest("user/getFeedbackDetail", data);
};
export const getNoteAdd = (data) => {
  //添加学习笔记
  return postRequest("study/course/note/add", data);
};
export const getNoteList = (data) => {
  //学习笔记列表
  return postRequest("study/course/note/list", data);
};
export const getNoteEdit = (data) => {
  //修改学习笔记
  return postRequest("study/course/note/edit", data);
};
export const getNoteDel = (data) => {
  //删除学习笔记
  return postRequest("study/course/note/del", data);
};
// 课后专属服务
export const currentAffairs = (data) => {
  //时事政治
  return postRequest("mock/currentAffairs", data);
};
export const currentAffairsPost = (data) => {
  //时事政治提交
  return postRequest("mock/currentAffairsPost", data);
};
export const getNewTime = (data) => {
  //知识点补充
  return postRequest("login/getNewTime", data);
};
export const getZsCourse = (data) => {
  //系统精讲课程
  return postRequest("login/getZsCourse", data);
};
export const logout = (data) => {
  //退出登录
  return postRequest("login/logout", data);
};
// export const loginMobile = (data) => {
//   //验证码登陆
//   return postRequest("login/loginMobile", data);
// };
export const getMobileCode = (data) => {
  //验证码登陆
  return postRequest("login/getMobileCode", data);
};
export const getZbCourse = (data) => {
  //直播列表
  return postRequest("login/getZbCourse", data);
};
export const getCouresing = (data) => {
  //正在直播
  return postRequest("login/getLiving", data);
};
export const getCoursePiece = (data) => {
  //课程列表
  return postRequest("login/getCoursePiece", data);
};
export const getCourseChapterSub = (data) => {
  //课程列表
  return postRequest("login/getCourseChapterSub", data);
};

export const getLiveInfo = (data) => {
  //课程列表
  return postRequest("login/getLiveInfo", data);
};

export const studyTabs = (data) => {
  //获取tabs列表
  return postRequest("study/tabs", data);
};
export const jobList = (data) => {
  //获取job列表
  return postRequest("study/job/list", data);
};
export const courseList = (data) => {
  //获取tabs列表
  return postRequest("study/course/list", data);
};

export const handoutList = (data) => {
  //获取tabs列表
  return postRequest("study/course/handout/list", data);
};

//课程学习
export const courseStudy = (data) => {
  //获取tabs列表
  return postRequest("study/course/study", data);
};
export const watchHistory = (data) => {
  //课程学习—上次观看时间点
  return postRequest("study/course/video/watch/history", data);
};

export const logTime = (data) => {
  //课程学习—设置观看时间点
  return postRequest("study/course/video/log/time", data);
};

export const logComplete = (data) => {
  //课程学习—学习完成
  return postRequest("study/course/video/log/complete", data);
};

export const studyCollect = (data) => {
  //添加视频收藏
  return postRequest("study/collect", data);
};

//个人中心
export const collectList = (data) => {
  //收藏列表
  return postRequest("study/collect/list", data);
};
export const userHeader = (data) => {
  //我的班主任
  return postRequest("study/user/header", data);
};

export const handoutList2 = (data) => {
  //我的收藏
  return postRequest("study/handout/list", data);
};

export const handoutAdd = (data) => {
  //我的收藏 添加记录
  return postRequest("study/handout/add", data);
};

export const getWrongData = (data) => {
  //我的错题巩固
  return postRequest("user/getWrongData", data);
};

export const reportList = (data) => {
  //学习报告
  return postRequest("study/report/list", data);
};
export const videoUnlock = (data) => {
  //视频解锁
  return postRequest("study/course/video/unlock", data);
};
export const getBannerList = (data) => {
  //banner
  return postRequest("study/study/getBannerList", data);
};
export const getOnlineSuccessTrainingClass = (data) => {
  //学习系统首页列表
  return postRequest("study/study/getOnlineSuccessTrainingClass", data);
};
export const getCateTreeList = (data) => {
  //学习系统分类
  return postRequest("study/study/getCateTreeList", data);
};
export const getOnlineSuccessTrainingClassDetail = (data) => {
  return postRequest("study/study/getOnlineSuccessTrainingClassDetail", data);
};

export const getCheckpointSection = (data) => {
  return postRequest("study/study/getCheckpointSection", data);
};
export const getSubjectContent = (data) => {
  return postRequest("study/study/getSubjectContent", data);
};

