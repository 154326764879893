<template>
	<div class="container">
        <div style="margin-top: 15px;" class="search_dd">
            <el-input placeholder="请输入内容" v-model="input3" class="input-with-select">
                <el-select v-model="select" slot="prepend" placeholder="请选择" style="width:100px;">
                    <el-option label="特训" value="1"></el-option>
                    <el-option label="专题" value="2"></el-option>
                </el-select>
                <el-button @click="searchStuWrongQuestion" slot="append" class="search_el" icon="el-icon-search"></el-button>
            </el-input>
            </div>
            <div class="wrong_list">
                <div class="wrong_i" v-for="(item,index) in wrongList" :key="index" @click="gojiex(item,index)">
                   <div class="wrong_icon">{{item.genre_name}}</div>
                   <div class="wrong_title">{{item.title}}</div>
                </div>
            </div>
		<!-- <div class="search_dd">
            
            <div class="search_el" @click="searchChange"><i class="el-icon-search" style="color:#ffffff;font-size:24px"></i></div>
        </div> -->
        <div class="anslist" v-if="wrongCount>0">
            <el-pagination
                background
                layout="prev, pager, next"
                :total="wrongCount"
                @current-change="serpage">
            </el-pagination>
        </div>
	</div>
</template>

<script>
	import {getIndex,searchStuWrongQuestion} from '@/utils/erpapi.js'
	export default {
		data() {
			return {
                value:"",
                input3: '',
                select: '1',
                wrongList:[],
                wrongCount:0,
                page:1
			}
		},
		created() {
		},
		methods: {
            gojiex(data,id){
                console.log(id)
			let sussArr =[]
            sussArr.push(data)
			localStorage.setItem('analysis', JSON.stringify(sussArr))
			let navarrs = {
				arr: '',
				title:'',
				kindex:0
			}
			const routeData = this.$router.resolve({
				path: '/Analysis?parameter',
				query: {
					parameter: encodeURIComponent(encodeURIComponent(JSON.stringify(
						navarrs))),
						kindex:0,
						subjectType:this.select,
                        content_id:data.id,
                        type:this.select
				}
			});
			window.open(routeData.href, '_blank');

			EventBus.$emit('pageRouter', {
				name: '错题解析',
				pages: '/Analysis?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(
					navarrs))),
					subjectType:this.select,
                    kindex:0,
			})
            },
            serpage(val){
                this.page=val
             this.searchStuWrongQuestion()
            },
			searchStuWrongQuestion(){
                searchStuWrongQuestion({
                    name:this.input3,
                    source:this.select,
                    page:this.page,
                    limit:15
                }).then(res=>{
                    let list=res.data.list
                    let listData=[]
                    for (let i in list) {
                        for (let j in list[i]) {
                            list[i][j].content_id=Object.keys(list)[0]
                            listData.push(list[i][j])
                        }
                    }
                    console.log(listData)
                     this.wrongCount=res.data.count
                    this.wrongList=listData
                })
            }
		}
	}
</script>

<style scoped lang="scss">
/deep/.el-input__inner{
    background:#ffffff00;
}
.wrong_list{
    width: 1200px;
    margin: 0 auto;
    // background: #FFFFFF;
    // box-shadow: 0px 0px 10px 0px #E8EDF3;
    border-radius: 6px;
    padding: 0 30px;
    .wrong_i{
        position: relative;
        display: flex;
        background: #FFFFFF;
        margin-bottom: 20px;
        box-shadow: 0px 0px 10px 0px #e6e7e8;
        padding:30px 20px;
        border-radius: 6px;
        .wrong_icon{
            position: absolute;
            top: 0;
            left: 0;
            padding: 2px 10px;
            background: #5694f738;
            border-radius: 2px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #308DFF;
            margin-right: 6px;
            border-bottom-right-radius: 6px;
            border-top-left-radius: 6px;
        }
        .wrong_title{
            width: 1090px;
            line-height: 25px;
            font-size: 16px;
        }
    }
}
/deep/.el-input-group__append, .el-input-group__prepend {
    border: none;
}
.anslist{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
 .search_dd{
    width: 680px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    margin: 40px 260px;
    border: 2px solid #5694f7;
    border-radius: 4px;
    input{
     width: 530px;
     height: 40px;
     border: none;
     padding-left: 10px;
    }
    .search_el{
        width: 140px;
        height: 43px;
        background: #5694f7;
        border-radius: 0px 4px 4px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        color: rgb(255, 255, 255);
        cursor: pointer;
    }
 }
</style>
