<template>
  <div class="container">
    <div class="schedule">
      <div class="studyplan_title">我的学习计划</div>
      <div class="tablex">
        <el-collapse accordion>
          <el-table :data="planData" border style="width: 100%">
            <el-table-column prop="plan_date" label="截止时间" width="200px" align="center">
            </el-table-column>
            <el-table-column prop="name" label="学习内容">
              <template slot-scope="scope">
                <div>
                  <div class="col_dec">
                    {{scope.row.title}}
                  </div>
                  <div class="col_centent">
                    <el-collapse-item>
                      <template slot="title">
                        <div class="colldi">
                          <div>计划学习内容</div>
                          <div>{{scope.row.section_info.length||''}} →</div>
                        </div>
                      </template>
                      <div class="colc_list">
                        <div
                          class="colc_i"
                          v-for="(item, index3) in scope.row.section_info"
                          :key="index3"
                          @click="goStyindex(item.id,item.checkpoint_info.training_info)"
                        >
                          <img v-if="item.is_status==0" src="../../assets/vwanc.png" alt="" />
                          <img v-else src="../../assets/images/login/icon_tj_ok.png" alt="" />
                          <div class="colc_i_name" v-if="item.checkpoint_info">
                            {{item.checkpoint_info.training_info.training_name}}-{{item.checkpoint_info.checkpoint_name}}-{{item.section_name}}
                          </div>
                          <div class="colc_i_pos">特训</div>
                        </div>
                      </div>
                    </el-collapse-item>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="状态" width="200px">
              <template slot-scope="scope">
                <div class="tabstatus">
                  <img v-if="scope.row.status==2" src="../../assets/images/login/icon_jh_a.png" alt="" />
                  <img v-if="scope.row.status==1" src="../../assets/images/login/icon_jh_b.png" alt="" />
                  <img v-if="scope.row.status==3" src="../../assets/images/login/icon_jh_c.png" alt="" />
                  <div v-if="scope.row.status==1" style="color: #FF6B49">未完成</div>
                  <div v-if="scope.row.status==2" style="color: #308dff">已完成</div>
                  <div v-if="scope.row.status==3" style="color: #999999">超时</div>
               </div>
                <div class="tabstatus">
                  <div
                    v-if="scope.row.status==2"
                    style="color: #308dff;margin-top:20px"
                    @click="
                      (e) => {
                        summarizeInfo = scope.row;
                        centerDialogVisible = true;
                      }
                    "
                  >
                    总结
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse>
      </div>
    </div>
    <!-- 总结 -->
    <el-dialog
      title="自我总结"
      :visible.sync="centerDialogVisible"
      width="30%"
      top="15%"
      center
    >
      <el-input
        type="textarea"
        :rows="5"
        placeholder="请输入内容"
        v-model="textarea"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitInput">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { updatePlan, getPlan } from "@/utils/erpapi.js";
export default {
  data() {
    return {
      textarea: "",
      tabIndex: 0,
      centerDialogVisible: false,
      summarizeInfo: {},
      planData:[],
      loading:""
    };
  },
  destroyed(){
    this.loading.close();
		},
    created() {
      this.loading = this.$loading({
        lock: true,
        text: "",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    this.getPlan();
  },
  methods: {
    // 学习计划
    getPlan() {
      getPlan({
        type: 2,
      }).then((res) => {
        this.planData = res.data;
        this.loading.close();
      });
    },
    // 提交总结
    submitInput() {
      updatePlan({
        id: this.summarizeInfo.id,
        section_id: "",
        plan_id: "",
        status: this.summarizeInfo.status,
        remark: this.textarea,
      }).then((res) => {
        this.centerDialogVisible = false;
      });
    },
    tabChange(index) {
      this.tabIndex = index;
    },
  },
};
</script>

<style scoped lang="scss">
// /deep/.el-collapse-item__header{
//   background: #FAFAFA;
//   height: 60px;
// }
/deep/.el-collapse {
  border: none;
}
.col_centent {
  margin-top: 20px;
}
/deep/.el-collapse-item__header {
  width: 705px;
}
// 设置鼠标经过时背景颜色
/deep/.el-table tbody tr:hover > td {
  background-color: #ffffff !important;
}
.col_dec {
  padding-left: 20px;
  padding-right: 20px;
}
.colldi {
  width: 178px;
  height: 38px;
  background: #f2f8ff;
  border: 1px solid #b6d7ff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  margin-left: 20px;
  div {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #308dff;
  }
}
/deep/.el-table thead tr > th {
  height: 50px;
  background-color: #308dff;
  color: #ffffff;
}
/deep/.el-table__header-wrapper {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
// 更改表格每行背景颜色
/deep/.el-table tbody tr > td {
  height: 75px;
}
/deep/.el-table__body-wrapper {
  border-left: 1px solid #e4e8f2;
  border-right: 1px solid #e4e8f2;
  border-top: none;
}
.elprox {
  display: flex;
  justify-content: center;
  margin: 30px auto 0 auto;
}
.tablex {
  width: 1138px;
  margin-top: 10px;
}
.schedule {
  width: 1200px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px #e8edf3;
  border-radius: 6px;
  padding: 30px;
  .studyplan_title {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #393f47;
    text-align: center;
    margin-bottom: 20px;
  }
}
.colc_list {
  display: flex;
  align-items: center;
  // justify-content: space-around;
  flex-wrap: wrap;
  .colc_i {
    width: 320px;
    height: 110px;
    background: #fafafb;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-right: 30px;
    padding: 46px 30px 0 30px;
    position: relative;
    img {
      width: 16px;
      height: 16px;
      margin-right: 16px;
      margin-top: 4px;
    }
    .colc_i_pos {
      position: absolute;
      top: 0;
      left: 0;
      width: 70px;
      height: 34px;
      background: #308dff1c;
      border-radius: 6px 0px 6px 0px;
      text-align: center;
      line-height: 34px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #308dff;
    }
    .colc_i_name {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
  }
}
.tabstatus {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  img {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
}
</style>
