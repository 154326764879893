import { render, staticRenderFns } from "./mySet.vue?vue&type=template&id=c65e36e8&scoped=true&"
import script from "./mySet.vue?vue&type=script&lang=js&"
export * from "./mySet.vue?vue&type=script&lang=js&"
import style0 from "./mySet.vue?vue&type=style&index=0&id=c65e36e8&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c65e36e8",
  null
  
)

export default component.exports