<template>
	<div class="container">
		<div class="content" v-loading>
			<div class="study_list">
				<div style="display: flex;align-items: center;justify-content: space-between;">
					<div class="tab_top">
						<div v-for="(item,index) in titleList" :class="current == index?'active':''"
							@click="studyClick(index,item.path)" :key="index">{{item.name}}</div>
					</div>
					<div style="display: flex;align-items: center;justify-content: space-between;" v-if="current == 1 || current == 2">
						<el-input v-model="search_content" placeholder="输入搜索关键词" style="margin-right: 10px;"></el-input>
						<el-button @click="searchClick" class="search_btn">搜索</i></el-button>
						<el-button @click="clearClick" class="clear_btn">清空</i></el-button>
					</div>
				</div>
				<div class="child_box">
					<router-view></router-view>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import navBar from '@/components/navBar/navBar'
	import tableList from '@/components/tableList/tableList'
	import turnPages from '@/components/turnPages/turnPages'
	import {
		getUserCourse
	} from '@/utils/api.js'
	import {
		EventBus
	} from '@/eventBus/eventBus'
	export default {
		components: {
			tableList,
			turnPages,
			navBar
		},
		data() {
			return {
				clasType: '',
				ClassName: '',
				current: 0,
				titleList: [ //'务实基础', '真题演练', '考前冲刺'
					{
						name: '基础提升',
						path: 'classList',
					}, {
						name: '真题演练',
						path: 'trueTopicList',
					}, {
						name: '考前冲刺',
						path: 'classSprint',
					}, {
						name: '时事政治',
						path: 'politic?type=online',
					}
				],
				tabTitle: ['课程名称', '课程类型', '学习进度', '操作'],
				typeList: [],
				pages: 1,
				count: 0,
				limit: 16,
				type: 1, //基础：type=1 提升：type=2 冲刺：type=3
				name: '',
				search_content: '',
				pathss:''
			}
		},
		mounted() {
			console.log(this.$route.path == '/classList')
			this.pathss = this.$route.path
			EventBus.$on('studyCurrent', index => {
				this.current = index
				localStorage.setItem('studyCurrent', index)
			})
			// localStorage.setItem('studyCurrent',bus.$on('studyCurrent'))
		},
		created() {
			
			// if (localStorage.getItem('studyCurrent')) {
			// 	this.current = localStorage.getItem('studyCurrent')
			// } else {
			// 	this.current = 0
			// }
			// this.$forceUpdate()
			// this.getData()
			// EventBus.$emit('pageRouter',{
			// 		name: '学习中心',
			// 		pages: '/study',
			// 	})
		},
		methods: {
			searchClick() {
				EventBus.$emit('search',this.search_content)
			},
			clearClick() {
				this.search_content = ''
				EventBus.$emit('clearSearch','')
			},
			async getData() {
				let promise = {
					type: this.type,
					limit: this.limit,
					page: this.pages,
					name: this.name
				}
				await getUserCourse(promise).then(res => {
					this.typeList = res.list
					this.count = Math.ceil(res.total / this.limit)
				})
			},
			studyClick(index, path) {
				this.pathss = '/' + path
				this.current = index
				localStorage.setItem('studyCurrent', index)
				this.$router.push('/' + path)
			},
		},
		beforeDestroy() {
			// localStorage.setItem('studyCurrent', 0)
		}

	}
</script>
<style scoped>
	.content {
		width: 1132px;
		background: #fff;
		min-height: 780px;
		padding: 20px;
		position: relative;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
	}
	.search_btn {
		background: #5694F7;
		color: #fff;
	}
	.clear_btn{
		background: #FF684B;
		color: #fff;
	}
	.title {
		font-size: 14px;
		color: #7B92CB;
	}

	/* .study_list{

} */
	.pages_box {
		position: absolute;
		bottom: 20px;
		right: 20px;
	}

	.tab_top {
		display: flex;
		align-items: center;
		cursor: pointer;
		justify-content: flex-start;
	}

	.tab_top div {
		width: 125px;
		height: 35px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
	}

	.tab_top .active {
		background: #47BE5E;
		border-radius: 18px;
		color: #fff;
	}

	.table_box {
		margin-top: 30px;
	}

	.table_title,
	.table_list {
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.table_title {
		background: #F7F7F7;
	}

	.table_title div {
		width: 17%;
		display: flex;
		font-size: 16px;
		font-weight: bold;
		color: #333;
		align-items: center;
		justify-content: center;
	}

	.table_title div:first-child,
	.table_list div:first-child {
		width: 49%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.table_list div {
		width: 17%;
		display: flex;
		color: #333;
		align-items: center;
		justify-content: center;
	}

	.table_list div:first-child {
		justify-content: flex-start;
	}
</style>
