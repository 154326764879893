<template>
  <div class="boxs">
    <auserInfo style=""></auserInfo>
    <div class="content">
       <div class="coninfo">
        <div class="path_crumb" @click="goRoute"><i class="el-icon-arrow-left"></i>返回</div>
         <div class="coninfo_router" v-if="sourceva==1">{{courseStudyDetail.section_info.checkpoint_info.training_info.training_name}}> {{courseStudyDetail.section_info.checkpoint_info.checkpoint_name}} > {{courseStudyDetail.section_info.section_name}}</div>
         <div class="coninfo_name">{{courseStudyDetail.name}}</div>
         <div class="coninfo_video">
          <div class="inf_vo">
            <div>
              <video-player
               :playsinline="true"
                v-if="playerOptions.sources[0].src"
                style="user-select: none"
                class="vjs-custom-skin"
                ref="videoPlayer"
                :options="playerOptions"
                @ended="onPlayerEnded($event)"
                @timeupdate="onTimeupdate"
          />
            </div>
          </div>
          <div class="coninfo_right">
            <div class="righy">
              <el-timeline :reverse="reverse">
              <el-timeline-item
                v-for="(activity, index) in courseStudyDetail.knowledge"
                :key="index"
                :timestamp="timeCli(activity.second)"
                :hide-timestamp="true"
                placement="top"
                color="#8a8a8a">
                <div class="shic" @click="daiTcge(activity.second)">{{timeCli(activity.second)}}</div>
               <div @click="daiTcge(index)" style="color:#ffffff">{{activity.text}}</div>
              </el-timeline-item>
            </el-timeline>
            </div>
          </div>
         </div>
       </div>
    </div>
     </div>
</template>

<script>
import { videoPlayer } from "vue-video-player";
import auserInfo from "@/components/userInfo/userInfo1.vue";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
import "videojs-contrib-hls";
import {getContent,addTrainingLog,getSubjectTopic,addSubjectLog} from "@/utils/erpapi.js";
export default {
  name: "live",
  components: {
    videoPlayer,
    auserInfo
  },
  data() {
    return {
      playerOptions: {
        playbackRates: [1.0,1.2,1.5,1.7,2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: 'video/mp4', // 类型
          src: '' // url地址
        }],
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
          progressControl: true, //进度条
        }
      },
      courseStudyDetail:{},
      timeValue:0,
      reverse: false,
      sourceva:1,
      routeInfo:{}
    };
  },
  watch: {
  },
  created() {
    this.routeInfo=this.$route.query
    this.sourceva=this.$route.query.source
    if(this.$route.query.source==2){
      this.getSubjectTopic(this.$route.query.id);
    }else{
       this.getContent(this.$route.query.id);
    }
   
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },

    playsinline() {
      let ua = navigator.userAgent.toLocaleLowerCase();
      // x5内核
      if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
        return false;
      } else {
        // ios端
        return true;
      }
    },
  },
  methods: {
    goRoute(){
      if(this.routeInfo.twoIndex){
        console.log(this.routeInfo)
	   this.$router.push('/styindex?id='+this.routeInfo.content_id+'&index='+this.routeInfo.index+'&twoindex='+this.routeInfo.twoIndex)
	  }else{
	  this.$router.go(-1)
	  }
      
    },
        // 时间处理
		timeCli(value) {
			var secondTime = parseInt(value);// 秒
			var minuteTime = 0;// 分
			var hourTime = 0;// 小时
			if (secondTime > 60) {//如果秒数大于60，将秒数转换成整数
				//获取分钟，除以60取整数，得到整数分钟
				minuteTime = parseInt(secondTime / 60);
				//获取秒数，秒数取余，得到整数秒数
				secondTime = parseInt(secondTime % 60);
				//如果分钟大于60，将分钟转换成小时
				if (minuteTime > 60) {
					//获取小时，获取分钟除以60，得到整数小时
					hourTime = parseInt(minuteTime / 60);
					//获取小时后取余的分，获取分钟除以60取余的分
					minuteTime = parseInt(minuteTime % 60);
				}
			}
			var result = parseInt(secondTime) < 10 ? '0' + parseInt(secondTime) : parseInt(secondTime);

        if (minuteTime > 0) {
          let min = parseInt(minuteTime) < 10 ? '0' + parseInt(minuteTime) : parseInt(minuteTime)
          result = min + ":" + result;
        } else {
          result = "00:" + result;
        }
			if (hourTime > 0) {
				let hour = parseInt(hourTime) < 10 ? '0' + parseInt(hourTime) : parseInt(hourTime)
				result = hour + ":" + result;
			} else {
				result = result;
			}
			return value?result:'00:00:00';
		},
    // 点击时间线
    daiTcge(time){
      console.log(time)
      this.$refs.videoPlayer.player.currentTime(time)
    },
    async getSubjectTopic(id) {
      getSubjectTopic({id:id}).then(res=>{
        this.courseStudyDetail = res.data
        this.playerOptions.sources[0].src=res.data.video_url
        this.$refs.videoPlayer.player.currentTime(res.data.video_time)
      })
    },
    // 
    async getContent(id) {
      getContent({id:id}).then(res=>{
        console.log(res)
        this.courseStudyDetail = res.data
        this.playerOptions.sources[0].src=res.data.video_url
        this.$nextTick(() => {
          this.daiTcge(res.data.video_time)
          })
      })
    },
      
    //监听媒体是否已到达结尾，播放完
    async onPlayerEnded(player) {
      this.addTrainingLog()
    },
    // 进度
    onTimeupdate(e){
      // console.log(e.cache_.currentTime.toFixed(0))
      this.timeValue = e.cache_.currentTime.toFixed(0)
    },
    // 添加学习记录
    addTrainingLog(){
      if(this.timeValue<1)return;
      if(this.sourceva==2){
        addSubjectLog({
        content_id:this.courseStudyDetail.id,
        video_time:this.timeValue,
      }).then(res=>{

      })
      }else{
        addTrainingLog({
        content_id:this.courseStudyDetail.id,
        video_time:this.timeValue,
        }).then(res=>{

        })
      }
      
    }
  },
  beforeDestroy(){
    this.addTrainingLog()
  },
};
</script>
<style lang="scss" scoped>
.path_crumb{
  margin: -20px 0 20px 0;
  color: #ffffff;
}
.vjs-mouse-display .vjs-time-tooltip {
  // background: rgba(255, 255, 255, 0) !important;
}

/deep/.vjs-play-progress .vjs-time-tooltip {
  display: none !important;
  color: #ff684b !important;
}

.vjs-custom-skin > .video-js .vjs-big-play-button {
  width: 60px !important;
  height: 60px !important;
  line-height: 60px !important;
  border-radius: 50% !important;
}

.vjs-big-play-button span {
  font-size: 56px !important;
}

.vjs-custom-skin > .video-js .vjs-play-progress,
.vjs-custom-skin > .video-js .vjs-volume-level {
  background: #ff684b !important;
  border-radius: 2px !important;
}

.video-js:hover .vjs-big-play-button,
.vjs-custom-skin > .video-js .vjs-big-play-button:focus,
.vjs-custom-skin > .video-js .vjs-big-play-button:active {
  background: #ff684b !important;
  opacity: 0.9 !important;
}

.video-js .vjs-play-progress:before {
  font-size: 0.9em;
  position: absolute;
  right: -0.5em;
  top: -0.333333333333333em;
  z-index: 1;
  color: #ff684b !important;
}

.video-js .vjs-slider {
  border-radius: 2px !important;
}

/* .vjs-custom-skin > .video-js .vjs-time-tooltip, .vjs-custom-skin > .video-js .vjs-mouse-display:after, .vjs-custom-skin > .video-js .vjs-play-progress:after{
		color: #47BE5E !important;
	} */
</style>
<style lang="scss" scoped>
.content{
  width: 100vw;
height: 750px;
background: #333333;
}
.coninfo{
  width: 1200px;
  margin: 0 auto;
  padding: 40px 0;
  .coninfo_router{
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
    margin-bottom: 30px;
  }
  .coninfo_name{
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
    margin-bottom: 30px;
  }
  .coninfo_video{
    display: flex;
    height: 559px;
    background: #3E3E3E;
    .inf_vo{
      width: 900px;
      background: #000000;
      div{
        margin: 25px auto;
      }
    }
    .coninfo_right{
      width: 300px;
      height: 559px;
      background: #3E3E3E;
      padding: 20px 0 0 20px;
      .righy{
        overflow-y: scroll;
        height: 500px;
        padding-left: 10px;
        padding-top: 10px;
      }
    }
  }
}
::-webkit-scrollbar {
    width: 0px;
    height: 1px;
}
/deep/.el-timeline-item__timestamp{
  color: #ffffff !important;
}
/deep/.el-timeline-item{
  padding-bottom: 40px !important;
}
/deep/.el-timeline-item__tail{
  border-left: 2px solid #8a8a8a;
  margin-bottom: 10px !important;
}
.shic{
  width: 50px;
height: 20px;
background: #565656;
border-radius: 3px;
font-size: 12px;
font-family: Arial;
font-weight: 400;
color: #E0E0E0;
line-height: 20px;
text-align: center;
cursor: pointer;
margin-bottom: 10px;
}
/deep/.shic:hover{
  background: #c2c1c1;
}
</style>