<template>
	<div>
		<div class="table_box">
			<div class="table_title">
				<div v-for="(item,index) in tabTitle" :key="index">{{item}}</div>
			</div>
			<div class="table_content" :class="typeList ?'':'nothing'">
				<img src="@/assets/images/nothing.png" v-if="!typeList">
				<div class="table_list" v-for="(item,index) in typeList" :key="index" v-else
					@click="listJump(item.id,item.name)">
					<div class="list_title" style=""><span
							class="kuai">{{index + 1 + pages*limit -limit}}</span>{{item.name}}</div>
							
							<div class="price" >￥{{item.price}}</div>
					<!-- <div>{{item.type}}</div> -->
					<div>{{item.progress}}%</div>
					<div style="width: 10% !important;"><span>立即学习</span></div>
					<div style="width: 10% !important;">
						<span v-if="item.handout.length>0" @click.stop="downBtn(item.handout)">下载课件</span>
					</div>
				</div>
			</div>
		</div>
		<el-dialog title="提示" :visible.sync="dialogVisible" width="50%">
			<div v-for="(item,index) in fileList" :key="index" class="file_list">
				<div style="font-size: 14px;">{{item.name}}</div>
				<div class="down" style="font-size: 14px;" @click="dowbLoadFile(item)"><img
						src="@/assets/downloadIcon.png">下载课件</div>
			</div>

		</el-dialog>
		<turnPages class="pages_box" :count="count" :pages="pages" @fanye="fanyeClick" @inputPage="inputPage">
		</turnPages>
	</div>
</template>

<script>
	import navBar from '@/components/navBar/navBar'
	import tableList from '@/components/tableList/tableList'
	import turnPages from '@/components/turnPages/turnPages'
	import {
		EventBus
	} from '@/eventBus/eventBus'
	import {
		getUserCourse
	} from '@/utils/api.js'
	export default {
		components: {
			tableList,
			turnPages,
			navBar
		},
		data() {
			return {
				clasType: '',
				ClassName: '',
				current: 0,
				titleList: [ //'务实基础', '真题演练', '考前冲刺'
					{
						name: '务实基础',
						path: '/classList',
					}, {
						name: '真题演练',
						path: '/classList',
					}, {
						name: '真题演练',
						path: '/classList',
					}
				],
				tabTitle: ['课程名称','课程价格', '学习进度', '操作'],
				typeList: [],
				pages: 1,
				count: 1,
				limit: 8,
				type: 3, //基础：type=1 提升：type=2 冲刺：type=3
				name: '',
				navList: [{
						path: '/classList',
						name: '首页',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 0
					},
					{
						path: '/classList',
						name: '在线学习',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 0
					},
					{
						path: '/classSprint',
						name: '考前冲刺',
						studyCurrent: 2,
						tabbarChildCurrent: 0,
						tabbarCurrent: 0
					}
				],
				dialogVisible: false,
				fileList: []
			}
		},
		created() {
			EventBus.$emit('navList', this.navList)
			window.localStorage.setItem('studyCurrent',2)
			this.getData()
		},
		mounted() {
			EventBus.$on('search', res => {
					if (this.search_content == res) {
						return
					}
					this.search_content = res
					
					this.getData()
				}),
			EventBus.$on('clearSearch', res => {
					if (this.search_content == res) {
						return
					}
					this.search_content = res
					this.getData()
				})
		},
		methods: {
			async getData() {
				let promise = {
					search: this.search_content,
					type: this.type,
					limit: this.limit,
					page: this.pages,
					name: this.name
				}
				await getUserCourse(promise).then(res => {
					this.typeList = res.list
					this.count = Math.ceil(res.total / this.limit)
				})
			},
			listJump(id, title) {
				this.navList.push({
					path: '/studyList',
					name: title,
					studyCurrent: 0,
					tabbarChildCurrent: 2,
					tabbarCurrent: 0
				})
				this.$router.push('/studyList?id=' + id + '&titles=' + title + '&page=chongci&navList=' + escape(JSON
					.stringify(this.navList)))
			},
			downBtn(e) {
				this.dialogVisible = true
				this.fileList = e
			},
			dowbLoadFile(e) {
				console.log(e)
				window.open(e.url)
			},
			fanyeClick(e) {
				if (e == 'next') {
					this.pages++
					console.log(this.pages)
					if (this.pages <= this.count) {
						this.getData()
					} else {
						this.pages = this.count
					}
				} else {
					this.pages--
					if (this.pages >= 1) {
						this.getData()
					} else {
						this.pages = 1
					}
				}
			},
			inputPage(e) {
				this.pages = e * 1
				this.getData()
			}
		}

	}
</script>
<style scoped>
	.list_title {
		font-weight: bold;
		font-size: 15px;
	}

	.content {
		width: 1132px;
		background: #fff;
		/* height: 510px
		height: 780px;
		/* margin-top: 1.25rem; */
		/* border-radius: .625rem; */
		/* padding: 1.25rem 1.5625rem; */
		padding: 20px;
		position: relative;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
	}

	.title {
		font-size: .875rem;
		color: #7B92CB;
	}

	/* .study_list{

} */
	.kuai {
		background: #47BE5E;
		margin-right: 10px;
	}

	.table_list div span {
		background: #5694F7;
		color: #fff;
		display: inline;
		padding: 5px 10px;
		border-radius: 3px;
		cursor: pointer
	}

	.pages_box {
		position: absolute;
		bottom: 20px;
		right: 20px;
	}

	.tab_top {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.tab_top div {
		width: 125px;
		height: 35px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
	}

	.tab_top .active {
		background: #47BE5E;
		border-radius: 18px;
		color: #fff;
	}

	.table_box {
		margin-top: 30px;
	}

	.table_title,
	.table_list {
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.table_list {
		/* padding: 10px 0 !important; */
		height: 70px;
		padding-left: 15px;
	}

	.table_list:hover {
		background: rgba(71, 190, 94, .3);
		border-radius: 10px;
		/* color: #FFF; */
	}

	.table_title {
		background: #F7F7F7;
	}

	.table_title div {
		width: 17%;
		display: flex;
		font-size: 16px;
		font-weight: bold;
		color: #333;
		align-items: center;
		justify-content: center;
	}

	.table_title div:first-child,
	.table_list div:first-child {
		width: 49%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.table_list div {
		width: 17%;
		display: flex;
		color: #333;
		align-items: center;
		justify-content: center;
	}

	.table_title div:last-child {
		width: 20% !important;
	}
	.price{
		font-size: 16px;
		font-weight: bold;
		color: #5694F7 !important;
	}
	/* .table_title div:nth-child(4) {
		width: 9.4% !important;
	} */
.table_title div:nth-child(3) {
		width: 16% !important;
	}

	.table_list div:first-child {
		justify-content: flex-start;
	}

	.file_list {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #eee;
		padding: 30px 0;

	}

	.file_list .down {

		display: flex;
		align-items: center;
		justify-content: flex-start;
		color: #47be5e;
		background: #E5FFEC;
		padding: 5px 10px;
		border-radius: 10px;
	}

	.down img {
		width: 20px;
		height: 20px;
	}
</style>
