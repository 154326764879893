<template>
  <div
    id="app"
    :class="$route.meta.bg ? 'bgapp' : $route.meta.nobg ? '' : 'apps'"
  >
    <div id="nav">
      <router-link to="/"></router-link>
      <tab-bar class="tabbar" v-if="$route.meta.showTab"></tab-bar>
    </div>
    <router-view />
  </div>
</template>
<script>
import tabBar from "./components/tabBar/tabBar.vue";
export default {
  components: {
    tabBar,
  },
  mounted() {
    // 关闭浏览器窗口的时候清空浏览器缓存在localStorage的数据
    // window.onbeforeunload = function(e) {
    // 	// window.localStorage.removeItem('navListss');
    // 	window.localStorage.setItem('studyCurrent',0)
    // }
     // 根据不同路由跳转不同页面
     if (this._isMobile()) {
       if(localStorage.getItem('UserToken')){
        window.location.href='https://studyh5.sz517.com/#/?token='+localStorage.getItem('UserToken')
       }else{
        window.location.href='https://studyh5.sz517.com/#/'
       }
      // this.$router.replace('www.baidu.com')
    } else {
       if(this.$route.query.token&&this.$route.query.token!=''&&this.$route.query.token!='null'){
        localStorage.setItem('UserToken', this.$route.query.token.replaceAll(' ','+'))
        this.$router.replace('/erpindex')
       }else{
        // localStorage.removeItem('UserToken')
        // this.$router.replace('/')
       }
    }
  },
  methods: {
    // 判断是否是手机端，如果是，返回true
    _isMobile () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    }
  }
};
</script>
<style lang="scss">
.ellipsis {
  //多行省略
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;

  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /*autoprefixer: on */
  -webkit-line-clamp: 1;
}

* {
  padding: 0;
  margin: 0;
  /* color: #222222; */
  font-size: 14px;
  text-decoration: none;
  outline: none;
  font-style: normal;
  box-sizing: border-box;
  font-family: "微软雅黑", Arial, "Helvetica Neue", Helvetica, "PingFang SC",
    "Hiragino Sans GB", "Microsoft YaHei", sans-serif;
  cursor: default;
}

::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

/* 滚动条整体部分，可以设置宽度啥的 */
/* ::-webkit-scrollbar-button 滚动条两端的按钮 */
::-webkit-scrollbar-track {
  background: #f3f4ff;
}

/* 外层轨道 */
::-webkit-scrollbar-track-piece {
}

/* 内层滚动槽 */
::-webkit-scrollbar-thumb {
  background: #47be5e;
  border-radius: 5px;
}

/* 滚动的滑块 */
html,
body {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  overflow-x: auto;
  background: #f4f6f8;
  box-sizing: border-box;
}

img {
  vertical-align: middle !important;
}

.apps {
  width: 80rem;
  height: 100%;
  background: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.bgapp {
  /* background: url('./assets/images/login/login_bg.png') center no-repeat; */
  width: 100vw;
  height: 100vh;
  background-size: 100% 100%;
}

.tabbar {
  /* position: absolute; */
  /* left: 1.5625rem; */
  /* top: 5rem; */
  margin-right: -15rem;
  position: relative;
  z-index: 2;
}

/* .container {
		width: 78rem;
		height: 60rem;
		border-radius: 1.25rem .3125rem .3125rem 1.25rem;
		background: #F3F4FF;
		padding-left: 17rem;
		padding-right: .625rem;
	} */
.nothing {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 200px !important; 
  // height: 600px !important; 
}

.nothing img {
  width: 300px;
}

.back_imgs {
  width: 30px;
  height: auto;
  position: absolute;
  top: 0;
  right: 10px;
  cursor: pointer;
}
</style>
