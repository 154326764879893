<template>
    <div>
      <div class="page_title">{{ zoNmae }}</div>
      <div class="container">
        <!-- <div class="page_title">第六篇 事业单位人事管理条例高频考点</div> -->
        <div class="content">
          <div class="content_ss" style="position: relative">
            <!-- <img class="close" src="@/assets/closeAnswer.png" title="退出并保存答题进度" @click="$router.go(-1)"> -->
            <!-- <div>{{ title }}</div> -->
            <div class="wraps">
              <div class="answer_title">
                <span>{{
                  current + 1 > answerList.length
                    ? answerList.length
                    : current + 1
                }}</span
                >&nbsp;/&nbsp;{{ answerList.length }}题
              </div>
            </div>
            <!-- <el-carousel :interval="5000" :autoplay="false" :initial-index="current" :loop="false" arrow="never"
                      class="swiper_box" indicator-position="none" ref="cardShow"
                      :style="{height:1100 + 'px'}">
                      <el-carousel-item v-for="(item,index) in answerList" :key="index" class="swiper_box"
                          :style="{height:1100 + 'px'}">
                          <aAsnwer :answer="item" @wd="selectClick" :index="index" :current="current"
                              :length="answerList.length" @tab="nextAnswer" ref="answerBox">
                          </aAsnwer>
                      </el-carousel-item>
                  </el-carousel> -->
            <div
              v-for="(item, index) in answerList"
              :key="index"
              class="swiper_box"
              v-if="index == current"
            >
              <aAsnwer
              :anstype="contInfo.type"
                :source="2"
                :answer="item"
                @wd="selectClick"
                :index="index"
                :current="current"
                :length="answerList.length"
                :courseSubId="answerId"
                @tab="nextAnswer"
                ref="answerBox"
              >
              </aAsnwer>
            </div>
          </div>
          <div class="note_box" v-if="false">
            <div class="note_title">练习笔记</div>
            <div class="note">
              <textarea
                rows=""
                cols=""
                maxlength="1000"
                placeholder="请输入您的答题感想"
                v-model="answer_note"
              ></textarea>
              <span>{{ answer_note.length }}/1000</span>
              <div>提交</div>
            </div>
            <div class="note_list">
              <div class="note_pic">
                <img src="../../../assets/images/study/demo.jpg" />
              </div>
              <div class="note_con">
                <div class="note_info">
                  <div class="lines">
                    <div class="user_name">看见诗遇见你</div>
                    <div class="note_time">{{ (1263000, 3 | dtime) }}</div>
                  </div>
                  <div class="note_btns">
                    <div
                      style="
                        background: #ecf8ef;
                        border-radius: 3px;
                        color: #5694f7;
                        margin-right: 20px;
                      "
                    >
                      修改
                    </div>
                    <div>删除</div>
                  </div>
                </div>
                <div class="note_text">
                  事业单位管理体制是国家对于事业单位的权限及其活动进行管理的制度。它不是事业单位内部自身的管理机构和体制。
                  实质是政府与事业单位的关系问题，包括国家出台的一系列政策、法规和措施。
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <button class="subMiter" @click="generatePdf()" style="margin: 0 13px 16px 13px;">
              生成PDF
            </button>
          <div class="time_box">
            <div class="time_box_title">答题时长</div>
            <div class="time_box_time">{{ timer }}</div>
          </div>
          <div class="answer_card">
            <div class="time_box_title">答题卡</div>
            <button v-if="subBtnshow" class="subMiter" @click="submitTo()">
              提交
            </button>
            <div class="answer_card_content">
              <div
                class="answer_card_s"
                @click="cardClick(index)"
                :class="
                  answerList[index].correct == 'yes'
                    ? 'yesgreen'
                    : answerList[index].correct == 'no'
                    ? 'yesgreen'
                    : ''
                "
                v-for="(item, index) in answerList"
                :key="index"
              >
                {{ index + 1 }}
              </div>
              <div class="card_jia" v-for="index in 5" :key="'aa' + index"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import aAsnwer from '@/components/answer/index'
  import navBar from '@/components/navBar/navBar'
  import {getSubjectTopic,addStuSubjectLog,addSubjectLog,makePdf} from '@/utils/erpapi.js'
  import {
      EventBus
  } from '@/eventBus/eventBus'
  export default {
      components: {
          aAsnwer,
          navBar
      },
      data() {
          return {
              contInfo:{},
              timer: '00:00:00',
              stimxx: 0,
              current: 0,
              correctlv: 99,
              maskShow: false,
              navList: [],
              answer_note: '',
              answerList: [],
              zoNmae: "",
              timess: {
                  s: '00',
                  m: '00',
                  h: '00',
                  Hour: 0,
                  minute: 0,
                  second: 0,
              },
              answerType: '1',
              answerNum: 0,
              answerId: 2,
              take_time: 0,
              dtTimes: '',
              leix: 0,
              swiperOptions: {
                  autoHeight: true
              },
              sub_id: 0,
              limit: 500,
              pages: 1,
              count: 1,
              gonggu: 0,
              title: '',
              errnum: 0,
              subBtnshow: false,
              loading:""
          }
      },
    watch: {
      $route (to, from ) {
        console.log(to.path)
        if (to.path === '/yourpageUrl') {
          // do anything you want
        }
      }
    },
    destroyed(){
    this.loading.close();
		},
	created() {
        this.loading = this.$loading({
            lock: true,
            text: "",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          this.answerId = Number(this.$route.query.id)
          this.answerType = this.$route.query.type
          let answerArrs = ''
          answerArrs = JSON.parse(localStorage.getItem('answerArrLx'+this.answerId+4))
          if (answerArrs) {
              console.log('有缓存')
              let ansArr = answerArrs
              let ansNum = 0
              let acsdList = answerArrs
              console.log(acsdList.answerList)
              acsdList.answerList.forEach((item, index) => {
                  if (!acsdList.answerList[index].user_res) {
                      ansNum++
                  }
              })
              console.log(ansNum)
              this.$confirm('检测到上次作答 ' + ansNum + ' 未完成, 是否继续完成上次作答?', '提示', {
                  confirmButtonText: '完成上次',
                  cancelButtonText: '清除上次记录',
                  type: 'warning'
              }).then(() => {
                  this.$message.success('加载成功')
                  this.answerList = ansArr.answerList
                  this.timess = ansArr.timess
                  this.take_time = ansArr.time
                  this.title = ansArr.name
                  this.answerNum = ansArr.answerNum
                  this.leix = ansArr.leix
                  this.gonggu = ansArr.gonggu
                  let ss = []
                  this.subBtnshow = true
                  for (let i in this.answerList) {
                      if (!this.answerList[i].user_res) {
                          ss.push(i)
                      }
                  }
                  this.current = parseInt(ss[0])
              }).catch(() => {
                  if (this.answerType == 2) {
                      localStorage.removeItem('answerArrCy')
                  } else {
                      answerArrs = localStorage.removeItem('answerArrLx'+this.answerId+4)
                  }
                  this.$message.success('清除成功')
                  // if (this.$route.query.parameter) {
                  // 	this.parameter(this.$route.query.parameter)
                  // } else {
                  // 	this.answerId = this.$route.query.id
                  // }
                  // this.$router.go(0)
                  this.getData()
              });
          }else{
              this.getData()
          }
  
      },
      watch: {
      },
      mounted() {
          this.dtTimes = setInterval(() => {
              this.take_time++
              this.timess.s = ++this.timess.second;
              if (parseInt(this.timess.s) < 10) {
                  this.timess.s = '0' + this.timess.second
              }
              if (parseInt(this.timess.s) == 60) {
                  this.timess.m = ++this.timess.minute
                  this.timess.second = 0
                  this.timess.s = '0' + this.timess.second
              }
              if (parseInt(this.timess.m) < 10) {
                  this.timess.m = '0' + this.timess.minute
              }
              if (parseInt(this.timess.m) == 60) {
                  this.timess.h = ++this.timess.Hour
                  this.timess.minute = 0
                  this.timess.m = '0' + this.timess.minute
                  if (parseInt(this.timess.h) < 10) {
                      this.timess.h = '0' + this.timess.Hour
                  }
              }
              this.stimxx = Number(this.timess.h * 60 * 60) + Number(this.timess.m * 60) + Number(this.timess.s)
              this.timer = this.timess.h + ':' + this.timess.m + ':' + this.timess.s
          }, 1000)
      },
      methods: {
        generatePdf(){
          makePdf({
            id:'s'+this.contInfo.id,
            name:this.contInfo.name,
            ques_data:this.contInfo.ques,
            score:this.contInfo.score
          }).then(res=>{
            console.log(res)
            window.open(res.data)
          })
        },
          dataTyps(res) {
              res.data.exam?.forEach((item, index) => {
                  res.data.exam[index].option_name = res.data.exam[index].option_name
              })
              console.log(res.data)
              res.data = res.data.exam || res.data.data
              this.answerList = res.data
              console.log(this.answerList)
              this.answerNum = res.total
                  this.count = Math.ceil(res.total / this.limit)
              let arr = []
              for (let i of this.answerList) {
                  let str = i.genre.toString()
                  i.genreType = str.substr(str.length - 1, 1)
                  if (i.genreType == '6') {
                      i.titles = []
                      arr = i.title.split('____')
                      i.titles = arr
                  }
              }
          },
          async getData() {
                    console.log(this.answerType)
                    await getSubjectTopic({
                            id: this.answerId
                        }).then(res => {
                  this.answerType=res.data.type
                  this.answerId=Number(res.data.id)
                      this.contInfo=res.data
                      this.zoNmae = res.data.name
                      res.data.exam = res.data.ques
                      this.loading.close();
                      this.dataTyps(res)
                      if(!res.data){
                      this.$message({
                        message: '此特训分组已过期，请联系客服续期！',
                        type: 'error'
                      });
                       this.$router.go(-1)
                      }
                  }).catch(err => {
                    this.loading.close();
                      console.log(err)
                     
                  })
          },
          stopClick(text) {
              this.timeAdd('stop')
              localStorage.removeItem('times')
          },
          dtCardClick() {
              this.maskShow = true
              // 计算正确率
              var yes = this.answerList.filter(function (item) { //查找回答正确的数组并组成新数组
                  return item.correct == 'yes';
              })
              this.correctlv = (yes.length / this.answerList.length * 100).toFixed(2)
          },
          cardClick(i) {
              this.current = i
              this.maskShow = false
          },
          // 提交
          submitTo() {
          let meidaArr=this.answerList.filter(item=>!item.is_right)
              this.$confirm('你还有' + meidaArr.length + '道题还没做，是否提前交卷', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
              }).then(() => {
                  console.log(this.$refs.answerBox)
                  this.$refs.answerBox[0].manySelect(1)
              }).catch(() => {
  
              });
          },
          // 去重
          removeDuplicateObj(arr) {
              let obj = {};
              arr = arr.reduce((newArr, next) => {
                  obj[next.id] ? "" : (obj[next.id] = true && newArr.push(next));
                  return newArr;
              }, []);
              return arr;
          },
          selectClick(e) {
            console.log(e)
              this.subBtnshow = true
              let that = this
              this.answerList[e.index].correct = e.correct
              this.answerList[e.index].ansTimer = e.ansTimer
              let userLes = JSON.parse(localStorage.getItem('userLes')) != null ? JSON.parse(localStorage.getItem('userLes')) : []
              // return false
                this.answerList[e.index].user_res = e.user_res
                  this.answerList[e.index].status = e.correct == 'no' ? 1 : 2
          this.answerList[e.index].is_right = e.correct == 'no' ? 2 : e.correct == 'yes'?1:3
                  this.answerList[e.index].index = e.index
                  this.answerList[e.index].ansTimer = e.ansTimer
                  this.answerList[e.index].correct = e.correct
  
        //  缓存已答的题
              setTimeout(() => {
                  localStorage.setItem('userLes', JSON.stringify(this.answerList))
              }, 500)
               console.log(this.answerList)
              // this.answerList[e.index].user_res = e.user_res
              var yesArr = this.answerList.filter(function (item) { //查找已经作答的数组
                  return item.correct;
              })
              let mz = this.answerList.filter(item => {  //未作答的题
                  return !item.correct
              })
              if (this.answerList.length === yesArr.length || e.classify == 1) { //判断是否答完题如果答完跳转到结束页面
  
                  // this.subBtnshow=true
                  clearInterval(this.dtTimes)
                  var yes = this.answerList.filter(function (item) { //查找回答正确的数组并组成新数组
                      return item.correct == 'yes';
                  })
                  let error_num = this.answerList.filter(function (item) { //查找回答错误的数组并组成新数组
                      return item.correct == 'no';
                  })
                  console.log(yes)
                  this.correctlv = ((yes.length / this.answerList.length) * 100).toFixed(2) //计算正确率
                  let obj1 = {
                      ques_id: this.answerId,
                      exam_num: this.answerList.length,
                      score: this.answerList.length - error_num.length,
                      spent_time: this.take_time,
                      content: this.answerList,
                  }
          localStorage.removeItem('answerArrLx'+this.answerId+4)
                  this.ijuan()//提交
  
                  return
              } else if (e.index + 1 == this.answerList.length) {
                  let wedata = this.answerList
                  this.$confirm('你还有' + mz.length + '道题还没做，请点击答题卡查看', '提示', {
                      confirmButtonText: '去完成',
                      cancelButtonText: '提交',
                      callback: action => {
                          this.maskShow = true
                          if (action == 'confirm') {
                              try {
                                  wedata.forEach((item, index) => {
                                  if (!wedata[index].is_right || wedata[index].is_right == 'undefined') {
                                      this.current = index
                                      throw new Error("ending")
                                  }
                                 })
                              } catch (e) {
                                  if (e.message == "ending") {
                                      console.log("结束了");
                                  } else {
                                      console.log(e.message);
                                  }
                              };
                          } else {
                              this.ijuan()
                          }
                      }
                  });
              } else {
                  let answerArr = {
                      name: this.title,
                      answerList: this.answerList,
                      time: this.take_time,
                      timess: this.timess,
                      id: this.answerId,
                      answerNum: this.answerNum,
                      page: this.pages,
                      count: this.count,
                      type: this.answerType,
                      leix: this.leix,
                      gonggu: this.gonggu
                  }
                  console.log(answerArr)
                  console.log(this.answerType)
                  if (this.answerType == 4) {
                      localStorage.setItem('answerArrLx'+this.answerId+4, JSON.stringify(answerArr))
                  }
              }
              if (e.next == true) {
                  this.current = e.index + 1
                  // this.$refs.cardShow.next()
  
              }
          },
          // 交卷
          ijuan(locS) {
        console.log('交卷')
              let arrs = {
                  List: this.answerList,
                  time: this.timer,
                  stimxx: this.stimxx,
                  correctlv: this.correctlv
              }
              localStorage.setItem('answer', JSON.stringify(arrs))
        let quesData=[]
        console.log(this.answerList)
        this.answerList.forEach((item,index)=>{
          quesData.push({
            is_right:item.is_right?item.is_right:3,
            subject_topic_id:(this.contInfo.type==4||this.answerType==2)?item.id:"",
            ques_list_id:item.id||"",
            user_answer:item.user_res||"",
            spent_time:item.ansTimer=='00:00:00'?1:item.ansTimer,
            score:item.score
          })
        })
        var successArr = this.answerList.filter(function (item) { //对
                  return item.is_right==1;
              })
        var errorArr = this.answerList.filter(function (item) { //错
                  return item.is_right==2;
              })
              addSubjectLog({
          content_id:this.contInfo.id||this.answerId,
          sum_num:this.answerList.length,
          completed_num:Number(successArr.length+errorArr.length),
          success_num:successArr.length,
          error_num:errorArr.length,
        }).then(res=>{
          let navarrs = {
                  ques: quesData,
                  content_id:this.contInfo.id||this.answerId,
                  type: this.contInfo.type||this.answerType,
                  subject_log_id:res.data,
                  paths:this.$route.fullPath
              }
         console.log(navarrs)
         localStorage.removeItem('result_id')
              setTimeout(() => {
                  this.$router.push('/AnswerEnd?source=2&subjectType=2'+"&type="+this.contInfo.type+'&parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(
                      navarrs))));
              }, 500)
        })
          },
          nextAnswer(e) { //切换题目
              if (e.next == true) {
                  // this.$refs.cardShow.next()
                  this.current = e.index + 1
              } else {
                  // this.$refs.cardShow.prev()
                  this.current = e.index - 1
              }
          },
          // getswiperHeight(e) {
          // 	// this.swiperHeight = 
          // 	this.answerList[e.index].swiperHeight = e.height + 28 < 1110 ?e.height + 28:1110
          // }
      },
      beforeDestroy() {
          clearInterval(this.dtTimes)
      }
  }
  </script>
  <style type="text/css">
  .el-carousel__item {
    overflow: visible !important;
  }
  </style>
  <style scoped="scoped">
  /* @import url("../../../assets/css/answer.css"); */
  /* @import 'swiper/dist/css/swiper.css'; */
  .page_title {
    width: 932px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    text-align: center;
    margin: 20px 0;
  }
  
  .card_jia {
    width: 30px;
    height: 30px;
    margin: 7px;
  }
  
  .notess {
    background: #f7f7f7;
    padding-top: 40px;
  }
  
  .note_box {
    padding: 30px 20px;
    margin-top: 40px;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  }
  
  .note_title {
    font-size: 18px;
    font-weight: bold;
    padding-left: 15px;
    border-left: 5px solid #5694f7;
  }
  
  .note {
    padding: 30px 20px;
    position: relative;
  }
  
  .note span {
    position: absolute;
    top: 160px;
    right: 40px;
    color: #666;
  }
  
  .note textarea {
    height: 150px;
    width: 100%;
    resize: none;
    border: none;
    background: #f7f7f7;
    padding: 15px;
  }
  
  .note div {
    width: 80px;
    height: 36px;
    background: #5694f7;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 16px;
    margin-top: 15px;
  }
  
  .note_list {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
  }
  
  .note_pic {
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
  
  .note_pic img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
  
  .note_con {
    flex: 1;
    padding-left: 20px;
  }
  
  .note_info {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  
  .note_time {
    font-size: 14px;
    color: #666;
    margin-left: 15px;
  }
  
  .user_name {
    font-size: 16px;
    color: #666;
  }
  
  .note_text {
    margin-top: 27px;
    font-size: 16px;
  }
  
  .note_btns div {
    width: 46px;
    height: 24px;
    background: #eeeeee;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #999;
  }
  
  .lines,
  .note_btns {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }
  
  .content {
    width: 932px;
    margin-bottom: 30px;
    /* background: #fff; */
    /* height: 510px
          /* height: 780px; */
    /* margin-top: 20px; */
    /* border-radius: 10px; */
    /* padding: 20px 19px; */
    /* padding: 20px; */
    /* position: relative; */
    /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05); */
    /* overflow: hidden; */
  }
  
  .content_ss {
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    padding: 20px;
    background: url(../../../assets/answer_BG.png) #fff;
    box-sizing: border-box;
  }
  
  .card {
    position: relative;
    width: 248px;
  }
  
  .close {
    position: absolute;
    width: 40px;
    height: auto;
    right: 0;
    top: 0;
  }
  
  .card_title {
    width: 100%;
    text-align: center;
    font-size: 22px;
    color: #fff;
    font-weight: bold;
  }
  
  .correctlv {
    font-size: 16px;
    color: #fff;
    margin-top: 10px;
  }
  
  .yesred {
    color: #ff684b !important;
    border: 1px solid #ffbaad !important;
    background: #fff0ed !important;
  }
  
  .yesgreen {
    /* background: #02D19D !important; */
    color: #5694f7 !important;
    border: 1px solid #5694f7 !important;
    background: #fafcff !important;
  }
  
  .number_box {
    position: absolute;
    top: 168px;
    left: 40px;
    width: 415px;
    height: 160px;
    overflow-y: scroll;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .number_box::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 4px;
    scrollbar-arrow-color: red;
  }
  
  .number_box::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px;
    /* -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2); */
    background: #fff;
    scrollbar-arrow-color: red;
  }
  
  .number_box::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    /* -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2); */
    border-radius: 0;
    background: #fff;
  }
  
  .number_box div {
    width: 32px;
    margin: 5px 3px;
  }
  
  .numbers {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    background: #f7f7f9;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 3px;
  }
  
  .submit_btn {
    position: absolute;
    bottom: 41.5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 495px;
    padding: 0 130px;
  }
  
  .submit_btn div {
    width: 86px;
    height: 34px;
    background: #5694f7;
    border-radius: 10px;
    text-align: center;
    line-height: 35.5px;
    color: #fff;
  }
  
  .submit_btn .jixu {
    background: #fa4e53;
  }
  
  .container {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 1200px;
  }
  
  .time_box {
    height: 135px;
    background: #fff;
    padding: 15px 0;
  }
  
  .time_box_title {
    border-left: 5px solid #5694f7;
    font-size: 18px;
    font-weight: bold;
    padding-left: 20px;
  }
  
  .time_box_time {
    font-size: 36px;
    color: #666;
    height: 117px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
  
  .answer_card {
    margin-top: 20px;
    background: #fff;
  }
  
  .answer_card {
    padding: 15px 0;
  }
  
  .answer_card_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    padding: 0 10px;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 696px;
    overflow-y: scroll;
  }
  
  .answer_card_content .answer_card_s {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: #f7f7f7;
    border: 1px solid #dadadb;
    color: #666;
    border-radius: 2px;
    margin: 7px;
  }
  
  .answer_card_content div {
    width: 30px;
    height: 30px;
    margin: 8px;
  }
  
  .pages_box {
    position: absolute;
    bottom: 20px;
    right: 210px;
  }
  
  .wraps {
    width: 854px;
    margin: 10px auto 0 auto;
  }
  
  .answer_title {
    /* display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative; */
    height: 40px;
    font-size: 16px;
    color: #666;
  }
  
  .answer_title span:first-child {
    font-size: 26px;
    color: #5694f7;
    font-weight: bold;
  }
  
  .answer_title .big {
    /* width: 810px; */
    height: 40px;
    background: linear-gradient(90deg, #505ddf 0%, #64b0fe 100%);
    border-radius: 0px 10px 10px 0px;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 20px;
    padding: 0 27px;
    padding-right: 48px;
    position: absolute;
    left: -27px;
    z-index: 3;
    top: 0;
  }
  
  .answer_title .big img {
    position: absolute;
    width: 34px;
    right: 0;
    top: 12px;
    height: 17px;
  }
  
  .answer_title .small {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .small div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
  }
  
  .small div img {
    margin-right: 9px;
    width: 18px;
    height: 18px;
  }
  
  .small .times {
    color: #666666;
  }
  
  .small .card_btn {
    margin-left: 112px;
    color: #fa4e53;
  }
  
  .swiper_box {
    width: 854px;
    margin: 0 auto;
    min-height: 440px;
  }
  
  .subMiter {
    width: 222px;
    height: 42px;
    text-align: center;
    line-height: 42px;
    background: #5694f7;
    border-radius: 5px;
    margin: 20px 13px 0 13px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    border: none;
    cursor: pointer;
  }
  </style>
  <style>
  .swiper-container {
    width: 1092px;
    height: 100% !important;
    overflow: hidden;
  }
  
  .swiper-slide {
    width: 1092px;
    height: 100% !important;
    text-align: left !important;
    background: none !important;
    display: flex;
    align-items: flex-start !important;
  }
  
  .swiper-slide.swiper-slide-active {
    width: 1092px !important;
  }
  
  .answer {
    width: 1092px !important;
  }
  .child_box{
		margin-bottom: 16px;
	}
  </style>
  