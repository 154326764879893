<template>
  <div class="uinfo" ref="ioggg">
    <div class="uinfo_left">
      <div class="userdetail">
        <div class="user_title">个人信息<span></span></div>
        <div class="userde_info">
          <div class="userde_info_left">
            <img @click="atarVisible=true" :src="userinfod.avatar" alt="" />
            <div class="info_left_cece">
              <div class="ceceflex">{{userinfod.name}}</div>
              <div>{{userinfod.mobile}}</div>
            </div>
          </div>
          <!-- <div class="userde_info_right" @click="dialogVisible=true">修改昵称</div> -->
          <div class="userde_info_right" @click="e=>{
            dialogVisiblePwd=true
            code=''
            password=''
          }">修改密码</div>
        </div>
      </div>
      <div class="left_i">
        <div class="question_set" style="margin-bottom: 20px">特训<span style="weight:100px">（最近学习）</span></div>
        <div
          class="course_list"
          :style="{
            height: kanzkShow
              ? stutraData.length < 3
                ? 'auto'
                : '380px'
              : 'auto',
          }"
        >
          <div
            class="course_i"
            v-for="(item, index2) in stutraData"
            :key="index2"
            style="width: 100%"
            @click="goStyindex(0,item,item.training_id)"
          >
            <div class="cour_info" style="width: 100%">
              <div class="cour_details">
                <div class="cour_name">{{ item.training_name }}</div>
                <div class="cour_kankj">
                  <!-- <div style="width: 180px">
                    课程时长：<span>{{
                      item.training_statistics.count_time || 0
                    }}</span>
                    分钟
                  </div> -->
                  <div class="cour_progress" style="margin-left:0">
                    <div
                      class="courpro_lg"
                      :style="{
                        width: item.training_statistics.total_progress + '%',
                      }"
                    ></div>
                    <div class="cour_gard">
                      <div v-for="(item, index) in 4" :key="index"></div>
                    </div>
                  </div>
                  <div>
                    已完成：<span>{{
                      item.training_statistics.total_progress || 0
                    }}</span>
                    %
                  </div>
                </div>
              </div>
              <div
                class="cour_btn"
                v-if="item.training_statistics.total_progress == 100"
              >
                已完成
              </div>
              <div class="cour_btn" v-else style="background:#308DFF;color:#ffffff;">
                继续学习
              </div>
            </div>
          </div>
        </div>
        <div class="more_n" @click="kanzkShow = !kanzkShow">
          {{ kanzkShow ? "展开更多" : "收起"
          }}<img src="../../../assets/images/login/icon_tx_more.png" alt="" />
        </div>
      </div>
      <div class="left_i" >
        <div class="quenflex">
          <div class="question_set" style="margin-bottom: 20px">专题<div>（最近学习）</div></div>
          <div class="quenflex_input">
            <img src="../../../assets/search.png" alt="" />
            <input
              @keyup.enter="blurSeacrh"
              v-model="inputject"
              type="text"
              placeholder="输入专题关键词"
            />
          </div>
        </div>
        <el-collapse accordion v-model="activeName">
          <div class="elcoll" v-for="(subj, index4) in subjData" :key="index4">
            <el-collapse-item name="1">
              <template slot="title">
                <div class="collapse_title">
                  <div class="coll_name">{{ subj.catalogue_name }}</div>
                  <div class="coll_dec">
                    已添加<span>{{ subj.count }}</span
                    >个专题
                  </div>
                </div>
              </template>
              <div class="coll_list">
                <div
                  class="coll_i"
                  v-for="(jd, index5) in subj.subject"
                  :key="index5"
                  @click="goTopicinfo(jd)"
                >
                  <div class="coll_name">{{ jd.subject_name }}</div>
                  <div class="coll_dec">
                    已添加<span>{{ jd.content_nums }}</span
                    >个专题
                  </div>
                  <div class="coll_btn">查看详情</div>
                </div>
              </div>
            </el-collapse-item>
          </div>
        </el-collapse>
      </div>
      <div class="left_i" v-if="false">
        <template>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane :label="'收藏真题('+collectList.length+')'" name="first">
              <div class="writing_list">
                <div class="writing_i" v-for="(cell,index1) in collectList" :key="index1" @click="goAns(cell)">
                  <div class="writing_i_left">
                    <img src="@/assets/images/login/icon_tb_b.png" alt="" />
                    {{cell.content_info}}
                  </div>
                  <div class="writing_i_right">{{cell.counts}}</div>
                </div>
                <div class="pagedv">
                  <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="collectCount"
                    @current-change="getMyCollect"
                  >
                  </el-pagination>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="我的资料" name="second">
              <div style="width: 800px">
                <el-radio-group @tab-click="tableClick" v-model="tabPosition" style="margin-bottom: 30px;">
                  <el-radio-button label="1">特训</el-radio-button>
                  <el-radio-button label="2">专题</el-radio-button>
              </el-radio-group>
                <div class="cour_i" v-for="(fora,index2) in formaList" :key="index2">
                  <div class="cour_info">
                    <div class="cour_details">
                      <div class="cour_name">{{fora.name}}</div>
                      <div class="cour_kankj">
                        <div>
                          <img
                            src="../../../assets/images/login/icon_zt_zip.png"
                            alt=""
                            style="margin-right: 8px"
                          />PDF电子资料
                        </div>
                        <div style="width: 180px">
                          <!-- 共<span :style="{ color: backgChange(3) }">1</span> 份 -->
                        </div>
                      </div>
                    </div>
                    <div class="cour_btn" @click="forDoen(fora.file_url)">查看</div>
                  </div>
                </div>
                <div class="pagedv">
                  <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="formaCount"
                    @current-change="getMyInformation"
                  >
                  </el-pagination>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane :label="'错题巩固('+wrongList.length+')'" name="third">
              <el-table :data="wrongList" stripe style="width: 100%">
                <el-table-column prop="content_name" label="课程名称">
                </el-table-column>
                <el-table-column
                  prop="type"
                  label="类型"
                  width="80"
                  align="center"
                >
                <template slot="header" slot-scope="scope">
                  <el-dropdown @command=commandDro>
                  <span class="el-dropdown-link">
                    {{commanValue==1?'特训':'专题'}}<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="1">特训</el-dropdown-item>
                    <el-dropdown-item command="2">专题</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                </template>
                <template slot-scope="scope">
                  {{scope.row.type==2?'测验':'练习'}}
                </template>
                </el-table-column>
                <el-table-column
                  prop="exam_start_time"
                  label="巩固进度"
                  align="center"
                >
                  <template slot-scope="scope">
                    <div class="prol">
                      <div class="cour_progress">
                        <div
                          class="courpro_lg"
                          :style="{
                            width: (scope.row.recall_counts/scope.row.counts)*100 + '%',
                          }"
                        ></div>
                        <div class="cour_gard">
                          <div v-for="(item, index) in 4" :key="index"></div>
                        </div>
                      </div>
                      <div class="pnumv">{{scope.row.recall_counts}}/<span>{{scope.row.counts}}</span></div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="操作"
                  width="150px"
                  align="center"
                >
                  <template slot-scope="scope">
                    <div class="gubuttion" @click="goWorngd(scope.row)">立即巩固</div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="pagedv" style="margin: 20px auto;"><el-pagination
                      background
                      layout="prev, pager, next"
                      :total="wrongCount"
                      @current-change="getWrongQuestionRecall">
                    </el-pagination>
                    </div>
            </el-tab-pane>
          </el-tabs>
        </template>
      </div>
    </div>
    <div class="uinfo_right">
      <div class="uinfo_right_top" v-if="false" style="margin-bottom:30px">
        <div class="user_title">我的班主任</div>
        <div class="us_banzu"><img src="" alt="" />文文</div>
        <div class="banzu_pof">
          <div class="pof_left">
            <img
              src="@/assets/images/user/phone.png"
              alt=""
              style="height: 16px; margin-right: 6px"
            />
            联系电话
          </div>
          <div class="pof_right">1313123</div>
        </div>
        <div class="banzu_pof">
          <div class="pof_left">
            <img
              src="@/assets/images/user/wx.png"
              alt=""
              style="height: 14px; margin-right: 6px"
            />
            联系电话
          </div>
          <div class="pof_right">1313123</div>
        </div>
        <div class="stu_jiy">
          <div class="stu_jiy_title">
            <div>学习建议</div>
            <span>更新于2023-12-12</span>
          </div>
          <div class="stu_info">
            <img src="@/assets/images/login/icon_tj_ok.png" alt="" />
            <div>个人中心 - 首页个人中心 - 首页个人中心 - 首页</div>
          </div>
        </div>
      </div>
      <div class="right_i" style="margin-top:0">
              <div class="question_set" style="margin-bottom:20px;">题本</div>
              <div class="quenflex_input" style="margin:0 auto 30px auto" @click="inputClick">
                <img src="../../../assets/search.png" alt="">
                <!-- <input @click="inputClick" type="text" disabled placeholder="搜题"> -->
                <div style="color:#999">搜题</div>
            </div>
              <div class="question_i" @click="goEth('third')">
                <div class="question_left">
                   <div><img src="../../../assets/images/login/icon_tb_a.png" alt=""></div>
                   错题
                </div>
                <div class="question_num">{{ctatiInfo.error_num}}</div>
              </div>
              <div class="question_i" @click="goEth('notes')">
                <div class="question_left">
                   <div><img src="../../../assets/images/login/icon_tb_b.png" alt=""></div>
                  笔记
                </div>
                <div class="question_num" style="color:#308DFF;">{{ctatiInfo.note_count}}</div>
              </div>
              <div class="question_i" @click="goEth('first')">
                <div class="question_left">
                   <div><img src="../../../assets/images/login/icon_tb_c.png" alt=""></div>
                  收藏
                </div>
                <div class="question_num" style="color:#F7A243;">{{ctatiInfo.collect_count}}</div>
              </div>
              <div class="question_i" @click="goEth('second')">
                <div class="question_left">
                   <div><img style="width:22px;height:22px" src="../../../assets/images/login/woelss.png" alt=""></div>
                  资料
                </div>
                <div class="question_num" style="color:#ff3069;">{{ctatiInfo.Information_num}}</div>
              </div>
            </div>
    </div>
    <!--  -->
    <el-dialog
  title="修改昵称"
  :visible.sync="dialogVisible"
  width="30%"
  top="40vh">
   <div><el-input placeholder="请输入内容" v-model="userName">
    <template slot="prepend">新昵称</template>
  </el-input></div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="updateNickname">确 定</el-button>
  </span>
</el-dialog>
    <el-dialog
        title="修改密码"
        :visible.sync="dialogVisiblePwd"
        width="30%"
        top="40vh">
        <div>
        <el-input placeholder="请输入手机号" disabled v-model="mobile" class="inpuwit">
          <template slot="prepend">手机号</template>
        </el-input>
      </div>
      <div>
        <el-input placeholder="请输入验证码" v-model="code" class="inpuwit">
          <template slot="prepend">验证码</template>
          <el-button slot="append" @click="codeSend">{{ codeText }}</el-button>
        </el-input>
      </div>
      <div>
        <el-input placeholder="请输入密码" v-model="password" class="inpuwit">
          <template slot="prepend">新密码</template>
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisiblePwd = false">取 消</el-button>
    <el-button type="primary" @click="updatePassword">确 定</el-button>
  </span>
    </el-dialog>
  <el-dialog
    title="修改头像"
    :visible.sync="atarVisible"
    width="30%"
    top="35vh">
        <div>
            <el-upload
          class="avatar-uploader"
          action="https://erpapi.sz517.com/study/public/qiniuImageUpload"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          :headers="importHeaders">
          <img v-if="imageUrl" :src="imageUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
    </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="atarVisible = false">取 消</el-button>
    <el-button type="primary" @click="avatarChange">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
// (function() {var _53code = document.createElement("script");_53code.src = "https://tb.53kf.com/code/code/043988c5790032a0f9caef5a4257fd113/3";var s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(_53code, s);})();
import { getUserInfo, getStuTraining, getSubject,getSubjectStatistics,
  updateNickname,updatePassword,updateAvatar,getMyCollect,getMyInformation,getWrongQuestionRecall,
  getTeachers,getMobileCode} from "@/utils/erpapi.js";
import { mapState } from 'vuex'; // 导入mapState辅助函数
export default {
  data() {
    return {
      activeName:"",
      atarVisible:false,
      dialogVisible:false,
      dialogVisiblePwd:false,
      stutraData: [], //特训
      subjData: [],
      kanzkShow: true,
      inputject: "",
      userName:"",
      code:"",
      password:"",
      activeName: "first",
      ctatiInfo:{},//题本统计
      userinfod:{},
      imageUrl: '',
      collectCount:0,
      collectList:[],
      wrongList:[],
      wrongCount:0,
      formaList:[],
      formaCount:0,
      commanValue:1,
      tabIndex:0,
      tabShow:true,
      tabPosition:1,
      codeText: "发送验证码",
      timer: "",
      mobile:"",
      importHeaders: {
        "verify-token": localStorage.getItem("UserToken"),
      },
    };
  },
  computed: {
    ...mapState(['pathName']) // 将指定的状态名称添加到计算属性中
  },
  watch:{
    tabPosition(val){
      this.getMyInformation()
    }
  },
  components: {},
  created() {
console.log(this.$store.state.pathName)
    this.getStuTraining();
    this.getSubject();
    this.getSubjectStatistics()
    this.getUserInfo()
    // this.getMyCollect()
    // this.getMyInformation()
    // this.getWrongQuestionRecall()
    this.getTeachers()
  },
  methods: {
    goEth(name){
      this.$router.push("/thatperson?name=" + name);
    },
    // 班主任
    getTeachers(){
      getTeachers().then(res=>{
         
      })
    },
    // 错题巩固
    goWorngd(data){
     console.log(data)
     this.$router.push("/erroranswer?id=" + data.content_id + "&type=" + data.type+"&source="+this.commanValue+"&name="+data.content_name);
    },
    // 
        goAns(data){
          if(data.source==1){
            this.$router.push("/answer?id=" + data.content_id+"&type=" + data.type);
          }else{
            this.$router.push("/Cetopanswer?id=" + data.content_id + "&type=" + data.type);
          }
        
        },
          commandDro(e){
            this.commanValue=e
            this.getWrongQuestionRecall()
          },
          // 错题巩固
          getWrongQuestionRecall(page){
        getWrongQuestionRecall({
          page:page||1,
          limit:10,
          source:this.commanValue
        }).then(res=>{
          this.wrongList=res.data.list
          this.wrongCount=res.data.count
        })
      },
          // 发送验证码
    codeSend() {
      if (this.codeText !== "发送验证码") {
        return;
      }
      if (this.userPhone == "") {
        this.$message.error({
          message: "手机号码不能为空",
          showClose: true,
        });
        return;
      }

      getMobileCode({
        mobile: this.mobile,
        type:2,
      }).then((res) => {
        this.$message.success({
          message: res.msg,
          showClose: true,
        });
      });
      let time = 60;
      this.timer = setInterval(() => {
        if (time > 0) {
          time--;
          this.codeText = "倒计时" + time + "秒";
        } else {
          clearInterval(this.timer);
          this.codeText = "发送验证码";
        }
      }, 1000);
    },
    // 我的收藏
    getMyCollect(val){
      getMyCollect({
       page:val||1,
       limit:10
      }).then(res=>{
        this.collectCount=res.data.count
        this.collectList=res.data.list
      })
    },
    // 我的资料
    getMyInformation(page){
      getMyInformation({
        page:page||1,
        limit:10,
        source:this.tabPosition
      }).then(res=>{
           this.formaList=res.data.list
           this.formaCount=res.data.count
           console.log(res.data)
      })
    },
    tableClick(){
      console.log('888')
      this.getMyInformation()
    },
    // 下载
    forDoen(url){
       window.open(url);
    },
    
    // 用户信息
    getUserInfo(){
      getUserInfo().then(res=>{
        this.userinfod=res.data
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        this.$store.state.pathName = res.data
        this.mobile=res.data.mobile
      })
    },
    // 修改用户昵称
    updateNickname(){
      updateNickname({
        name:this.userName
      }).then(res=>{
        this.dialogVisible=false
         this.getUserInfo()
      })
    },
    // 修改用户密码
    updatePassword(){
      if(this.code.length <= 0){
        this.$message.error('请输入验证码！');
        return
      } else if(this.code.length != 6){
        this.$message.error('请输入6位验证码！');
        return
      }
      if(this.password.length <= 0){
        this.$message.error('请输入密码！');
        return
      } else if(this.password.length <= 5){
        this.$message.error('请输入6位及以上密码！');
        return
      }
      updatePassword({
        mobile:this.mobile,
        code:this.code,
        password:this.password
      }).then(res=>{
        this.$message.success({
          message: res.msg,
          showClose: true,
        });
        this.dialogVisiblePwd=false
      })
    },
    // 题本统计
    getSubjectStatistics(){
        getSubjectStatistics().then(res=>{
          this.ctatiInfo=res.data
        })
      },
    handleClick(tab, event) {
      console.log(tab.index)
      console.log(this.tabIndex)
      if(tab.index==1&&this.tabIndex==tab.index){
         this.tabShow=!this.tabShow
         this.getMyInformation()
      }
      this.tabIndex=tab.index
    },
    // 修改头像
    avatarChange(){
      updateAvatar({
        avatar:this.imageUrl
      }).then(res=>{
        this.atarVisible=false
        this.getUserInfo()
      })
    },
          // 题搜索
        inputClick(){
        this.$router.push('/courseSearch')
    },
    // 专题搜索
    blurSeacrh() {
      this.getSubject();
      this.activeName="1"
    },
    // 专题详情
    goTopicinfo(data) {
      this.$router.push("/topicinfo?id=" + data.id);
    },
    getStuTraining() {
      getStuTraining().then((res) => {
        this.stutraData = res.data;
      });
    },
    // 专题
    getSubject() {
      getSubject({
        name: this.inputject,
      }).then((res) => {
        this.subjData = res.data;
      });
    },
    handleAvatarSuccess(res, file) {
      console.log(res)
        this.imageUrl = res.data.src;
      },
      beforeAvatarUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isLt2M;
      },
  },
};
</script>

<style scoped="scoped" lang="scss">
/deep/.el-dropdown-link{
  color: #ffffff;
}
/deep/.avatar-uploader{
  width: 180px;
  margin: 0 auto;
}
.right_i{
      width: 300px;
      background: #FFFFFF;
      box-shadow: 0px 0px 10px 0px #E8EDF3;
      border-radius: 6px;
      padding: 30px;
      margin-top: 30px;
}
  .question_i{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 1px solid #F7F7F7;
    .question_left{
      display: flex;
      align-items: center;
      div{
        width: 40px;
        height: 40px;
        background: #F5F4F4;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #393F47;
        margin-right: 10px;
        img{
          width: 18px;
          height: 18px;
        }
      }
    }
    .question_num{
      font-size: 18px;
      font-family: DIN Next LT Pro;
      font-weight: bold;
      color: #FF564C;
    }
  }
.prol {
  display: flex;
  align-items: center;
  .pnumv {
    margin-left: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    span {
      font-size: 18px;
      font-family: DIN Next LT Pro;
      font-weight: bold;
      color: #308dff;
    }
  }
}
.cour_progress {
  position: relative;
  width: 100px;
  height: 10px;
  margin-left: 35px;
  margin-right: 15px;
  background: #d6e8ff;
  border-radius: 2px;
  .courpro_lg {
    position: absolute;
    height: 10px;
    z-index: 90;
    width: 50%;
    background: #308dff;
    top: 0;
    left: 0;
    border-radius: 2px;
  }
  .cour_gard {
    position: absolute;
    z-index: 99;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    div {
      width: 20px;
      height: 10px;
      background: #e4050500;
      border-right: 4px solid #ffffff;
      margin: 0;
    }
  }
}
.gubuttion {
  width: 100px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 16px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-align: center;
  line-height: 32px;
  margin: 0 auto;
}
/deep/.el-table thead tr > th {
  height: 50px;
  background-color: #308dff;
  color: #ffffff;
}
/deep/.el-table__header-wrapper {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
// 更改表格每行背景颜色
/deep/.el-table tbody tr > td {
  height: 75px;
}
/deep/.el-table td {
  border-bottom: none;
}
/deep/.el-table__body-wrapper {
  border-left: 1px solid #e4e8f2;
  border-right: 1px solid #e4e8f2;
  border-top: none;
}
.pagedv {
  margin: 10px auto;
  text-align: center;
}
.writing_list {
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .writing_i {
    width: 390px;
    height: 70px;
    background: #f8f9fb;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 30px;
    .writing_i_left {
      display: flex;
      align-items: center;
      img {
        width: 19px;
        height: 19px;
        margin-right: 15px;
      }
    }
    .writing_i_right {
      width: 40px;
      height: 22px;
      background: #308dff;
      border-radius: 11px;
      font-size: 14px;
      font-family: Arial;
      font-weight: 400;
      color: #ffffff;
      line-height: 22px;
      text-align: center;
    }
  }
}
//
.uinfo {
  width: 1200px;
  margin: -30px auto 30px auto;
  display: flex;
  justify-content: space-between;
  overflow-y: scroll;
  .uinfo_left {
    width: 870px;
    .userdetail {
      box-sizing: border-box;
      width: 870px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px #e8edf3;
      border-radius: 6px;
      padding: 30px;
      .userde_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
        .userde_info_left {
          display: flex;
          align-items: center;
          img {
            width: 90px;
            height: 90px;
            border-radius: 50%;
            margin-right: 40px;
          }
          .info_left_cece {
            height: 60px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #393f47;
          }
        }
        .userde_info_right {
          width: 94px;
          height: 40px;
          background: #ffffff;
          border: 1px solid #ebebeb;
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #393f47;
        }
      }
    }
  }
  .uinfo_right {
    width: 300px;
  }
}
.user_title {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #393f47;
}
.uinfo_right_top {
  width: 300px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px #e8edf3;
  border-radius: 6px;
  padding: 30px;
  .us_banzu {
    margin-top: 30px;
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 14px;
    }
  }
  .banzu_pof {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 14px;
    .pof_left {
      display: flex;
      align-items: center;
    }
  }
}
.stu_jiy {
  margin-top: 14px;
  width: 246px;
  height: 116px;
  background: #fafafa;
  border: 1px solid #f1f1f1;
  border-radius: 6px;
  padding: 20px;
  .stu_jiy_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #393f47;
    }
    span {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
  }
  .stu_info {
    margin-top: 10px;
    display: flex;
    img {
      width: 19px;
      height: 19px;
      margin-right: 10px;
    }
    div {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #393f47;
    }
  }
}
//
.course_list {
  overflow: hidden;
  .course_i {
    width: 810px;
    height: 110px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .cour_info {
      width: 690px;
      padding: 0 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .cour_btn {
        width: 100px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 16px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        text-align: center;
        line-height: 32px;
      }
      .cour_details {
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .cour_name {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #393f47;
        }
        .cour_kankj {
          display: flex;
          align-items: center;
          .cour_progress {
            position: relative;
            width: 100px;
            height: 10px;
            margin-left: 35px;
            margin-right: 15px;
            background: #d6e8ff;
            border-radius: 2px;
            .courpro_lg {
              position: absolute;
              height: 10px;
              z-index: 90;
              width: 50%;
              background: #308dff;
              top: 0;
              left: 0;
              border-radius: 2px;
            }
            .cour_gard {
              position: absolute;
              z-index: 99;
              width: 100%;
              top: 0;
              left: 0;
              display: flex;
              align-items: center;
              div {
                width: 20px;
                height: 10px;
                background: #e4050500;
                border-right: 4px solid #ffffff;
                margin: 0;
              }
            }
          }
          div {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            margin-right: 8px;
            span {
              font-size: 18px;
              color: #308dff;
              font-weight: bold;
            }
          }
        }
      }
    }
    .cour_img {
      width: 120px;
      height: 110px;
      background: linear-gradient(90deg, #0fa2ff, #17bdff);
      border-radius: 6px 0px 0px 6px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 30px;
        height: 30px;
      }
      div {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        margin-top: 10px;
      }
    }
  }
}
.question_set {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #393f47;
  display: flex;
  align-items: baseline;
}
.left_i {
  width: 870px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px #e8edf3;
  border-radius: 6px;
  margin-bottom: 30px;
  padding: 30px;
  box-sizing: border-box;
  margin-top: 30px;
}
.more_n {
  width: 180px;
  height: 40px;
  background: #f7f7f7;
  border-radius: 20px;
  margin: 10px auto;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #393f47;
  img {
    width: 9px;
    height: 10px;
    margin-left: 6px;
  }
}
.collapse_title {
  display: flex;
  align-items: center;
  .coll_name {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #393f47;
    padding-left: 10px;
  }
  .coll_dec {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-left: 10px;
    display: flex;
    align-items: center;
    span {
      color: #308dff;
      font-weight: bold;
      display: block;
      margin: 0 4px;
    }
  }
}
.elcoll {
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  margin-bottom: 20px;
}
/deep/.el-collapse-item__header {
  background: #fafafa;
  height: 60px;
}
.quenflex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.quenflex_input {
  width: 200px;
  height: 40px;
  background: #f7f7f7;
  border: 1px solid #ebebeb;
  border-radius: 20px;
  display: flex;
  align-items: center;
  input {
    height: 38px;
    border: none;
    background: #f7f7f7;
    width: 100px;
  }
  img {
    width: 17px;
    height: 17px;
    margin: 0 10px 0 20px;
  }
}
.coll_list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 30px 30px 0 30px;
  .coll_i {
    width: 360px;
    height: 171px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 6px;
    padding-top: 24px;
    position: relative;
    margin-bottom: 30px;
    .coll_btn {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 360px;
      height: 50px;
      background: #fafafa;
      border-radius: 6px;
      line-height: 50px;
      text-align: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #393f47;
    }
    .coll_name {
      width: 324px;
      height: 41px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      line-height: 26px;
      margin: 0 auto 0 10px;
    }
    .coll_dec {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-left: 10px;
      display: flex;
      align-items: center;
      margin-left: 10px;
      margin-top: 18px;
      span {
        color: #308dff;
        font-weight: bold;
        display: block;
        margin: 0 4px;
      }
    }
  }
}
.cour_i {
  width: 810px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  padding: 20px 0;
  margin-bottom: 20px;
}
.cour_info {
  width: 810px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .cour_btn {
    width: 100px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 16px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    text-align: center;
    line-height: 32px;
  }
  .cour_details {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .cour_name {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #393f47;
    }
    .cour_kankj {
      display: flex;
      align-items: baseline;
      .cour_progress {
        position: relative;
        width: 100px;
        height: 10px;
        margin-left: 35px;
        margin-right: 15px;
        background: #d6e8ff;
        border-radius: 2px;
        .courpro_lg {
          position: absolute;
          height: 10px;
          z-index: 90;
          width: 50%;
          background: #308dff;
          top: 0;
          left: 0;
          border-radius: 2px;
        }
        .cour_gard {
          position: absolute;
          z-index: 99;
          width: 100%;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          div {
            width: 20px;
            height: 10px;
            background: #e4050500;
            border-right: 4px solid #ffffff;
            margin: 0;
          }
        }
      }
      div {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        margin-right: 8px;
        span {
          font-size: 18px;
          color: #308dff;
          font-weight: bold;
        }
      }
    }
  }
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  /deep/.el-icon-plus{
    border: 1px dashed #999;
  }
  .ceceflex{
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  .nibtn{
    color: #409EFF;
  }
  .inpuwit{
    width: 300px;
    margin-bottom: 15px;
  }
  // .el-dialog__wrapper{
  //   z-index: 10 !important;
  // }
</style>