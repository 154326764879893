<template>
	<div class="container">
		<!-- <userInfo></userInfo> -->
		<div class="content">
			<Calendar :markDate="dateArray" @changeMonth="changeDate" @choseDay="clickDay"></Calendar> <!-- @choseDay="clickDay" 点击日期筛选-->
			<div class="events">
				<div class="title">{{eventTitle}}</div>
				<div class="event_con">
					<div class="event_list" v-for="(item,index) in aEvents" @click="selectClick(index)" v-if="item.date"> <!-- 点击日期筛选 -->
					<!-- <div class="event_list" v-for="(item,index) in aEvents" @click="selectClick(index)"> -->
						<div class="event_tit">{{item.name}}</div>
						<div class="time_box">
							<div v-if="item.status_text == '报名已结束'">{{item.status_text}}</div>
							<div v-else>
								<div>开始时间：{{item.start_time}}</div>
								<div style="margin-top:10px;">结束时间：{{item.end_time}}</div>
							</div>
						</div>
						<div class="selects">
							<transition name="el-zoom-in-center">
								<div v-if="current == index && slidShow" class="selectedBtn"
									:style="item.exam_url == ''?'justify-content: center;':''">
									<div v-if="item.exam_url !== ''" @click.stop="signClick(item.exam_url,item.status_text)"
										:style="item.status_text == '报名已结束'?'background: #FA4E53;':''">
										{{item.status_text == '报名已结束'?'报名已结束':'前往报名'}}
									</div>
									<!-- <div v-else style="background: #FA4E53;" @click.stop="signClick(item.id,2)">取消标记</div> -->
									<div @click.stop="jumpClick(item.info_url)">查看详情</div>
								</div>
							</transition>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Calendar from 'vue-calendar-component'
	import {
		getCalendar,
		getexamSign
	} from '@/utils/api.js'
	import {
		EventBus
	} from '@/eventBus/eventBus'
	export default {
		components: {
			Calendar
		},
		data() {
			return {
				dateArray: [],
				navList: [{
						path: '/classList',
						name: '首页',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 0
					},
					{
						path: '/Examination',
						name: '考试日历',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 3
					}
				],
				eventTitle: '本月全部信息',
				aEvents: [],
				slidShow: true,
				current: 0,
			}
		},
		created() {
			this.getData()
			EventBus.$emit('navList', this.navList)
		},
		methods: {
			async getData() {
				var day2 = new Date();
				day2.setTime(day2.getTime());
				// var s2 = day2.getFullYear() + "/" + (day2.getMonth() + 1) + "/" + day2.getDate();
				// console.log(s2)
				this.year = day2.getFullYear()
				this.month = day2.getMonth() + 1
				// let day = day2
				let aaa = []
				await getCalendar({
					year: this.year,
					month: this.month
				}).then(res => {
					this.aEvents = res.data.list
					for (let item of this.aEvents) {
						// item.date = s2
						// console.log((item.exam_end_time - item.exam_start_time) / 86400)
						if(item.status_text != '报名已结束'){
							item.date = this.getAllDate(item.start_time.split(' ')[0], item.end_time.split(' ')[0])
							this.dateArray = this.unique(this.dateArray.concat(item.date))
						}
						
						
					}
				})
				console.log()
			},
			// 选中某天
			clickDay(data) {
				for (let item of this.aEvents) {
					item.date = ''
				}
				let times = data.split('/')
				let year = times[0]
				let month = times[1]
				let day = times[2]
				this.eventTitle = year+'年'+month+'月'+day+'日信息'
				
				this.slidShow = false
				this.$EventCalendar.toDate(data)
				let mon = data.split('/')[1] < 10 ? '0' + data.split('/')[1] : data.split('/')[1]
				let tian = data.split('/')[2] < 10 ? '0' + data.split('/')[2] : data.split('/')[2]
				for (let item of this.aEvents) {
					item.data = ''
					let smon = item.start_time.split('-')[1]
					let sday = item.start_time.split('-')[2].split(' ')[0]
					let emon = item.end_time.split('-')[1]
					let eday = item.end_time.split('-')[2].split(' ')[0]
					if (smon == mon && mon == emon) { //全是当月的情况下
						if (sday <= tian && tian <= eday) {
							item.date = data
						}
					} else if (smon < mon && mon == emon) { //开始时间不是当月结束时间是当月的情况下
						if (tian <= eday) {
							item.date = data
						}
					} else if (smon = mon && mon < emon) { //开始时间是当月，结束时间不是当月的情况下
						if (tian >= sday) {
							item.date = data
						}
					} else if (smon < mon && mon < emon) { //开始和结束时间都不是当月的情况下
						item.date = data
					}
				}
				this.$forceUpdate()
			},
			// 左右点击切换月份
			async changeDate(data) {
				
				this.aEvents = []
				this.dateArray = []
				console.log(data)
				let times = data.split('/')
				let year = times[0]
				let month = times[1]
				let day = times[2]
				this.eventTitle = year+'年'+month+'月全部信息'
				this.year = year
				this.month= month
				await getCalendar({
					year: this.year,
					month: this.month
				}).then(res => {
					this.aEvents = res.data.list
					for (let item of this.aEvents) {
						// item.date = s2
						// console.log((item.exam_end_time - item.exam_start_time) / 86400)
						if(item.status_text != '报名已结束'){
							item.date = this.getAllDate(item.start_time.split(' ')[0], item.end_time.split(' ')[0])
							this.dateArray = this.unique(this.dateArray.concat(item.date))
						}
						// item.date = this.getAllDate(item.start_time.split(' ')[0], item.end_time.split(' ')[0])
						// this.dateArray = this.unique(this.dateArray.concat(item.date))
					}
					console.log(this.dateArray)
				})
			},
			selectClick(index) {
				this.current = index
				this.slidShow = true
			},
			signClick(url, text) { //标记
				window.open(url)
			},
			jumpClick(url) {
				// window.location.href = url
				window.open(url)
			},
			getAllDate(start, end) { //求开始时间和结束时间中间的时间
				let dateArr = []
				let startArr = start.split('-')
				let endArr = end.split('-')
				let db = new Date()
				db.setUTCFullYear(startArr[0], startArr[1] - 1, startArr[2])
				let de = new Date()
				de.setUTCFullYear(endArr[0], endArr[1] - 1, endArr[2])
				let unixDb = db.getTime()
				let unixDe = de.getTime()
				let stamp
				const oneDay = 24 * 60 * 60 * 1000;
				for (stamp = unixDb; stamp <= unixDe;) {
					dateArr.push(this.format(new Date(parseInt(stamp))))
					stamp = stamp + oneDay
				}
				return dateArr
			},
			format(time) { //拼接日期
				let ymd = ''
				let mouth = (time.getMonth() + 1) >= 10 ? (time.getMonth() + 1) : ('0' + (time.getMonth() + 1))
				let day = time.getDate() >= 10 ? time.getDate() : ('0' + time.getDate())
				ymd += time.getFullYear() + '-' // 获取年份。
				ymd += mouth + '-' // 获取月份。
				ymd += day // 获取日。
				return ymd // 返回日期。
			},
			unique(arr) { //数组去重
				return Array.from(new Set(arr))
			}
		}

	}
</script>
<style type="text/css">
	.wh_jiantou1[data-v-2ebcbc83] {
		width: 12px;
		height: 12px;
		border-top: 2px solid #222 !important;
		border-left: 2px solid #222 !important;
	}

	.wh_jiantou2[data-v-2ebcbc83] {
		width: 12px;
		height: 12px;
		border-top: 2px solid #222 !important;
		border-right: 2px solid #222 !important;
	}

	.wh_top_changge li {
		color: #222 !important;
		font-size: 24px !important;
		font-weight: bold !important;
	}

	.wh_container {
		margin: 0 !important;
		max-width: 450px !important;
	}

	.wh_content_all {
		background: #fff !important;
	}

	.wh_content_item {
		color: #222 !important;
		height: 70px !important;
		width: 14.28% !important;
		font-size: 16px !important;
	}

	.wh_top_tag {
		/* color: #222 !important; */
		font-weight: bold;
		font-size: 20px !important;
	}


	.wh_content_item>.wh_isMark {
		/* color: #fff !important; */
		background: #fff !important;
		/* border-bottom: 1px solid #47BE5E !important; */
		font-size: 16px !important;
	}

	.wh_content:last-child .wh_content_item>.wh_isMark::before {
		content: "";
		position: absolute;
		/* left: 0; */
		top: 36px;
		width: 5px;
		height: 5px;
		border-radius: 50%;
		background-color: #FA4E53
	}

	.wh_content_item .wh_chose_day {
		background: #fff !important;
		border: 1px solid red !important;
	}

	.wh_content_item .wh_isToday {
		color: #fff !important;
		background: #47BE5E !important;
		border: 1px solid #47BE5E !important
	}

	.wh_content_item:first-child .wh_top_tag,
	.wh_content_item:last-child .wh_top_tag {
		color: #FA4E53 !important;
	}
</style>
<style scoped="scoped">
	.content {
		width: 1132px;
		background: #fff;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px;
		position: relative;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
	}

	.events {
		width: 450px;
		/* background: red; */
		height: 475px;
	}

	.selectedBtn {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 100%;
		background: rgba(255, 255, 255, .7);
		color: #fff;
		padding: 0 50px;
		border-radius: 5px;
	}

	.selectedBtn div {
		padding: 5px 10px;
		border-radius: 4px;
		background: #00D3AA;
		font-size: 18px;
	}

	.event_con {
		margin-top: 20px;
		height: 400px;
		overflow-y: scroll;
		padding: 15px;
	}

	.events .title {
		text-align: center;
		font-size: 20px;
		font-weight: bold;
	}

	.event_list {
		padding: 15px;
		box-sizing: border-box;
		box-shadow: 0px 0px 20px 0px rgba(47, 102, 228, 0.1);
		margin-bottom: 20px;
		border-radius: 10px;
		background: #fff;
		cursor: pointer;
		position: relative;
	}

	.event_tit {
		font-size: 16px;
		line-height: 1.5;
		padding-bottom: 5px;
		border-bottom: 1px solid #f1f1f1;

	}

	.selects {
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		z-index: 99;
		/*  */
	}

	.time_box {
		/* display: flex; */
		/* align-items: center; */
		/* justify-content: space-between; */
		/* border-top: 1px solid #ccc; */
		margin-top: 10px;
		padding-top: 10px;
	}
</style>
