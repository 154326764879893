<template>
	<div class="remTmine">
		<!-- <div>{{hour? hourString+':'+minuteString+':'+secondString : minuteString+':'+secondString}}</div> -->
        <div class="tim_num" v-if="day>0">{{ day }}</div>
        <span class="tim_name" v-if="day>0">天</span>
        <div class="tim_num" v-for="(hr,index) in splitNum(Number(hourString))" :key="index">{{ hr }}</div>
        <span class="tim_name">时</span>
        <div class="tim_num" v-for="(mi,indexa) in splitNum(Number(minuteString))" >{{ mi }}</div>
        <span class="tim_name">分</span>
        <div class="tim_num" v-for="(se,indexb) in splitNum(Number(secondString))" >{{ se }}</div>
        <span class="tim_name">秒</span>
	</div>
</template>
<script>
	export default {
		data() {
      return {
        hour: '',
        day:'',
        minute: '',
        second: '',
        timer: ''
      };
    },
    props: {
      remainTime: {    // 倒计时间总秒数
        default: ''
      },
    },
      mounted () {
      if (this.remainTime > 0) {
        this.day=Math.floor((this.remainTime / 3600) / 24)
        this.hour = Math.floor((this.remainTime / 3600) % 24)
        this.minute = Math.floor((this.remainTime / 60) % 60)
        this.second = Math.floor(this.remainTime % 60)
        this.countDowm()
      }
    },
    methods: {
        splitNum(num){
         return JSON.stringify(num).split('')
        },
        // 
      countDowm () {
        var self = this
        clearInterval(this.timer)
        this.timer = setInterval(function () {
          if (self.hour === 0) {
            if (self.minute !== 0 && self.second === 0) {
              self.second = 59
              self.minute -= 1
            } else if (self.minute === 0 && self.second === 0) {
              self.second = 0
              self.$emit('countDowmEnd', true)
              clearInterval(self.timer)
            } else {
              self.second -= 1
            }
          } else {
            if (self.minute !== 0 && self.second === 0) {
              self.second = 59
              self.minute -= 1
            } else if (self.minute === 0 && self.second === 0) {
              self.hour -= 1
              self.minute = 59
              self.second = 59
            } else {
              self.second -= 1
            }
          }
        }, 1000)
      },
      formatNum (num) {
        return num < 10 ? '0' + num : '' + num
      }
    },
    computed: {
      hourString () {
        return this.formatNum(this.hour)
      },
      minuteString () {
        return this.formatNum(this.minute)
      },
      secondString () {
        return this.formatNum(this.second)
      }
    }
	}
</script>
<style>
.remTmine{
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.tim_num{
    background: #FFFFFF;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
    border-radius: 4px;
    padding: 2px 7px;
    border: 2px solid #5694F7;
    color: #5694F7;
    font-size: 40px;
    font-weight: bold;
    -webkit-box-reflect: below 1px linear-gradient(transparent, rgba(0, 0, 0, .15));
    margin-right: 10px;
}
    .tim_name{
    margin-right: 10px;
    }
</style>
