<template>
  <div>
    <!-- <userInfo></userInfo> -->
    <div class="content">
      <div class="warps">
        <div class="kt_box" v-if="ggAdt">
          <div
            v-for="(item, index) in answerList"
            :key="index"
            class="swiper_box"
          >
            <div class="boxs">
              <div class="xuhao">{{ index + 1 + "、" }}</div>
              <div class="answer_box">
                <span v-for="(item2, index2) in item.title_text" :key="index2">
                  <span v-html="item2"></span>
                  <span v-if="index2 + 1 !== item.title_text.length">（ </span>
                  <input
                    :name="'name' + index2"
                    class="inputs"
                    oncopy="return false"
                    onpaste="return false"
                    :class="item.wd === false ? 'errinputs' : ''"
                    v-model="item.answer_text[index2].answer"
                    :disabled="true"
                    :style="{ width: text(item.answer_text[index2].answer) }"
                    v-if="index2 + 1 !== item.title_text.length"
                  />
                  <span v-if="index2 + 1 !== item.title_text.length">）</span>
                </span>
                <!-- <span v-html="item.title_text"></span> -->
              </div>
            </div>
          </div>
          <div class="kt_btns">
            <div></div>
            <div class="kt_btn" @click="ksdtClick()">开始答题</div>
          </div>
        </div>
        <!-- 	<el-carousel :interval="5000" :autoplay="false" :initial-index="current" arrow="never" 
					class="swiper_box" indicator-position="none" ref="cardShow" style="width: 100%;" v-else> -->
        <template v-else>
          <div
            v-for="(item, index) in answerList"
            :key="index"
            class="swiper_box"
            v-if="current == index"
          >
            <div class="boxs">
              <div class="xuhao">{{ index + 1 + "、" }}</div>
              <div class="answer_box">
                <span v-for="(item2, index2) in item.title_text" :key="index2">
                  <span v-html="item2"></span>
                  <span v-if="index2 + 1 !== item.title_text.length">（ </span>
                  <input
                    :name="'name' + index2"
                    class="inputs"
                    @keydown.tab.prevent="byTab()"
                    :class="
                      error === true && errorCurrent == index2
                        ? 'errinputs'
                        : ''
                    "
                    oncopy="return false"
                    onpaste="return false"
                    v-model="item.answer_text[index2].user_answer"
                    :disabled="disbade"
                    :style="{
                      width: text(item.answer_text[index2].user_answer),
                    }"
                    v-if="index2 + 1 !== item.title_text.length"
                    @blur="inputBlur(index, index2)"
                    autocomplete="off"
                  />
                  <span v-if="index2 + 1 !== item.title_text.length">）</span>
                </span>
              </div>
            </div>
            <div class="kt_btns">
              <div></div>
              <div class="btns">
                <div class="kt_btnss" v-if="disbade" @click="againClick(index)">
                  重答此题
                </div>
                <div class="kt_btn" @click="cxggClick">重新巩固</div>
                <!-- <div class="kt_btn" @click="zwClick">本题已掌握</div> -->
                <div
                  class="kt_btn"
                  v-if="item.collect == 0"
                  @click="scClick(item.sub_id, item.id)"
                >
                  收藏本知识点
                </div>
                <div
                  class="kt_btn"
                  style="background: #fa4e53"
                  v-if="item.collect > 0"
                  @click="scClick(item.sub_id, item.id)"
                >
                  取消收藏
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- </el-carousel> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCourseSubKnowledge,
  submitKnowledgePlay,
  addKnowledgeCollect,
  getCollectKnowledge,
} from "@/utils/api.js";
export default {
  data() {
    return {
      navList: [],
      ggAdt: true,
      id: "",
      answerList: [],
      current: 0,
      inputModle: [],
      disbade: false,
      error: false,
      errorCurrent: null,
      times: "",
      dtTime: 0,
      error_num: 0,
      title: "",
      zId: "",
      reViewType: "",
      a_num: 0,
      nextId: "",
    };
  },
  props: {
    myid: {
      type: Number,
      default: "",
    },
    myzId: {
      type: String || Number,
      default: "",
    },
    mytitle: {
      type: String,
      default: "",
    },
  },
  created() {
    this.parameter(this.$route.query.parameter);
    this.getData();
    let nextId = localStorage.getItem("nextId");
    if (nextId) {
      this.nextId = nextId;
    }
    // this.$nextTick(() => {
    // 	// // 禁用右键
    // 	// document.oncontextmenu = new Function("event.returnValue=false");
    // 	// 禁用选择
    // 	document.onselectstart = new Function("event.returnValue=false");
    // });
  },
  methods: {
    zwClick() {
      if (this.answerList.length > this.current + 1) {
        // this.$refs.cardShow.next()
        this.current++;
      } else {
        let arrs = {
          id: this.id,
          title: this.title,
          navList: this.navList,
          gonggu: 0,
        };
        submitKnowledgePlay({
          sub_id: this.id,
          error_num: this.error_num,
          ques_num: this.answerList.length,
          second: this.dtTime,
        }).then((res) => {
          if (this.a_num == 0) {
            console.log(this.nextId);
            if (this.nextId == "last") {
              this.$confirm(
                "恭喜你！本套课程已经学习完毕，是否返回学习中心？",
                "恭喜你！",
                {
                  confirmButtonText: "确定",
                  cancelButtonText: "返回课程列表",
                  type: "success",
                }
              )
                .then(() => {
                  // this.$router.go(-1)
                  this.$router.replace("/classList");
                })
                .catch(() => {
                  this.$router.go(-2);
                });
            } else {
              this.$confirm(
                "恭喜你！本小节已经全部掌握了，是否开始学习下一小节？",
                "恭喜你！",
                {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "success",
                }
              ).then(() => {
                let playerInfo = JSON.parse(localStorage.getItem("playerInfo"));
                playerInfo.id = parseInt(this.nextId);
                localStorage.setItem("playerInfo", JSON.stringify(playerInfo));
                this.$router.replace("/VideoPlay");
              });
            }
          } else {
            this.$confirm(
              "恭喜你，本小节知识点已经全部巩固。是否前往小节真题闯关?",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "返回上一页",
                type: "success",
              }
            )
              .then(() => {
                this.$router.push(
                  "/Answer?parameter=" +
                    encodeURIComponent(encodeURIComponent(JSON.stringify(arrs)))
                );
              })
              .catch(() => {
                this.$router.go(-1);
              });
          }
        });
        // console.log('')
      }
    },
    async getData() {
      if (this.reViewType == "sc") {
        console.log(1);
        await getCollectKnowledge({
          sub_id: this.id,
        }).then((res) => {
          this.answerList = res.data;
          for (let item of this.answerList) {
            item.title_text = item.title_text
              .replace(/\n/g, "<br>")
              .split("_____");
          }
        });
      } else {
        await getCourseSubKnowledge({
          id: this.id,
        })
          .then((res) => {
            this.answerList = res.data;
            for (let item of this.answerList) {
              item.title_text = item.title_text
                .replace(/\n/g, "<br>")
                .split("_____");
            }
          })
          .catch((res) => {
            let that = this;
            submitKnowledgePlay({
              sub_id: that.id,
              error_num: 0,
              ques_num: 0,
              second: 0,
            }).then((res) => {});
          });
      }
    },
    parameter(arrx) {
      let opdata = arrx ? JSON.parse(decodeURIComponent(arrx)) : {};
      if (this.myid) {
        opdata = {
          id: this.myid,
          zId: this.myzId,
          title: this.mytitle,
        };
      }
      console.log(opdata);
      this.id = opdata.id || 5815;
      //   this.navList = opdata.navList;
      this.zId = opdata.zId || 2380;
      this.title = opdata.title || "第二节  教育学的产生与发展";
      this.reViewType = opdata.reViewType;
      this.a_num = opdata.answerNum;
      console.log(this.a_num);
    },
    ksdtClick() {
      this.ggAdt = false;
      this.current = 0;
      this.times = setInterval(() => {
        this.dtTime++;
      }, 1000);
    },
    inputBlur(index1, index2) {
      let list = this.answerList[index1].answer_text[index2];
      // console.log(this.answerList[index1].answer_text[index2])
      if (list.user_answer !== "") {
        if (
          encodeURI(list.answer).replace("%E2%80%8C", "") !==
          encodeURI(this.Trim(list.user_answer, "g"))
        ) {
          this.disbade = true;
          list.user_answer = "答案错误";
          this.error = true;
          this.errorCurrent = index2;
          this.error_num++;
          setTimeout(() => {
            this.disbade = false;
            list.user_answer = "";
            this.error = false;
          }, 1000);
          console.log(this.error_num);
        } else {
          let yesNumber = 0;
          for (let items of this.answerList[index1].answer_text) {
            if (this.Trim(items.user_answer, "g") == items.answer) {
              yesNumber++;
            }
          }
          if (index1 + 1 < this.answerList.length) {
            if (yesNumber == this.answerList[index1].answer_text.length) {
              console.log("恭喜你，本题全部回答正确,是否开始作答下一题");
              // this.$refs.cardShow.next()
              this.current = index1 + 1;
            }
          } else {
            if (yesNumber == this.answerList[index1].answer_text.length) {
              clearInterval(this.times);

              submitKnowledgePlay({
                sub_id: this.id,
                error_num: this.error_num,
                ques_num: this.answerList.length,
                second: this.dtTime,
              }).then((res) => {
                if (this.a_num == 0) {
                  if (this.nextId == "last") {
                    this.$confirm(
                      "恭喜你！本套课程已经学习完毕，是否返回学习中心？",
                      "恭喜你！",
                      {
                        confirmButtonText: "确定",
                        cancelButtonText: "返回课程列表",
                        type: "success",
                      }
                    )
                      .then(() => {
                        // this.$router.go(-1)
                        this.$router.replace("/classList");
                      })
                      .catch(() => {
                        this.$router.go(-2);
                      });
                  } else {
                    this.$confirm(
                      "恭喜你！本小节已经全部掌握了，是否开始学习下一小节？",
                      "恭喜你！",
                      {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "success",
                      }
                    ).then(() => {
                      let playerInfo = JSON.parse(
                        localStorage.getItem("playerInfo")
                      );
                      playerInfo.id = parseInt(this.nextId);
                      localStorage.setItem(
                        "playerInfo",
                        JSON.stringify(playerInfo)
                      );
                      this.$router.replace("/VideoPlay");
                    });
                  }
                } else {
                  this.$confirm(
                    "恭喜你，本小节知识点已经全部巩固。是否前往小节真题闯关?",
                    "提示",
                    {
                      confirmButtonText: "确定",
                      cancelButtonText: "返回上一页",
                      type: "success",
                    }
                  )
                    .then(() => {
                      let arrs = {
                        id: this.id,
                        title: this.title,
                        navList: this.navList,
                        gonggu: 0,
                      };
                      this.$router.push(
                        "/Answer?parameter=" +
                          encodeURIComponent(
                            encodeURIComponent(JSON.stringify(arrs))
                          )
                      );
                    })
                    .catch(() => {
                      this.$router.go(-1);
                    });
                }
              });
            }
          }
        }
      }
    },
    Trim(str, is_global) {
      var result;
      result = str.replace(/(^\s+)|(\s+$)/g, "");
      if (is_global == "g") {
        result = result.replace(/\s/g, "");
      }
      return result;
    },
    scClick(subId, id) {
      addKnowledgeCollect({
        knowledge_id: id,
        sub_id: subId,
        chapter_id: this.zId,
      }).then((res) => {
        let ii = this.answerList[this.current];
        if (ii.collect == 0) {
          ii.collect = 1;
          this.$message.success("收藏成功");
        } else if (ii.collect > 0) {
          ii.collect = 0;
          this.$message.success("取消成功");
        }
      });
    },
    againClick(index) {
      this.disbade = false;
      this.error = false;
      this.errorCurrent = null;
      for (let item of this.answerList[index].answer_text) {
        item.user_answer = "";
      }
    },
    byTab(e) {},
    cxggClick() {
      this.disbade = false;
      this.error = false;
      this.errorCurrent = null;
      for (let item of this.answerList) {
        for (let items of item.answer_text) {
          items.user_answer = "";
        }
      }
      this.ggAdt = true;
      clearInterval(this.times);
      this.dtTime = 0;
    },
  },
  beforeDestroy() {
    clearInterval(this.times);
  },
  computed: {
    text() {
      return function (value) {
        if (value) {
          let zhongwen = value.match(/[\u4e00-\u9fa5]/g);
          let chinese = "";
          if (zhongwen) {
            chinese = zhongwen.join("");
          }
          let english = value.length - chinese.length;
          let length = chinese.length * 20 + 20 + (english * 10 + 20);
          return length + "px";
        }
      };
    },
  },
};
</script>

<style scoped="scoped" lang="scss">
.swiper_box {
}

.xuhao {
  font-size: 20px;
  margin-top: 32px;
}

.content {
  width: 1132px;
  background: #fff;
  padding: 20px !important;
  position: relative;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  /* overflow-y: scroll; */
}

.wraps {
  margin-top: 10px;
}

.inputs {
  border: none;
  /* border-bottom: 1px solid #505DDF; */
  padding: 5px 10px;
  font-size: 20px;
  color: #505ddf;
  width: 48px;
  max-width: 100% !important;
  text-align: center;
  background: #fff;
  box-sizing: border-box;
  overflow: hidden;
  resize: none;
}

.errinputs {
  /* border-bottom: 1px solid #FA4E53 !important; */
  color: #fa4e53 !important;
}

.boxs {
  display: flex;
  justify-content: space-between;
}

.answer_box {
  /* width: 55.3125rem; */
  width: 100%;
  margin-top: 30px;
}

.btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.answer_box span {
  font-size: 20px;
}

.kt_btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
}

.kt_btnss {
  padding: 5px 16px;
  background: #47be5e;
  color: #fff;
  font-size: 18px;
  border-radius: 10pxf;
}

.kt_btn {
  padding: 5px 16px;
  background: #47be5e;
  color: #fff;
  font-size: 18px;
  border-radius: 10px;
  margin-left: 20px;
}
</style>
