<template>
	<div class="my_class" v-loading="loading" element-loading-text="正在加载中...">
		<div class="serve_box">
			<div class="serve_title">
				<div class="big">课后专属服务</div>
				<div class="small" @click="jumps">更多<img src="@/assets/images/index/blue_r.png"></div>
			</div>
			<div class="yuan">
				<div class="yuan_box">
					<el-progress color="#505DDF" type="circle" class="quan"
						:percentage="classTime.course_percent == 0 ? 1 : classTime.course_percent" :width="198"
						:show-text="false" :stroke-width="14"></el-progress>
					<div class="tj_box">
						<div class="tj">
							<div>
								<div class="tj_title">系统精讲网课</div>
								<div class="tj_content">本月学习时长：{{classTime.course}}分钟</div>
							</div>
						</div>
					</div>
					<div class="yuan_line" style="width: 160px;height: 160px;border-radius: 50%;top: 18px;left: 18px;">
						<div class="hez">
							<div class="wc" style="	font-size: 18px;left: 52.5px;top: 20px;margin-bottom: 15px;">
								已完成
							</div>
							<div>
								<span class="big_number"
									style="font-size: 65px;height: 61px;box-shadow: 0px 0px 8px #ccc;border-radius: 10px;margin-left: 6px;padding: 0 10px;"
									v-for="(item,index) in String(classTime.course_percent?classTime.course_percent:0)"
									:key="index">{{item}}</span>
								<span class="fen" style="font-size: 26px;color: #505DDF;margin: 6px;">%</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="serve_box serve_boxsss" style="">
			<div class="serve_title">
				<div class="big">在线学习</div>
				<div class="small" @click="jumpClick()">更多<img src="@/assets/images/index/blue_r.png"></div>
			</div>
			<div class="chart_box">
				<div class="chart_line_box" v-for="(item,index) of chartList" :key="index"
					@click="listJump(item.id,item.name)" :title="item.name">
					<div class="chart_line">
						<div class="numbers" style="">{{item.progress}} <span style="font-size: 14px;">%</span> </div>
						<div class="chart_act_line" :style="{height:(180/100 * item.progress) + 'px'}"></div>
					</div>
					<div class="chart_name">{{item.name}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getUserClass,
		getUserCourse,
		getMyStudy
	} from '@/utils/api.js'

	import {
		EventBus
	} from '@/eventBus/eventBus'
	export default {
		data() {
			return {
				times: '80',
				chartList: [],
				classTime: {},
				navList: [{
						path: '/classList',
						name: '首页',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 0
					},
					{
						path: '/userInfo',
						name: '个人中心',
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 5
					},
					{
						path: '/myClass',
						name: '我的课程',
						studyCurrent: 0,
						tabbarChildCurrent: 1,
						tabbarCurrent: 5
					}
				],
				loading: true,
			}
		},
		created() {
			EventBus.$emit('navList', this.navList)
			let oneHeight = (170 / 100)
			console.log(oneHeight)
			for (let i of this.chartList) {
				i.heights = (oneHeight * i.number) / 16
			}
			console.log(this.chartList)
			this.getMyStudy()
		},
		methods: {
			async getData() {
				await getUserCourse().then(res => {

					this.getMyStudy()
				})
			},
			async getMyStudy() {
				await getMyStudy().then(res => {
					this.chartList = res.course
					this.classTime = res.data
					this.loading = false
					console.log(this.classTime)
				})
			},
			jumpClick() {
				this.$router.push('/Study')
				EventBus.$emit('current', 2)
				localStorage.setItem('current', 2);
			},
			listJump(id, title) {
				// this.$router.push('/studyList?id='+id+'&titles='+title)
			},
			jumps() {
				this.$router.push('systemClass')
				localStorage.setItem('tabbarChildCurrent', 0)
				EventBus.$emit('current', 0)
				localStorage.setItem('tabbarCurrent', 0)
			}
		}
	}
</script>

<style scoped="scoped">
	.serve_box {
		padding: 20px 25px;
		background: #fff;
		border-radius: 10px;

		/* box-shadow: 0px 0px 6px 0px rgba(80, 93, 223, 0.18); */
	}

	.serve_boxsss {
		margin-top: 20px;
		/* height: 290px; */
	}

	.serve_title {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.serve_title .big {
		font-size: 20px;
		color: #161616;
	}

	.serve_title .small {
		display: flex;
		align-items: center;
		font-size: 16px;
		cursor: pointer;
		color: #7B92CB;
	}

	.serve_title .small img {
		width: auto;
		height: auto;
		margin-left: 6px;
	}

	.yuan {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 30px;
	}

	.yuan_box {
		position: relative;
	}

	.yuan_line {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #fff;
	}

	.big_number {
		font-weight: bold;
		color: #505DDF;
		background: #fff;
		margin-left: 6px;
	}

	.fen {
		font-size: 26px;
		color: #505DDF;
		margin: 6px;
	}

	.wc {
		color: #505DDF;
		font-weight: bold;
		text-align: center;
		position: absolute;
	}

	.tj_box {
		width: 100%;
		height: 82px;
		border: 1px #00D3AA dashed;
		border-radius: 10px;
		padding: 6px;
		margin-top: 20px;
	}

	.tj {
		width: 100%;
		height: 100%;
		background: #00D3AA;
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.tj div {
		text-align: center;
		color: #fff;
		font-size: 16px;
	}

	.tj .tj_title {
		font-size: 20px;
	}

	.chart_box {
		display: flex;
		justify-content: flex-start;
		margin-top: 60px;
		overflow-x: scroll;
		background: #fff;
	}

	.chart_line_box {
		width: 100px;
		cursor: pointer;
	}

	.chart_line {
		height: 180px;
		width: 20px;
		background: #F3F4FF;
		/* border-radius: 15px 15px 0 0; */
		margin: 0 auto;
		text-align: center;
		position: relative;
	}

	.chart_name {
		font-size: 20px;
		width: 100px;
		color: #161616;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding: 20px 10px;

	}

	.chart_act_line {
		background: #47BE5E;
		/* border-radius: 15px 15px 0 0; */
		border-radius: 3px;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 20px;
	}

	.chart_line .numbers {
		display: flex;
		align-items: flex-end;
		justify-content: flex-start;
		position: absolute;
		font-size: 16px;
		font-weight: bold;
		color: #47BE5E;
		top: -30px;
		left: 1px;
	}
</style>
