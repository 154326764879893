var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"question_set"},[_vm._v("答疑服务")]),_c('div',{staticClass:"content_div"},[_c('div',{staticClass:"con_header"},[_c('div',{staticClass:"con_hleft"},_vm._l((_vm.tablist),function(item,index){return _c('div',{key:index,style:(_vm.tabIndex==index?'font-weight: bold':''),on:{"click":function (e){
              _vm.tabIndex=index
              _vm.getAnswerlist()
             }}},[_vm._v(_vm._s(item))])}),0),_c('div',{staticClass:"cor_button",on:{"click":function (e){
             _vm.$refs.askru.cate_id=''
             _vm.$refs.askru.vice_id=''
             _vm.$refs.askru.dialogImageUrl=[]
            _vm.$refs.askru.question=''
            _vm.$refs.askru.askShow=true
          }}},[_c('img',{attrs:{"src":require("../../assets/images/slices/button.png"),"alt":""}}),_vm._v(" 我要提问 ")])]),_vm._l((_vm.ansList),function(item,index){return _c('div',{key:index,staticClass:"subject",on:{"click":function($event){return _vm.toGdetail(item.id)}}},[_c('div',{staticClass:"subject_header"},[_c('div',{staticClass:"subject_left"},[_c('img',{attrs:{"src":require("../../assets/images/slices/yiwen.png"),"alt":""}}),_c('div',{staticStyle:{"width":"960px"}},[_vm._v(_vm._s(item.question))])]),_vm._m(0,true)]),(item.ques!='')?_c('div',{staticClass:"subtt"},[_c('div',{staticClass:"subtt_ic"},[_vm._v(_vm._s(item.ques.genre_name))]),_c('div',{staticClass:"subtt_name"},[_vm._v(_vm._s(item.ques.title))])]):_vm._e()])}),_c('div',{staticClass:"pagin_d"},[_c('el-pagination',{attrs:{"background":"","page-size":5,"layout":"prev, pager, next","total":_vm.ansCont},on:{"current-change":_vm.getAnswerlist}})],1)],2),_c('askQuestion',{ref:"askru",on:{"hwuRee":_vm.hwuRee}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"subject_right"},[_c('div',[_vm._v("查看详情")]),_c('img',{attrs:{"src":require("../../assets/images/slices/right.png"),"alt":""}})])}]

export { render, staticRenderFns }