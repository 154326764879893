<template>
  <div class="logini">
    <div class="login_back">
      <img class="back_icon" src="../../assets/login/icon.png" alt="">
      <img class="back_dalogo" src="../../assets/login/dalogo.png" alt="">
      <div class="log_text">考编制之路  莘知起步</div>
    </div>
    <div class="login_bun" v-if="pascodeShow">
      <div class="login_title">欢迎登录</div>
      <div class="login_dec">莘知教育学习平台</div>
      <div class="input_box">
        <input
          style="cursor: text;"
          type="text"
          v-model="userPhone"
          maxlength="11"
          @input="inputChange"
          placeholder="请输入登陆账号"
        />
      </div>
      <div class="passedit">
          <input type="text" style="cursor: text;" v-model="code" placeholder="请输入验证码">
          <div
          class="code"
          style="color:#007dff"
          @click="codeSend"
          :style="codeText == '发送验证码' ? '' : 'background:#999;'"
        >
          {{ codeText }}
        </div>
      </div>
      <div class="logintype" @click="pascodeShow=!pascodeShow,userPas=''">密码登录</div>
      <div class="login_btn" @click="stbinBtn">登录</div>
    </div>
    <div class="login_bun" v-else>
      <div class="login_title">欢迎登录</div>
      <div class="login_dec">莘知教育学习平台</div>
      <div class="input_box">
        <input
        style="cursor: text;"
          type="text"
          v-model="userPhone"
          maxlength="11"
          @input="inputChange"
          placeholder="请输入登陆账号"
        />
      </div>
      <div class="passedit" v-if="editShow">
        <input style="cursor: text;" type="text" v-model="code" placeholder="请输入验证码">
        <div
        class="code"
        @click="codeSend"
        style="color:#007dff"
        :style="codeText == '发送验证码' ? '' : 'background:#999;'"
      >
        {{ codeText }}
      </div>
      </div>
      <div class="input_box">
        <input
        style="cursor: text;"
          type="password"
          v-model="userPas"
          maxlength="16"
          placeholder="请输入登录密码"
        />
      </div>
      <div class="logintype" @click="pascodeShow=!pascodeShow,userPas=''">验证码登录</div>
      <div class="login_btn" v-if="!editShow" @click="loginBtn">登录</div>
      <div class="login_btn" v-if="editShow" @click="passeditc">确认修改</div>
      <div class="passxg" @click="editShow=!editShow,userPas=''">{{editShow?'去登录':'忘记密码'}}</div>
    </div>
  </div>
</template>

<script>
import { Login, getUserInfo, loginMobile, getMobileCode,updatePassword } from "@/utils/erpapi.js";
import captcha from "vue-social-captcha";
import { EventBus } from "@/eventBus/eventBus";

import auserInfo from "@/components/userInfo/userInfo1.vue";
export default {
  data() {
    return {
      userPhone: "",
      userPas: "",
      codeText: "发送验证码",
      timer: "",
      editShow:false,
      code:"",
      pascodeShow:true
    };
  },
  components: {
    auserInfo,
  },
  created() {
    if (localStorage.getItem("userpasNum")) {
      let userpasNum = JSON.parse(localStorage.getItem("userpasNum"));
      this.userPhone = userpasNum.userPhone;
      this.userPas = userpasNum.userPas;
    }
    if (localStorage.getItem("UserToken")) {
      console.log(1111);
      this.$router.push("/userinfo");
      localStorage.setItem("tabbarCurrent", 0);
      localStorage.setItem("tabbarChildCurrent", 0);
    } else {
    }
  },
  methods: {
    // 修改密码
    passeditc(){
      if (this.userPhone == "") {
        this.$message.error({
          message: "手机号码不能为空",
          showClose: true,
        });
        return;
      }
      if (this.code == "") {
        this.$message.error({
          message: "验证码不能为空",
          showClose: true,
        });
        return;
      }
      if (this.userPas == "") {
        this.$message.error({
          message: "请输入新密码",
          showClose: true,
        });
        return;
      }
      updatePassword({
        mobile: this.userPhone,
        code:this.code,
        password: this.userPas,
      }).then((res) => {
        this.$message.success({
                message: res.msg,
                showClose: true,
            });
            this.userPas=''
            this.editShow=false
      });
    },
    // 发送验证码
    codeSend() {
      if (this.codeText !== "发送验证码") {
        return;
      }
      if (this.userPhone == "") {
        this.$message.error({
          message: "手机号码不能为空",
          showClose: true,
        });
        return;
      }

      getMobileCode({
        mobile: this.userPhone,
        type:this.pascodeShow?1:2,
      }).then((res) => {
        console.log(res);
      });
      let time = 60;
      this.timer = setInterval(() => {
        if (time > 0) {
          time--;
          this.codeText = "倒计时" + time + "秒";
        } else {
          clearInterval(this.timer);
          this.codeText = "发送验证码";
        }
      }, 1000);
    },
    inputChange(e) {
      this.phoneShow = false;
      this.pasShow = false;
    },
    // 验证码登录
    stbinBtn(){
      console.log('1')
      var result = RegExp(/^1[345789]\d{9}$/).test(this.userPhone);
      if (this.userPhone == "") {
        this.$message.error({
          message: "手机号码不能为空",
          showClose: true,
        });
        return;
      } else if (!result) {
        this.$message.error({
          message: "手机号码格式不正确",
          showClose: true,
        });
        return;
      } else if (this.code == "") {
        this.$message.error({
          message: "验证码不能为空",
          showClose: true,
        });
        return;
      }
      console.log('2')
      loginMobile({
        mobile:this.userPhone,
        code:this.code
      }).then(res=>{
        console.log(res);
              if (res.code == 200) {
                let token = res.token;
                localStorage.setItem("UserToken", token);
                getUserInfo().then((res) => {
                  EventBus.$emit("userinfo", res.data);
                  localStorage.setItem("userInfo", JSON.stringify(res.data));
                  this.$store.state.pathName = res.data
                  this.$message.success({
                    message: "登陆成功",
                    showClose: true,
                  });
                  this.$router.push("/erpindex");
                });

                return;
              } else {
                this.$message.error({
                  message: res.msg,
                  showClose: true,
                });
              }
      })
    },
    loginBtn() {
      var result = RegExp(/^1[345789]\d{9}$/).test(this.userPhone);
      if (this.userPhone == "") {
        this.$message.error({
          message: "手机号码不能为空",
          showClose: true,
        });
        return;
      } else if (!result) {
        this.$message.error({
          message: "手机号码格式不正确",
          showClose: true,
        });
        return;
      } else if (this.userPas == "") {
        this.$message.error({
          message: "密码不能为空",
          showClose: true,
        });
        return;
      } else {
        if (this.checked == true) {
          let userpasNum = {
            userPhone: this.userPhone,
            userPas: this.userPas,
          };
          localStorage.setItem("userpasNum", JSON.stringify(userpasNum));
        } else {
          localStorage.removeItem("userpasNum");
        }
        this.captcha = new TencentCaptcha("2062784548", (rsp) => {
          if (rsp.ret === 0) {
            //当ret是0得时候代表成功填写了验证码，我在里面进行了axios操作。
            Login({
              mobile: this.userPhone,
              password: this.userPas,
            }).then((res) => {
              console.log(res);
              if (res.code == 200) {
                let token = res.token;
                localStorage.setItem("UserToken", token);
                getUserInfo().then((res) => {
                  EventBus.$emit("userinfo", res.data);
                  localStorage.setItem("userInfo", JSON.stringify(res.data));
                  this.$store.state.pathName = res.data
                  this.$message.success({
                    message: "登陆成功",
                    showClose: true,
                  });
                  this.$router.push("/erpindex");
                });

                return;
              } else {
                this.$message.error({
                  message: res.msg,
                  showClose: true,
                });
              }
            });
          } else {
            this.$message({
              message: "验证不通过",
              type: "warning",
              showClose: true,
            });
          }
        });
        this.captcha.show();
        return;
        Login({
          mobile: this.userPhone,
        }).then((res) => {
          console.log(rComma);
        });
      }
    },
  },
};
</script>
<style lang="scss">
.passxg{
  width: 358px;
  text-align: right;
  color: #666;
  margin-top: 20px;
}
.logintype{
  width: 358px;
  text-align: left;
  color: #007dff;
  margin-bottom:10px;
  
}
.logini{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
}
.login_back{
  position: relative;
  width: 56%;
  height: 100%;
  background-image: url('../../assets/login/back.png');
  background-size: 100% 100%;
  display: flex;
 align-items: center;
  justify-content: center;
}
.log_text{
  position: absolute;
  left: 0;
  bottom: 10.18%;
  text-align: center;
  width: 100%;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #11110F;
  line-height: 73px;
  letter-spacing: 2px;
}
 .back_dalogo{
  width: 38vw;
  height: 38vw;
}
.back_icon{
position: absolute;
top: 84px;
left: 92px;
width: 14.21vw;
height: 3.75vw;
}
.login_bun{
  width: 44%;
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
 align-items: center;
  justify-content: center;
}
.passedit{
  width: 358px;
height: 54px;
background: #E7EBF5;
border: 1px solid #E7EBF5;
border-radius: 10px;
margin-bottom: 22px;
display: flex;
align-items: center;
justify-content: space-between;
padding: 0 20px 0 30px;
input{
  height: 50px;
  border: none;
  width: 200px;
  background: #E7EBF5;
}
}
.input_box{
width: 358px;
height: 54px;
background: #F4F6FA;
border: 1px solid #E7EBF5;
border-radius: 10px;
margin-bottom: 25px;
}
.input_box input{
  background: #F4F6FA;
  border: none;
  width: 358px;
  height: 54px;
  padding-left: 24px;
}
.login_title{
  font-size: 38px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  line-height: 91px;
}
.login_btn{
  width: 358px;
  height: 50px;
  background: #1071E2;
  border: 1px solid #1A69C5;
  border-radius: 10px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 54px;
  text-align: center;
  margin-top: 15px;
}
.login_dec{
  font-size: 20px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #313131;
margin-bottom: 56px;
}
</style>
