<template>
	<div class="container" v-loading="loading" element-loading-text="正在加载中...">
		<!-- <userInfo></userInfo> -->
		<div class="content">
			<div class="head">
				<div class="swiper">
					<el-carousel trigger="click" class="swiper_box">
						<el-carousel-item>
							<img :src="liveInfo.icon" >
						</el-carousel-item>
					</el-carousel>
					<!-- <div class="img_mini">
						<img :src="liveInfo.icon" style="width: 60px;height: 60px;">
					</div> -->
				</div>
				<div class="detial">
					<div>
						<div class="detial_title">{{liveInfo.name}}</div>
						<div class="detial_detail">{{liveInfo.brief}}</div>
					</div>
				</div>
			</div>
			<div class="content_box">
				<div class="content_tab">
					<div @click="() => {tabcurrent = 0;}" :class="tabcurrent == 0?'active':''">课程详情</div>
					<div @click="() => {tabcurrent = 1;}" :class="tabcurrent == 1?'active':''">课程目录</div>
				</div>
				<div class="class_detail" v-if="tabcurrent == 0">
					<div v-html="liveInfo.content" class="htmls"></div>
				</div>
				<div class="class_list" v-if="tabcurrent == 1">
					<div class="pian" v-for="(item,index) in classList" :key="index"
						@click="()=>{if(current !== index){slide = true;sild2=true}else{slide = !slide;sild2=false} current = index;current2 = 0;}">
						<div class="pian_title">
							<img src="@/assets/icon/icon_open_s.png" alt="" v-if="current == index && slide">
							<img src="@/assets/icon/icon_open.png" alt="" v-else>
							{{item.name}}
						</div>
						<div class="pian_content">
							<el-collapse-transition class="pian_content" v-if="current == index && slide">
								<div>
									<div class="zhang" v-for="(item2,index2) in item.courseChapter" :key="index2"
										@click.stop="slidClick(index,index2)">
										<div class=""
											style="display: flex;align-items: center;justify-content: space-between;">
											<div class="zhang_title">
												<img src="@/assets/icon/icon_open_s.png" alt=""
													v-if="current2 == index2  && slide2">
												<img src="@/assets/icon/icon_open.png" alt="" v-else>
												{{item2.name}}
											</div>
										</div>
										<div class="zhang_content">
											<el-collapse-transition class="pian_content"
												v-if="current2 == index2 && slide2">
												<div>
													<div class="jie" v-for="(item3,index3) in item2.courseSub"
														title="前往学习" :key="index3">
														<div class="jie_title"
															@click.stop="playeVideo(item3.name,item3.id,index,index3,index2,item3.chapter_id)">
															<span>{{item3.name}}</span>
															<div
																style="display: flex;align-items: center;justify-content: space-between;">

																<div class="dt-ntn">前往学习</div>
															</div>

														</div>
													</div>
												</div>
											</el-collapse-transition>
										</div>
									</div>
									<!-- <div class="">本篇测验</div> -->
								</div>

							</el-collapse-transition>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import turnPages from '@/components/turnPages/turnPages'
	import {
		getCourseDetail,
		getCourseDetail1,
		getCourseSubKnowledge,
		getTestPaperQues,
		getLiveInfo
	} from '@/utils/api.js'
	import {
		EventBus
	} from '../../eventBus/eventBus'
	export default {
		components: {
			turnPages,
		},
		data() {
			return {
				pages: 1,
				tabcurrent: 0,
				count: 100,
				navList: [],
				classList: [],
				loading: true,
				id: '',
				title: '',
				current: 0,
				current2: 0,
				zhangdt: 0,
				exclusive: '',
				slide: true,
				slide2: true,
				page: '',
				liveInfo: {}
			}
		},
		created() {
			localStorage.removeItem('times')

			this.parameter(this.$route.query)
			EventBus.$emit('navList', this.navList)
			// this.getData()
			this.getData()
		},
		mounted() {

		},
		methods: {
			async getData() {
				console.log(1)
				await getLiveInfo({
					id: this.id
				}).then(res => {
					this.liveInfo = res.data.info
					this.liveInfo.content = this.liveInfo.content.replace(/<img/g,
						'<img style="width: 80%;margin-left:10%"');

					this.loading = false
					// this.$forceUpdate()
					// this.classList = res.data
					// this.zhangdt = 0
					this.getData1()
				})
			},
			format(percentage) {
				return percentage === 100 ? '满' : `${percentage}%`;
			},
			async getData1() {
				await getCourseDetail1({
					id: this.id
				}).then(res => {
					this.loading = false
					this.$forceUpdate()
					this.classList = res.data
					console.log(this.classList)
					let yes
					for (let item1 of this.classList) {
						for (let item2 of item1.courseChapter) {
							yes = item2.courseSub.filter(item => {
								return item.ques > 0
							})
							if (item2.courseSub.length == yes.length) {
								item2.zhangdt = 1
							} else {
								item2.zhangdt = 0
							}
						}
					}
				})
			},
			slidClick(index, index2) {
				if (this.current2 !== index2) {
					this.slide2 = true
				} else {
					this.slide2 = !this.slide2
				}
				this.current = index
				this.current2 = index2
			},
			parameter(arr) {
				console.log(arr)
				if (JSON.stringify(arr) !== '{}') {
					this.id = arr.id
					this.title = arr.titles
					this.exclusive = arr.exclusive
					this.page = arr.page
					this.navList = JSON.parse(unescape(arr.navList))
					localStorage.setItem('studyList', JSON.stringify({
						id: this.id,
						title: this.title,
						navList: this.navList,
						exclusive: this.exclusive,
						page: this.page
					}))
				} else {
					let arrsaa = JSON.parse(localStorage.getItem('studyList'))
					console.log(arrsaa)
					this.id = arrsaa.id
					this.title = arrsaa.title
					this.navList = arrsaa.navList
					this.exclusive = arrsaa.exclusive
					this.page = arrsaa.page
					localStorage.setItem('studyList', JSON.stringify({
						id: this.id,
						title: this.title,
						navList: this.navList,
						exclusive: arrsaa.exclusive,
						page: this.page
					}))
					// this.navList = arr
				}
			},
			answerClcik(title, id, yes) {
				if (yes > 0 || this.exclusive == 'yes') {
					let arrs = {
						navList: this.navList,
						title: title,
						id: id,
						type: 1,
					}
					this.$router.push('/Answer?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(arrs))));
				} else {
					this.$message.error('请先完成巩固练习')
				}

			},
			playeVideo(title, id, index, index3, index2, zId, count_time_text, sid, chatId) {
				console.log(title, id, index3, index2)
				this.$message.error('直播尚未开始，请留意直播开始时间！！！')
				return
				let classLi = this.classList[index].courseChapter
				console.log(classLi[index2])
				if (this.exclusive !== 'yes' && this.page !== 'chongci') {
					if (index3 !== 0 || index2 !== 0) {
						if (index2 >= 1 && classLi[index2 - 1].zhangdt == 0) {
							console.log(classLi[index2 - 1])
							return this.$message.error('请先完成前面的章节')
						} else {
							if (index3 > 0 && classLi[index2].courseSub[index3 - 1].ques == 0) {
								return this.$message.error('请先完成前面的小节')

							}
						}
					}
				}

				let arrs = {
					arr: this.navList,
					title: title,
					id: id
				}
				if (this.exclusive == 'yes') {
					arrs = {
						arr: this.navList,
						title: title,
						id: id,
						exclusive: 'yes',
						sid: sid,
						chatId: chatId
					}
				} else {
					arrs = {
						arr: this.navList,
						title: title,
						id: id,
						zId: zId,
						listId: chatId,
						page: this.page,

					}
				}
				if (count_time_text == '直播中') {
					this.$router.push('/livePlayer?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(
						arrs))));
				} else {
					this.$router.push('/VideoPlay?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(
						arrs))));
				}
			},
			async reviewClick(title, id, yes) {
				if (yes > 0) {
					await getCourseSubKnowledge({
						id: this.id
					}).then(res => {
						if (res.code !== 400) {
							let arrs = {
								arr: this.navList,
								title: title,
								id: id
							}
							this.$router.push('/studyReview?parameter=' + encodeURIComponent(
								encodeURIComponent(JSON.stringify(arrs))));
						}
					})
				} else {
					this.$message.error('请先看完视频')
				}
			},
			testClick(items, id) {
				console.log(items)
				if (items == 0) {
					this.$message.error('请先完成本章节所有测试')
				} else {
					let arrs = {
						// arr: this.navList,
						title: items.name,
						id: id,
						type: 2
					}
					this.$router.push('/Answer?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(arrs))));
				}
				// let yes = items.courseSub.filter(item => {
				// 	return item.knowledge >　0
				// })
				// if(yes.length == items.courseSub.length){

				// }else{
				// 	this.$message.error('请先完成本章节所有测试')
				// }


			},
		}
	}
</script>

<style scoped>
	.content {
		width: 1132px;
		background: #fff;
		min-height: 700px;
		padding: 25px;
		position: relative;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
	}

	.head {
		display: flex;
		justify-content: space-between;
	}
	.dt-ntn {
		padding: 8px 15px;
		background: #5694F7;
		color: #fff;
		border-radius: 10px;
	}
	.swiper {
		width: 330px;
		height: 250px;
		background: #000000;
	}
	.swiper_box{
		height: 250px;
	}
	.swiper_box img{
		width: 330px;
		height: 250px;
	}
	.img_mini {
		height: 60px;
		width: 330px;
		margin-top: 10px;
		background: #F1F0F0;
	}

	.htmls p img {
		width: 100% !important;
	}

	.detial {
		flex: 1;
		padding-left: 25px;
		height: 250px;
		position: relative;
		/* padding-bottom: 60px; */
	}

	.detial_title {
		font-size: 24px;
		color: #333;
		width: 727px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		word-wrap: break-word;
		-webkit-box-orient: vertical;
	}

	.detial_detail {
		font-size: 16px;
		color: #666;
		margin-top: 20px;
	}

	.numbers {
		position: absolute;
		bottom: 0;
		left: 25px;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 1px dashed #eee;
		width: calc(100% - 25px);
		color: #666;
	}

	.numbers div {
		display: flex;
		align-items: center;
		font-size: 16px;
		color: #999;
	}

	.numbers div img {
		margin-right: 5px;
	}

	.content_box {
		border-top: 1px solid #eee;
		margin-top: 30px;
	}

	.content_tab {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 25px 0;
	}

	.content_tab div {
		width: 125px;
		height: 36px;
		border-radius: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.content_tab .active {
		background: #47BE5E;
		color: #fff;
	}

	.pian_title {
		font-size: 16px;
		font-weight: bold;
		height: 50px;
		display: flex;
		align-items: center;
	}

	.zhang_title img,
	.pian_title img {
		margin-right: 10px;
	}

	.zhang_title,
	.jie_title {
		font-size: 16px;
		min-height: 50px;
		display: flex;
		align-items: center;

	}

	.jie_title {
		justify-content: space-between;
		cursor: pointer;
		padding: 10px 15px;
	}

	.jie_title:hover {

		background: #e7fdeb;
		border-radius: 5px;
	}

	/* .jie_title:hover span {
		color: #fff !important;
	}
	.jie_title:hover div {
		color: #fff !important;
	} */

	img {
		cursor: pointer
	}

	.pian_content,
	.zhang_content {
		padding-left: 20px;
	}

	.pages_box {
		position: absolute;
		bottom: 30px;
		right: 20px;
	}

	.wraps {
		margin-top: .625rem;
		height: calc(100% - 0.875rem);
		overflow-y: scroll;
	}

	.wraps::-webkit-scrollbar {
		display: none;
	}

	.class_title {
		color: #000000;
		font-size: 1.125rem;
		padding: 1.1875rem 0;
	}

	.class_content {
		background: #F3F4FF;
		padding-left: 4.0625rem;
		padding-right: 2.1875rem;
		border-radius: .625rem;
		padding-top: 1.25rem;
	}

	.class_content_box {
		/* display: flex;
		justify-content: space-between;
		align-items: center; */
		/* height: 3.5625rem; */
		border-bottom: 1px solid #E4E4E4;
		margin-bottom: 1.25rem;
	}

	.class_content_box:last-child {
		margin-bottom: 0 !important
	}

	.jie_box {
		padding-left: 1.875rem;
		padding-top: 1.25rem;
	}

	.jie_list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 3.5625rem;
		font-size: 1rem;
	}

	.class_content_box:last-child {
		border: none
	}

	.jie_class_name {
		width: 22.1875rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding-right: 1.875rem;
		font-size: 1.125rem;
		color: #333;
	}

	.class_name {
		/* width: 22.1875rem; */
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		/* padding-right: 1.875rem; */
		font-size: 1.125rem;
		color: #333;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.btns_box {
		flex: 1;
		display: flex;
		justify-content: space-between;
	}

	.btns_box div {
		min-width: 5.625rem;
		color: #fff;
		font-size: 1rem;
		padding: .3125rem .625rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 10px;
	}

	.btns {
		background: #00D3AA;
		min-width: 5.625rem;
		color: #fff;
		font-size: 1rem;
		padding: .3125rem .625rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 10px;
	}

	.sp {
		background: #FA4E53;
	}
</style>
