<template>
	<div class="help">
		<div class="top">
			<img style="width: 3.1875rem;height: 0.75rem;" src="@/assets/images/user/red_sj.png">
			<div class="fank" @click="$router.push('/mySet')"><img src="@/assets/images/user/yw.png">我要反馈</div>
		</div>
		<div class="box" v-for="(item,index) of content" :key="index">
			<div class="box_title">
				<img class="green" src="@/assets/images/user/green_sj.png">
				{{item.title}}
				<img class="dian" src="@/assets/images/user/dian.png">
			</div>
			<div class="box_con">
				<p v-for="(item2,index2) in item.cont" :key="index2">{{index2+1+'、'+item2}}</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data() {
			return{
				content:[
					{
						title:'为什么选择我们？',
						cont:['对呀，为什么呀？']
					},
					{
						title:'怎么加入我们？？',
						cont:['对呀，怎么加入呀？']
					}
				]
			}
		}
	}
</script>

<style scoped="scoped">
	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 1.25rem;
	}

	.top .fank {
		display: flex;
		align-items: center;
		font-size: 1.125rem;
		color: #FA4E53;
	}

	.top .fank img {
		width: 1.0625rem;
		height: 1.0625rem;
		margin-right: 0.4375rem;
	}

	.box {
		padding: 0.9375rem 1.6875rem;
		background: #fff;
		border-radius:10px;
		margin-top: 1.25rem;
		box-shadow: 0px 0px 6px 0px rgba(80, 93, 223, 0.18);
	}

	.box_title {
		height: 2.0625rem;
		background: linear-gradient(90deg, #44C8A1 0%, #8EE19D 100%);
		border-radius: 0px 1rem 1rem10px;
		display: inline-block;
		line-height: 2.0625rem;
		color: #fff;
		font-size: 1.25rem;
		font-weight: bold;
		padding-left: 1.6875rem;
		padding-right: 3.375rem;
		position: relative;
		margin-left: -2.0625rem;
	}
	.box_title .green{
		position: absolute;
		width: 0.375rem;
		height: 0.4375rem;
		top: -0.375rem;
		left: 0;
	}
	.box_title .dian{
		position: absolute;
		width: 1.9375rem;
		height: 0.875rem;
		top: 0.59375rem;
		right: 1.25rem;
	}
	.box_con{
		margin-top: 1.125rem;
	}
	p{
		font-size: 1rem;
		color: #161616;
		line-height: 1.5rem
	}
</style>
