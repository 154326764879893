<template>
	<div class="container" v-loading="liading">
		<!-- <userInfo></userInfo> -->
		<div class="content" style="position: relative;">

			<div style="display: flex;justify-content: flex-end;align-items: center;">
				<div style="margin-right: 20px;" @click="collectVideo">
					<!-- <i style="font-size: 22px;color: #05953c;" class="el-icon-star-on " v-if="is_collect"></i>
					<i style="font-size: 22px;color: #05953c;" class="el-icon-star-off" v-else></i> -->
				</div>
				<img style="width: 20px;height: 20px;" src="@/assets/back.png" @click="$router.go(-1)" title="返回上一页">
			</div>
			<!-- <navBar :navList="navList"></navBar> -->
			<div class="warps">
				<div class="boxs">
					<div class="warps">
						<video-player class="vjs-custom-skin" ref="videoPlayer" :options="playerOptions"
							@ready="onPlayerReadied" @timeupdate="onTimeupdate" :playsinline="playsinline"
							@ended="onPlayerEnded($event)" @play="onPlayerPlay" @pause="onPlayerPause" :title="title">
						</video-player>
					</div>
				</div>
			</div>
			<template v-if="!exclusive">
				<div v-if="k_num > 0 || a_num > 0">
					<div class="titles">知识点回顾</div>
					<div class="hg_btns">
						<div class="btn" @click="reviewClick" v-if="k_num > 0">小节背题模式</div>
						<div class="btn" @click="answerClick" v-if="a_num > 0">小节真题闯关</div>
						<div class=" btn ggbtn" @click="answerggClick" v-if="wrong > 0">
							<div class="gg_num">{{ wrong }}</div>错题重练
						</div>
						<!-- <div @click="downloadClick">下载讲义</div> -->
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import download from "downloadjs";
import {
	videoPlayer
} from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
import 'videojs-contrib-hls'

import {
	getCourseSubVideo,
	getCourseSubVideoLog,
	getCourseSubKnowledge,
	getCourseDetail,
	submitKnowledgePlay,
	collectVideo,
} from '@/utils/api.js'
import {
	EventBus
} from '@/eventBus/eventBus'
export default {
	name: 'live',
	components: {
		videoPlayer
	},
	data() {
		return {
			navList: [],
			liading: true,
			initialized: false,
			currentTech: '',
			streams: {
				rtmp: '',
				hls: ''
			},
			name: '',
			title: '',
			playTime: '',
			// playerEnd: 2,
			lookTime: 0,
			timesss: '',
			watch_to: 0,
			exclusive: '',
			zId: '',
			wrong: 0,
			k_num: 0,
			a_num: 0,
			type: 0,
			playerOptions: {
				overNative: true,
				playbackRates: [0.75, 1.0, 1.25, 1.5, 2.0], //播放速度
				autoplay: false, // 如果为true,浏览器准备好时开始回放。
				muted: false, // 默认情况下将会消除任何音频。
				loop: false, // 是否视频一结束就重新开始。
				controls: true,
				preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
				aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
				techOrder: ['html5'],
				fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
				sourceOrder: true,
				language: 'zh-CN',
				flash: {
					hls: {
						withCredentials: false
					}
				},
				html5: {
					hls: {
						withCredentials: false
					}
				},
				controlBar: {
					timeDivider: true, // 当前时间和持续时间的分隔符
					durationDisplay: true, // 显示持续时间
					remainingTimeDisplay: false, // 是否显示剩余时间功能
					fullscreenToggle: true // 是否显示全屏按钮
				},
				notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
				sources: [{
					withCredentials: false,
					type: 'application/x-mpegURL',
					src: ''
				}],
				knowledge: 0,

				answer: 0,
				listId: 0,
				idList: [],
				nextId: 0,
				is_collect: 0
				// poster: require("@/assets/images/study/demo.jpg"), //你的封面地址
			}
		}
	},
	created() {
		this.parameter(this.$route.query.parameter)
		EventBus.$emit('navList', this.navList)
		this.getData(this.id)

	},
	computed: {
		player() {
			return this.$refs.videoPlayer.player
		},
		currentStream() {
			return this.currentTech === 'Flash' ? 'RTMP' : 'HLS'
		},

		playsinline() {
			let ua = navigator.userAgent.toLocaleLowerCase()
			// x5内核
			if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
				return false
			} else {
				// ios端
				return true
			}
		}
	},
	methods: {

		collectVideo() {

			collectVideo({
				video_id: this.listId
			}).then(res => {
				this.$message({
					message: res.msg,
					type: 'success'
				});
				this.is_collect = !this.is_collect;
				this.$forceUpdate()

			})
		},
		async getData(id) {
			getCourseSubVideo({
				id: id
			}).then(res => {
				// return
				if (this.exclusive !== 'yes') {
					this.getList()
				}
				if (!res.data.video_url) {
					this.playerOptions.sources[0].src = res.data.live_url

					this.liading = false
					return
				}
				this.playerOptions.sources[0].src = JSON.parse(res.data.video_url.replace(/\\/g, '')).AUTO;
				this.name = res.data.name
				if (res.data.type == 1) {
					this.watch_to = 0
				} else {
					this.watch_to = res.data.watch_to
				}
				this.knowledge = res.data.knowledge
				this.answer = res.data.answer
				this.wrong = res.data.wrong
				this.k_num = res.data.k_num
				this.a_num = res.data.a_num
				this.type = res.data.type
				this.is_collect = res.data.is_collect
				this.liading = false
				console.log(this.type);

			})
		},
		getList() { //获取章节列表
			let idList = []
			getCourseDetail({
				id: this.listId
			}).then(res => {
				for (let item of res.data) {
					for (let item2 of item.courseChapter) {
						for (let item3 of item2.courseSub) {
							idList.push(item3.id)
						}
					}
				}
				this.idList = idList //该套课程和中所有的小节id
				Array.prototype.getArrayIndex = function (obj) { //采用prototype原型实现方式，查找元素在数组中的索引值
					for (var i = 0; i < this.length; i++) {
						if (this[i] === obj) {
							return i;
						}
					}
					return -1;
				}
				let nowId = this.idList.getArrayIndex(this.id) //获取当前小节视频id的索引值
				if (nowId < this.idList.length - 1) {
					this.nextId = this.idList[nowId + 1] //获取下一个小节的视频id
					localStorage.setItem('nextId', this.nextId)
				} else {
					localStorage.setItem('nextId', 'last')
				}
			})
		},
		times() {

		},
		downloadClick() {
			var name = '测试文件';
			var url = 'https://www.sz517.com/upload/file/20210906/af5b36f2a4dbfa1caaee2341c446de84.pdf';
			let str = url.split("/").pop().split("?")[0] //88584712-ec31-4c50-81b8-b7e618f60409.cdr
			let strType = String(str.substring(str.indexOf(".")).trim()) //.cdr  文件类型
			download(url)
		},
		async reviewClick(title, id, yes) { //前往背题模式
			let arrs = {
				id: this.id
			}
			if (this.knowledge > 0 || this.exclusive == 'yes') {
				getCourseSubKnowledge({
					id: this.id
				}).then(res => {
					if (res.code !== 400) {
						let arrs = {
							id: this.id,
							navList: this.navList,
							title: this.title,
							zId: this.zId,
							answerNum: this.a_num
						}
						this.$router.push('/studyReview?parameter=' + encodeURIComponent(
							encodeURIComponent(JSON.stringify(arrs))));
					} else {

					}
				}).catch(res => {
					let that = this
					submitKnowledgePlay({
						sub_id: that.id,
						error_num: 0,
						ques_num: 0,
						second: 0
					}).then(res => {
						this.$alert('小节暂无知识点。是否前往小节真题闯关?', '提示', {
							confirmButtonText: '确定',
							callback: action => {
								let arrs = {
									id: this.id,
									title: this.title,
									navList: this.navList,
									gonggu: 0,
								}
								this.$router.push('/Answer?parameter=' +
									encodeURIComponent(
										encodeURIComponent(JSON.stringify(arrs))));
							}
						});
					})
				})

			} else {
				this.$message.error('请先看完视频')
			}

		},
		answerClick() {
			if (this.answer > 0 || this.exclusive == 'yes') {
				let arrs = {
					id: this.id,
					title: this.title,
					navList: this.navList,
					gonggu: 0,
				}
				this.$router.push('/Answer?parameter=' + encodeURIComponent(
					encodeURIComponent(JSON.stringify(arrs))));
			} else {
				this.$message.error('请先完成巩固')
			}
		},
		answerggClick() {
			let arrs = {
				id: this.id,
				title: this.title,
				navList: this.navList,
				gonggu: 1,
			}
			this.$router.push('/Answer?parameter=' + encodeURIComponent(
				encodeURIComponent(JSON.stringify(arrs))));
		},
		parameter(arrx) {
			let opdata
			if (arrx) {
				opdata = JSON.parse(decodeURIComponent(arrx))
				this.id = opdata.id
				this.zId = opdata.zId
				this.title = opdata.title
				this.exclusive = opdata.exclusive
				this.navList = opdata.arr
				this.listId = opdata.listId
				this.navList.push({
					name: '课程视频',
					path: "/VideoPlay",
					studyCurrent: 0,
					tabbarChildCurrent: 0,
					tabbarCurrent: 0,
				})
				let playerInfo = {
					id: this.id,
					title: this.title,
					exclusive: this.exclusive,
					navList: this.navList,
					zId: this.zId,
					listId: this.listId
				}
				localStorage.setItem('playerInfo', JSON.stringify(playerInfo))
			} else {
				opdata = JSON.parse(localStorage.getItem('playerInfo'))
				this.id = opdata.id
				this.zId = opdata.zId
				this.title = opdata.title
				this.exclusive = opdata.exclusive
				this.navList = opdata.navList
				this.listId = opdata.listId
				let playerInfo = {
					id: this.id,
					title: this.title,
					exclusive: this.exclusive,
					zId: this.zId,
					navList: this.navList,
					listId: this.listId
				}
				localStorage.setItem('playerInfo', JSON.stringify(playerInfo))
			}
		},
		onPlayerReadied(player) {
			if (!this.initialized) {
				this.initialized = true
				this.currentTech = this.player.techName_
			}
			player.currentTime(this.watch_to)
		},
		onPlayerPlay() { //监听播放按钮
			this.timesss = setInterval(() => {
				this.lookTime++
			}, 1000);
		},
		onPlayerPause() {
			clearInterval(this.timesss)
		},
		// record current time
		onTimeupdate(e) { //播放进度
			this.playTime = e.cache_.currentTime.toFixed(0)
			// this.times()
		},
		//监听媒体是否已到达结尾，播放完
		onPlayerEnded(player) {
			clearInterval(this.timesss)
			this.playerEnd = 1
			getCourseSubVideoLog({
				sub_id: this.id,
				watch_to: this.playTime,
				watch_time: this.lookTime,
				a_num: this.a_num,
				k_num: this.k_num,
				type: 1,
			}).then(res => {
				this.getData(this.id)
				if (this.k_num == 0 && this.a_num == 0) {
					if (this.exclusive !== 'yes' && this.id == this.idList[this.idList.length - 1]) {
						this.$confirm('恭喜你！本套课程已经学习完毕，是否返回学习中心？', '恭喜你！', {
							confirmButtonText: '确定',
							cancelButtonText: '返回课程列表',
							type: 'success'
						}).then(() => {
							// this.$router.go(-1)
							this.$router.replace('/classList')
						}).catch(() => {
							this.$router.go(-1)
						})
					} else if (this.exclusive !== 'yes') {
						this.$confirm('恭喜你！本小节已经全部掌握了，是否开始学习下一小节？', '恭喜你！', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'success'
						}).then(() => {
							// this.$router.go(-1)
							this.getData(this.nextId);
							this.id = this.nextId;
						})
					}
				} else {
					if (this.exclusive !== 'yes') {
						this.$confirm('视频已经看完了是否开始课后练习？', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'success'
						}).then(() => {
							this.reviewClick()
						})
					}
				}
			})

		},
	},
	beforeDestroy() {
		clearInterval(this.timesss)
		console.log(this.type);
		getCourseSubVideoLog({
			sub_id: this.id,
			watch_to: this.playTime,
			watch_time: this.lookTime,
			a_num: this.a_num,
			k_num: this.k_num,
			type: this.type
		}).then(res => {

		})
	}
}
</script>
<style type="text/css">
.vjs-mouse-display .vjs-time-tooltip {
	background: rgba(255, 255, 255, 0) !important
}

.vjs-play-progress .vjs-time-tooltip {
	display: none !important;
}

.vjs-custom-skin>.video-js .vjs-big-play-button {
	width: 60px !important;
	height: 60px !important;
	line-height: 60px !important;
	border-radius: 50% !important;
}

.vjs-big-play-button span {
	font-size: 56px !important;
}

.vjs-custom-skin>.video-js .vjs-play-progress,
.vjs-custom-skin>.video-js .vjs-volume-level {
	background: #FF684B !important;
	border-radius: 2px !important;
}

.video-js:hover .vjs-big-play-button,
.vjs-custom-skin>.video-js .vjs-big-play-button:focus,
.vjs-custom-skin>.video-js .vjs-big-play-button:active {
	background: #FF684B !important;
	opacity: .9 !important;
}

.video-js .vjs-play-progress:before {
	font-size: 0.9em;
	position: absolute;
	right: -0.5em;
	top: -0.333333333333333em;
	z-index: 1;
	color: #FF684B !important;
}

.video-js .vjs-slider {
	border-radius: 2px !important;
}

/* .vjs-custom-skin > .video-js .vjs-time-tooltip, .vjs-custom-skin > .video-js .vjs-mouse-display:after, .vjs-custom-skin > .video-js .vjs-play-progress:after{
		color: #47BE5E !important;
	} */
</style>

<style scoped>
.titles {
	font-size: 18px;
	font-weight: bold;
	padding-left: 15px;
	border-left: 5px solid #47BE5E;
	margin-top: 30px;
}

.hg_btns {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.hg_btns .btn {
	padding: 0 10px;
	height: 36px;
	border-radius: 8px;
	background: #47BE5E;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 30px;
	margin-right: 30px;
}

.ggbtn {
	position: relative;
}

.gg_num {
	position: absolute;
	top: -8px;
	right: -8px;
	color: #fff;
	background: #FF684B;
	border-radius: 8px;
	color: #fff;
	min-width: 15px;
	height: 15px;
	font-size: 12px;
	padding: 0 3px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.liveView {
	position: relative;
}

.wraps {
	margin-top: 10px;
}

.content {
	width: 1132px;
	background: #fff;
	padding: 20px;
	position: relative;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
}

.video-player {
	width: 892px;
	height: 590px;
}

.vjs_video_3-dimensions {
	width: 886px !important;
	height: 590px !important;
}

.boxs {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 30px;
	position: relative;
	padding-left: 5px;
}

.video_title {
	position: absolute;
	left: 60px;
	top: 55px;
	z-index: 99;
	color: #fff;
	font-size: 20px;
}
</style>
