<template>
	<div>
		<div class="aatitle">
			知识点更新补充
		</div>
		<div class="table_box">
			<div class="table_title">
				<div v-for="(item,index) in tabTitle" :key="index">{{item}}</div>
			</div>
			<div class="table_content" :class="typeList ?'':'nothing'">
				<img src="@/assets/images/nothing.png" v-if="!typeList">
				<div class="table_list" v-for="(item,index) in typeList" :key="index" v-else
					@click="listJump(item.id,item.name)" :title="item.name">
					<div>
						<span style="margin-right: 10px;">{{index + 1 }}</span>
						{{item.name}}
					</div>
					<div><span>立即学习</span></div>
				</div>
				
			</div>
		</div>

		<turnPages class="pages_box" :count="count" :pages="pages" @fanye="fanyeClick" @inputPage="inputPage">
		</turnPages>
	</div>
</template>

<script>
	import turnPages from '@/components/turnPages/turnPages'
	import {
		getNewTime
	} from '@/utils/api.js'
	export default {
		components: {
			turnPages
		},
		data() {
			return {
				clasType: '',
				ClassName: '',
				count: 1,
				pages: 1,
				limit: 9,
				current: 0,
				titleList: ['务实基础', '真题演练', '考前冲刺'],
				tabTitle: ['课程名称', '操作'],
				typeList: [],
				type: 1, //基础：type=1 提升：type=2 冲刺：type=3
				name: '',
				navList: []
			}
		},
		created() {
			this.getData()
		},
		methods: {
			async getData() {
				let promise = {
					limit: this.limit,
					page: this.pages,
				}
				await getNewTime(promise).then(res => {
					this.typeList = res.data.data
					console.log(this.typeList)
					this.count = Math.ceil(res.data.tatol / this.limit)
				})
			},
			listJump(title, id) {
				this.$router.push('/studyList?id=' + id + '&titles=' + title + '&exclusive=yes')
			},
			playeVideo(title, id) {
				// let arrs = {
				// 	arr: this.navList,
				// 	title: title,
				// 	id: id
				// }
				this.$router.push('/studyList?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(arrs))));
			},
			fanyeClick(e) {
				if (e == 'next') {
					this.pages++
					console.log(this.pages)
					if (this.pages <= this.count) {
						this.getData()
					} else {
						this.pages = this.count
					}
				} else {
					this.pages--
					if (this.pages >= 1) {
						this.getData()
					} else {
						this.pages = 1
					}
				}
			},
			inputPage(e) {
				this.pages = e * 1
				this.getData()
			}
		}

	}
</script>
<style scoped>
	.aatitle {
		margin-bottom: 15px;
		padding-left: 15px;
		border-left: 5px solid #47BE5E;
		font-size: 18px;
		font-weight: bold;
	}

	.content {
		width: 1132px;
		background: #fff;
		/* height: 50.625rem; */
		height: 780px;
		/* margin-top: 1.25rem; */
		/* border-radius: .625rem; */
		/* padding: 1.25rem 1.5625rem; */
		padding: 20px;
		position: relative;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
	}

	.title {
		font-size: .875rem;
		color: #7B92CB;
	}

	/* .study_list{
	
	} */
	.pages_box {
		position: absolute;
		bottom: 20px;
		right: 20px;
	}

	.tab_top {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.tab_top div {
		width: 125px;
		height: 35px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
	}

	.tab_top .active {
		background: #47BE5E;
		border-radius: 18px;
		color: #fff;
	}

	.table_box {
		/* margin-top: 30px; */
	}

	.table_title,
	.table_list {
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.table_title {
		background: #F7F7F7;
	}

	.table_list {
		/* padding: 10px 0 !important; */
		height: 65px;
		padding-left: 15px;
	}

	.table_list:hover {
		background: rgba(71, 190, 94, .3);
		border-radius: 5px;
		/* color: #FFF; */
	}

	.table_title div {
		width: 17%;
		display: flex;
		font-size: 16px;
		font-weight: bold;
		color: #333;
		align-items: center;
		justify-content: center;
	}

	.table_title div:first-child,
	.table_list div:first-child {
		flex: 1;
		white-space: nowrap;
		overflow: hidden;
		/* text-overflow属性表示规定当文本溢出包含元素时发生的事情;
			            ellipsis表示显示省略符号来代表被修剪的文本。*/
		text-overflow: ellipsis;
	}

	.table_list div {
		cursor: pointer;
		width: 17%;
		display: flex;
		color: #333;
		align-items: center;
		justify-content: center;
	}

	.table_list div:first-child {
		justify-content: flex-start;
	}

	.table_list div span {
		background: #5694F7;
		color: #fff;
		display: inline;
		padding: 5px 10px;
		border-radius: 3px;
	}
</style>
