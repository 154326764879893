<template>
  <div
    class="user_info_box"
    :style="pathName ? '' : 'background:none;box-shadow:none;'"
  >
    <div class="user_info_con">
      <!-- <div class="times_box" v-if="timesShow">
				<div class="tit"><span style="color: #fff;">总学习时长</span><img :src="userInfos.head_portrait?userInfos.head_portrait:noPic"></div>
				<div class="time">125,56h</div>
			</div>
			<div class="login_out" @click="loginOut"><img src="@/assets/images/index/loginout.png">退出登录</div> -->
      <div>
        <img
          src="@/assets/icon/icon_logo.png"
          class="images"
          mode=""
          @click="goindex"
        />
      </div>
      <div class="citysty" v-if="cateShowxx" @click="cityShow=true">
         {{ cityList[cityIndex].shortname }}
          <span>▼</span>
        </div>
      <div class="navListy">
        <div
          class="nav_box"
          v-for="(item, index) in topNav"
          :key="index"
          :class="topCurrent == index ? 'active' : ''"
          @click="gourl(item, index)"
          v-if="(userInfos&&item.name=='首页')?false:true"
        >
          {{ item.name }}
        </div>
        <!-- <div class="nav_box"
					 @click="toForum()">系统升级建议
				</div> -->
      </div>
      <div
        class="user_info"
        v-if="userInfos"
        @click="gourl({ noWin: true, url: '/userInfo' }, 99)"
      >
        <div class="user_pic">
         <!-- <div style="color:#ffffff;height:1px"> {{pathName.avatar}}</div> -->
          <img
            fit="contain"
            :src="pathName.avatar||userInfos.avatar"
            class="list_avatar"
            style="
              width: 50px;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          />
        </div>
        <div class="user_text">
          <div class="user_name">
            {{ pathName.name||userInfos.name }}
          </div>
          <!-- <div class="user_class">师途启航班——在职定制班（综合+教育）</div> -->
        </div>
        <div class="login_out" @click.stop="loginOut" title="退出登录">
          <img src="@/assets/images/index/loginout.png" />
        </div>
      </div>
      <div v-else style="display: flex;align-items: center;">
        <div
          style="    display: flex;    align-items: center;margin-right: 20px"
          @click="openKefu(1)"
        >
          <img
            src="@/assets/login/nav1.png"
            style="margin-right: 10px"
          />在线咨询
        </div>
        <div
          @click="goLogin()"
          style="display: flex;    align-items: center;margin-right: 20px"
        >
          去登录
        </div>

        <el-button
          v-if="showToLogin"
          @click="toLogin"
          type="primary"
          round
          style="height: 30px;display: flex;align-items: center;justify-content: center;"
        >
          登录</el-button
        >
      </div>
    </div>
    <div class="citiv" v-if="cityShow">
        <div class="citiv-cen">
        <div class="tiv-guicon" @click="cityShow=false">×</div>
           <div class="citiv-title">选择地区</div>
           <div class="citivlist">
              <div class="citiv-i" v-for="(its,index2) in cityList" :key="index2" @click="cityClick(index2)">
               <div :class="cityIndex==index2?'ciaacsty':''">{{ its.shortname }}</div>
              </div>
           </div>
        </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/eventBus/eventBus";
import { logout,getCityList} from "@/utils/erpapi.js";
import { Message } from "element-ui";
import { mapState } from 'vuex'; // 导入mapState辅助函数
export default {
  props: {
    timesShow: {
      type: Boolean,
      default: false,
    },
    cateShowxx:{
      type: Boolean,
      default: false,
    },
    cityIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState(['pathName']) // 将指定的状态名称添加到计算属性中
  },
  data() {
    return {
      showToLogin: false,
      cityShow:false,
      userInfos: [],
      cityList:[],
      userPic: "",
      topCurrent: 0,
      noPic:
        "https://www.sz517.com/upload/answer_image/20210729/9576015054b54ff779fbdc37342101b8.png",
      topNav: [
        {
          name: "首页",
          url: "/shopindex",
          noWin: true,
        },
        {
          name: "学习中心",
          url: "/erpindex",
          noWin: true,
        },
        // {
        //   name: "题库中心",
        //   url: "https://www.gongzhaotiku.com/#/",
        // },
        {
          name: "考试日程",
          url: "/schedule",
          noWin: true,
        },
        {
          name: "学习计划",
          url: "/studyplan",
          noWin: true,
        },
        {
          name: "个人中心",
          url: "/userInfo",
          noWin: true,
        },
        {
          name: "答疑服务",
          url: "/Doubt",
          noWin: true,
        },
      ],
      /* topNav: [{
					name: '首页',
					url: 'https://www.sz517.com/'
				}, {
					name: '招录信息',
					url: 'https://www.sz517.com/exam/index'
				}, {
					name: '热门信息',
					url: 'https://www.sz517.com/szwk-wk'
				}, {
					name: '备考指导',
					url: 'https://www.sz517.com/vdzl_bkzl'
				}, {
					name: '在线题库',
					url: 'https://tk.sz517.com/#/'
				}], */
    };
  },
  created() {
    let userinfo = localStorage.getItem("userInfo");
    this.userInfos = JSON.parse(userinfo);
    this.$route.path;
     this.getCityList()
    if (this.$route.path == "/userInfo") {
      this.showToLogin = true;
    }
    for (const i in this.topNav) {
      if (this.topNav[i].url == this.$route.path) {
        this.topCurrent = i;
      }
    }
  },
  mounted() {
    EventBus.$on("erpindex", (res) => {
      this.userInfos = res;
    });
  },
  methods: {
    cityClick(index){
        // this.cityIndex=index
        let data={
          index:index,
          id:this.cityList[index].id
        }
        this.cityShow=false
        this.$emit('actiChange',data)
    },
    getCityList(){
      getCityList().then(res=>{
         this.cityList=res.data
      })
    },
    openKefu(i) {
      if (i) {
        window.open(
          "https://tb.53kf.com/code/client/043988c5790032a0f9caef5a4257fd113/2?u_custom_info='学习平台咨询'"
        );
      } else {
        window.open("https://www.sz517.com/know/downloads.html");
      }
    },
    goLogin(){
      this.$router.push("/Login");
    },
    goindex() {
      this.$router.push("/erpindex");
      return;
      this.$router.push("/index");
      localStorage.setItem("tabbarChildCurrent", 0);
      localStorage.setItem("studyCurrent", 0);
      EventBus.$emit("tabbarCurrent", 0);
    },
    gourl(item, index) {
      if (!window.localStorage.getItem("UserToken")) {
        if(item.name=='首页'){
          this.$router.push("/shopindex");
          return
        }
        Message.error({
          message: "请登录！",
          showClose: true,
        });
        this.$router.push("/Login");
        return;
      }

      this.topCurrent = index;
      if (item.noWin) {
        this.$router.push(item.url);
      } else {
        window.open(item.url);
      }
    },
    toForum() {
      this.$router.push("/forum");
    },
    toLogin() {
      this.$router.push("/Login");
    },
    loginOut() {
      this.$confirm("是否要退出登录？", "提示", {
        confirmButtonText: "确定退出",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          logout().then((res) => {
            localStorage.removeItem("UserToken");
            localStorage.removeItem("userInfo");
            localStorage.removeItem("navListss");
            this.$emit("stop", "stop");
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.$router.push("/Login");
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
.images {
  width: 145px;
  height: 40px;
}
.user_info_box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #fff !important;
  height: 70px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  user-select: none;
}

.navListy {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 120px;
}

.navListy div {
  font-size: 18px;
  color: #666666;
  font-weight: bold;
  margin-right: 40px;
  cursor: pointer;
}
.nav_box:hover {
  color: #5694f7 !important;
}
.active {
  color: #5694f7 !important;
}

.user_info_con {
  width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user_info {
  /* flex: 1; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.user_info .user_pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 12px;
}

.user_pic img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.user_text .user_name {
  font-size: 16px;
  font-weight: bold;
}

.user_text .user_class {
  font-size: 12px;
  margin-top: 5px;
  color: #878c94;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.times_box {
  width: 230px;
  height: 60px;
  background: #6672f2;
  padding: 14px 30px;
  border-radius: 10px;
}

.times_box .tit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}

.times_box .time {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.login_out {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 30px;
  cursor: pointer;
}

.login_out img {
  width: 16px;
  height: 16px;
}
.citysty{
  margin-left: 20px;
  font-size: 16px !important;
  color: #333333;
  span{
    font-size: 12px !important;
    color: #999999;
  }
}
.citiv{
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000075;
  display: flex;
  align-items: center;
  justify-content: center;
  .citiv-cen{
    width: 900px;
    height: 400px;
    background: #ffffff;
    border-radius: 4px;
    padding: 20px;
    .citiv-title{
      color: #393f47;
    }
    .tiv-guicon{
      width: 100%;
      text-align: right;
      font-size: 34px;
      margin-top: -20px;
      color: #999999;
    }
    .citivlist{
      display: -webkit-flex;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-right: -10px;
      .citiv-i{
        width: 12.5%;
        height: 36px;
        text-align: center;
        line-height: 36px;
        box-sizing: border-box;
        margin-top: 20px;
        padding: 0 10px 0 10px;
        cursor: pointer;
        div{
          border: 1px solid #ebebeb;
          border-radius: 4px;
        }
        div:hover{
          background: #5694f7;
          cursor: pointer;
          border: 1px solid #5694f7;
          color: #ffffff;
        }
        .ciaacsty{
          background: #5694f7 !important;
          cursor: pointer !important;
          border: 1px solid #5694f7 !important;
          color: #ffffff !important;
        }
      }
    }
  }
}
</style>
